import React from 'react';
import Table from 'components/Table';
import columns from './columns';
import { useGetInventoryByArea } from 'modules/inventory';
import SearchInput from 'components/SearchInput';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const Inventory = ({ location }) => {
  const {
    data,
    count,
    loading,
    search,
    handleSearch,
    toggleIssues,
    ...tableProps
  } = useGetInventoryByArea(location);
  return (
    <Table
      title="Inventario"
      action={
        <>
          <FormControlLabel
            control={<Checkbox color="primary" name="issues" />}
            label="Incidencias"
            onChange={toggleIssues}
          />
          <SearchInput
            value={search}
            onChange={(event) => handleSearch(event.target.value)}
          />
        </>
      }
      columns={columns}
      data={data}
      loading={loading}
      count={count}
      {...tableProps}
    />
  );
};

export default Inventory;
