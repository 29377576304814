import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, colors } from '@material-ui/core';
import View from 'components/View';
import Loader from 'components/Loader';
import { useGetMaintenanceDetails } from 'modules/maintenances';
import Actions from './Actions';
import ConfirmDelete from './ConfirmDelete';
import Summary from './Summary';
import MaintenanceModal from 'views/MaintenanceModal';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const MaintenanceDetails = () => {
  const classes = useStyles();
  const { data, loading, error } = useGetMaintenanceDetails();
  return (
    <View
      title="Mantenimiento"
      loading={loading}
      error={error}
      subtitle={data?.maintenance ? `#${data.maintenance.id}` : ''}
      actions={<Actions maintenance={data?.maintenance} />}>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Summary maintenance={data?.maintenance} />
      </div>
      <ConfirmDelete />
      <MaintenanceModal />
    </View>
  );
};

export default MaintenanceDetails;
