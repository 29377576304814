import React from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';
import moment from 'moment';
import Link from 'components/Link';
import useClasses from 'hooks/useClasses';

const styles = theme => ({
  root: {
    minHeight: 300,
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 }
});

const Features = props => {
  const classes = useClasses(styles);
  const { organization_user, maintenance, due_to_date } = props;
  return (
    <Card className={classes.root}>
      <CardHeader title="Datos" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Mantenimiento</TableCell>
              <TableCell className={classes.data}>
                {maintenance.title ? (
                  <Link
                    title={maintenance.title}
                    to={`/maintenances/${maintenance.id}`}
                  />
                ) : (
                  'Desconocido'
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Elemento</TableCell>
              <TableCell className={classes.data}>
                {maintenance?.elemento_inventario ? (
                  <Link
                    title={maintenance.elemento_inventario.name}
                    to={`/inventory/${maintenance.elemento_inventario.id}`}
                  />
                ) : (
                  'Desconocido'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Responsable</TableCell>
              <TableCell className={classes.data}>
                {organization_user
                  ? `${organization_user.name} ${organization_user.surname}`
                  : 'Desconocido'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Vencimiento</TableCell>
              <TableCell className={classes.data}>
                {' '}
                {due_to_date
                  ? moment(due_to_date).format('DD/MM/YYYY')
                  : 'Desconocido'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Features;
