import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { WORKER_FRAGMENT } from '../fragments';

export const GET_WORKERS_BY_ORGANIZATION = gql`
  query getWorkersByOrganization($organizationId: Int!) {
    workers: organization_user(
      where: { organization_id: { _eq: $organizationId } }
      order_by: { id: asc }
    ) {
      ...workerFields
    }
  }

  ${WORKER_FRAGMENT}
`;

export const useGetWorkersByOrganization = (organizationId) => {
  const { data, error, loading } = useQuery(GET_WORKERS_BY_ORGANIZATION, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organizationId
    }
  });

  return {
    data,
    error,
    loading
  };
};
