import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { parseState } from 'utils/issues';
import { makeStyles } from '@material-ui/styles';
import List from './List';
import ListItem from './List/ListItem';
import { useGetIssuesByState } from 'modules/issues/graphql';
import Loader from 'components/Loader';
import { useIssuesMutation, useIssuesDispatch } from 'modules/issues';
import WorkOrderModal from 'views/WorkOrderModal';
import ErrorModal from 'views/ErrorModal';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(3),
    overflowX: 'auto'
  },
  container: {
    flexGrow: 1,
    padding: theme.spacing(0, 3, 3, 1),
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'wrap'
  }
}));

const Board = () => {
  const classes = useStyles();
  const { data, loading } = useGetIssuesByState();
  const { deleteWorkOrdersByIssue } = useIssuesMutation();
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const handleErrorModalClose = () => {
    setOpenErrorModal(false);
  };

  const handleDragEnd = event => {
    const { source, destination } = event;
    if (!destination) {
      return;
    }

    if (
      source.droppableId !== destination.droppableId &&
      destination.droppableId === 'inProgress'
    ) {
      //openWorkOrderModal({ issue: { id: Number(event.draggableId) } });
      deleteWorkOrdersByIssue({
        id: Number(event.draggableId),
        state: 'inProgress'
      });
    }
    if (
      source.droppableId !== destination.droppableId &&
      destination.droppableId === 'toDo'
    ) {
      deleteWorkOrdersByIssue({ id: Number(event.draggableId), state: 'toDo' });
    }
    if (destination.droppableId === 'done') {
      deleteWorkOrdersByIssue({ id: Number(event.draggableId), state: 'done' });
    }
  };

  return (
    <div className={classes.root}>
      {loading && <Loader />}
      {data && (
        <DragDropContext onDragEnd={handleDragEnd}>
          {Object.keys(data).map((list, index) => {
            const states = Object.keys(data);
            return (
              <Droppable droppableId={states[index]} key={states[index]}>
                {(provided, snapshot) => (
                  <List
                    provided={provided}
                    snapshot={snapshot}
                    title={parseState(states[index])}
                    total={data[states[index]].length}>
                    {data[states[index]].map((issue, index) => (
                      <Draggable
                        draggableId={String(issue.id)}
                        index={index}
                        key={issue.id}>
                        {(provided, snapshot) => (
                          <ListItem
                            provided={provided}
                            snapshot={snapshot}
                            issue={issue}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            );
          })}
        </DragDropContext>
      )}
      <WorkOrderModal />
      <ErrorModal
        open={openErrorModal}
        close={handleErrorModalClose}
        message={
          'Existen órdenes de trabajo sin completar. Hasta que no se finalicen, no podrá completar esta incidencia.'
        }
      />
    </div>
  );
};

export default Board;
