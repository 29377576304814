import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector';
import { createStructuredSelector } from 'reselect';

const selectedOrganizationSelector = (state) =>
  state.users.selectedOrganization;
const showOrganizationModalSelector = (state) =>
  state.users.showOrganizationModal;
const showDeleteOrganizationModalSelector = (state) =>
  state.users.showDeleteOrganizationModal;

const selectedWorkerSelector = (state) => state.users.selectedWorker;
const showWorkerModalSelector = (state) => state.users.showWorkerModal;
const showDeleteWorkerModalSelector = (state) =>
  state.users.showDeleteWorkerModal;

const selectedProfileSelector = (state) => state.users.selectedProfile;
const showProfileModalSelector = (state) => state.users.showProfileModal;
const showDeleteProfileModalSelector = (state) =>
  state.users.showDeleteProfileModal;
const showUpdateProfileModalSelector = (state) =>
  state.users.showUpdateProfileModal;
const selectedRolesSelector = (state) => state.users.selectedRoles;
const showWorkerUploadModalSelector = (state) =>
  state.users.showWorkerUploadModal;
const showOrganizationUploadModalSelector = (state) =>
  state.users.showOrganizationUploadModal;
const selectedFileSelector = (state) => state.users.selectedFile;

const alertSelector = (state) => state.users.alert;

const showWebUserModalSelector = (state) => state.users.showWebUserModal;

const selectors = createStructuredSelector({
  selectedOrganization: selectedOrganizationSelector,
  selectedWorker: selectedWorkerSelector,
  showOrganizationModal: showOrganizationModalSelector,
  showWorkerModal: showWorkerModalSelector,
  showDeleteOrganizationModal: showDeleteOrganizationModalSelector,
  showDeleteWorkerModal: showDeleteWorkerModalSelector,
  selectedProfile: selectedProfileSelector,
  showProfileModal: showProfileModalSelector,
  showDeleteProfileModal: showDeleteProfileModalSelector,
  showUpdateProfileModal: showUpdateProfileModalSelector,
  selectedRoles: selectedRolesSelector,
  showWorkerUploadModal: showWorkerUploadModalSelector,
  showOrganizationUploadModal: showOrganizationUploadModalSelector,
  alert: alertSelector,
  selectedFile: selectedFileSelector,
  showWebUserModal: showWebUserModalSelector
});

export function useUsersState() {
  return useShallowEqualSelector(selectors);
}
