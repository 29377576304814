import React from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button,
  Grid,
  TextField
} from '@material-ui/core';
import { useFormik } from 'formik';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import { useUsersMutation } from 'modules/users';

const InformationModal = ({
  fireOnClose = () => {},
  information = {},
  open,
  handleClose
}) => {
  const classes = useClasses(styles);

  const { user, city, country } = information;
  const { updateSettings } = useUsersMutation();
  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      name: user?.name || '',
      surname: user?.surname || '',
      email: user?.email || '',
      phone: user?.phone || '',
      city,
      country
    },
    onSubmit: async values => {
      const result = await updateSettings({ ...values });
      if (result) {
        fireOnClose();
      }
      handleClose();
    }
  });

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form}>
          <CardHeader
            title={
              <Typography component="h1" variant="h3">
                {'Editar perfil'}
              </Typography>
            }
            className={classes.header}
          />
          <Divider className={classes.divider} />
          <CardContent className={classes.content}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Nombre"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Apellidos"
                    name="surname"
                    onChange={handleChange}
                    value={values.surname}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Correo electrónico"
                    name="username"
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Teléfono"
                    name="phone"
                    onChange={handleChange}
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Ciudad"
                    name="city"
                    onChange={handleChange}
                    value={values.city}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    fullWidth
                    label="País"
                    name="country"
                    onChange={handleChange}
                    value={values.country}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </div>
          </CardContent>
          <Divider className={classes.divider} />
          <CardActions className={classes.actions}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              color="primary"
              type="submit"
              onClick={handleSubmit}
              variant="contained">
              {'Actualizar'}
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
};

export default InformationModal;
