import React from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button,
  Grid,
  TextField
} from '@material-ui/core';
import { Formik } from 'formik';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import { useToken } from 'modules/authentication/hooks/useToken';
import { useUsersState, useUsersDispatch } from 'modules/users';

const initialValues = {
  email: '',
  password: '',
  confirmPassword: ''
};

const WebUserModal = () => {
  const classes = useClasses(styles);
  const [token] = useToken();
  const { showWebUserModal, selectedWorker } = useUsersState();
  const {
    closeWebUserModal,
    openSuccessAlert,
    openErrorAlert
  } = useUsersDispatch();
  const updateWebUser = async ({ email, password }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + '/users/password',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token?.token}`
          },
          body: JSON.stringify({ email, password })
        }
      );
      if (response.status === 204) {
        openSuccessAlert('Acceso web actualizado correctamente.');
      } else {
        openErrorAlert(
          'Error al actualizar el acceso web del usuario. Consulte con el administrador'
        );
      }
    } catch (error) {
      openErrorAlert(error.msg);
      return {
        code: 500,
        message:
          error.msg ||
          `Error al actualizar el acceso web del usuario. Consulte con el administrador`
      };
    }
  };
  const handleClose = () => {
    closeWebUserModal();
  };

  return (
    <Modal onClose={handleClose} open={showWebUserModal}>
      <Card className={classes.root}>
        <Formik
          initialValues={
            selectedWorker ? { ...selectedWorker, password: '' } : initialValues
          }
          onSubmit={(values) => {
            if (
              values.password &&
              values.email &&
              values.password === values.confirmPassword
            ) {
              updateWebUser({
                email: values.email,
                password: values.password
              });
            }

            handleClose();
          }}>
          {({
            values: { email, password, confirmPassword },
            handleSubmit,
            handleChange
          }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardHeader
                title={
                  <Typography component="h1" variant="h3">
                    {'Usuario web'}
                  </Typography>
                }
                className={classes.header}
              />
              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        value={email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        type="password"
                        label="Contraseña"
                        name="password"
                        onChange={handleChange}
                        value={password}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        type="password"
                        label="Confirmar contraseña"
                        name="confirmPassword"
                        onChange={handleChange}
                        value={confirmPassword}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
              <Divider className={classes.divider} />
              <CardActions className={classes.actions}>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  disabled={password !== confirmPassword}>
                  {'Actualizar'}
                </Button>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

export default WebUserModal;
