import React, { useState, useEffect } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button,
  Tabs,
  Tab
} from '@material-ui/core';
import { Formik } from 'formik';
import {
  useIssuesState,
  useIssuesDispatch,
  useIssuesMutation
} from 'modules/issues';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import Summary from './Summary';
import Location from './Location';
import { useMapDispatch, useMapState } from 'modules/map';
import moment from 'moment';
import Images from './Images';
import { uploadAWS } from 'utils/files';

const initialValues = {
  title: '',
  description: '',
  date: moment(),
  state: 'toDo',
  solved_at: null,
  files: []
};

const tabs = [
  { value: 'summary', label: 'General' },
  { value: 'images', label: 'Imágenes' },
  { value: 'location', label: 'Localización' }
];

const IssueModal = () => {
  const classes = useClasses(styles);
  const [currentTab, setCurrentTab] = useState('summary');
  const { showIssueModal, selectedIssue } = useIssuesState();
  const { selectedFeature } = useMapState();
  const { addIssue, updateIssue } = useIssuesMutation();
  const { closeIssueModal } = useIssuesDispatch();
  const { selectFeature } = useMapDispatch();

  useEffect(() => {
    selectFeature(selectedIssue && selectedIssue.elemento_inventario);
  }, [selectedIssue]);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleClose = () => {
    setCurrentTab('summary');
    closeIssueModal();
  };

  const handleAddIssue = async values => {
    const upload = await Promise.all(values.files.map(file => uploadAWS(file)));
    addIssue([{ ...values, files: upload }]);
  };

  return (
    <Modal onClose={handleClose} open={showIssueModal}>
      <Card className={classes.root}>
        <Formik
          initialValues={
            selectedIssue
              ? selectedIssue
              : {
                  ...initialValues,
                  resource_id: selectedFeature && selectedFeature.id
                }
          }
          onSubmit={(values, { setSubmitting }) => {
            selectedIssue ? updateIssue({ ...values }) : handleAddIssue(values);
            setSubmitting(false);
            handleClose();
          }}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardHeader
                title={
                  <Typography component="h1" variant="h3">
                    {selectedIssue ? 'Editar incidencia' : 'Nueva incidencia'}
                  </Typography>
                }
                className={classes.header}
              />
              <Tabs
                className={classes.tabs}
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={currentTab}
                variant="scrollable"
                indicatorColor="primary">
                {tabs
                  .filter(tab => (selectedIssue ? tab.value !== 'images' : tab))
                  .map(tab => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
              </Tabs>
              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                {currentTab === 'summary' && <Summary />}
                {currentTab === 'location' && <Location />}
                {!selectedIssue && currentTab === 'images' && <Images />}
              </CardContent>
              <Divider className={classes.divider} />
              <CardActions className={classes.actions}>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained">
                  {selectedIssue ? 'Actualizar' : 'Guardar'}
                </Button>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

export default IssueModal;
