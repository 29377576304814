import React from 'react';
import { Typography } from '@material-ui/core';
import useClasses from 'hooks/useClasses';
const styles = theme => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(3)
  }
});

function Placeholder({ className }) {
  const classes = useClasses(styles);

  return (
    <div className={classes.root}>
      <Typography variant="subtitle2">
        Ninguna notificación pendiente
      </Typography>
    </div>
  );
}

export default Placeholder;
