import gql from 'graphql-tag';

export const SENSOR_FRAGMENT = gql`
  fragment sensorFields on sensor {
    id
    datastreams(order_by: [{ id: asc }], limit: 2) {
      id
      observation
      unit
      observation_values(order_by: { time: desc }) {
        id
        result
        time
      }
    }
  }
`;
