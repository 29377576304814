import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { useSensorsDispatch } from 'modules/sensors';
import useRouter from 'hooks/useRouter';
import { useMapDispatch } from 'modules/map';
import { useRoles } from 'modules/authentication';

const Actions = ({ sensor }) => {
  const { history } = useRouter();
  const { sensors__update, sensors__delete } = useRoles();
  const { openSensorModal, openDeleteSensorModal } = useSensorsDispatch();
  const { selectFeature } = useMapDispatch();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <Tooltip title="Acciones">
        <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <MenuItem component={Link} to={`/sensors/${sensor?.id}`}>
          <ListItemText primary="Ver" />
        </MenuItem>
        {sensors__update && (
          <MenuItem
            onClick={() => {
              openSensorModal(sensor);
              setOpenMenu(false);
            }}>
            <ListItemText primary="Editar" />
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            selectFeature(sensor);
            history.push('/global');
          }}>
          <ListItemText primary="Ubicación" />
        </MenuItem>
        {sensors__delete && (
          <MenuItem
            onClick={() => {
              openDeleteSensorModal(sensor);
              setOpenMenu(false);
            }}>
            <ListItemText primary="Eliminar" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

Actions.propTypes = {
  className: PropTypes.string
};

export default Actions;
