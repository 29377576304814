import React from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button
} from '@material-ui/core';
import { Formik } from 'formik';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import Summary from './Summary';
import {
  useUsersState,
  useUsersDispatch,
  useUsersMutation
} from 'modules/users';

const initialValues = {
  name: '',
  description: '',
  code: '',
  email: '',
  profiles: []
};

const OrganizationModal = () => {
  const classes = useClasses(styles);
  const { showOrganizationModal, selectedOrganization } = useUsersState();
  const { addOrganization, updateOrganization } = useUsersMutation();
  const { closeOrganizationModal } = useUsersDispatch();

  const handleClose = () => {
    closeOrganizationModal();
  };

  return (
    <Modal onClose={handleClose} open={showOrganizationModal}>
      <Card className={classes.root}>
        <Formik
          initialValues={
            selectedOrganization ? selectedOrganization : initialValues
          }
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            selectedOrganization
              ? await updateOrganization({ ...values })
              : await addOrganization({ ...values });
            setSubmitting(false);
            handleClose();
          }}>
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardHeader
                title={
                  <Typography component="h1" variant="h3">
                    {selectedOrganization
                      ? 'Editar organización'
                      : 'Nueva organización'}
                  </Typography>
                }
                className={classes.header}
              />

              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                <Summary organizationId={selectedOrganization?.id} />
              </CardContent>
              <Divider className={classes.divider} />
              <CardActions className={classes.actions}>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  disabled={isSubmitting}>
                  {selectedOrganization ? 'Actualizar' : 'Guardar'}
                </Button>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

export default OrganizationModal;
