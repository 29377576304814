import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import * as serviceWorker from 'modules/core/serviceWorker';
import { client, store, Root } from 'modules/core';
import locale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import theme from 'theme';
import Authentication from 'modules/authentication/hooks/useAuth';

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <Authentication>
            <Root />
          </Authentication>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
