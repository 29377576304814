import React from 'react';
import Table from 'components/Table';
import moment from 'moment';
import { parseUnit } from 'utils/sensors';

const DataTable = ({ datastream }) => {
  const { observation_values, unit } = datastream;
  const columns = [
    { Header: 'Valor', accessor: data => `${data.result}${parseUnit(unit)}` },
    {
      Header: 'Hora',
      accessor: data => moment(data.time).format('HH:mm:ss')
    },
    {
      Header: 'Fecha',
      accessor: data => moment(data.time).format('DD/MM/YYYY')
    }
  ];
  return (
    <Table
      columns={columns}
      data={observation_values}
      title="Registro de observaciones"
    />
  );
};

export default DataTable;
