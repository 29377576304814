import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector';
import { createStructuredSelector } from 'reselect';

const selectedMaintenanceSelector = state =>
  state.maintenances.selectedMaintenance;
const showMaintenanceModalSelector = state =>
  state.maintenances.showMaintenanceModal;
const showDeleteMaintenanceModallSelector = state =>
  state.maintenances.showDeleteMaintenanceModal;
const selectedTaskSelector = state => state.maintenances.selectedTask;
const showTaskSelector = state => state.maintenances.showTaskModal;
const showDeleteTaskModalSelector = state =>
  state.maintenances.showDeleteTaskModal;

const selectors = createStructuredSelector({
  selectedMaintenance: selectedMaintenanceSelector,
  showMaintenanceModal: showMaintenanceModalSelector,
  showDeleteMaintenanceModal: showDeleteMaintenanceModallSelector,
  selectedTask: selectedTaskSelector,
  showTaskModal: showTaskSelector,
  showDeleteTaskModal: showDeleteTaskModalSelector
});

export function useMaintenancesState() {
  return useShallowEqualSelector(selectors);
}
