import React from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button,
  Grid,
  TextField
} from '@material-ui/core';
import { useFormik } from 'formik';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import { useToken } from 'modules/authentication/hooks/useToken';

const SecurityModal = ({
  open,
  handleClose,
  setShowMessageSuccess = () => {},
  setShowMessageError = () => {}
}) => {
  const classes = useClasses(styles);
  const [token] = useToken();

  const updatePassword = async ({ oldPassword, password }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + '/users/me',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token?.token}`
          },
          body: JSON.stringify({ old_pass: oldPassword, new_pass: password })
        }
      );
      const body = await response.json();
      if (response.ok) {
        setShowMessageSuccess('Contraseña actualizada correctamente.');
        return body;
      }
      setShowMessageError(body.msg);
      return { code: response.status, message: body.msg };
    } catch (error) {
      setShowMessageError(error.msg);
      return {
        code: 500,
        message:
          error.msg ||
          `Error al actualizar al contraseña. Consulte con el administrador`
      };
    }
  };

  const { handleSubmit, handleChange, values, resetForm } = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      passwordConfirmation: ''
    },
    onSubmit: values => {
      if (values.password === values.passwordConfirmation) {
        updatePassword({
          password: values.password,
          oldPassword: values.oldPassword
        });
      }
      resetForm();
      handleClose();
    }
  });
  const { oldPassword, password, passwordConfirmation } = values;
  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form}>
          <CardHeader
            title={
              <Typography component="h1" variant="h3">
                {'Actualizar contraseña'}
              </Typography>
            }
            className={classes.header}
          />
          <Divider className={classes.divider} />
          <CardContent className={classes.content}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="password"
                    label="Contraseña antigua"
                    name="oldPassword"
                    onChange={handleChange}
                    value={oldPassword}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="password"
                    label="Contraseña nueva"
                    name="password"
                    onChange={handleChange}
                    value={password}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="password"
                    label="Confirmar contraseña"
                    name="passwordConfirmation"
                    onChange={handleChange}
                    value={passwordConfirmation}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </div>
          </CardContent>
          <Divider className={classes.divider} />
          <CardActions className={classes.actions}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              color="primary"
              type="submit"
              onClick={handleSubmit}
              variant="contained">
              {'Actualizar'}
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
};

export default SecurityModal;
