import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Environment from './Environment';
import Dentrometry from './Dentrometry';
import State from './State';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Tree = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item lg={4} md={6} xs={12}>
        <State />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <Environment />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <Dentrometry />
      </Grid>
    </Grid>
  );
};

export default Tree;
