import gql from 'graphql-tag';
import {
  VEGETATION_FRAGMENT,
  TREE_FRAGMENT,
  BUSH_FRAGMENT,
  CARPET_FRAGMENT,
  FURNITURE_FRAGMENT,
  SURFACE_FRAGMENT,
  PLAYGROUND_FRAGMENT
} from 'modules/inventory/graphql/fragments';

export const MAINTENANCE_FRAGMENT = gql`
  fragment maintenanceFields on maintenance {
    id
    type
    priority
    state
    organization_id
    created_by_user_id
    processed_by_user_id
    resource_id
    description
    title
    created_at
    updated_at
    scheduled_at
    elemento_inventario {
      id
      name
      location
      inventoryItemType
      areaByUdAdminId {
        id
        name
      }
      areaByUdGestionId {
        id
        name
      }
      area {
        id
        name
      }
      address
      addressNumber
      vegetacion_inventariables {
        ...vegetationFields
        arbusto_inventariables {
          ...bushFields
        }
        tapiz_inventariables {
          ...carpetFields
        }
        arbol_inventariables {
          ...treeFields
        }
        palmera_inventariables {
          tipo_hoja
        }
      }
      mobiliario_urbanos {
        ...furnitureFields
      }
      surfaces {
        ...surfaceFields
      }
      playgrounds {
        ...playgroundFields
      }
    }
    informant: organization_user {
      id
      name
      surname
      file {
        id
        name
        url
      }
    }
    processor: organizationUserByProcessedByUserId {
      id
      name
      surname
    }
    organization {
      id
      name
      file {
        id
        name
        url
      }
    }
  }
  ${VEGETATION_FRAGMENT}
  ${FURNITURE_FRAGMENT}
  ${TREE_FRAGMENT}
  ${BUSH_FRAGMENT}
  ${CARPET_FRAGMENT}
  ${SURFACE_FRAGMENT}
  ${PLAYGROUND_FRAGMENT}
`;
