import React from 'react';
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Divider
} from '@material-ui/core';
const Observations = ({ observations }) => {
  return (
    <Card>
      <CardHeader title="Observaciones" />
      <Divider />
      <CardContent>
        <Typography variant="body1">{observations}</Typography>
      </CardContent>
    </Card>
  );
};

export default Observations;
