import gql from 'graphql-tag';

export const FURNITURE_FRAGMENT = gql`
  fragment furnitureFields on mobiliario_urbano {
    subtipo_mobil_urban {
      id
      nombre
    }
    furnitureTypeId
    manufacturer
    model
    hydraulic
    integration
    electric
    installationDate
    lastRepairDate
  }
`;
