import React from 'react';

const initialValues = {
  limit: 10,
  offset: 0,
  count: 0
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'LIMIT':
      return { ...state, limit: payload };
    case 'OFFSET':
      return { ...state, offset: payload };
    case 'COUNT':
      return { ...state, count: payload };
    case 'PREVIOUS':
      return { ...state, offset: state.offset - payload };
    case 'NEXT':
      return { ...state, offset: payload + state.offset };
    case 'RESET':
      return { ...state, limit: 10, offset: 0 };
    default:
      return state;
  }
};

export const usePagination = () => {
  const [{ limit, offset, count }, dispatch] = React.useReducer(reducer, {
    ...initialValues
  });

  const setLimit = payload => dispatch({ type: 'LIMIT', payload });
  const setOffset = payload => dispatch({ type: 'OFFSET', payload });
  const handlePrevious = payload => dispatch({ type: 'PREVIOUS', payload });
  const handleNext = payload => dispatch({ type: 'NEXT', payload });
  const setCount = payload => dispatch({ type: 'COUNT', payload });
  const resetPagination = () => dispatch({ type: 'RESET' });

  return {
    setLimit,
    setOffset,
    limit,
    offset,
    count,
    handlePrevious,
    handleNext,
    setCount,
    resetPagination
  };
};

export default usePagination;
