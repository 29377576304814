import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Typography
} from '@material-ui/core';
import useClasses from 'hooks/useClasses';
import { inventoryTypes } from 'utils/inventory';
import useRouter from 'hooks/useRouter';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '60px',
    right: '10px',
    maxWidth: '320px'
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  },
  placeholder: {
    textAlign: 'center',
    padding: theme.spacing(3, 0, 0, 0)
  }
});

const Inventory = ({
  inventoryWithIssues = [],
  inventoryWithoutIssues = []
}) => {
  const classes = useClasses(styles);
  const { history } = useRouter();
  return (
    <List>
      {inventoryWithoutIssues.length > 0 || inventoryWithIssues.length > 0 ? (
        [...inventoryWithIssues, ...inventoryWithoutIssues].map(item => {
          const { id, name, inventoryItemType, issues } = item;
          return (
            <ListItem
              button
              divider
              key={id}
              onClick={() =>
                history.push(
                  `${
                    inventoryItemType !== 'sensor' ? 'inventory' : 'sensors'
                  }/${id}`
                )
              }>
              <ListItemText
                className={classes.listItemText}
                primary={name}
                primaryTypographyProps={{ variant: 'h6', noWrap: true }}
                secondary={inventoryTypes[inventoryItemType]}
              />
              <Tooltip
                title={
                  issues?.aggregate?.count > 0
                    ? 'Incidencias pendientes'
                    : 'Sin incidencias'
                }>
                <ListItemIcon>
                  <PriorityHighIcon
                    color={issues?.aggregate?.count > 0 ? 'error' : 'disabled'}
                  />
                </ListItemIcon>
              </Tooltip>
            </ListItem>
          );
        })
      ) : (
        <div className={classes.placeholder}>
          <Typography variant="subtitle2">
            Ningún elemento que mostrar
          </Typography>
        </div>
      )}
    </List>
  );
};

export default Inventory;
