import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_CHAT_LIST } from '../queries/getChatList';
import { GET_CHAT_USERS } from '../queries/getChatUsers';

export const DELETE_CHAT = gql`
  mutation deleteChat($chatId: Int!) {
    delete_chat_users(where: { chat_id: { _eq: $chatId } }) {
      affected_rows
    }
    delete_message(where: { chat_id: { _eq: $chatId } }) {
      affected_rows
    }
    delete_chat(where: { id: { _eq: $chatId } }) {
      affected_rows
    }
  }
`;

export const useDeleteChat = () => {
  let [mutate] = useMutation(DELETE_CHAT);
  return ({ chatId, userId }) => {
    return mutate({
      variables: {
        chatId
      },
      refetchQueries: [
        { query: GET_CHAT_LIST, variables: { id: userId } },
        { query: GET_CHAT_USERS, variables: { id: userId } }
      ]
    });
  };
};
