import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  GET_ISSUES,
  GET_ISSUES_BY_INVENTORY_ITEM,
  GET_ISSUES_BY_STATE
} from '../queries';
import { useIssuesDispatch } from 'modules/issues/hooks';
import { useInfo } from 'modules/authentication';
import { useHistory } from 'react-router-dom';
export const ADD_ISSUE = gql`
  mutation addIssue($objects: [issue_insert_input!]!) {
    insert_issue(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const useAddIssue = () => {
  const { userId } = useInfo();
  const history = useHistory();
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(ADD_ISSUE, {
    onCompleted: (data) => {
      history.push(`/issues/${data.insert_issue.returning[0].id}`);
      openSuccessAlert('Incidencia creada correctamente.');
    },
    onError: () => openErrorAlert(error?.message)
  });

  return (args) => {
    return mutate({
      variables: {
        objects: args.map(
          ({
            title,
            description,
            date,
            processed_by_user_id,
            resource_id,
            state,
            files
          }) => {
            return {
              title,
              description,
              date,
              issuer_user_id: userId,
              processed_by_user_id,
              resource_id,
              state,
              files: files ? { data: files } : undefined
            };
          }
        )
      },
      refetchQueries: [
        { query: GET_ISSUES },
        { query: GET_ISSUES_BY_STATE },
        {
          query: GET_ISSUES_BY_INVENTORY_ITEM,
          variables: { inventoryItemId: args[0].resource_id }
        }
      ]
    });
  };
};
