import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Information from './Information';
import Avatar from './Avatar';
import Security from './Security';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader';

const useStyles = makeStyles(() => ({
  root: {}
}));

const General = ({ settings }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item lg={3} xs={12}>
        <Avatar {...settings} />
      </Grid>
      <Grid item lg={4} xs={12}>
        <Information {...settings} />
      </Grid>
      <Grid item lg={4} xs={12}>
        <Security />
      </Grid>
    </Grid>
  );
};

export default General;
