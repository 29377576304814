import gql from 'graphql-tag';
import { VEGETATION_FRAGMENT } from 'modules/inventory/graphql/fragments';

export const WORK_ORDER_FRAGMENT = gql`
  fragment workOrderFields on work_order {
    id
    title
    description
    issue_id
    organization_id
    priority
    processed_by_user_id
    state
    due_to_date
    organization {
      name
      file {
        id
        name
        url
      }
    }
    issue {
      id
      title
      elemento_inventario {
        id
        name
        location
        inventoryItemType
        areaByUdAdminId {
          id
          name
        }
        areaByUdGestionId {
          id
          name
        }
        area {
          id
          name
        }
        address
        addressNumber
        vegetacion_inventariables {
          ...vegetationFields
        }
      }
    }
  }
  ${VEGETATION_FRAGMENT}
`;
