import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import View from 'components/View';
import Loader from 'components/Loader';
import { useGetIssueDetails } from 'modules/issues/graphql';
import Actions from './Actions';
import ConfirmDelete from './ConfirmDelete';
import Summary from './Summary';
import IssueModal from 'views/IssueModal';
import WorkOrderModal from 'views/WorkOrderModal';
import WorkOrders from './WorkOrders';
import Alert from 'components/Alert';
import { useIssuesState, useIssuesDispatch } from 'modules/issues';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const IssueDetails = () => {
  const classes = useStyles();
  const { data, loading } = useGetIssueDetails();
  const { alert } = useIssuesState();
  const { closeAlert } = useIssuesDispatch();
  const [currentTab, setCurrentTab] = useState('summary');
  const tabs = [
    { value: 'summary', label: 'Información' },
    { value: 'workOrders', label: 'Órdenes de trabajo' }
  ];

  const handleTabsChange = (event, value) => {
    event.preventDefault();
    setCurrentTab(value);
  };

  return (
    <View
      title="Incidencia"
      subtitle={`#${!loading && data && data.issue ? data.issue.id : ''}`}
      actions={<Actions issue={data && data.issue} />}>
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={currentTab}
        variant="scrollable"
        indicatorColor="primary">
        {tabs.map(tab => {
          const { value, label } = tab;
          return <Tab key={value} label={label} value={value} />;
        })}
      </Tabs>
      <Divider className={classes.divider} />
      {loading && <Loader />}
      {data && data.issue && (
        <div className={classes.content}>
          {currentTab === 'summary' && <Summary issue={data.issue} />}
          {currentTab === 'workOrders' && (
            <WorkOrders issueId={data.issue.id} />
          )}
        </div>
      )}

      <ConfirmDelete />
      <IssueModal />
      <WorkOrderModal />
      <Alert
        show={alert}
        message={alert?.message}
        severity={alert?.severity}
        fireOnClose={() => closeAlert()}
      />
    </View>
  );
};

export default IssueDetails;
