import React from 'react';
import img from 'assets/images/avatar2.jpg';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Avatar, Typography } from '@material-ui/core';
import { useUsersDispatch } from 'modules/users';

const useStyles = makeStyles(theme => ({
  root: { height: '100%' },
  content: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlgin: 'center'
  },
  name: {
    padding: theme.spacing(1)
  },
  organization: {
    padding: theme.spacing(0)
  },
  avatar: {
    height: 200,
    width: 200
  }
}));

const Profile = ({ worker }) => {
  const classes = useStyles();
  const { openWorkerUploadModal } = useUsersDispatch();
  if (!worker) return null;
  const { name, surname, profile } = worker;
  return (
    <Card className={classes.root}>
      <CardContent
        className={classes.content}
        onClick={() => openWorkerUploadModal({ worker, file: worker?.file })}>
        <Avatar className={classes.avatar} src={worker?.file?.url} />
        <Typography className={classes.name} gutterBottom variant="h3">
          {`${name} ${surname}` || 'Desconocido'}
        </Typography>
        <Typography className={classes.organization} gutterBottom variant="h5">
          {profile?.name || 'Desconocido'}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Profile;
