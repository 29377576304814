import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import Filter from './Filter';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingBottom: theme.spacing(1)
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480
  },
  filterButton: {
    marginLeft: 'auto'
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  }
}));

function SearchBar({ className, datastream }) {
  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false);

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Button
        className={classes.filterButton}
        onClick={handleFilterOpen}
        size="large"
        variant="text">
        <FilterListIcon className={classes.filterIcon} /> Filtrar
      </Button>

      <Filter
        onClose={handleFilterClose}
        open={openFilter}
        datastream={datastream}
      />
    </div>
  );
}

SearchBar.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func
};

export default SearchBar;
