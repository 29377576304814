import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button
} from '@material-ui/core';
import {
  useIssuesState,
  useIssuesDispatch,
  useIssuesMutation
} from 'modules/issues';
import useRouter from 'hooks/useRouter';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    height: 200
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ConfirmDelete = () => {
  const classes = useStyles();
  const { showDeleteReportModal, selectedReport } = useIssuesState();
  const { deleteReport } = useIssuesMutation();
  const { closeDeleteReportModal } = useIssuesDispatch();

  if (!showDeleteReportModal) {
    return null;
  }

  return (
    <Modal onClose={closeDeleteReportModal} open={showDeleteReportModal}>
      <Card className={classes.root}>
        <CardHeader title="¿Eliminar reporte?" />
        <Divider />
        <CardContent>
          <Typography variant="body1">
            Si elimina el reporte seleccionado. No podrá recuperarlo más tarde.
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button onClick={closeDeleteReportModal}>Cancelar</Button>
          <Button
            color="secondary"
            onClick={async () => {
              await deleteReport({
                id: selectedReport.id,
                taskId: selectedReport.task_id
              });
              closeDeleteReportModal();
            }}
            variant="contained">
            Sí, eliminar
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ConfirmDelete;
