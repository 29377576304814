import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button
} from '@material-ui/core';

import {
  useUsersState,
  useUsersMutation,
  useUsersDispatch
} from 'modules/users';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    height: 200
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ConfirmUpdate = ({ fireOnClose = () => {} }) => {
  const classes = useStyles();
  const {
    selectedProfile,
    selectedRoles,
    showUpdateProfileModal
  } = useUsersState();
  const { updateRolesByProfile } = useUsersMutation();
  const { closeUpdateProfileModal } = useUsersDispatch();

  if (!showUpdateProfileModal && !selectedProfile && !selectedRoles) {
    return null;
  }
  return (
    <Modal onClose={closeUpdateProfileModal} open={showUpdateProfileModal}>
      <Card className={classes.root}>
        <CardHeader title="¿Actualizar permisos?" />
        <Divider />
        <CardContent>
          <Typography variant="body1">
            Si actualiza los permisos de este perfil, es posible que el acceso a
            la aplicación sea diferente. ¿Está seguro?
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button onClick={closeUpdateProfileModal}>Cancelar</Button>
          <Button
            color="secondary"
            onClick={async () => {
              await updateRolesByProfile({
                profileId: selectedProfile.id,
                roles: [...selectedRoles]
              });
              fireOnClose();
              closeUpdateProfileModal();
            }}
            variant="contained">
            Sí, actualizar
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ConfirmUpdate;
