import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    minHeight: 300,
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 }
}));

const Summary = ({
  ground_type,
  description,
  surface_m2,
  topography,
  typology,
  location_type
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title="Datos" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Suelo</TableCell>
              <TableCell className={classes.data}>
                {ground_type?.name || 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Topografía</TableCell>
              <TableCell className={classes.data}>
                {topography || 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tipología</TableCell>
              <TableCell className={classes.data}>
                {typology || 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Localización</TableCell>
              <TableCell className={classes.data}>
                {location_type || 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Superficie</TableCell>
              <TableCell className={classes.data}>
                {surface_m2 ? `${surface_m2} m2` : 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Descripción</TableCell>
              <TableCell className={classes.data}>{description}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Summary;
