import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Alert from 'components/Alert';
import ErrorBoundary from 'components/ErrorBoundary';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    marginTop: theme.spacing(1),
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}));

const View = ({ title, subtitle, actions, children, loading, error }) => {
  const classes = useStyles();
  if (loading) return <Loader />;
  if (error) return <Alert show={error} message={error.message} />;
  return (
    <ErrorBoundary>
      <Container maxWidth="xl" className={classes.root}>
        {title && (
          <Header title={title} subtitle={subtitle}>
            {actions}
          </Header>
        )}
        <div className={classes.content}>{children}</div>
      </Container>
    </ErrorBoundary>
  );
};

export default View;
