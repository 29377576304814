import gql from 'graphql-tag';

export const TASK_FRAGMENT = gql`
  fragment taskFields on work_order_task {
    id
    description
    due_to_date
    in_charge_user_id
    state
    title
    work_order_id
    workOrder: work_order {
      id
      title
      description
      issue_id
      organization_id
      priority
      processed_by_user_id
      state
      due_to_date
      organization {
        name
      }
      issue {
        id
        title
        elemento_inventario {
          id
          name
          location
          inventoryItemType
          areaByUdAdminId {
            id
            name
          }
          areaByUdGestionId {
            id
            name
          }
          area {
            id
            name
          }
          address
          addressNumber
        }
      }
    }
    organization_user {
      id
      name
      surname
    }
  }
`;
