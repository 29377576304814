import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Toolbar, Input, IconButton, Divider, List } from '@material-ui/core';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ChatListItem from './ChatListItem';
import { useGetChatList } from 'modules/chat';
import useRouter from 'hooks/useRouter';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white
  },
  searchInput: {
    flexGrow: 1
  }
}));

const ChatList = ({ className }) => {
  const classes = useStyles();
  const { data } = useGetChatList();
  const [chats, setChats] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setChats(data?.chats);
  }, [data]);

  const handleChange = event => {
    event.preventDefault();
    setSearch(event.target.value);
    let newChats = data?.chats?.filter(chat => {
      let user = `${chat?.users[0]?.organization_user?.name} ${chat?.users[0]?.organization_user?.surname}`;
      return user.toLowerCase().includes(event.target.value);
    });
    setChats(newChats);
  };

  const handleReset = () => {
    setSearch('');
    setChats(data?.chats);
  };

  const { match } = useRouter();
  return (
    <div className={clsx(classes.root, className)}>
      <Toolbar>
        <Input
          className={classes.searchInput}
          disableUnderline
          placeholder="Buscar usuario"
          onChange={handleChange}
          value={search}
        />
        {search ? (
          <IconButton edge="end" onClick={handleReset}>
            <ClearIcon />
          </IconButton>
        ) : (
          <IconButton edge="end">
            <SearchIcon />
          </IconButton>
        )}
      </Toolbar>
      <Divider />
      <List disablePadding>
        {chats?.map((chat, i) => (
          <ChatListItem
            active={chat.id === Number(match?.params?.id)}
            chat={chat}
            divider={i < data.chats.length - 1}
            key={chat.id}
          />
        ))}
      </List>
    </div>
  );
};

export default ChatList;
