import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Tabs,
  Tab
} from '@material-ui/core';
import Actions from './Actions';
import { useGetInventoryItemDetails } from 'modules/inventory/graphql';
import { inventoryTypes } from 'utils/inventory';
import Summary from './Summary';
import Issues from './Issues';
import { useRoles } from 'modules/authentication';
import Loader from 'components/Loader';
import { checkAuth } from 'utils/roles';
import Maintenances from './Maintenances';
import Alert from 'components/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '60px',
    right: '10px',
    maxWidth: '380px'
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const FeaturePanel = ({ feature }) => {
  const {
    issues__solver,
    issues__administrator,
    issues__generator,
    maintenances__solver,
    maintenances__administrator
  } = useRoles();
  const { data, loading, error } = useGetInventoryItemDetails(feature);
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('summary');
  const tabs = [
    { value: 'summary', label: 'Información' },
    {
      value: 'issues',
      label: 'Incidencias',
      auth: [issues__solver, issues__administrator, issues__generator]
    },
    {
      value: 'maintenances',
      label: 'Mantenimientos',
      auth: [, maintenances__solver, maintenances__administrator]
    }
  ];
  const handleTabsChange = (event, value) => {
    event.preventDefault();
    setCurrentTab(value);
  };
  if (loading) return <Loader />;
  if (error) return <Alert error={error.message} show={error} />;
  const { inventoryItem } = data;
  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          inventoryTypes[inventoryItem?.inventoryItemType] || 'Desconocido'
        }
        action={<Actions feature={inventoryItem} />}
      />
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={currentTab}
        variant="scrollable"
        indicatorColor="primary">
        {tabs
          .filter(tab => checkAuth(tab.auth))
          .map(tab => {
            const { value, label } = tab;
            return <Tab key={value} label={label} value={value} />;
          })}
      </Tabs>
      <Divider />
      <CardContent className={classes.content}>
        {currentTab === 'summary' && <Summary {...inventoryItem} />}
        {currentTab === 'issues' && <Issues {...inventoryItem} />}
        {currentTab === 'maintenances' && <Maintenances {...inventoryItem} />}
      </CardContent>
    </Card>
  );
};

export default FeaturePanel;
