import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  arrowForwardIcon: {
    color: theme.palette.icon
  }
}));

function NotificationList({ notifications, className, onClose, ...rest }) {
  const classes = useStyles();
  return (
    <List {...rest} className={classes.root} disablePadding>
      {notifications.map((notification, i) => (
        <ListItem
          component={RouterLink}
          className={classes.listItem}
          divider={i < notifications.length - 1}
          key={notification.id}
          onClick={onClose}
          to={`/issues/${notification.id}`}>
          <ListItemIcon style={{ justifyContent: 'center' }}>
            <ErrorOutlineIcon color="error" />
          </ListItemIcon>
          <ListItemText
            primary={notification.title}
            primaryTypographyProps={{ variant: 'body1' }}
            secondary={moment(notification.date).fromNow()}
          />
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </ListItem>
      ))}
    </List>
  );
}

export default NotificationList;
