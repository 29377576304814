import { useDispatch } from 'react-redux';
import * as slices from '../redux';

export function useIssuesDispatch() {
  const dispatch = useDispatch();
  return {
    loadIssues: () => dispatch(slices.loadIssues()),
    selectIssue: payload => dispatch(slices.selectIssue(payload)),
    openIssueModal: payload => dispatch(slices.openIssueModal(payload)),
    closeIssueModal: () => dispatch(slices.closeIssueModal()),
    openDeleteIssueModal: payload =>
      dispatch(slices.openDeleteIssueModal(payload)),
    closeDeleteIssueModal: () => dispatch(slices.closeDeleteIssueModal()),
    openWorkOrderModal: payload => dispatch(slices.openWorkOrderModal(payload)),
    closeWorkOrderModal: () => dispatch(slices.closeWorkOrderModal()),
    openDeleteWorkOrderModal: payload =>
      dispatch(slices.openDeleteWorkOrderModal(payload)),
    closeDeleteWorkOrderModal: () =>
      dispatch(slices.closeDeleteWorkOrderModal()),
    selectWorkOrder: () => dispatch(slices.selectWorkOrder()),
    openTaskModal: payload => dispatch(slices.openTaskModal(payload)),
    closeTaskModal: () => dispatch(slices.closeTaskModal()),
    openDeleteTaskModal: payload =>
      dispatch(slices.openDeleteTaskModal(payload)),
    closeDeleteTaskModal: () => dispatch(slices.closeDeleteTaskModal()),
    openReportModal: payload => dispatch(slices.openReportModal(payload)),
    closeReportModal: () => dispatch(slices.closeReportModal()),
    openDeleteReportModal: payload =>
      dispatch(slices.openDeleteReportModal(payload)),
    closeDeleteReportModal: () => dispatch(slices.closeDeleteReportModal()),
    openIssueUploadModal: payload =>
      dispatch(slices.openIssueUploadModal(payload)),
    closeIssueUploadModal: () => dispatch(slices.closeIssueUploadModal()),
    uploadImage: payload => dispatch(slices.uploadImage(payload)),
    openErrorAlert: payload => dispatch(slices.openErrorAlert(payload)),
    closeAlert: () => dispatch(slices.closeAlert()),
    openSuccessAlert: payload => dispatch(slices.openSuccessAlert(payload))
  };
}
