import React from 'react';

const Tree = (props) => {
  return (
    <svg width="50" height="50" viewBox="0 0 256 256" fill="none" {...props}>
      <path
        d="M142.856 248H109.157C107.541 248 106.231 246.69 106.231 245.074V82.298H145.782V245.075C145.782 246.69 144.472 248 142.856 248Z"
        fill="#A56021"
      />
      <path
        d="M136.782 242C135.125 242 133.782 240.657 133.782 239V206C133.782 204.343 135.125 203 136.782 203C138.439 203 139.782 204.343 139.782 206V239C139.782 240.657 138.439 242 136.782 242Z"
        fill="#BF6C0D"
      />
      <path
        d="M127.231 245.074V82.297H106.231V245.073C106.231 246.689 107.541 247.999 109.157 247.999H130.157C128.541 248 127.231 246.69 127.231 245.074Z"
        fill="#8C4C17"
      />
      <path
        d="M180.821 138.925H180.653H172.747H171.233H149.03H148.862H140.956H139.442H115.22H107.146H105.632H83.43H75.356C74.414 138.925 73.555 139.427 73.137 140.227C69.655 146.884 54.712 176.692 48.408 189.282C47.096 191.903 49.114 194.923 52.177 194.923H73.843H77.375H105.634H111.185H113.203H142.975H144.993H148.862H178.803H180.653H203.824C206.93 194.923 208.946 191.824 207.549 189.197L180.821 138.925Z"
        fill="#CCC033"
      />
      <path
        d="M195.618 188.924C194.544 188.924 193.506 188.346 192.967 187.332L172.765 149.334C171.987 147.871 172.542 146.055 174.005 145.277C175.469 144.501 177.284 145.056 178.062 146.518L198.264 184.516C199.042 185.979 198.487 187.795 197.024 188.573C196.575 188.811 196.093 188.924 195.618 188.924Z"
        fill="#EACF32"
      />
      <path
        d="M102.02 85.426L143.002 26.94L132.732 10.28C130.868 7.25701 125.45 7.23601 123.55 10.245L113.08 26.829L72.02 85.426C70.867 87.072 72.306 89.13 74.61 89.13H104.61C102.306 89.131 100.867 87.072 102.02 85.426Z"
        fill="#FCDA4F"
      />
      <path
        d="M78.407 189.282C84.712 176.692 99.654 146.884 103.136 140.227C103.554 139.428 104.413 138.925 105.355 138.925H83.43H75.356C74.414 138.925 73.555 139.427 73.137 140.227C69.655 146.884 54.712 176.692 48.408 189.282C47.096 191.903 49.115 194.923 52.177 194.923H73.843H77.375H82.177C79.114 194.923 77.095 191.903 78.407 189.282Z"
        fill="#99AF17"
      />
      <path
        d="M167.689 85.19H167.508H159.011H157.384H97.179H88.501C87.488 85.19 86.565 85.692 86.116 86.492C82.373 93.149 66.312 122.957 59.536 135.547C58.125 138.168 60.295 141.188 63.587 141.188H86.874H127.011H129.181H167.509H192.414C195.752 141.188 197.919 138.089 196.418 135.462L167.689 85.19Z"
        fill="#EACF32"
      />
      <path
        d="M89.535 135.546C96.312 122.956 112.373 93.148 116.115 86.491C116.564 85.692 117.488 85.189 118.5 85.189H97.179H88.501C87.488 85.189 86.565 85.691 86.116 86.491C82.373 93.148 66.312 122.956 59.536 135.546C58.126 138.167 60.295 141.187 63.587 141.187H86.874H93.587C90.294 141.188 88.125 138.167 89.535 135.546Z"
        fill="#CCC033"
      />
      <path
        d="M183.98 85.426L143.028 26.982L132.732 10.28C130.868 7.25701 125.45 7.23601 123.55 10.245L113.08 26.829L72.02 85.426C70.867 87.072 72.306 89.13 74.61 89.13H181.39C183.694 89.131 185.133 87.072 183.98 85.426Z"
        fill="#FCDA4F"
      />
      <path
        d="M102.02 85.426L143.002 26.94L132.732 10.28C130.868 7.25701 125.45 7.23601 123.55 10.245L113.08 26.829L72.02 85.426C70.867 87.072 72.306 89.13 74.61 89.13H104.61C102.306 89.131 100.867 87.072 102.02 85.426Z"
        fill="#EACF32"
      />
      <path
        d="M100.308 188.563C106.349 188.563 111.246 183.666 111.246 177.625C111.246 171.584 106.349 166.687 100.308 166.687C94.2671 166.687 89.37 171.584 89.37 177.625C89.37 183.666 94.2671 188.563 100.308 188.563Z"
        fill="#99AF17"
      />
      <path
        d="M110.808 137.563C116.849 137.563 121.746 132.666 121.746 126.625C121.746 120.584 116.849 115.687 110.808 115.687C104.767 115.687 99.87 120.584 99.87 126.625C99.87 132.666 104.767 137.563 110.808 137.563Z"
        fill="#CCC033"
      />
    </svg>
  );
};
export const SelectedTree = (props) => (
  <svg width="50" height="50" viewBox="0 0 256 256" fill="none" {...props}>
    <g style={{ mixBlendMode: 'multiply' }}>
      <ellipse cx="125" cy="242" rx="66" ry="11" fill="#249A77" />
      <path
        d="M142.856 248H109.157C107.541 248 106.231 246.69 106.231 245.074V82.298H145.782V245.075C145.782 246.69 144.472 248 142.856 248Z"
        fill="#A56021"
      />
      <path
        d="M136.782 242C135.125 242 133.782 240.657 133.782 239V206C133.782 204.343 135.125 203 136.782 203C138.439 203 139.782 204.343 139.782 206V239C139.782 240.657 138.439 242 136.782 242Z"
        fill="#BF6C0D"
      />
      <path
        d="M127.231 245.074V82.297H106.231V245.073C106.231 246.689 107.541 247.999 109.157 247.999H130.157C128.541 248 127.231 246.69 127.231 245.074Z"
        fill="#8C4C17"
      />
      <path
        d="M180.821 138.925H180.653H172.747H171.233H149.03H148.862H140.956H139.442H115.22H107.146H105.632H83.43H75.356C74.414 138.925 73.555 139.427 73.137 140.227C69.655 146.884 54.712 176.692 48.408 189.282C47.096 191.903 49.114 194.923 52.177 194.923H73.843H77.375H105.634H111.185H113.203H142.975H144.993H148.862H178.803H180.653H203.824C206.93 194.923 208.946 191.824 207.549 189.197L180.821 138.925Z"
        fill="#CCC033"
      />
      <path
        d="M195.618 188.924C194.544 188.924 193.506 188.346 192.967 187.332L172.765 149.334C171.987 147.871 172.542 146.055 174.005 145.277C175.469 144.501 177.284 145.056 178.062 146.518L198.264 184.516C199.042 185.979 198.487 187.795 197.024 188.573C196.575 188.811 196.093 188.924 195.618 188.924Z"
        fill="#EACF32"
      />
      <path
        d="M102.02 85.426L143.002 26.94L132.732 10.28C130.868 7.25701 125.45 7.23601 123.55 10.245L113.08 26.829L72.02 85.426C70.867 87.072 72.306 89.13 74.61 89.13H104.61C102.306 89.131 100.867 87.072 102.02 85.426Z"
        fill="#FCDA4F"
      />
      <path
        d="M78.407 189.282C84.712 176.692 99.654 146.884 103.136 140.227C103.554 139.428 104.413 138.925 105.355 138.925H83.43H75.356C74.414 138.925 73.555 139.427 73.137 140.227C69.655 146.884 54.712 176.692 48.408 189.282C47.096 191.903 49.115 194.923 52.177 194.923H73.843H77.375H82.177C79.114 194.923 77.095 191.903 78.407 189.282Z"
        fill="#99AF17"
      />
      <path
        d="M167.689 85.19H167.508H159.011H157.384H97.179H88.501C87.488 85.19 86.565 85.692 86.116 86.492C82.373 93.149 66.312 122.957 59.536 135.547C58.125 138.168 60.295 141.188 63.587 141.188H86.874H127.011H129.181H167.509H192.414C195.752 141.188 197.919 138.089 196.418 135.462L167.689 85.19Z"
        fill="#EACF32"
      />
      <path
        d="M89.535 135.546C96.312 122.956 112.373 93.148 116.115 86.491C116.564 85.692 117.488 85.189 118.5 85.189H97.179H88.501C87.488 85.189 86.565 85.691 86.116 86.491C82.373 93.148 66.312 122.956 59.536 135.546C58.126 138.167 60.295 141.187 63.587 141.187H86.874H93.587C90.294 141.188 88.125 138.167 89.535 135.546Z"
        fill="#CCC033"
      />
      <path
        d="M183.98 85.426L143.028 26.982L132.732 10.28C130.868 7.25701 125.45 7.23601 123.55 10.245L113.08 26.829L72.02 85.426C70.867 87.072 72.306 89.13 74.61 89.13H181.39C183.694 89.131 185.133 87.072 183.98 85.426Z"
        fill="#FCDA4F"
      />
      <path
        d="M102.02 85.426L143.002 26.94L132.732 10.28C130.868 7.25701 125.45 7.23601 123.55 10.245L113.08 26.829L72.02 85.426C70.867 87.072 72.306 89.13 74.61 89.13H104.61C102.306 89.131 100.867 87.072 102.02 85.426Z"
        fill="#EACF32"
      />
      <path
        d="M100.308 188.563C106.349 188.563 111.246 183.666 111.246 177.625C111.246 171.584 106.349 166.687 100.308 166.687C94.2671 166.687 89.37 171.584 89.37 177.625C89.37 183.666 94.2671 188.563 100.308 188.563Z"
        fill="#99AF17"
      />
      <path
        d="M110.808 137.563C116.849 137.563 121.746 132.666 121.746 126.625C121.746 120.584 116.849 115.687 110.808 115.687C104.767 115.687 99.87 120.584 99.87 126.625C99.87 132.666 104.767 137.563 110.808 137.563Z"
        fill="#CCC033"
      />
    </g>
  </svg>
);
export default Tree;
