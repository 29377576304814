import React, { useState, useEffect } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button,
  Tab,
  Tabs
} from '@material-ui/core';
import { Formik } from 'formik';
import {
  useMaintenancesState,
  useMaintenancesDispatch,
  useMaintenancesMutation
} from 'modules/maintenances';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import Summary from './Summary';
import Location from './Location';
import { useMapDispatch } from 'modules/map';

const initialValues = {
  title: '',
  description: '',
  priority: 'low',
  state: 'toDo',
  scheduled_at: null,
  organization_id: null,
  processed_by_user_id: null,
  issue_id: null
};

const tabs = [
  { value: 'summary', label: 'General' },
  { value: 'location', label: 'Localización' }
];

const MaintenanceModal = () => {
  const classes = useClasses(styles);
  const [currentTab, setCurrentTab] = useState('summary');
  const { showMaintenanceModal, selectedMaintenance } = useMaintenancesState();
  const { addMaintenance, updateMaintenance } = useMaintenancesMutation();
  const { closeMaintenanceModal } = useMaintenancesDispatch();
  const { selectFeature } = useMapDispatch();

  useEffect(() => {
    selectFeature(selectedMaintenance?.elemento_inventario);
  }, [selectedMaintenance]);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleClose = () => {
    setCurrentTab('summary');
    closeMaintenanceModal();
  };

  return (
    <Modal onClose={handleClose} open={showMaintenanceModal}>
      <Card className={classes.root}>
        <Formik
          initialValues={
            selectedMaintenance
              ? selectedMaintenance
              : {
                  ...initialValues
                }
          }
          onSubmit={(values, { setSubmitting }) => {
            selectedMaintenance
              ? updateMaintenance({ ...values })
              : addMaintenance([{ ...values }]);
            setSubmitting(false);
            handleClose();
          }}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardHeader
                title={
                  <Typography component="h1" variant="h3">
                    {selectedMaintenance
                      ? 'Editar mantenimiento'
                      : 'Nuevo mantenimiento'}
                  </Typography>
                }
                className={classes.header}
              />

              <Tabs
                className={classes.tabs}
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={currentTab}
                variant="scrollable"
                indicatorColor="primary">
                {tabs.map(tab => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                {currentTab === 'summary' && <Summary />}
                {currentTab === 'location' && <Location />}
              </CardContent>
              <Divider className={classes.divider} />
              <CardActions className={classes.actions}>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained">
                  {selectedMaintenance ? 'Actualizar' : 'Guardar'}
                </Button>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

export default MaintenanceModal;
