import React, { useRef } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { useMapDispatch } from 'modules/map';
import useRouter from 'hooks/useRouter';

const Actions = ({ feature }) => {
  const { selectFeature } = useMapDispatch();
  const { history } = useRouter();
  const moreRef = useRef(null);

  return (
    <>
      <Tooltip title="Vista global">
        <IconButton
          ref={moreRef}
          size="small"
          onClick={() => {
            selectFeature(feature);
            history.push('/global');
          }}>
          <LaunchIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default Actions;
