import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const GET_SURFACE_TYPES = gql`
  query getSurfaceTypes {
    surfaceTypes: surface_type {
      id
      name
    }
  }
`;

export const useGetSurfaceTypes = () => {
  const { data, error, loading } = useQuery(GET_SURFACE_TYPES);
  return { data, error, loading };
};
