import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_ORGANIZATION_DETAILS } from '../queries';
import { useUsersDispatch } from 'modules/users/hooks';

export const ADD_ORGANIZATION_AVATAR = gql`
  mutation addOrganizationAvatar($objects: [file_insert_input!]!) {
    insert_file(objects: $objects) {
      affected_rows
    }
  }
`;

export const useAddOrganizationAvatar = () => {
  const { openSuccessAlert, openErrorAlert } = useUsersDispatch();
  let [mutate, { data, error, loading }] = useMutation(
    ADD_ORGANIZATION_AVATAR,
    {
      onCompleted: () => openSuccessAlert('Avatar creado correctamente.'),
      onError: () => openErrorAlert(error?.message)
    }
  );
  return ({ file = {}, organizationId = 0 }) => {
    return mutate({
      variables: {
        objects: {
          ...file,
          organization_id: organizationId
        }
      },
      refetchQueries: [
        {
          query: GET_ORGANIZATION_DETAILS,
          variables: { id: organizationId, limit: 10, offset: 0 }
        }
      ]
    });
  };
};
