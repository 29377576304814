import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { PROFILE_FRAGMENT } from '../fragments';
import usePagination from 'hooks/usePagination';
import { useRoles } from 'modules/authentication';

export const GET_PROFILES = gql`
  query getProfiles($limit: Int, $offset: Int) {
    profiles: profile(limit: $limit, offset: $offset, order_by: { id: asc }) {
      ...profileFields
    }
    profilesCount: profile_aggregate {
      aggregate {
        count
      }
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const useGetProfiles = () => {
  const {
    limit,
    offset,
    setLimit,
    setOffset,
    handleNext,
    handlePrevious,
    setCount,
    resetPagination
  } = usePagination();
  const { users__administrator, users__organization } = useRoles();
  const query = useQuery(GET_PROFILES, {
    variables: { limit, offset },
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        'x-hasura-role': users__administrator
          ? 'users__administrator'
          : users__organization
          ? 'users__organization'
          : undefined
      }
    }
  });
  return {
    limit,
    offset,
    setLimit,
    setOffset,
    handleNext,
    handlePrevious,
    setCount,
    resetPagination,
    ...query
  };
};
