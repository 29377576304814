import React from 'react';
import Table from 'components/Table';
import { useGetUsers } from 'modules/users';
import Link from 'components/Link';
import Actions from './Actions';
import { getInitials } from 'utils/users';
import { Avatar } from '@material-ui/core';
const columns = [
  {
    Header: 'Nombre',
    accessor: data => data,
    Cell: ({ cell: { value } }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{ height: 50, width: 50, marginRight: 10 }}
          src={value.file?.url}>
          {getInitials(value.name)}
        </Avatar>
        <div>
          <Link to={`/users/organizations/${value.id}`} title={value.name} />
          <div>{value.email}</div>
        </div>
      </div>
    )
  },
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Descripción',
    accessor: 'description'
  },
  {
    Header: 'Trabajadores',
    accessor: row => row.organization_users.length
  },
  {
    Header: '',
    id: 'actions',
    accessor: data => data,
    Cell: ({ cell: { value } }) => (
      <Actions organization={value} profiles={value.profiles} />
    )
  }
];

const Organizations = () => {
  const {
    data: {
      organizations = [],
      organizationsCount: {
        aggregate: { count: organizationsCount = 0 } = {}
      } = {}
    } = {},
    loading,
    error,
    resetPagination,
    ...tableProps
  } = useGetUsers();
  return (
    <Table
      columns={columns}
      data={organizations}
      count={organizationsCount}
      {...tableProps}
    />
  );
};

export default Organizations;
