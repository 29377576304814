import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import View from 'components/View';
import Furniture from './Furniture';
import Vegetation from './Vegetation';
import Surface from './Surface';
import Tree from './Tree';
import Issues from './Issues';
import InventoryModal from 'views/InventoryModal';
import {
  useGetInventoryItemDetails,
  useInventoryState,
  useInventoryDispatch
} from 'modules/inventory';
import Actions from './Actions';
import ConfirmDelete from './ConfirmDelete';
import IssueModal from 'views/IssueModal';
import MaintenanceModal from 'views/MaintenanceModal';
import Maintenances from './Maintenances';
import { useRoles } from 'modules/authentication';
import { checkAuth } from 'utils/roles';
import Alert from 'components/Alert';
import Playground from './Playground';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const renderTabs = (tabs, type, subtype) => {
  return tabs
    .filter(
      (tab) =>
        tab.value === type ||
        tab.value === 'issues' ||
        tab.label === subtype ||
        tab.value === 'maintenances'
    )
    .filter((tab) => checkAuth(tab?.auth))
    .map((tab) => {
      const { value, label } = tab;
      return <Tab key={value} label={label} value={value} />;
    });
};

const InventoryDetails = () => {
  const classes = useStyles();
  const {
    issues__solver,
    issues__administrator,
    maintenances__solver,
    maintenances__administrator
  } = useRoles();
  const { data, loading, error } = useGetInventoryItemDetails();
  const { alert } = useInventoryState();
  const { closeAlert } = useInventoryDispatch();
  const [currentTab, setCurrentTab] = useState(null);

  useEffect(() => {
    data && setCurrentTab(data?.inventoryItem?.inventoryItemType);
  }, [data]);

  const tabs = [
    { value: 'furniture', label: 'Información' },
    { value: 'vegetation', label: 'Información' },
    { value: 'surface', label: 'Información' },
    { value: 'playground', label: 'Parque infantil' },
    {
      value: 'tree',
      label: 'Árbol'
    },
    {
      value: 'issues',
      label: 'Incidencias',
      auth: [issues__solver, issues__administrator]
    },
    {
      value: 'maintenances',
      label: 'Mantenimientos',
      auth: [maintenances__solver, maintenances__administrator]
    }
  ];

  const handleTabsChange = (event, value) => {
    event.preventDefault();
    setCurrentTab(value);
  };
  return (
    <View
      title="Inventario"
      subtitle={data ? `#${data.inventoryItem.id}` : 'Cargando...'}
      actions={<Actions data={data?.inventoryItem} />}
      loading={loading}
      error={error}>
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={currentTab}
        variant="scrollable"
        indicatorColor="primary">
        {renderTabs(
          tabs,
          data?.inventoryItem.inventoryItemType,
          data?.inventoryItem.vegetacion_inventariables[0] &&
            data?.inventoryItem.vegetacion_inventariables[0].vegetationType
        )}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {currentTab === 'furniture' &&
          data?.inventoryItem.inventoryItemType === 'furniture' && (
            <Furniture inventoryItem={data?.inventoryItem} />
          )}
        {currentTab === 'playground' &&
          data?.inventoryItem.inventoryItemType === 'playground' && (
            <Playground inventoryItem={data?.inventoryItem} />
          )}
        {currentTab === 'vegetation' &&
          data?.inventoryItem.inventoryItemType === 'vegetation' && (
            <Vegetation inventoryItem={data?.inventoryItem} />
          )}
        {currentTab === 'surface' &&
          data?.inventoryItem.inventoryItemType === 'surface' && (
            <Surface inventoryItem={data?.inventoryItem} />
          )}
        {currentTab === 'tree' && <Tree />}
        {currentTab === 'issues' && <Issues {...data?.inventoryItem} />}
        {currentTab === 'maintenances' && (
          <Maintenances {...data?.inventoryItem} />
        )}
      </div>

      <InventoryModal />
      <ConfirmDelete />
      <IssueModal />
      <MaintenanceModal />
      <Alert
        show={alert}
        message={alert?.message}
        severity={alert?.severity}
        fireOnClose={() => closeAlert()}
      />
    </View>
  );
};

export default InventoryDetails;
