import gql from 'graphql-tag';

export const REPORT_FRAGMENT = gql`
  fragment reportFields on task_report {
    id
    task_id
    date
    hours
    notes
  }
`;
