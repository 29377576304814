import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_SENSORS } from '../queries';
import { useRoles } from 'modules/authentication';
import { useSensorsDispatch } from 'modules/sensors';

export const ADD_SENSOR = gql`
  mutation addSensor($objects: [sensor_insert_input!]!) {
    insert_sensor(objects: $objects) {
      returning {
        id
      }
    }
  }
`;
export const useAddSensor = () => {
  const { sensors__insert } = useRoles();
  const { openSuccessAlert, openErrorAlert } = useSensorsDispatch();
  let [mutate, { data, loading, error }] = useMutation(ADD_SENSOR, {
    onCompleted: () => openSuccessAlert('Sensor creado correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });
  const date = new Date().toDateString();
  return {
    addSensor: args =>
      mutate({
        variables: {
          objects: args.map(
            ({
              inventoryItemType,
              name,
              districtId,
              neighborhoodId,
              managementUnitId,
              address,
              observations,
              location,
              sensor
            }) => {
              return {
                elemento_inventario: {
                  data: {
                    inventoryItemType,
                    name,
                    districtId,
                    neighborhoodId,
                    managementUnitId,
                    address,
                    observations,
                    location: {
                      ...location,
                      crs: {
                        type: 'name',
                        properties: {
                          name: 'urn:ogc:def:crs:EPSG::4326'
                        }
                      }
                    }
                  }
                },
                datastreams: sensor ? { data: sensor.datastreams } : undefined
              };
            }
          )
        },
        refetchQueries: [
          { query: GET_SENSORS, variables: { date, limit: 10, offset: 0 } }
        ],
        context: {
          headers: {
            'x-hasura-role': sensors__insert && 'sensors__insert'
          }
        }
      }),
    data,
    loading,
    error
  };
};
