import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import {
  VEGETATION_FRAGMENT,
  FURNITURE_FRAGMENT,
  INVENTORY_ITEM_FRAGMENT,
  TREE_FRAGMENT,
  BUSH_FRAGMENT,
  CARPET_FRAGMENT,
  SURFACE_FRAGMENT,
  PLAYGROUND_FRAGMENT
} from '../fragments';
import { ISSUE_FRAGMENT } from 'modules/issues/graphql/fragments';
import useRouter from 'hooks/useRouter';
import { useRoles } from 'modules/authentication';

export const GET_INVENTORY_ITEM_DETAILS = gql`
  query getInventoryItemDetails($id: Int!) {
    inventoryItem: elemento_inventario_by_pk(id: $id) {
      ...inventoryItemFields
      mobiliario_urbanos {
        ...furnitureFields
      }
      vegetacion_inventariables {
        ...vegetationFields
        arbusto_inventariables {
          ...bushFields
        }
        tapiz_inventariables {
          ...carpetFields
        }
        arbol_inventariables {
          ...treeFields
        }
        palmera_inventariables {
          tipo_hoja
        }
      }
      surfaces {
        ...surfaceFields
      }
      playgrounds {
        ...playgroundFields
      }
      issues(order_by: { date: desc }) {
        ...issueFields
      }
    }
  }
  ${INVENTORY_ITEM_FRAGMENT}
  ${FURNITURE_FRAGMENT}
  ${VEGETATION_FRAGMENT}
  ${TREE_FRAGMENT}
  ${BUSH_FRAGMENT}
  ${CARPET_FRAGMENT}
  ${SURFACE_FRAGMENT}
  ${ISSUE_FRAGMENT}
  ${PLAYGROUND_FRAGMENT}
`;

export const useGetInventoryItemDetails = (payload) => {
  const { match } = useRouter();
  const { inventory__administrator } = useRoles();
  const { id } = match.params || {};
  const { data, error, loading } = useQuery(GET_INVENTORY_ITEM_DETAILS, {
    variables: { id: payload ? payload : id },
    context: {
      headers: {
        'x-hasura-role': inventory__administrator
          ? 'inventory__administrator'
          : 'user'
      }
    }
  });
  return {
    data,
    error,
    loading
  };
};
