export const modules = {
  sensors: {
    title: 'Sensores',
    roles: {
      sensors__insert: 'Insertar',
      sensors__update: 'Editar',
      sensors__delete: 'Eliminar',
      sensors__administrator: 'Administrador'
    }
  },
  inventory: {
    title: 'Inventario',
    roles: {
      inventory__insert: 'Insertar',
      inventory__update: 'Editar',
      inventory__delete: 'Eliminar',
      inventory__import: 'Importar',
      inventory__export: 'Exportar',
      inventory__administrator: 'Inventariador'
    }
  },
  issues: {
    title: 'Incidencias',
    roles: {
      issues__select: 'Consultar',
      issues__insert: 'Insertar',
      issues__update: 'Editar',
      issues__delete: 'Eliminar',
      issues__administrator: 'Administrador de incidencias',
      workorders__administrator: 'Administrador de órdenes de trabajo',
      issues__solver: 'Solucionador de incidencias',
      issues__generator: 'Generador de incidencias'
    }
  },
  maintenances: {
    title: 'Mantenimientos',
    roles: {
      maintenances__select: 'Consultar',
      maintenances__insert: 'Insertar',
      maintenances__update: 'Editar',
      maintenances__delete: 'Eliminar',
      maintenances__administrator: 'Administrador de mantenimientos',
      maintenances__solver: 'Solucionador de mantenimientos'
    }
  },
  users: {
    title: 'Usuarios',
    roles: {
      users__insert: 'Insertar',
      users__select: 'Consultar',
      users__update: 'Editar',
      users__delete: 'Eliminar',
      users__administrator: 'Administrador',
      users__organization: 'Organización'
    }
  },
  chat: {
    title: 'Chat',
    roles: {
      chat__select: 'Consultar',
      chat__insert: 'Insertar',
      chat__update: 'Editar',
      chat__delete: 'Eliminar',
      chat__administrator: 'Administrador',
      chat__organization: 'Organización',
      chat__worker: 'Trabajador'
    }
  }
};

export function checkAuth(auth = []) {
  if (auth && Array.isArray(auth) && auth.length > 0)
    return auth.some(role => role);
  return true;
}
