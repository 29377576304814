import { useDispatch } from 'react-redux';
import * as actions from '../redux';

export function useMapDispatch() {
  const dispatch = useDispatch();
  return {
    setLayers: payload => dispatch(actions.setLayers(payload)),
    selectFeature: payload => dispatch(actions.selectFeature(payload)),
    removeFeature: () => dispatch(actions.removeFeature()),
    selectArea: payload => dispatch(actions.selectArea(payload)),
    removeArea: () => dispatch(actions.removeArea())
  };
}
