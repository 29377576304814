import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, FormControlLabel, Grid, Checkbox } from '@material-ui/core';
import Description from './Description';
import Map from './Map';
import Status from './Status';
import LastUpdate from './LastUpdate';
import { observations, parseObservation } from 'utils/sensors';

const useStyles = makeStyles(theme => ({
  root: { display: 'flex', flexWrap: 'wrap', height: '100%' },
  column: {
    display: 'flex',
    flexFirection: 'column',
    flexGrow: 1,
    height: '100%'
  }
}));

const Summary = ({ sensor }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item lg={6} xs={12}>
        <Grid container spacing={4}>
          <Grid item lg={6} xs={12}>
            <Status datastreams={sensor.sensor.status} />
          </Grid>
          <Grid item lg={6} xs={12}>
            <LastUpdate datastreams={sensor.sensor.lastUpdate} />
          </Grid>
          <Grid item xs={12}>
            <Card
              style={{
                display: 'flex',
                justifyContent: 'space-around'
              }}>
              {observations.map((observation, index) => (
                <FormControlLabel
                  key={index}
                  style={{
                    paddingLeft: '1rem'
                  }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={sensor.sensor.datastreams.some(
                        datastream => datastream.observation === observation
                      )}
                    />
                  }
                  label={parseObservation(observation)}
                />
              ))}
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Description description={sensor.observations} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Map feature={sensor} />
      </Grid>
    </Grid>
  );
};

Summary.propTypes = {
  className: PropTypes.string,
  sensor: PropTypes.object.isRequired
};

export default Summary;
