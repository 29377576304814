import { useAddChat, useAddMessage, useDeleteChat } from '../graphql/mutations';

export const useChatMutation = () => {
  const addChat = useAddChat();
  const addMessage = useAddMessage();
  const deleteChat = useDeleteChat();
  return {
    addChat,
    addMessage,
    deleteChat
  };
};
