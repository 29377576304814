import React, { useCallback } from 'react';

const emptyFeature = {
  type: 'Feature',
  geometry: {}
};
const emptyFeatureCollection = [];
const initialValues = {
  area: emptyFeature,
  sensors: emptyFeatureCollection,
  furniture: emptyFeatureCollection,
  surface: emptyFeatureCollection,
  vegetation: emptyFeatureCollection,
  playground: emptyFeatureCollection
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_AREA':
      return { ...state, area: payload };
    case 'SET_SENSORS':
      return { ...state, sensors: payload };
    case 'SET_FURNITURE':
      return { ...state, furniture: payload };
    case 'SET_PLAYGROUND':
      return { ...state, playground: payload };
    case 'SET_SURFACE':
      return { ...state, surface: payload };
    case 'SET_VEGETATION':
      return { ...state, vegetation: payload };

    default:
      return state;
  }
};

export const useLayers = () => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialValues
  });

  const setArea = useCallback(
    (payload) => {
      dispatch({ type: 'SET_AREA', payload });
    },
    [dispatch]
  );
  const setSensors = useCallback(
    (payload) => {
      dispatch({ type: 'SET_SENSORS', payload });
    },
    [dispatch]
  );
  const setFurniture = useCallback(
    (payload) => {
      dispatch({ type: 'SET_FURNITURE', payload });
    },
    [dispatch]
  );
  const setPlayground = useCallback(
    (payload) => {
      dispatch({ type: 'SET_PLAYGROUND', payload });
    },
    [dispatch]
  );
  const setSurface = useCallback(
    (payload) => {
      dispatch({ type: 'SET_SURFACE', payload });
    },
    [dispatch]
  );
  const setVegetation = useCallback(
    (payload) => {
      dispatch({ type: 'SET_VEGETATION', payload });
    },
    [dispatch]
  );

  return {
    setArea,
    setSensors,
    setFurniture,
    setPlayground,
    setSurface,
    setVegetation,
    ...state
  };
};

export default useLayers;
