import React from 'react';

const Playground = (props) => {
  return (
    <svg width="50" height="50" viewBox="0 0 256 256" fill="none" {...props}>
      <path
        d="M219.724 59.069C219.722 59.069 219.719 59.069 219.716 59.069L36.274 58.751C33.513 58.746 31.277 56.245 31.282 53.165C31.286 50.088 33.524 47.597 36.282 47.597C36.285 47.597 36.287 47.597 36.29 47.597L219.732 47.915C222.493 47.92 224.729 50.421 224.724 53.501C224.72 56.578 222.482 59.069 219.724 59.069Z"
        fill="#EACF32"
      />
      <path
        d="M72.274 58.751C69.513 58.746 67.277 56.245 67.282 53.165C67.286 50.354 69.155 48.039 71.58 47.658L36.29 47.597C36.287 47.597 36.285 47.597 36.282 47.597C33.524 47.597 31.287 50.088 31.282 53.165C31.278 56.245 33.513 58.746 36.274 58.751H72.274Z"
        fill="#CCC033"
      />
      <path
        d="M14.01 248C13.68 248 13.346 247.975 13.01 247.925C8.904 247.314 6.02 243.107 6.567 238.528L33.247 15.26C33.743 11.104 36.923 8 40.681 8C40.685 8 40.689 8 40.693 8L215.331 8.318C219.085 8.325 222.256 11.426 222.753 15.577L249.434 238.526C249.982 243.105 247.098 247.314 242.992 247.925C238.883 248.539 235.114 245.319 234.566 240.74L208.752 25.037L47.246 24.743L21.434 240.738C20.932 244.943 17.711 248 14.01 248Z"
        fill="#CCC033"
      />
      <path
        d="M15.566 245.22L42.247 21.953C42.744 17.796 45.923 14.693 49.681 14.693C49.685 14.693 49.689 14.693 49.693 14.693L222.661 15.008C221.955 11.148 218.911 8.325 215.331 8.319L40.693 8C40.689 8 40.685 8 40.681 8C36.923 8 33.744 11.104 33.247 15.26L6.566 238.528C6.019 243.107 8.904 247.315 13.009 247.925C13.345 247.975 13.679 248 14.009 248C14.559 248 15.096 247.925 15.617 247.796C15.485 246.966 15.461 246.1 15.566 245.22Z"
        fill="#99AF17"
      />
      <path
        d="M143.943 181.648C142.562 181.648 141.443 180.529 141.443 179.148V47.751C141.443 46.37 142.562 45.251 143.943 45.251C145.324 45.251 146.443 46.37 146.443 47.751V179.148C146.443 180.529 145.324 181.648 143.943 181.648Z"
        fill="#FFD551"
      />
      <path
        d="M190.443 181.648C189.062 181.648 187.943 180.529 187.943 179.148V47.751C187.943 46.37 189.062 45.251 190.443 45.251C191.824 45.251 192.943 46.37 192.943 47.751V179.148C192.943 180.529 191.824 181.648 190.443 181.648Z"
        fill="#FFDF74"
      />
      <path
        d="M58.443 181.648C57.062 181.648 55.943 180.529 55.943 179.148V47.751C55.943 46.37 57.062 45.251 58.443 45.251C59.824 45.251 60.943 46.37 60.943 47.751V179.148C60.943 180.529 59.824 181.648 58.443 181.648Z"
        fill="#FFD551"
      />
      <path
        d="M104.943 181.648C103.562 181.648 102.443 180.529 102.443 179.148V47.751C102.443 46.37 103.562 45.251 104.943 45.251C106.324 45.251 107.443 46.37 107.443 47.751V179.148C107.443 180.529 106.324 181.648 104.943 181.648Z"
        fill="#FFDF74"
      />
      <path
        d="M64.688 189L55.508 194.912L43.141 171.02C41.677 168.193 42.546 164.577 45.081 162.945C47.616 161.313 50.857 162.281 52.321 165.109L64.688 189Z"
        fill="#FF4B34"
      />
      <path
        d="M97.188 189L106.368 194.912L118.734 171.021C120.198 168.194 119.329 164.578 116.794 162.946C114.259 161.314 111.018 162.282 109.554 165.11L97.188 189Z"
        fill="#FF624B"
      />
      <path
        d="M151.625 189.143L142.445 194.911L130.079 171.598C128.615 168.839 129.484 165.311 132.019 163.718C134.554 162.125 137.795 163.07 139.259 165.829L151.625 189.143Z"
        fill="#FF4B34"
      />
      <path
        d="M184.273 189.143L193.453 194.911L205.819 171.598C207.283 168.839 206.414 165.311 203.879 163.718C201.344 162.125 198.103 163.07 196.639 165.829L184.273 189.143Z"
        fill="#FF624B"
      />
      <path
        d="M193.443 194.911H142.467V177.885C142.467 173.742 145.825 170.384 149.968 170.384H185.943C190.086 170.384 193.444 173.742 193.444 177.885V194.911H193.443Z"
        fill="#FF624B"
      />
      <path
        d="M238.9 216.207C237.403 216.207 236.108 215.087 235.925 213.563L223.644 110.938C223.447 109.293 224.621 107.8 226.266 107.603C227.904 107.404 229.404 108.58 229.601 110.225L241.882 212.85C242.079 214.495 240.905 215.988 239.26 216.185C239.14 216.199 239.02 216.207 238.9 216.207Z"
        fill="#EACF32"
      />
      <path
        d="M170.909 192.849C175.741 192.849 179.659 188.931 179.659 184.099C179.659 179.267 175.741 175.349 170.909 175.349C166.077 175.349 162.159 179.267 162.159 184.099C162.159 188.931 166.077 192.849 170.909 192.849Z"
        fill="#FF4B34"
      />
      <path
        d="M165.343 170.385H149.968C145.825 170.385 142.467 173.743 142.467 177.886V194.912H157.842V177.886C157.842 173.743 161.2 170.385 165.343 170.385Z"
        fill="#FF4B34"
      />
      <path
        d="M106.443 194.911H55.467V177.885C55.467 173.742 58.825 170.384 62.968 170.384H98.943C103.086 170.384 106.444 173.742 106.444 177.885V194.911H106.443Z"
        fill="#FF624B"
      />
      <path
        d="M83.909 192.849C88.7415 192.849 92.659 188.931 92.659 184.099C92.659 179.267 88.7415 175.349 83.909 175.349C79.0765 175.349 75.159 179.267 75.159 184.099C75.159 188.931 79.0765 192.849 83.909 192.849Z"
        fill="#FF4B34"
      />
      <path
        d="M78.343 170.385H62.968C58.825 170.385 55.467 173.743 55.467 177.886V194.912H70.842V177.886C70.842 173.743 74.2 170.385 78.343 170.385Z"
        fill="#FF4B34"
      />
    </svg>
  );
};

export const SelectedPlayground = (props) => (
  <svg width="50" height="50" viewBox="0 0 256 256" fill="none" {...props}>
    <g clipPath="url(#clip0)">
      <ellipse cx="129" cy="248" rx="128" ry="15" fill="#249A77" />
      <path
        d="M219.724 59.069C219.722 59.069 219.719 59.069 219.716 59.069L36.274 58.751C33.513 58.746 31.277 56.245 31.282 53.165C31.286 50.088 33.524 47.597 36.282 47.597C36.285 47.597 36.287 47.597 36.29 47.597L219.732 47.915C222.493 47.92 224.729 50.421 224.724 53.501C224.72 56.578 222.482 59.069 219.724 59.069Z"
        fill="#EACF32"
      />
      <path
        d="M72.274 58.751C69.513 58.746 67.277 56.245 67.282 53.165C67.286 50.354 69.155 48.039 71.58 47.658L36.29 47.597C36.287 47.597 36.285 47.597 36.282 47.597C33.524 47.597 31.287 50.088 31.282 53.165C31.278 56.245 33.513 58.746 36.274 58.751H72.274Z"
        fill="#CCC033"
      />
      <path
        d="M14.0099 248C13.6799 248 13.3459 247.975 13.0099 247.925C8.90394 247.314 6.01994 243.107 6.56694 238.528L33.2469 15.26C33.7429 11.104 36.9229 8 40.6809 8C40.6849 8 40.6889 8 40.6929 8L215.331 8.318C219.085 8.325 222.256 11.426 222.753 15.577L249.434 238.526C249.982 243.105 247.098 247.314 242.992 247.925C238.883 248.539 235.114 245.319 234.566 240.74L208.752 25.037L47.2459 24.743L21.4339 240.738C20.9319 244.943 17.7109 248 14.0099 248Z"
        fill="#CCC033"
      />
      <path
        d="M15.5659 245.22L42.2469 21.953C42.7439 17.796 45.9229 14.693 49.6809 14.693C49.6849 14.693 49.6889 14.693 49.6929 14.693L222.661 15.008C221.955 11.148 218.911 8.325 215.331 8.319L40.6929 8C40.6889 8 40.6849 8 40.6809 8C36.9229 8 33.7439 11.104 33.2469 15.26L6.56594 238.528C6.01894 243.107 8.90394 247.315 13.0089 247.925C13.3449 247.975 13.6789 248 14.0089 248C14.5589 248 15.0959 247.925 15.6169 247.796C15.4849 246.966 15.4609 246.1 15.5659 245.22Z"
        fill="#99AF17"
      />
      <path
        d="M143.943 181.648C142.562 181.648 141.443 180.529 141.443 179.148V47.751C141.443 46.37 142.562 45.251 143.943 45.251C145.324 45.251 146.443 46.37 146.443 47.751V179.148C146.443 180.529 145.324 181.648 143.943 181.648Z"
        fill="#FFD551"
      />
      <path
        d="M190.443 181.648C189.062 181.648 187.943 180.529 187.943 179.148V47.751C187.943 46.37 189.062 45.251 190.443 45.251C191.824 45.251 192.943 46.37 192.943 47.751V179.148C192.943 180.529 191.824 181.648 190.443 181.648Z"
        fill="#FFDF74"
      />
      <path
        d="M58.443 181.648C57.062 181.648 55.943 180.529 55.943 179.148V47.751C55.943 46.37 57.062 45.251 58.443 45.251C59.824 45.251 60.943 46.37 60.943 47.751V179.148C60.943 180.529 59.824 181.648 58.443 181.648Z"
        fill="#FFD551"
      />
      <path
        d="M104.943 181.648C103.562 181.648 102.443 180.529 102.443 179.148V47.751C102.443 46.37 103.562 45.251 104.943 45.251C106.324 45.251 107.443 46.37 107.443 47.751V179.148C107.443 180.529 106.324 181.648 104.943 181.648Z"
        fill="#FFDF74"
      />
      <path
        d="M64.688 189L55.508 194.912L43.141 171.02C41.677 168.193 42.546 164.577 45.081 162.945C47.616 161.313 50.857 162.281 52.321 165.109L64.688 189Z"
        fill="#FF4B34"
      />
      <path
        d="M97.188 189L106.368 194.912L118.734 171.021C120.198 168.194 119.329 164.578 116.794 162.946C114.259 161.314 111.018 162.282 109.554 165.11L97.188 189Z"
        fill="#FF624B"
      />
      <path
        d="M151.625 189.143L142.445 194.911L130.079 171.598C128.615 168.839 129.484 165.311 132.019 163.718C134.554 162.125 137.795 163.07 139.259 165.829L151.625 189.143Z"
        fill="#FF4B34"
      />
      <path
        d="M184.273 189.143L193.453 194.911L205.819 171.598C207.283 168.839 206.414 165.311 203.879 163.718C201.344 162.125 198.103 163.07 196.639 165.829L184.273 189.143Z"
        fill="#FF624B"
      />
      <path
        d="M193.443 194.911H142.467V177.885C142.467 173.742 145.825 170.384 149.968 170.384H185.943C190.086 170.384 193.444 173.742 193.444 177.885V194.911H193.443Z"
        fill="#FF624B"
      />
      <path
        d="M238.9 216.207C237.403 216.207 236.108 215.087 235.925 213.563L223.644 110.938C223.447 109.293 224.621 107.8 226.266 107.603C227.904 107.404 229.404 108.58 229.601 110.225L241.882 212.85C242.079 214.495 240.905 215.988 239.26 216.185C239.14 216.199 239.02 216.207 238.9 216.207Z"
        fill="#EACF32"
      />
      <path
        d="M170.909 192.849C175.741 192.849 179.659 188.931 179.659 184.099C179.659 179.267 175.741 175.349 170.909 175.349C166.076 175.349 162.159 179.267 162.159 184.099C162.159 188.931 166.076 192.849 170.909 192.849Z"
        fill="#FF4B34"
      />
      <path
        d="M165.343 170.385H149.968C145.825 170.385 142.467 173.743 142.467 177.886V194.912H157.842V177.886C157.842 173.743 161.2 170.385 165.343 170.385Z"
        fill="#FF4B34"
      />
      <path
        d="M106.443 194.911H55.4669V177.885C55.4669 173.742 58.8249 170.384 62.9679 170.384H98.9429C103.086 170.384 106.444 173.742 106.444 177.885V194.911H106.443Z"
        fill="#FF624B"
      />
      <path
        d="M83.9089 192.849C88.7414 192.849 92.6589 188.931 92.6589 184.099C92.6589 179.267 88.7414 175.349 83.9089 175.349C79.0764 175.349 75.1589 179.267 75.1589 184.099C75.1589 188.931 79.0764 192.849 83.9089 192.849Z"
        fill="#FF4B34"
      />
      <path
        d="M78.3429 170.385H62.9679C58.8249 170.385 55.4669 173.743 55.4669 177.886V194.912H70.8419V177.886C70.8419 173.743 74.1999 170.385 78.3429 170.385Z"
        fill="#FF4B34"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="256" height="256" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Playground;
