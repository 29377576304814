import React, { useState, useRef, useEffect } from 'react';
import View from 'components/View';
import InventoryModal from 'views/InventoryModal';
import {
  Divider,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  TextField
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import ConfirmDelete from './ConfirmDelete';
import ConfirmType from './ConfirmType';
import {
  useInventoryDispatch,
  useGetInventory,
  useInventoryState
} from 'modules/inventory';
import columns from './columns';
import Table from 'components/Table';
import { useRoles } from 'modules/authentication';
import Alert from 'components/Alert';
import SearchInput from 'components/SearchInput';

const tabs = [
  { value: 'all', label: 'Todo' },
  { value: 'vegetation', label: 'Vegetación' },
  { value: 'furniture', label: 'Mobiliario urbano' },
  { value: 'playground', label: 'Parques infantiles' }
];

const Inventory = () => {
  const classes = useClasses(styles);
  const moreRef = useRef(null);
  const {
    inventory__insert,
    inventory__import,
    inventory__export
  } = useRoles();
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };
  const {
    openInventoryItemModal,
    openConfirmTypeModal,
    closeAlert
  } = useInventoryDispatch();
  const { alert } = useInventoryState();
  const [currentTab, setCurrentTab] = useState('all');
  const {
    data,
    count,
    loading,
    error,
    search,
    handleSearch,
    ...pagination
  } = useGetInventory(currentTab);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };
  return (
    <View
      title="Inventario"
      error={error}
      actions={
        <>
          <SearchInput
            value={search}
            onChange={(event) => handleSearch(event.target.value)}
          />
          {inventory__insert && (
            <Tooltip title="Añadir elemento">
              <IconButton onClick={() => openInventoryItemModal()} size="small">
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
          {(inventory__import || inventory__export) && (
            <>
              <Tooltip title="Acciones">
                <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
                  <MoreIcon />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={moreRef.current}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                elevation={1}
                onClose={handleMenuClose}
                open={openMenu}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}>
                {inventory__import && (
                  <MenuItem
                    onClick={() => {
                      openConfirmTypeModal('import');
                      setOpenMenu(false);
                    }}>
                    <ListItemText primary="Importar" />
                  </MenuItem>
                )}

                {inventory__export && (
                  <MenuItem
                    onClick={() => {
                      openConfirmTypeModal('export');
                      setOpenMenu(false);
                    }}>
                    <ListItemText primary="Exportar" />
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </>
      }>
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={currentTab}
        variant="scrollable"
        indicatorColor="primary">
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Table
          columns={columns}
          data={data}
          loading={loading}
          count={count}
          {...pagination}
        />
      </div>
      <InventoryModal />
      <ConfirmDelete />
      <ConfirmType />
      <Alert
        show={alert}
        message={alert?.message}
        severity={alert?.severity}
        fireOnClose={() => closeAlert()}
      />
    </View>
  );
};

export default Inventory;
