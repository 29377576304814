import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar } from '@material-ui/core';
import { parseState, stateColors, renderStateIcon } from 'utils/issues';
const useStyles = makeStyles(theme => ({
  root: {
    height: 120,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  avatar: {
    backgroundColor: props => stateColors[props.state],
    color: theme.palette.common.white,
    height: 48,
    width: 48
  }
}));

const State = ({ state }) => {
  const classes = useStyles({ state });
  return (
    <Card className={classes.root}>
      <div>
        <Typography component="h3" gutterBottom variant="overline">
          Estado
        </Typography>
        <div className={classes.details}>
          <Typography variant="h4">{parseState(state)}</Typography>
        </div>
      </div>
      <Avatar className={classes.avatar}>{renderStateIcon(state)}</Avatar>
    </Card>
  );
};

export default State;
