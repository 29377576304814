import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, colors } from '@material-ui/core';
import View from 'components/View';
import Loader from 'components/Loader';
import { useGetTaskDetails } from 'modules/maintenances';
import Actions from './Actions';
import ConfirmDelete from './ConfirmDelete';
import Summary from './Summary';
import MaintenanceTaskModal from 'views/MaintenanceTaskModal';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const MaintenanceTaskDetails = () => {
  const classes = useStyles();
  const { data, loading, error } = useGetTaskDetails();
  return (
    <View
      title="Tarea"
      loading={loading}
      error={error}
      subtitle={
        !loading && data && data.task ? `#${data.task.id}` : 'Cargando...'
      }
      actions={<Actions task={!loading && data && data.task} />}>
      <Divider className={classes.divider} />

      <div className={classes.content}>
        <Summary task={data?.task} />
      </div>

      <ConfirmDelete />
      <MaintenanceTaskModal maintenance={data?.task?.maintenance} />
    </View>
  );
};

export default MaintenanceTaskDetails;
