import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, colors } from '@material-ui/core';
import View from 'components/View';
import { useGetProfileDetails } from 'modules/users';
import Actions from './Actions';
import ConfirmDelete from './ConfirmDelete';
import Roles from './Roles';
import ProfileModal from 'views/ProfileModal';
import Alert from 'components/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const ProfileDetails = () => {
  const classes = useStyles();
  const { data, loading, error } = useGetProfileDetails();

  return (
    <View
      title="Perfil"
      loading={loading}
      subtitle={data?.profile?.name}
      actions={<Actions profile={data?.profile} />}>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Roles
          permissions={data?.permissions}
          accesses={data?.accesses}
          profile={data?.profile}
        />
      </div>

      <ConfirmDelete />
      <ProfileModal />
      <Alert show={!!error} />
    </View>
  );
};

export default ProfileDetails;
