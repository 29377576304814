import React from 'react';
import { Avatar as MaterialAvatar, Tooltip } from '@material-ui/core';
import { getInitials } from 'utils/users';

const Avatar = ({ name = '', url, height = 40, width = 40, ...props }) => {
  return (
    <Tooltip title={name} disableHoverListener={!Boolean(name)}>
      <MaterialAvatar {...props} style={{ height: 50, width: 50 }} src={url}>
        {getInitials(name)}
      </MaterialAvatar>
    </Tooltip>
  );
};

export default Avatar;
