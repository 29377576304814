import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import useRouter from 'hooks/useRouter';

export const GET_AREA_DETAILS = gql`
  query getAreaDetails($areaId: Int!, $geometry: geometry) {
    area: area_by_pk(id: $areaId) {
      id
      name
      level
      ground_type_id
      ground_type {
        id
        name
      }
      topography
      typology
      location_type
    }
    inventoryWithIssues: elemento_inventario(
      where: {
        issues: { state: { _eq: "toDo" } }
        location: { _st_within: $geometry }
      }
      limit: 10
    ) {
      id
      name
      inventoryItemType
      issues: issues_aggregate(where: { state: { _eq: "toDo" } }) {
        aggregate {
          count
        }
      }
    }
    inventoryWithoutIssues: inventory_item_without_pending_issues(
      limit: 5
      where: { location: { _st_within: $geometry } }
    ) {
      id
      name
      inventoryItemType: type
    }
  }
`;

export const useGetAreaDetails = (areaId = 0, geometry = {}) => {
  const { match } = useRouter();
  const { id } = match.params || {};
  const query = useQuery(GET_AREA_DETAILS, {
    variables: {
      areaId: id ? id : areaId,
      geometry: {
        ...geometry,
        crs: {
          type: 'name',
          properties: {
            name: 'urn:ogc:def:crs:EPSG::4326'
          }
        }
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  return query;
};
