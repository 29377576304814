import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_INVENTORY_ITEM_DETAILS } from '../queries';

export const UPDATE_INVENTORY_FILE = gql`
  mutation updateInventoryFile($id: Int!, $url: String, $name: String) {
    update_file(where: { id: { _eq: $id } }, _set: { url: $url, name: $name }) {
      affected_rows
    }
  }
`;

export const useUpdateInventoryFile = () => {
  let [mutate] = useMutation(UPDATE_INVENTORY_FILE);

  return ({ file, inventoryId }) => {
    return mutate({
      variables: {
        id: file.id,
        url: file.url,
        name: file.name
      },
      refetchQueries: [
        {
          query: GET_INVENTORY_ITEM_DETAILS,
          variables: { id: inventoryId }
        }
      ]
    });
  };
};
