import { useAuth } from './useAuth';

export const useRoles = () => {
  const auth = useAuth();
  let roles = {};
  if (auth?.info) {
    auth.info['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'].forEach(
      role => (roles[role] = true)
    );
  }
  return roles;
};
