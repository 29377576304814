import React from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button
} from '@material-ui/core';
import { Formik } from 'formik';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import Summary from './Summary';
import {
  useUsersState,
  useUsersDispatch,
  useUsersMutation
} from 'modules/users';

const WorkerModal = () => {
  const classes = useClasses(styles);
  const {
    showWorkerModal,
    selectedWorker,
    selectedOrganization
  } = useUsersState();
  const { addWorker, updateWorker } = useUsersMutation();
  const { closeWorkerModal } = useUsersDispatch();

  const initialValues = {
    name: '',
    surname: '',
    external_id: '',
    organization_id: selectedOrganization ? selectedOrganization.id : null,
    profile_id: null
  };
  const handleClose = () => {
    closeWorkerModal();
  };

  return (
    <Modal onClose={handleClose} open={showWorkerModal}>
      <Card className={classes.root}>
        <Formik
          initialValues={selectedWorker ? selectedWorker : initialValues}
          onSubmit={(values, { setSubmitting }) => {
            selectedWorker
              ? updateWorker({ ...values })
              : addWorker({ ...values });
            setSubmitting(false);
            handleClose();
          }}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardHeader
                title={
                  <Typography component="h1" variant="h3">
                    {selectedWorker ? 'Editar trabajador' : 'Nuevo trabajador'}
                  </Typography>
                }
                className={classes.header}
              />

              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                <Summary />
              </CardContent>
              <Divider className={classes.divider} />
              <CardActions className={classes.actions}>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained">
                  {selectedWorker ? 'Actualizar' : 'Guardar'}
                </Button>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

export default WorkerModal;
