import React, { useState } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button
} from '@material-ui/core';
import { Formik } from 'formik';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import FileUpload from './FileUpload';
import FileEdit from './FileEdit';
import {
  useUsersState,
  useUsersMutation,
  useUsersDispatch
} from 'modules/users';
import Loader from 'components/Loader';
import { uploadFile } from 'modules/core';

const OrganizationUploadModal = () => {
  const [loading, setLoading] = useState(false);
  const classes = useClasses(styles);
  const {
    selectedOrganization,
    selectedFile,
    showOrganizationUploadModal
  } = useUsersState();
  const { closeOrganizationUploadModal } = useUsersDispatch();
  const {
    addOrganizationAvatar,
    updateOrganizationAvatar
  } = useUsersMutation();

  const handleSubmit = async ({ file }) => {
    setLoading(true);
    const upload = await uploadFile(file);
    if (upload && !selectedFile) {
      addOrganizationAvatar({
        file: { ...upload },
        organizationId: selectedOrganization.id
      }).then(() => {
        setLoading(false);
        closeOrganizationUploadModal();
      });
    }
    if (upload && selectedFile) {
      updateOrganizationAvatar({
        file: { id: selectedFile.id, ...upload },
        organizationId: selectedOrganization.id
      }).then(() => {
        setLoading(false);
        closeOrganizationUploadModal();
      });
    }
  };

  if (!selectedOrganization) return null;
  return (
    <Modal
      onClose={closeOrganizationUploadModal}
      open={showOrganizationUploadModal}>
      <div>
        <Formik
          initialValues={{ file: selectedFile }}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}>
          {({ handleSubmit, values }) => (
            <Card className={classes.root}>
              <form onSubmit={handleSubmit} className={classes.form}>
                <CardHeader
                  title={
                    <Typography component="h1" variant="h3">
                      {selectedFile ? 'Editar avatar' : 'Nuevo avatar'}
                    </Typography>
                  }
                  className={classes.header}
                />

                <Divider className={classes.divider} />
                <CardContent className={classes.content}>
                  {loading ? (
                    <Loader />
                  ) : selectedFile ? (
                    <FileEdit />
                  ) : (
                    <FileUpload />
                  )}
                </CardContent>
                <Divider className={classes.divider} />
                <CardActions className={classes.actions}>
                  <Button onClick={closeOrganizationUploadModal}>
                    Cancelar
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={loading || !values.file}>
                    Enviar
                  </Button>
                </CardActions>
              </form>
            </Card>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default OrganizationUploadModal;
