import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector';
import { createStructuredSelector } from 'reselect';

const selectedChatSelector = state => state.chat.selectedChat;
const showDeleteChatModalSelector = state => state.chat.showDeleteChatModal;

const selectors = createStructuredSelector({
  selectedChat: selectedChatSelector,
  showDeleteChatModal: showDeleteChatModalSelector
});

export function useChatState() {
  const chatModule = useShallowEqualSelector(selectors);
  return chatModule;
}
