import React, { useState } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button
} from '@material-ui/core';
import { Formik } from 'formik';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import FileUpload from './FileUpload';
import FileEdit from './FileEdit';
import {
  useInventoryMutation,
  useInventoryState,
  useInventoryDispatch
} from 'modules/inventory';
import Loader from 'components/Loader';
import { uploadFile } from 'modules/core';
import Actions from './Actions';

const InventoryUploadModal = () => {
  const [loading, setLoading] = useState(false);
  const classes = useClasses(styles);
  const {
    selectedInventoryItem,
    selectedFile,
    showInventoryUploadModal
  } = useInventoryState();
  const { closeInventoryUploadModal } = useInventoryDispatch();
  const { addInventoryFiles, updateInventoryFile } = useInventoryMutation();

  const handleSubmit = async ({ files }) => {
    setLoading(true);
    const inventoryUpload = await Promise.all(
      files.map((file) => uploadFile(file))
    );
    if (!selectedFile && inventoryUpload && selectedInventoryItem) {
      addInventoryFiles({
        files: inventoryUpload,
        inventoryId: selectedInventoryItem.id
      }).then(() => {
        setLoading(false);
        closeInventoryUploadModal();
      });
    }
    if (inventoryUpload && selectedFile) {
      updateInventoryFile({
        file: { id: selectedFile.id, ...inventoryUpload[0] },
        inventoryId: selectedFile.inventory_id
      }).then(() => {
        setLoading(false);
        closeInventoryUploadModal();
      });
    }
  };

  if (!selectedInventoryItem) return null;
  return (
    <Modal onClose={closeInventoryUploadModal} open={showInventoryUploadModal}>
      <Formik
        initialValues={{ files: [] }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}>
        {({ handleSubmit, values }) => (
          <Card className={classes.root}>
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardHeader
                action={<Actions file={selectedFile} />}
                title={
                  <Typography component="h1" variant="h3">
                    {selectedFile ? 'Editar imagen' : 'Nueva imagen'}
                  </Typography>
                }
                className={classes.header}
              />

              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                {loading ? (
                  <Loader />
                ) : selectedFile ? (
                  <FileEdit />
                ) : (
                  <FileUpload />
                )}
              </CardContent>
              <Divider className={classes.divider} />
              <CardActions className={classes.actions}>
                <Button onClick={closeInventoryUploadModal}>Cancelar</Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  disabled={loading || !values.files.length > 0}>
                  Enviar
                </Button>
              </CardActions>
            </form>
          </Card>
        )}
      </Formik>
    </Modal>
  );
};

export default InventoryUploadModal;
