import React from 'react';
import App from './App';
import Login from 'views/Login';
import { useAuth } from 'modules/authentication';

export const Root = () => {
  const auth = useAuth();
  if (!auth) return <Login />;
  return <App />;
};

export default Root;
