import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  FormControlLabel,
  Checkbox,
  Divider
} from '@material-ui/core';
import Type from './Type';
import Subtype from './Subtype';
import Map from '../Map';
import Images from '../Images';
import Features from './Features';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', flexWrap: 'wrap', height: '100%' },
  column: {
    display: 'flex',
    flexFirection: 'column'
  }
}));

const Furniture = ({ inventoryItem = {} }) => {
  const classes = useStyles();
  const { observations } = inventoryItem;
  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xl={6} xs={12}>
        <Grid container spacing={4}>
          <Grid item lg={6} xs={12}>
            <Type />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Subtype name={inventoryItem.name} />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Features {...inventoryItem} />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Images inventoryItem={inventoryItem} />
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader title="Observaciones" />
              <Divider />
              <CardContent>
                <Typography variant="body1">{observations}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={6} xs={12}>
        <Map inventoryItem={inventoryItem} />
      </Grid>
    </Grid>
  );
};

export default Furniture;
