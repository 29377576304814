import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_PROFILE_DETAILS } from '../queries';

export const UPDATE_ROLES_BY_PROFILE = gql`
  mutation updateRolesByProfile(
    $profileId: Int!
    $objects: [profile_role_association_insert_input!]!
  ) {
    delete_profile_role_association(
      where: { profile_id: { _eq: $profileId } }
    ) {
      affected_rows
    }
    insert_profile_role_association(objects: $objects) {
      affected_rows
    }
  }
`;

export const useUpdateRolesByProfile = () => {
  let [mutate] = useMutation(UPDATE_ROLES_BY_PROFILE);
  return ({ profileId, roles }) => {
    return mutate({
      variables: {
        profileId,
        objects: [
          { profile_id: profileId, role_id: 1 },
          ...roles.map(roleId => ({
            profile_id: profileId,
            role_id: roleId
          }))
        ]
      },
      refetchQueries: [{ query: GET_PROFILE_DETAILS, variables: { profileId } }]
    });
  };
};
