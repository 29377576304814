import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { MAINTENANCE_FRAGMENT } from '../fragments';
import { useRoles } from 'modules/authentication';

export const GET_MAINTENANCES_BY_INVENTORY_ITEM = gql`
  query($inventoryItemId: Int) {
    maintenances: maintenance(
      where: { resource_id: { _eq: $inventoryItemId } }
      order_by: { id: desc }
    ) {
      ...maintenanceFields
    }
  }
  ${MAINTENANCE_FRAGMENT}
`;

export const useGetMaintenancesByInventoryItem = (inventoryItemId) => {
  const { maintenances__administrator, maintenances__solver } = useRoles();
  const { data, error, loading } = useQuery(
    GET_MAINTENANCES_BY_INVENTORY_ITEM,
    {
      variables: { inventoryItemId },
      fetchPolicy: 'cache-and-network',
      context: {
        headers: {}
      }
    }
  );
  return {
    data,
    loading,
    error
  };
};
