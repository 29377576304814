import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar } from '@material-ui/core';
import { parseUnit } from 'utils/sensors';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  avatar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    height: 48,
    width: 48
  }
}));

const CurrentValue = ({ datastream, className }) => {
  const classes = useStyles();
  const { observation_values, unit } = datastream;
  return (
    <Card className={clsx(classes.root, className)}>
      <div>
        <Typography
          color="inherit"
          component="h3"
          gutterBottom
          variant="overline">
          Valor actual
        </Typography>
        <div className={classes.details}>
          <Typography color="inherit" variant="h3">
            {`${
              observation_values?.length > 0
                ? observation_values[0].result
                : 'No hay datos disponibles'
            } ${parseUnit(unit) || ''}`}
          </Typography>
        </div>
      </div>
    </Card>
  );
};

CurrentValue.propTypes = {
  className: PropTypes.string
};

export default CurrentValue;
