import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import moment from 'moment';
import { useRoles } from 'modules/authentication';

export const GET_DATASTREAM = gql`
  query getDatastream(
    $datastreamId: Int!
    $startDate: timestamp
    $endDate: timestamp
  ) {
    datastream: datastream_by_pk(id: $datastreamId) {
      id
      observation
      unit
      observation_values(
        order_by: { time: desc }
        where: { time: { _gte: $startDate, _lte: $endDate } }
      ) {
        id
        result
        time
      }
    }
  }
`;

export const useGetDatastream = (datastreamId, startDate, endDate) => {
  const defaultStartDate = moment()
    .add(-7, 'days')
    .format('MM/DD/YYYY');
  const defaultEndDate = moment()
    .add(1, 'days')
    .format('MM/DD/YYYY');
  const { sensors__administrator } = useRoles();
  const { data, error, loading } = useQuery(GET_DATASTREAM, {
    variables: {
      datastreamId,
      startDate: startDate ? startDate : defaultStartDate,
      endDate: endDate ? endDate : defaultEndDate
    },
    context: {
      headers: {
        'x-hasura-role': sensors__administrator
          ? 'sensors__administrator'
          : undefined
      }
    }
  });
  return {
    data,
    error,
    loading
  };
};
