import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import Label from 'components/Label';

const useStyles = makeStyles(theme => ({
  root: {
    userSelect: 'none',
    whiteSpace: 'normal',
    height: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    verticalAlign: 'top',
    width: 500,
    padding: theme.spacing(0, 1),
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down('xs')]: {
      width: 300
    }
  },
  isDraggingOver: {},
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2, 2),
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center'
  },
  counter: {
    fontSize: 'medium'
  },
  headerAction: {
    marginLeft: 'auto'
  },
  content: {
    flexGrow: 1,
    overflowY: 'hidden'
  },
  inner: {
    padding: theme.spacing(2, 3)
  }
}));

function TaskList({
  title,
  total,
  provided,
  snapshot,
  className,
  children,
  ...rest
}) {
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      ref={provided.innerRef}>
      <div className={classes.header}>
        <Typography color="inherit" variant="h5">
          {title}
        </Typography>

        <div className={classes.headerAction}>
          <Label
            className={classes.counter}
            color={'secondary'}
            shape="rounded"
            variant="contained">
            {total}
          </Label>
        </div>
      </div>
      <div
        className={clsx(classes.content, {
          [classes.isDraggingOver]: snapshot.isDraggingOver
        })}>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <div className={classes.inner}>{children}</div>
        </PerfectScrollbar>
      </div>
    </div>
  );
}

TaskList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  provided: PropTypes.object.isRequired,
  snapshot: PropTypes.object.isRequired,
  title: PropTypes.string,
  total: PropTypes.number
};

export default TaskList;
