import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import useRouter from 'hooks/useRouter';

export const GET_AREA_DETAILS = gql`
  query getAreaDetails($areaId: Int!) {
    area: area_by_pk(id: $areaId) {
      id
      name
      level
      location
      description
      surface_m2
      typology
      location_type
      topography
      ground_type_id
      ground_type {
        id
        name
      }
    }
  }
`;

export const useGetAreaDetails = () => {
  const { match } = useRouter();
  const { areaId } = match.params || {};
  const query = useQuery(GET_AREA_DETAILS, {
    variables: {
      areaId
    },
    fetchPolicy: 'cache-and-network'
  });

  return query;
};
