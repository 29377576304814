import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showInventoryItemModal: false,
  showDeleteInventoryItemModal: false,
  selectedInventoryItem: undefined,
  inventoryItemTypes: ['furniture', 'vegetation', 'playground'],
  showInventoryUploadModal: false,
  selectedFile: undefined,
  selectedArea: undefined,
  alert: undefined,
  showConfirmTypeModal: false,
  importSubmitted: false,
  exportSubmitted: false
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    loadInventory: () => initialState,
    openInventoryItemModal: (state, { payload }) => ({
      ...state,
      selectedInventoryItem: payload,
      showInventoryItemModal: true
    }),
    closeInventoryItemModal: (state) => ({
      ...state,
      selectedInventoryItem: undefined,
      showInventoryItemModal: false
    }),
    openDeleteInventoryItemModal: (state, { payload }) => ({
      ...state,
      selectedInventoryItem: payload,
      showDeleteInventoryItemModal: true
    }),
    closeDeleteInventoryItemModal: (state) => ({
      ...state,
      selectedInventoryItem: undefined,
      showDeleteInventoryItemModal: false
    }),
    openInventoryUploadModal: (state, { payload }) => ({
      ...state,
      selectedInventoryItem: payload.inventoryItem,
      selectedFile: payload.file,
      showInventoryUploadModal: true
    }),
    closeInventoryUploadModal: (state) => ({
      ...state,
      selectedInventoryItem: undefined,
      selectedFile: undefined,
      showInventoryUploadModal: false
    }),
    openAreaModal: (state, { payload }) => ({
      ...state,
      selectedArea: payload,
      showAreaModal: true
    }),
    closeAreaModal: (state) => ({
      ...state,
      selectedArea: undefined,
      showAreaModal: false
    }),
    openDeleteAreaModal: (state, { payload }) => ({
      ...state,
      selectedArea: payload,
      showDeleteAreaModal: true
    }),
    closeDeleteAreaModal: (state) => ({
      ...state,
      selectedArea: undefined,
      showDeleteAreaModal: false
    }),
    openErrorAlert: (state, { payload }) => ({
      ...state,
      alert: {
        severity: 'error',
        message: payload
      }
    }),
    openSuccessAlert: (state, { payload }) => ({
      ...state,
      alert: {
        severity: 'success',
        message: payload
      }
    }),
    closeAlert: (state) => ({
      ...state,
      alert: undefined
    }),
    openConfirmTypeModal: (state, { payload }) => ({
      ...state,
      showConfirmTypeModal: true,
      importSubmitted: payload === 'import',
      exportSubmitted: payload === 'export'
    }),
    closeConfirmTypeModal: (state, { payload }) => ({
      ...state,
      showConfirmTypeModal: false,
      importSubmitted: false,
      exportSubmitted: false
    })
  }
});

export const {
  loadInventory,
  openInventoryItemModal,
  closeInventoryItemModal,
  openDeleteInventoryItemModal,
  closeDeleteInventoryItemModal,
  openInventoryUploadModal,
  closeInventoryUploadModal,
  openAreaModal,
  closeAreaModal,
  openDeleteAreaModal,
  closeDeleteAreaModal,
  openErrorAlert,
  openSuccessAlert,
  closeAlert,
  openConfirmTypeModal,
  closeConfirmTypeModal
} = inventorySlice.actions;

export default inventorySlice.reducer;
