import gql from 'graphql-tag';

export const ROLE_FRAGMENT = gql`
  fragment roleFields on organization_user_role {
    id
    name
    type
    module
  }
`;
