import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_INVENTORY } from '../queries';
import { useInventoryDispatch } from 'modules/inventory/hooks';
import { parseType, parseSubtype } from 'utils/inventory';

export const IMPORT_INVENTORY = gql`
  mutation importInventory($objects: [elemento_inventario_insert_input!]!) {
    insert_elemento_inventario(objects: $objects) {
      returning {
        id
      }
    }
  }
`;
export const useImportInventory = () => {
  const { openSuccessAlert, openErrorAlert } = useInventoryDispatch();
  let [mutate, { data, loading, error }] = useMutation(IMPORT_INVENTORY, {
    onCompleted: () => openSuccessAlert('Elementos importados correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });

  return {
    importInventory: (args) =>
      mutate({
        variables: {
          objects: args.map(
            ([
              inventoryItemType,
              subtype,
              name,
              observations,
              address,
              manufacturer,
              model,
              installationDate,
              commonName,
              species,
              plantingDate,
              longitude,
              latitude,
              shape,
              carpetType,
              tipo_hoja = '',
              alcorque_deter_pavimento,
              alcorque_estado,
              alcorque_tipo,
              ca_estado,
              ca_tipo,
              clasificacion_riesgo,
              dd_altura_primera_rama_m,
              dd_altura_total_m,
              dd_diam_copa_cm,
              dd_perimetro_tronco_cm,
              de_ancho_acera_m,
              de_dist_ejes_alineacion,
              de_distancia_fachada_m,
              de_entorno_calzada,
              de_marco_plantacion,
              de_protector,
              de_protector_estado,
              de_tipo_posicion,
              de_tipo_riego,
              de_tipo_superficie,
              de_tipo_vial,
              e_base_huecos_cm,
              e_copa_chupones_cm,
              e_copa_ramas_secas_percent,
              e_tronco_inclinacion_grad,
              fecha_ult_poda,
              nivel_riesgo,
              od_edad_relativa,
              od_estructura,
              od_origen_specie,
              od_procedencia,
              od_vigor_vitalidad,
              tutor_estado,
              tutor_tipo,
              valoracion
            ]) => {
              const parsedInventoryItemType = parseType(inventoryItemType);
              const parsedSubtype = parseSubtype(subtype);
              const location = {
                type: 'Point',
                crs: {
                  type: 'name',
                  properties: {
                    name: 'urn:ogc:def:crs:EPSG::4326'
                  }
                },
                coordinates: [Number(longitude), Number(latitude)]
              };
              return {
                inventoryItemType: parsedInventoryItemType,
                name,
                address,
                observations,
                location,
                mobiliario_urbanos:
                  parsedInventoryItemType === 'furniture'
                    ? {
                        data: [
                          {
                            manufacturer,
                            model,
                            installationDate: installationDate
                              ? new Date(String(installationDate))
                              : undefined
                          }
                        ]
                      }
                    : undefined,
                playgrounds:
                  parsedInventoryItemType === 'playground'
                    ? {
                        data: [
                          {
                            manufacturer,
                            model,
                            installation_date: installationDate
                              ? new Date(String(installationDate))
                              : undefined
                          }
                        ]
                      }
                    : undefined,
                vegetacion_inventariables:
                  parsedInventoryItemType === 'vegetation'
                    ? {
                        data: [
                          {
                            vegetationType: parsedSubtype,
                            plantingDate: plantingDate
                              ? new Date(String(plantingDate))
                              : undefined,
                            commonName,
                            species,
                            arbusto_inventariables:
                              parsedSubtype === 'Arbusto'
                                ? {
                                    data: [
                                      {
                                        shape
                                      }
                                    ]
                                  }
                                : undefined,
                            tapiz_inventariables:
                              parsedSubtype === 'Superficie vegetal'
                                ? {
                                    data: [
                                      {
                                        carpetType
                                      }
                                    ]
                                  }
                                : undefined,
                            palmera_inventariables:
                              parsedSubtype === 'Palmera'
                                ? {
                                    data: [
                                      {
                                        tipo_hoja
                                      }
                                    ]
                                  }
                                : undefined,
                            arbol_inventariables:
                              parsedSubtype === 'Árbol'
                                ? {
                                    data: [
                                      {
                                        alcorque_deter_pavimento,
                                        alcorque_estado,
                                        alcorque_tipo,
                                        ca_estado,
                                        ca_tipo,
                                        clasificacion_riesgo,
                                        dd_altura_primera_rama_m,
                                        dd_altura_total_m,
                                        dd_diam_copa_cm,
                                        dd_perimetro_tronco_cm,
                                        de_ancho_acera_m,
                                        de_dist_ejes_alineacion,
                                        de_distancia_fachada_m,
                                        de_entorno_calzada,
                                        de_marco_plantacion,
                                        de_protector,
                                        de_protector_estado,
                                        de_tipo_posicion,
                                        de_tipo_riego,
                                        de_tipo_superficie,
                                        de_tipo_vial,
                                        e_base_huecos_cm,
                                        e_copa_chupones_cm,
                                        e_copa_ramas_secas_percent,
                                        e_tronco_inclinacion_grad,
                                        fecha_ult_poda,
                                        nivel_riesgo,
                                        od_edad_relativa,
                                        od_estructura,
                                        od_origen_specie,
                                        od_procedencia,
                                        od_vigor_vitalidad,
                                        tutor_estado,
                                        tutor_tipo,
                                        valoracion
                                      }
                                    ]
                                  }
                                : undefined
                          }
                        ]
                      }
                    : undefined
              };
            }
          )
        },
        refetchQueries: [
          { query: GET_INVENTORY, variables: { limit: 10, offset: 0 } }
        ]
      }),
    data,
    loading,
    error
  };
};
