import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button
} from '@material-ui/core';

import {
  useUsersState,
  useUsersMutation,
  useUsersDispatch
} from 'modules/users';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    height: 200
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ConfirmDelete = () => {
  const classes = useStyles();
  const {
    selectedOrganization,
    showDeleteOrganizationModal,
    selectedWorker,
    showDeleteWorkerModal,
    selectedProfile,
    showDeleteProfileModal
  } = useUsersState();
  const {
    deleteOrganization,
    deleteWorker,
    deleteProfile
  } = useUsersMutation();
  const {
    closeDeleteOrganizationModal,
    closeDeleteWorkerModal,
    closeDeleteProfileModal
  } = useUsersDispatch();

  if (showDeleteOrganizationModal) {
    return (
      <Modal
        onClose={closeDeleteOrganizationModal}
        open={showDeleteOrganizationModal}>
        <Card className={classes.root}>
          <CardHeader title="¿Eliminar organización?" />
          <Divider />
          <CardContent>
            <Typography variant="body1">
              Si elimina la organización seleccionada, todos los trabajadores
              asociados se eliminarán. No podrá recuperarlos más tarde.
            </Typography>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button onClick={closeDeleteOrganizationModal}>Cancelar</Button>
            <Button
              color="secondary"
              onClick={async () => {
                await deleteOrganization({
                  id: selectedOrganization.id
                });
                closeDeleteOrganizationModal();
              }}
              variant="contained">
              Sí, eliminar
            </Button>
          </CardActions>
        </Card>
      </Modal>
    );
  }
  if (showDeleteWorkerModal) {
    return (
      <Modal onClose={closeDeleteWorkerModal} open={showDeleteWorkerModal}>
        <Card className={classes.root}>
          <CardHeader title="¿Eliminar trabajador?" />
          <Divider />
          <CardContent>
            <Typography variant="body1">
              Si elimina el trabajador seleccionado, no podrá recuperarlo más
              tarde.
            </Typography>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button onClick={closeDeleteWorkerModal}>Cancelar</Button>
            <Button
              color="secondary"
              onClick={async () => {
                await deleteWorker({
                  id: selectedWorker.id
                });
                closeDeleteWorkerModal();
              }}
              variant="contained">
              Sí, eliminar
            </Button>
          </CardActions>
        </Card>
      </Modal>
    );
  }

  if (showDeleteProfileModal) {
    return (
      <Modal onClose={closeDeleteProfileModal} open={showDeleteProfileModal}>
        <Card className={classes.root}>
          <CardHeader title="¿Eliminar perfil?" />
          <Divider />
          <CardContent>
            <Typography variant="body1">
              Si elimina el perfil seleccionado, no podrá recuperarlo más tarde
              y los usuarios con dicho perfil perderán el acceso a la aplicación
              hasta que se les asigne uno nuevo.
            </Typography>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button onClick={closeDeleteProfileModal}>Cancelar</Button>
            <Button
              color="secondary"
              onClick={async () => {
                await deleteProfile({
                  profileId: selectedProfile.id
                });
                closeDeleteProfileModal();
              }}
              variant="contained">
              Sí, eliminar
            </Button>
          </CardActions>
        </Card>
      </Modal>
    );
  }
  return null;
};

export default ConfirmDelete;
