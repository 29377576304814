import React, { useState, useEffect } from 'react';
import MapGL, { Layer, Source } from 'react-map-gl';
import LocationPanel from 'components/LocationPanel';
import Marker from 'components/Marker';
const initialData = {
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: []
  }
};

const surfaceLayer = {
  id: 'surface',
  type: 'fill',
  layout: {},
  paint: {
    'fill-color': '#088',
    'fill-opacity': 0.7
  }
};
const pointLayer = {
  id: 'point',
  type: 'circle',
  paint: {
    'circle-color': 'rgba(100, 100, 255, 1)',
    'circle-radius': {
      base: 1.75,
      stops: [
        [12, 2],
        [22, 180]
      ]
    },
    'circle-stroke-width': 3,
    'circle-stroke-color': '#fff'
  }
};

const renderLayer = (data, type) => {
  return (
    <Source type="geojson" data={data}>
      {type === 'surface' ? (
        <Layer {...surfaceLayer} />
      ) : (
        <Layer {...pointLayer} />
      )}
    </Source>
  );
};
const Map = ({ inventoryItem }) => {
  const { inventoryItemType, location } = inventoryItem || {};
  const initialViewport = {
    zoom: 19,
    pitch: 0,
    latitude:
      inventoryItemType === 'surface' && location?.type !== 'Point'
        ? location?.coordinates[0][0][1]
        : location?.coordinates[1],
    longitude:
      inventoryItemType === 'surface' && location?.type !== 'Point'
        ? location?.coordinates[0][0][0]
        : location?.coordinates[0]
  };

  const [viewport, setViewport] = useState(initialViewport);
  const updateViewport = (viewport) => {
    setViewport(viewport);
  };

  const [data, setData] = useState(initialData);
  useEffect(() => {
    let newData = {
      type: 'Feature',
      geometry: location
    };
    setData(newData);
    setViewport((prevViewport) => ({
      ...prevViewport,
      latitude:
        inventoryItemType === 'surface' && location?.type !== 'Point'
          ? location?.coordinates[0][0][1]
          : location?.coordinates[1],
      longitude:
        inventoryItemType === 'surface' && location?.type !== 'Point'
          ? location?.coordinates[0][0][0]
          : location?.coordinates[0]
    }));
  }, [inventoryItemType]);

  return (
    <div style={{ minHeight: 600, height: '100%' }}>
      <MapGL
        {...viewport}
        style={{
          borderRadius: 4,
          boxShadow:
            'rgba(63,63,68,.05) 0 0 0 1px,rgba(63,63,68,.15) 0 1px 3px 0'
        }}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
        onViewportChange={updateViewport}>
        <Marker inventoryItem={inventoryItem} />
        <LocationPanel feature={inventoryItem} />
      </MapGL>
    </div>
  );
};

export default Map;
