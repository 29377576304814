import axios from 'axios';
import { getAuthHeader } from 'modules/authentication/api';

export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export const uploadAWS = async file => {
  const formData = new FormData();
  const token = JSON.parse(getAuthHeader())?.token;
  formData.append('picture', file);
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/documents`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
  );
  return {
    name: file.name,
    size: file.size,
    url: response.data
  };
};
