import React from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button,
  Grid,
  TextField
} from '@material-ui/core';
import { Formik } from 'formik';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import {
  useUsersState,
  useUsersDispatch,
  useUsersMutation
} from 'modules/users';

const initialValues = {
  name: ''
};

const ProfileModal = () => {
  const classes = useClasses(styles);
  const { showProfileModal, selectedProfile } = useUsersState();
  const { addProfile, updateProfile } = useUsersMutation();
  const { closeProfileModal } = useUsersDispatch();

  const handleClose = () => {
    closeProfileModal();
  };

  return (
    <Modal onClose={handleClose} open={showProfileModal}>
      <Card className={classes.root}>
        <Formik
          initialValues={selectedProfile || initialValues}
          onSubmit={(values, { setSubmitting }) => {
            selectedProfile
              ? updateProfile({ ...values })
              : addProfile({ ...values });
            setSubmitting(false);
            handleClose();
          }}>
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardHeader
                title={
                  <Typography component="h1" variant="h3">
                    {selectedProfile ? 'Editar perfil' : 'Nuevo perfil'}
                  </Typography>
                }
                className={classes.header}
              />

              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Nombre"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
              <Divider className={classes.divider} />
              <CardActions className={classes.actions}>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained">
                  {selectedProfile ? 'Actualizar' : 'Guardar'}
                </Button>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

export default ProfileModal;
