import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import img from 'assets/images/chat.svg';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inner: {
    textAlign: 'center'
  },
  image: {
    maxWidth: 200
  },
  title: {
    margin: theme.spacing(4, 0, 1, 0)
  }
}));

function Placeholder({ className }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.inner}>
        <img
          alt="Selecciona una conversación"
          className={classes.image}
          src={img}
        />
        <Typography className={classes.title} variant="h4">
          Selecciona una conversación para mostrar
        </Typography>
        <Typography variant="subtitle1">
          Para empezar una nueva conversación, pulsa el icono de usuarios
          disponibles y selecciona uno.
        </Typography>
      </div>
    </div>
  );
}

export default Placeholder;
