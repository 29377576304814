import React from 'react';
import { Grid, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { KeyboardDatePicker as DatePicker } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import { useGetFurnitureTypes } from 'modules/inventory';
const Furniture = () => {
  const { values, handleChange, setFieldValue } = useFormikContext();
  const {
    furnitureTypeId,
    integration,
    electric,
    hydraulic,
    manufacturer,
    model,
    installationDate,
    lastRepairDate
  } = values.mobiliario_urbanos[0];
  const { data } = useGetFurnitureTypes();
  return (
    <Grid container spacing={3} alignItems="center" justify="center">
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Tipo"
          name="mobiliario_urbanos[0].furnitureTypeId"
          onChange={handleChange}
          select
          SelectProps={{ native: true }}
          value={furnitureTypeId}
          variant="outlined"
          InputLabelProps={{ shrink: Boolean(furnitureTypeId) }}>
          <option key="empty" value={''}></option>
          {data &&
            data.furnitureTypes.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
        </TextField>
      </Grid>

      <Grid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="mobiliario_urbanos[0].integration"
              checked={integration}
              onChange={handleChange}
              value={integration}
            />
          }
          label="Integración"
        />
      </Grid>
      <Grid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="mobiliario_urbanos[0].electric"
              checked={electric}
              onChange={handleChange}
              value={electric}
            />
          }
          label="Eléctrico"
        />
      </Grid>
      <Grid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="mobiliario_urbanos[0].hydraulic"
              checked={hydraulic}
              onChange={handleChange}
              value={hydraulic}
            />
          }
          label="Hidráulico"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Fabricante"
          name="mobiliario_urbanos[0].manufacturer"
          onChange={handleChange}
          value={manufacturer}
          variant="outlined">
          {manufacturer}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Modelo"
          name="mobiliario_urbanos[0].model"
          onChange={handleChange}
          value={model}
          variant="outlined">
          {model}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label="Instalación"
          fullWidth
          inputVariant="outlined"
          value={installationDate}
          format="dd/MM/yyyy"
          onChange={(date, value) =>
            setFieldValue('mobiliario_urbanos[0].installationDate', date, false)
          }
          cancelLabel="cancelar"
          invalidDateMessage="Fecha incorrecta"
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label="Última reparación"
          fullWidth
          inputVariant="outlined"
          value={lastRepairDate}
          format="dd/MM/yyyy"
          onChange={(date, value) =>
            setFieldValue('mobiliario_urbanos[0].lastRepairDate', date, false)
          }
          cancelLabel="cancelar"
          invalidDateMessage="Fecha incorrecta"
        />
      </Grid>
    </Grid>
  );
};

export default Furniture;
