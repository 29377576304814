import gql from 'graphql-tag';

export const INVENTORY_ITEM_FRAGMENT = gql`
  fragment inventoryItemFields on elemento_inventario {
    id
    inventoryItemType
    name
    districtId
    neighborhoodId
    managementUnitId
    observations
    location
    created_at
    updated_at
    areaByUdAdminId {
      id
      name
    }
    areaByUdGestionId {
      id
      name
    }
    area {
      id
      name
    }
    address
    addressNumber
    files(order_by: { id: desc }) {
      id
      name
      url
      size
      inventory_id
    }
  }
`;
