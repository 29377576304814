import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    height: '70%',
    minHeight: 100,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
