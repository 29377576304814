import React, { useRef } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  IconButton,
  Typography
} from '@material-ui/core';
import Actions from './Actions';
import Link from 'components/Link';
import ConfirmDelete from './ConfirmDelete';
import useClasses from 'hooks/useClasses';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import MaintenanceTaskModal from 'views/MaintenanceTaskModal';
import { useMaintenancesDispatch } from 'modules/maintenances';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0,
    width: '100%',
    height: '100%'
  },
  data: { fontWeight: 600 },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  arrowForwardIcon: {
    color: theme.palette.icon
  },
  toDo: {
    color: theme.palette.error.main
  },
  done: {
    color: theme.palette.success.main
  }
});

const Tasks = ({ tasks, maintenance }) => {
  const classes = useClasses(styles);
  const { openTaskModal } = useMaintenancesDispatch();
  const moreRef = useRef(null);
  return (
    <Card className={classes.root}>
      <CardHeader
        title="Tareas"
        action={
          <Tooltip title="Nueva tarea">
            <IconButton
              ref={moreRef}
              size="small"
              onClick={() => openTaskModal()}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Divider />
      <CardContent className={classes.content}>
        <List className={classes.root} disablePadding>
          {tasks && tasks.length > 0 ? (
            tasks.map((task, i) => (
              <ListItem
                className={classes.listItem}
                divider={i < tasks.length}
                key={task.id}>
                <ListItemIcon style={{ justifyContent: 'center' }}>
                  {task.state === 'done' ? (
                    <CheckIcon className={classes.done} />
                  ) : (
                    <ClearIcon className={classes.toDo} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h6' }}
                  secondary={
                    task.organization_user &&
                    `${task.organization_user.name} ${task.organization_user.surname}`
                  }>
                  <Link
                    to={`/maintenances/${task.maintenance_id}/tasks/${task.id}`}
                    title={task.title}
                  />
                </ListItemText>
                <Actions task={task} />
              </ListItem>
            ))
          ) : (
            <div style={{ textAlign: 'center', padding: '3rem' }}>
              <Typography variant="subtitle2">
                Ninguna tarea asignada
              </Typography>
            </div>
          )}
        </List>
      </CardContent>
      <ConfirmDelete />
      <MaintenanceTaskModal maintenance={maintenance} />
    </Card>
  );
};

export default Tasks;
