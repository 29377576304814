import React from 'react';

const PalmTree = (props) => {
  return (
    <svg width="50" height="50" viewBox="0 0 256 256" fill="none" {...props}>
      <path
        d="M118.085 248H137.914C140.762 248 143.044 245.564 142.952 242.624L137.072 55.318H118.928L113.048 242.624C112.955 245.564 115.238 248 118.085 248Z"
        fill="#FFDF74"
      />
      <path
        d="M128.169 242.624L134.049 55.317H118.928L113.048 242.624C112.956 245.565 115.238 248 118.086 248H133.207C130.359 248 128.076 245.564 128.169 242.624Z"
        fill="#FFD551"
      />
      <path
        d="M129.888 77.891C135.507 57.246 123.282 31.858 99.513 17.679C75.744 3.50001 48.351 5.25601 33.856 20.607L129.888 77.891Z"
        fill="#CCC033"
      />
      <path
        d="M117.655 52.647C116.552 52.647 115.491 52.037 114.967 50.983C110.508 42.024 102.863 33.994 93.44 28.373C92.017 27.524 91.552 25.683 92.401 24.26C93.249 22.836 95.09 22.371 96.514 23.221C106.912 29.424 115.374 38.334 120.338 48.31C121.076 49.793 120.472 51.594 118.989 52.332C118.56 52.546 118.104 52.647 117.655 52.647Z"
        fill="#EACF32"
      />
      <path
        d="M61.074 20.607C66.064 15.322 72.583 11.65 79.957 9.68302C61.999 5.42702 44.47 9.36702 33.856 20.607L129.888 77.891C131.25 72.885 131.555 67.6 130.909 62.264L61.074 20.607Z"
        fill="#99AF17"
      />
      <path
        d="M129.888 77.891C140.044 59.188 166.064 50.167 192.574 57.506C219.085 64.845 237.253 86.1 236.997 107.544L129.888 77.891Z"
        fill="#CCC033"
      />
      <path
        d="M225.201 97.573C224.936 97.573 224.667 97.538 224.399 97.464L178.934 84.878C177.337 84.436 176.401 82.783 176.843 81.186C177.285 79.591 178.939 78.653 180.534 79.095L226 91.681C227.597 92.123 228.533 93.776 228.091 95.373C227.723 96.701 226.516 97.573 225.201 97.573Z"
        fill="#EACF32"
      />
      <path
        d="M63.426 57.505C36.915 64.844 18.747 86.099 19.003 107.543L43.827 100.671L47.819 80.696L59.162 96.425L126.112 77.89C115.956 59.188 89.936 50.166 63.426 57.505Z"
        fill="#CCC033"
      />
      <path
        d="M159.727 17.679C183.496 3.50001 210.89 5.25601 225.384 20.607L203.127 33.883L186.634 22.675L189.378 42.085L129.352 77.891C123.734 57.246 135.958 31.857 159.727 17.679Z"
        fill="#CCC033"
      />
      <path
        d="M78.547 60.63C86.503 58.427 94.412 57.708 101.864 58.272C90.576 54.293 77.069 53.728 63.426 57.505C36.915 64.844 18.747 86.099 19.003 107.543L34.784 103.174C38.241 84.443 55.137 67.111 78.547 60.63Z"
        fill="#99AF17"
      />
      <path
        d="M177.873 17.679C185.776 12.965 194.078 10.022 202.175 8.74502C189.024 6.50102 173.746 9.31603 159.727 17.679C135.958 31.857 123.733 57.246 129.352 77.891L146.147 67.873C146.006 49.546 157.837 29.63 177.873 17.679Z"
        fill="#99AF17"
      />
      <path
        d="M207.695 73.128C215.887 75.396 223.279 78.995 229.58 83.515C221.919 71.727 208.818 62.002 192.574 57.505C166.063 50.166 140.043 59.187 129.887 77.89L152.265 84.085C164.909 72.01 186.139 67.161 207.695 73.128Z"
        fill="#99AF17"
      />
      <path
        d="M138.531 108.335H138.391C128.826 108.335 121.072 100.581 121.072 91.016V89.722C121.072 80.157 128.826 72.403 138.391 72.403H138.531C148.096 72.403 155.85 80.157 155.85 89.722V91.016C155.85 100.581 148.096 108.335 138.531 108.335Z"
        fill="#F9BB32"
      />
      <path
        d="M117.352 95.857H117.212C107.647 95.857 99.893 88.103 99.893 78.538V77.244C99.893 67.679 107.647 59.925 117.212 59.925H117.352C126.917 59.925 134.671 67.679 134.671 77.244V78.538C134.672 88.103 126.918 95.857 117.352 95.857Z"
        fill="#F9BB32"
      />
      <path
        d="M115.015 77.906V77.875C115.015 70.755 119.028 64.603 124.844 61.701C122.562 60.563 120.003 59.924 117.298 59.924H117.268C107.673 59.924 99.894 67.961 99.894 77.875V77.906C99.894 87.82 107.673 95.857 117.268 95.857H117.298C120.002 95.857 122.561 95.219 124.844 94.08C119.027 91.178 115.015 85.027 115.015 77.906Z"
        fill="#DB9C2E"
      />
      <path
        d="M136.193 90.385V90.354C136.193 83.234 140.205 77.082 146.022 74.18C143.74 73.041 141.181 72.403 138.477 72.403H138.447C128.851 72.403 121.073 80.44 121.073 90.354V90.385C121.073 100.299 128.852 108.336 138.447 108.336H138.477C141.181 108.336 143.74 107.698 146.022 106.559C140.205 103.656 136.193 97.505 136.193 90.385Z"
        fill="#DB9C2E"
      />
      <path
        d="M113.556 226.422H142.444L141.953 210.799H114.047L113.556 226.422Z"
        fill="#EACF32"
      />
      <path
        d="M114.537 195.176H141.463L140.972 179.553H115.028L114.537 195.176Z"
        fill="#EACF32"
      />
      <path
        d="M115.518 163.93H140.482L139.991 148.307H116.009L115.518 163.93Z"
        fill="#EACF32"
      />
      <path
        d="M116.499 132.684H139.501L139.01 117.061H116.989L116.499 132.684Z"
        fill="#EACF32"
      />
      <path
        d="M114.047 210.799L113.791 218.957L113.556 226.422H128.677L129.168 210.799H114.047Z"
        fill="#CCC033"
      />
      <path
        d="M115.028 179.553L114.977 181.152L114.537 195.176H129.658L130.149 179.553H115.028Z"
        fill="#CCC033"
      />
      <path
        d="M116.009 148.307L115.899 151.801L115.518 163.93H130.639L131.13 148.307H116.009Z"
        fill="#CCC033"
      />
      <path
        d="M116.989 117.061L116.735 125.174L116.499 132.684H131.62L132.111 117.061H116.989Z"
        fill="#CCC033"
      />
      <path
        d="M67.579 88.437C72.4115 88.437 76.329 84.5195 76.329 79.687C76.329 74.8545 72.4115 70.937 67.579 70.937C62.7465 70.937 58.829 74.8545 58.829 79.687C58.829 84.5195 62.7465 88.437 67.579 88.437Z"
        fill="#99AF17"
      />
    </svg>
  );
};
export const SelectedPalmTree = (props) => (
  <svg width="50" height="50" viewBox="0 0 256 256" fill="none" {...props}>
    <ellipse cx="125" cy="242" rx="66" ry="11" fill="#249A77" />
    <path
      d="M118.085 248H137.914C140.762 248 143.044 245.564 142.952 242.624L137.072 55.318H118.928L113.048 242.624C112.955 245.564 115.238 248 118.085 248Z"
      fill="#FFDF74"
    />
    <path
      d="M128.169 242.624L134.049 55.317H118.928L113.048 242.624C112.956 245.565 115.238 248 118.086 248H133.207C130.359 248 128.076 245.564 128.169 242.624Z"
      fill="#FFD551"
    />
    <path
      d="M129.888 77.891C135.507 57.246 123.282 31.858 99.5131 17.679C75.7441 3.50001 48.3511 5.25601 33.8561 20.607L129.888 77.891Z"
      fill="#CCC033"
    />
    <path
      d="M117.655 52.647C116.552 52.647 115.491 52.037 114.967 50.983C110.508 42.024 102.863 33.994 93.4401 28.373C92.0171 27.524 91.5521 25.683 92.4011 24.26C93.2491 22.836 95.0901 22.371 96.5141 23.221C106.912 29.424 115.374 38.334 120.338 48.31C121.076 49.793 120.472 51.594 118.989 52.332C118.56 52.546 118.104 52.647 117.655 52.647Z"
      fill="#EACF32"
    />
    <path
      d="M61.0741 20.607C66.0641 15.322 72.5831 11.65 79.9571 9.68302C61.9991 5.42702 44.4701 9.36702 33.8561 20.607L129.888 77.891C131.25 72.885 131.555 67.6 130.909 62.264L61.0741 20.607Z"
      fill="#99AF17"
    />
    <path
      d="M129.888 77.891C140.044 59.188 166.064 50.167 192.574 57.506C219.085 64.845 237.253 86.1 236.997 107.544L129.888 77.891Z"
      fill="#CCC033"
    />
    <path
      d="M225.201 97.573C224.936 97.573 224.667 97.538 224.399 97.464L178.934 84.878C177.337 84.436 176.401 82.783 176.843 81.186C177.285 79.591 178.939 78.653 180.534 79.095L226 91.681C227.597 92.123 228.533 93.776 228.091 95.373C227.723 96.701 226.516 97.573 225.201 97.573Z"
      fill="#EACF32"
    />
    <path
      d="M63.426 57.505C36.915 64.844 18.747 86.099 19.003 107.543L43.827 100.671L47.819 80.696L59.162 96.425L126.112 77.89C115.956 59.188 89.936 50.166 63.426 57.505Z"
      fill="#CCC033"
    />
    <path
      d="M159.727 17.679C183.496 3.50001 210.89 5.25601 225.384 20.607L203.127 33.883L186.634 22.675L189.378 42.085L129.352 77.891C123.734 57.246 135.958 31.857 159.727 17.679Z"
      fill="#CCC033"
    />
    <path
      d="M78.547 60.63C86.503 58.427 94.412 57.708 101.864 58.272C90.576 54.293 77.069 53.728 63.426 57.505C36.915 64.844 18.747 86.099 19.003 107.543L34.784 103.174C38.241 84.443 55.137 67.111 78.547 60.63Z"
      fill="#99AF17"
    />
    <path
      d="M177.873 17.679C185.776 12.965 194.078 10.022 202.175 8.74502C189.024 6.50102 173.746 9.31603 159.727 17.679C135.958 31.857 123.733 57.246 129.352 77.891L146.147 67.873C146.006 49.546 157.837 29.63 177.873 17.679Z"
      fill="#99AF17"
    />
    <path
      d="M207.695 73.128C215.887 75.396 223.279 78.995 229.58 83.515C221.919 71.727 208.818 62.002 192.574 57.505C166.063 50.166 140.043 59.187 129.887 77.89L152.265 84.085C164.909 72.01 186.139 67.161 207.695 73.128Z"
      fill="#99AF17"
    />
    <path
      d="M138.531 108.335H138.391C128.826 108.335 121.072 100.581 121.072 91.016V89.722C121.072 80.157 128.826 72.403 138.391 72.403H138.531C148.096 72.403 155.85 80.157 155.85 89.722V91.016C155.85 100.581 148.096 108.335 138.531 108.335Z"
      fill="#F9BB32"
    />
    <path
      d="M117.352 95.857H117.212C107.647 95.857 99.8931 88.103 99.8931 78.538V77.244C99.8931 67.679 107.647 59.925 117.212 59.925H117.352C126.917 59.925 134.671 67.679 134.671 77.244V78.538C134.672 88.103 126.918 95.857 117.352 95.857Z"
      fill="#F9BB32"
    />
    <path
      d="M115.015 77.906V77.875C115.015 70.755 119.028 64.603 124.844 61.701C122.562 60.563 120.003 59.924 117.298 59.924H117.268C107.673 59.924 99.894 67.961 99.894 77.875V77.906C99.894 87.82 107.673 95.857 117.268 95.857H117.298C120.002 95.857 122.561 95.219 124.844 94.08C119.027 91.178 115.015 85.027 115.015 77.906Z"
      fill="#DB9C2E"
    />
    <path
      d="M136.193 90.385V90.354C136.193 83.234 140.205 77.082 146.022 74.18C143.74 73.041 141.181 72.403 138.477 72.403H138.447C128.851 72.403 121.073 80.44 121.073 90.354V90.385C121.073 100.299 128.852 108.336 138.447 108.336H138.477C141.181 108.336 143.74 107.698 146.022 106.559C140.205 103.656 136.193 97.505 136.193 90.385Z"
      fill="#DB9C2E"
    />
    <path
      d="M113.556 226.422H142.444L141.953 210.799H114.047L113.556 226.422Z"
      fill="#EACF32"
    />
    <path
      d="M114.537 195.176H141.463L140.972 179.553H115.028L114.537 195.176Z"
      fill="#EACF32"
    />
    <path
      d="M115.518 163.93H140.482L139.991 148.307H116.009L115.518 163.93Z"
      fill="#EACF32"
    />
    <path
      d="M116.499 132.684H139.501L139.01 117.061H116.989L116.499 132.684Z"
      fill="#EACF32"
    />
    <path
      d="M114.047 210.799L113.791 218.957L113.556 226.422H128.677L129.168 210.799H114.047Z"
      fill="#CCC033"
    />
    <path
      d="M115.028 179.553L114.977 181.152L114.537 195.176H129.658L130.149 179.553H115.028Z"
      fill="#CCC033"
    />
    <path
      d="M116.009 148.307L115.899 151.801L115.518 163.93H130.639L131.13 148.307H116.009Z"
      fill="#CCC033"
    />
    <path
      d="M116.989 117.061L116.735 125.174L116.499 132.684H131.62L132.111 117.061H116.989Z"
      fill="#CCC033"
    />
    <path
      d="M67.579 88.437C72.4115 88.437 76.329 84.5195 76.329 79.687C76.329 74.8545 72.4115 70.937 67.579 70.937C62.7465 70.937 58.829 74.8545 58.829 79.687C58.829 84.5195 62.7465 88.437 67.579 88.437Z"
      fill="#99AF17"
    />
  </svg>
);
export default PalmTree;
