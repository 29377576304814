import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Typography,
  Tooltip
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import UserIcon from '@material-ui/icons/AccountCircleOutlined';
import InputIcon from '@material-ui/icons/Input';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import { useGetIssuesByState } from 'modules/issues/graphql';
import Notifications from './Notifications';
import ChatBar from './ChatBar';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import { useLogOut } from 'modules/authentication/hooks/useAuth';
import Settings from './Settings';
import { useRoles } from 'modules/authentication';
import logo from 'assets/images/logo-cabecera.png'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    background: 'linear-gradient(90deg, rgba(11,97,105,1) 0%, rgba(158,187,72,1) 100%)',
    borderBottom: '1px solid #eee'
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.white
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  logo: {
    height: '50%',
    width: '50%'
  }
}));

const Header = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const { chat__worker, chat__organization, chat__administrator } = useRoles();
  const { data } = useGetIssuesByState();
  const classes = useStyles();
  const notificationsRef = useRef(null);
  const settingsRef = useRef(null);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openChatBar, setOpenChatBar] = useState(false);

  const logOut = useLogOut();
  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleSettingsOpen = () => {
    setOpenSettings(true);
  };

  const handleSettingsClose = () => {
    setOpenSettings(false);
  };

  const handleLogOut = () => {
    logOut();
  };

  const handleChatBarOpen = () => {
    setOpenChatBar(true);
  };

  const handleChatBarClose = () => {
    setOpenChatBar(false);
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <div className={classes.brand}>
          <img src={logo} alt={logo} className={classes.logo} />
            {/*<NaturePeopleIcon color="inherit" />
            <Typography
              variant="h4"
              color="inherit"
              style={{ paddingLeft: '0.3rem' }}>{`Smart Garden`}</Typography>*/}
          </div>
        </RouterLink>
        <div className={classes.flexGrow} />
        {(chat__administrator || chat__organization || chat__worker) && (
          <Tooltip title="Usuarios disponibles">
            <IconButton
              className={classes.chatButton}
              color="inherit"
              onClick={handleChatBarOpen}>
              <PeopleIcon />
            </IconButton>
          </Tooltip>
        )}
        <Hidden mdDown>
          {false && (
            <Tooltip title="Notificaciones">
              <IconButton
                className={classes.notificationsButton}
                color="inherit"
                onClick={handleNotificationsOpen}
                ref={notificationsRef}>
                <Badge
                  badgeContent={data && data.toDo ? data.toDo.length : 0}
                  color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Usuario">
            <IconButton
              className={classes.notificationsButton}
              color="inherit"
              onClick={handleSettingsOpen}
              ref={settingsRef}>
              <UserIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={handleLogOut}>
            <InputIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Settings
        anchorEl={settingsRef.current}
        onClose={handleSettingsClose}
        open={openSettings}
      />
      <Notifications
        anchorEl={notificationsRef.current}
        notifications={data ? data.toDo : []}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
      {(chat__administrator || chat__organization || chat__worker) && (
        <ChatBar onClose={handleChatBarClose} open={openChatBar} />
      )}
    </AppBar>
  );
};

export default Header;
