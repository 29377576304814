import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, colors } from '@material-ui/core';
import View from 'components/View';
import Loader from 'components/Loader';
import { useGetWorkOrderDetails } from 'modules/issues/graphql';
import Actions from './Actions';
import ConfirmDelete from './ConfirmDelete';
import Summary from './Summary';
import IssueModal from 'views/IssueModal';
import WorkOrderModal from 'views/WorkOrderModal';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const WorkOrderDetails = () => {
  const classes = useStyles();
  const { data, loading } = useGetWorkOrderDetails();
  if (!data?.workOrder) return null;
  return (
    <View
      title="Orden de trabajo"
      subtitle={!loading && data.workOrder ? `#${data.workOrder.id}` : ''}
      actions={<Actions workOrder={data && data.workOrder} />}>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {loading && <Loader />}
        {data && <Summary workOrder={data.workOrder} />}
      </div>
      <ConfirmDelete />
      <IssueModal />
      <WorkOrderModal />
    </View>
  );
};

export default WorkOrderDetails;
