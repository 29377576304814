import * as React from 'react';
import { PureComponent } from 'react';
import { Marker } from 'react-map-gl';
import renderIcon from './icons';

// Important for perf: the markers never change, avoid rerender when the map viewport changes
export default class Pins extends PureComponent {
  render() {
    const { data = [], type, onClick } = this.props;
    return data.map((feature) => {
      return (
        <Marker
          key={`marker-${feature.properties.id}`}
          longitude={feature.geometry.coordinates[0]}
          latitude={feature.geometry.coordinates[1]}
          captureClick>
          {renderIcon({
            feature,
            type:
              type === 'selected'
                ? type
                : feature.properties.vegetacion_inventariables?.[0]
                    ?.vegetationType ||
                  feature.properties.subtype ||
                  type,
            onClick
          })}
        </Marker>
      );
    });
  }
}
