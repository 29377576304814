import React from 'react';
import Table from 'components/Table';
import Label from 'components/Label';
import { colors, Link } from '@material-ui/core';
import { useGetIssuesByInventoryItem } from 'modules/issues/graphql';
import { Link as RouterLink } from 'react-router-dom';
import Loader from 'components/Loader';
import Actions from './Actions';
import moment from 'moment';
import { parseState } from 'utils/issues';

const columns = [
  {
    Header: 'Título',
    accessor: data => data,
    Cell: ({ cell: { value } }) => (
      <Link
        color="inherit"
        component={RouterLink}
        to={`/issues/${value.id}`}
        variant="h6">
        {value.title}
      </Link>
    )
  },
  {
    Header: 'ID',
    accessor: row => `${row.id}`
  },
  {
    Header: 'Usuario',
    accessor: 'organization_user',
    Cell: ({ cell: { value } }) => value && `${value.name} ${value.surname} `
  },
  {
    Header: 'Reponsable',
    accessor: 'organizationUserByProcessedByUserId',
    Cell: ({ cell: { value } }) => value && `${value.name} ${value.surname} `
  },
  {
    Header: 'Creada',
    accessor: 'date',
    Cell: ({ cell: { value } }) => moment(value).format('DD/MM/YYYY')
  },
  {
    Header: 'Estado',
    accessor: 'state',
    Cell: ({ cell: { value } }) => (
      <Label color={colors.red[700]} variant="outlined">
        {parseState(value)}
      </Label>
    )
  },

  {
    Header: '',
    id: 'actions',
    accessor: data => data,
    Cell: ({ cell: { value } }) => <Actions issue={value} />
  }
];

const Issues = props => {
  const { data, loading } = useGetIssuesByInventoryItem(props.id);
  return <Table columns={columns} data={data?.issues} loading={loading} />;
};

export default Issues;
