import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { INVENTORY_ITEM_FRAGMENT } from '../fragments';
import {
  VEGETATION_FRAGMENT,
  FURNITURE_FRAGMENT,
  TREE_FRAGMENT,
  BUSH_FRAGMENT,
  CARPET_FRAGMENT,
  SURFACE_FRAGMENT,
  PLAYGROUND_FRAGMENT
} from 'modules/inventory/graphql/fragments';

const initialCoordinates = [
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0]
];

export const GET_INVENTORY = gql`
  query getInventory($geometry: geometry) {
    pointsWithIssues: elemento_inventario(
      where: {
        inventoryItemType: { _neq: "surface" }
        location: { _st_within: $geometry }
        issues: { state: { _eq: "toDo" } }
      }
    ) {
      ...inventoryItemFields
    }
    surfaceWithIssues: elemento_inventario(
      where: {
        inventoryItemType: { _eq: "surface" }
        location: { _st_within: $geometry }
        issues: { state: { _eq: "toDo" } }
      }
    ) {
      ...inventoryItemFields
    }
    sensors: elemento_inventario(
      where: {
        inventoryItemType: { _eq: "sensor" }
        location: { _st_within: $geometry }
      }
    ) {
      ...inventoryItemFields
      issuesCount: issues_aggregate(where: { state: { _eq: "toDo" } }) {
        aggregate {
          count
        }
      }
    }
    furniture: elemento_inventario(
      where: {
        inventoryItemType: { _eq: "furniture" }
        location: { _st_within: $geometry }
      }
    ) {
      ...inventoryItemFields
      issuesCount: issues_aggregate(where: { state: { _eq: "toDo" } }) {
        aggregate {
          count
        }
      }
    }
    vegetation: elemento_inventario(
      where: {
        inventoryItemType: { _eq: "vegetation" }
        location: { _st_within: $geometry }
      }
    ) {
      ...inventoryItemFields
      vegetacion_inventariables {
        ...vegetationFields
        arbusto_inventariables {
          ...bushFields
        }
        tapiz_inventariables {
          ...carpetFields
        }
        arbol_inventariables {
          ...treeFields
        }
        palmera_inventariables {
          tipo_hoja
        }
      }
      issuesCount: issues_aggregate(where: { state: { _eq: "toDo" } }) {
        aggregate {
          count
        }
      }
    }
    surface: elemento_inventario(
      where: {
        inventoryItemType: { _eq: "surface" }
        location: { _st_intersects: $geometry }
      }
    ) {
      ...inventoryItemFields
      issuesCount: issues_aggregate(where: { state: { _eq: "toDo" } }) {
        aggregate {
          count
        }
      }
    }
    playground: elemento_inventario(
      where: {
        inventoryItemType: { _eq: "playground" }
        location: { _st_within: $geometry }
      }
    ) {
      ...inventoryItemFields
      issuesCount: issues_aggregate(where: { state: { _eq: "toDo" } }) {
        aggregate {
          count
        }
      }
    }
  }
  ${INVENTORY_ITEM_FRAGMENT}
  ${VEGETATION_FRAGMENT}
  ${FURNITURE_FRAGMENT}
  ${TREE_FRAGMENT}
  ${BUSH_FRAGMENT}
  ${CARPET_FRAGMENT}
  ${SURFACE_FRAGMENT}
  ${PLAYGROUND_FRAGMENT}
`;

export const useGetInventory = (
  coordinates,
  level = 1,
  zoom = 15,
  signal = undefined
) => {
  let geometry = {
    type: 'Polygon',
    crs: {
      type: 'name',
      properties: {
        name: 'urn:ogc:def:crs:EPSG::4326'
      }
    },
    coordinates: [coordinates && zoom >= 15 ? coordinates : initialCoordinates]
  };
  const query = useQuery(GET_INVENTORY, {
    variables: {
      geometry
    },
    context: {
      fetchOptions: {
        signal
      }
    }
  });
  return query;
};
