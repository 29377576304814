import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 300,
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 }
}));

const Features = ({ inventoryItem }) => {
  const classes = useStyles();
  const { name, surfaces, created_at, updated_at } = inventoryItem || {};
  const { area, perimeter, slope } = (surfaces || [])[0] || {};
  return (
    <Card className={classes.root}>
      <CardHeader title="Datos" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell className={classes.data}>
                {name || 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Superficie</TableCell>
              <TableCell className={classes.data}>
                {area ? `${area} m2` : 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Perímetro</TableCell>
              <TableCell className={classes.data}>
                {perimeter ? `${perimeter} m` : 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pendiente</TableCell>
              <TableCell className={classes.data}>
                {slope ? `${slope} %` : 'Desconocido'}
              </TableCell>
            </TableRow>
            {/*}
            <TableRow>
              <TableCell>Creación</TableCell>
              <TableCell className={classes.data}>
                {created_at
                  ? new Date(created_at).toLocaleDateString()
                  : 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ultima modificación</TableCell>
              <TableCell className={classes.data}>
                {updated_at
                  ? new Date(updated_at).toLocaleDateString()
                  : 'Desconocido'}
              </TableCell>
                </TableRow>*/}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Features;
