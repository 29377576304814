import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  colors
} from '@material-ui/core';
import Label from 'components/Label';
import { useChatDispatch } from 'modules/chat';
import { getInitials } from 'utils/users';

const useStyles = makeStyles(theme => ({
  active: {
    boxShadow: `inset 4px 0px 0px ${theme.palette.primary.main}`,
    backgroundColor: colors.grey[50]
  },
  avatar: {
    height: 40,
    width: 40
  },
  details: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  unread: {
    marginTop: 2,
    padding: 2,
    height: 18,
    minWidth: 18
  }
}));

const Item = ({ active, chat, className }) => {
  const classes = useStyles();
  const lastMessage = chat?.messages?.length > 0 ? chat?.messages[0] : {};
  const { selectChat } = useChatDispatch();
  return (
    <ListItem
      button
      className={clsx(
        {
          [classes.active]: active
        },
        className
      )}
      component={RouterLink}
      to={`/chat/${chat?.id}`}
      onClick={() => selectChat(chat)}>
      <ListItemAvatar>
        <Avatar
          alt="Person"
          className={classes.avatar}
          src={chat?.users[0]?.organization_user?.file?.url}>
          {getInitials(
            `${chat?.users[0]?.organization_user?.name} ${chat?.users[0]?.organization_user?.surname}`
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${chat?.users[0]?.organization_user?.name} ${chat?.users[0]?.organization_user?.surname}`}
        primaryTypographyProps={{
          noWrap: true,
          variant: 'h6'
        }}
        secondary={chat?.messages[0]?.content}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'body1'
        }}
      />
      <div className={classes.details}>
        <Typography noWrap variant="body2">
          {moment(lastMessage.created_at).isSame(moment(), 'day')
            ? moment(lastMessage?.created_at).format('LT')
            : moment(lastMessage?.created_at).fromNow()}
        </Typography>
        {chat.unread > 0 && (
          <Label
            className={classes.unread}
            color={colors.red[500]}
            shape="rounded">
            {chat.unread}
          </Label>
        )}
      </div>
    </ListItem>
  );
};

export default Item;
