import React from 'react';
import { Link, Tooltip } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { inventoryTypes } from 'utils/inventory';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

export default [
  {
    Header: 'Nombre',
    accessor: data => data,
    Cell: ({ cell: { value } }) => (
      <Link
        color="inherit"
        component={RouterLink}
        to={`/inventory/${value.id}`}
        variant="h6">
        {value.name}
      </Link>
    )
  },
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Tipo',
    accessor: data => inventoryTypes[data.inventoryItemType]
  },

  {
    Header: 'Incidencias',
    accessor: 'issues',
    Cell: ({ cell: { value } }) => (
      <Tooltip
        title={
          value?.aggregate?.count > 0
            ? 'Incidencias pendientes'
            : 'Sin incidencias'
        }>
        <PriorityHighIcon
          color={value?.aggregate?.count > 0 ? 'error' : 'disabled'}
        />
      </Tooltip>
    )
  }
];
