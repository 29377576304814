import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import View from 'components/View';
import ConfirmDelete from './ConfirmDelete';
import OrganizationModal from 'views/OrganizationModal';
import WorkerModal from 'views/WorkerModal';
import Organizations from './Organizations';
import Workers from './Workers';
import Profiles from './Profiles';
import Actions from './Actions';
import ProfileModal from 'views/ProfileModal';
import { useRoles } from 'modules/authentication';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  tabs: {
    fontWeight: 600,
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

function useTabs(initialTabs = [], index = 0) {
  const [currentIndex, setCurrentIndex] = useState(index);

  const tabs = useMemo(() => {
    let filteredTabs = initialTabs.filter(tab =>
      tab.auth ? tab?.auth?.some(role => role) : true
    );
    return filteredTabs;
  }, [initialTabs]);

  const handleTabs = value => {
    let newIndex = tabs.findIndex(tab => tab.value === value);
    setCurrentIndex(newIndex);
  };

  return [tabs, handleTabs, currentIndex];
}

const Users = () => {
  const classes = useStyles();
  const { users__administrator, users__organization } = useRoles();

  const initialTabs = [
    {
      value: 'organizations',
      label: 'Organizaciones',
      auth: [users__administrator]
    },
    {
      value: 'workers',
      label: 'Trabajadores',
      auth: [users__administrator, users__organization]
    },
    { value: 'profiles', label: 'Perfiles', auth: [users__administrator, users__organization] }
  ];

  const [tabs, handleTabs, currentIndex] = useTabs(initialTabs, 0);

  return (
    <View title={'Usuarios'} actions={<Actions />}>
      <Tabs
        className={classes.tabs}
        scrollButtons="auto"
        onChange={(event, value) => handleTabs(value)}
        value={tabs[currentIndex].value}
        variant="scrollable"
        indicatorColor="primary">
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tabs[currentIndex].value === 'organizations' &&
          users__administrator && <Organizations />}
        {tabs[currentIndex].value === 'workers' && <Workers />}
        {tabs[currentIndex].value === 'profiles' && <Profiles />}
      </div>
      <OrganizationModal />
      <WorkerModal />
      <ProfileModal />
      <ConfirmDelete />
    </View>
  );
};

export default Users;
