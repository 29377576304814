import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector';
import { createStructuredSelector } from 'reselect';

const selectedSensorSelector = state => state.sensors.selectedSensor;
const selectedDatastreamSelector = state => state.sensors.selectedDatastream;
const showSensorModalSelector = state => state.sensors.showSensorModal;
const showDeleteSensorModalSelector = state =>
  state.sensors.showDeleteSensorModal;
const alertSelector = state => state.sensors.alert;

const selectors = createStructuredSelector({
  selectedSensor: selectedSensorSelector,
  selectedDatastream: selectedDatastreamSelector,
  showSensorModal: showSensorModalSelector,
  showDeleteSensorModal: showDeleteSensorModalSelector,
  alert: alertSelector
});

export function useSensorsState() {
  return useShallowEqualSelector(selectors);
}
