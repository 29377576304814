import { client } from 'modules/core';

export const LOGIN = `${process.env.REACT_APP_BACKEND_URL}/token`;
export const REFRESH_TOKEN = `${process.env.REACT_APP_BACKEND_URL}/token/refresh`;

export const login = async (email, password) => {
  try {
    const response = await fetch(LOGIN, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    });
    const body = await response.json();
    if (response.ok) return body;
    return { code: response.status, message: body.msg };
  } catch (error) {
    return { code: 500, message: error.message || `Invalid request ${LOGIN}` };
  }
};

export const logOut = () => {
  localStorage.removeItem('Authorization');
  return client.clearStore();
};

export const getAuthHeader = () => {
  return localStorage.getItem('Authorization') || null;
};

export const setAuthHeader = token => {
  localStorage.setItem('Authorization', token);
};

export const getNewAuthHeader = async () => {
  const refreshToken = JSON.parse(getAuthHeader())?.refresh;
  try {
    const response = await fetch(REFRESH_TOKEN, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${refreshToken}`
      }
    });
    const body = await response.json();
    if (response.ok) {
      setAuthHeader(JSON.stringify(body?.access_token));
    }
    return { code: response.status, message: body.msg };
  } catch (error) {
    return {
      code: 500,
      message: error.message || `Invalid request ${REFRESH_TOKEN}`
    };
  }
};
