import React from 'react';
import { Grid } from '@material-ui/core';
import Type from './Type';
import Subtype from './Subtype';
import Map from '../Map';
import Features from './Features';
import Images from '../Images';
import Observations from './Observations';

const Surface = ({ inventoryItem }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xl={6} xs={12}>
        <Grid container spacing={4}>
          <Grid item lg={6} xs={12}>
            <Type />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Subtype name={inventoryItem?.surfaces[0]?.surface_type?.name} />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Features inventoryItem={inventoryItem} />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Images inventoryItem={inventoryItem} />
          </Grid>
          <Grid item xs={12}>
            <Observations observations={inventoryItem?.observations} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={6} xs={12}>
        <Map inventoryItem={inventoryItem} />
      </Grid>
    </Grid>
  );
};

export default Surface;
