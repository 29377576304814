import gql from 'graphql-tag';

export const SETTINGS_FRAGMENT = gql`
  fragment settingsFields on settings {
    id
    default_location
    country
    city
    organization_user_id
  }
`;
