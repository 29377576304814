import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Divider } from '@material-ui/core';
import Toolbar from './Toolbar';
import Messages from './Messages';
import MessageBox from './MessageBox';
import Loader from 'components/Loader';
import ConfirmDelete from './ConfirmDelete';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white
  }
}));

const ChatDetails = ({ chat, messages, className }) => {
  const classes = useStyles();
  const [message, setMessage] = useState(messages);

  useEffect(() => {
    setMessage(messages);
  }, [messages]);

  return (
    <div className={clsx(classes.root, className)}>
      <Toolbar chat={chat} />
      <Divider />
      {!messages && <Loader />}
      <Messages messages={message} />
      <Divider />
      <MessageBox />
      <ConfirmDelete />
    </div>
  );
};

export default ChatDetails;
