import gql from 'graphql-tag';
import { useSubscription } from 'react-apollo';

export const GET_MESSAGE_ADDED = gql`
  subscription getMessageAdded {
    message {
      id
      chat_id
      sender {
        id
        name
      }
      content
      created_at
    }
  }
`;

export const useGetMessageAdded = () => {
  const { data, error, loading } = useSubscription(GET_MESSAGE_ADDED);
  return {
    data,
    error,
    loading
  };
};
