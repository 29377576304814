import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { CHAT_FRAGMENT } from '../fragments';
import { useInfo } from 'modules/authentication/hooks/useAuth';

export const GET_CHAT_LIST = gql`
  query getChatList($userId: Int) {
    chats: chat(
      where: { chat_users: { user_id: { _eq: $userId } } }
      order_by: [{ messages_aggregate: { max: { created_at: desc } } }]
    ) {
      ...chatFields
      users: chat_users(where: { user_id: { _neq: $userId } }) {
        organization_user {
          id
          name
          surname
          file {
            id
            name
            url
          }
        }
      }
    }
  }
  ${CHAT_FRAGMENT}
`;

export const useGetChatList = () => {
  const { userId } = useInfo();
  const { data, error, loading } = useQuery(GET_CHAT_LIST, {
    variables: {
      userId
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 500
  });
  return {
    data,
    error,
    loading
  };
};
