import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useClasses from 'hooks/useClasses';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.white
  }
});

const SearchInput = (props) => {
  const classes = useClasses(styles);
  return (
    <TextField
      className={classes.root}
      size="small"
      variant="outlined"
      placeholder="Buscar elemento"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
};

export default SearchInput;
