import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showOrganizationModal: false,
  showWorkerModal: false,
  showDeleteOrganizationModal: false,
  showDeleteWorkerModal: false,
  showProfileModal: false,
  showDeleteProfileModal: false,
  showUpdateProfileModal: false,
  selectedOrganization: undefined,
  selectedWorker: undefined,
  selectedProfile: undefined,
  selectedRoles: [],
  showWorkerUploadModal: false,
  showOrganizationUploadModal: false,
  selectedFile: undefined,
  alert: undefined,
  showWebUserModal: false
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loadUsers: () => initialState,
    openOrganizationModal: (state, { payload }) => ({
      ...state,
      selectedOrganization: payload.organization
        ? {
            ...payload.organization,
            profiles: payload.profiles
          }
        : undefined,
      showOrganizationModal: true
    }),
    closeOrganizationModal: (state) => ({
      ...state,
      selectedOrganization: undefined,
      showOrganizationModal: false
    }),
    openWorkerModal: (state, { payload }) => ({
      ...state,
      selectedWorker: payload?.worker,
      selectedOrganization: payload?.organization,
      showWorkerModal: true
    }),
    closeWorkerModal: (state) => ({
      ...state,
      selectedWorker: undefined,
      selectedOrganization: undefined,
      showWorkerModal: false
    }),
    openDeleteOrganizationModal: (state, { payload }) => ({
      ...state,
      selectedOrganization: payload,
      showDeleteOrganizationModal: true
    }),
    closeDeleteOrganizationModal: (state) => ({
      ...state,
      selectedOrganization: undefined,
      showDeleteOrganizationModal: false
    }),
    openDeleteWorkerModal: (state, { payload }) => ({
      ...state,
      selectedWorker: payload,
      showDeleteWorkerModal: true
    }),
    closeDeleteWorkerModal: (state) => ({
      ...state,
      selectedWorker: undefined,
      showDeleteWorkerModal: false
    }),
    openProfileModal: (state, { payload }) => ({
      ...state,
      selectedProfile: payload,
      showProfileModal: true
    }),
    closeProfileModal: (state) => ({
      ...state,
      selectedProfile: undefined,
      showProfileModal: false
    }),
    openDeleteProfileModal: (state, { payload }) => ({
      ...state,
      selectedProfile: payload,
      showDeleteProfileModal: true
    }),
    closeDeleteProfileModal: (state) => ({
      ...state,
      selectedProfile: undefined,
      showDeleteProfileModal: false
    }),
    openUpdateProfileModal: (state, { payload }) => ({
      ...state,
      selectedProfile: payload.profile,
      selectedRoles: payload.roles,
      showUpdateProfileModal: true
    }),
    closeUpdateProfileModal: (state) => ({
      ...state,
      selectedProfile: undefined,
      selectedRoles: [],
      showUpdateProfileModal: false
    }),
    openWorkerUploadModal: (state, { payload }) => ({
      ...state,
      selectedWorker: payload.worker,
      selectedFile: payload.file,
      showWorkerUploadModal: true
    }),
    closeWorkerUploadModal: (state) => ({
      ...state,
      selectedWorker: undefined,
      selectedFile: undefined,
      showWorkerUploadModal: false
    }),
    openOrganizationUploadModal: (state, { payload }) => ({
      ...state,
      selectedOrganization: payload.organization,
      selectedFile: payload.file,
      showOrganizationUploadModal: true
    }),
    closeOrganizationUploadModal: (state) => ({
      ...state,
      selectedOrganization: undefined,
      selectedFile: undefined,
      showOrganizationUploadModal: false
    }),
    openErrorAlert: (state, { payload }) => ({
      ...state,
      alert: {
        severity: 'error',
        message: payload
      }
    }),
    openSuccessAlert: (state, { payload }) => ({
      ...state,
      alert: {
        severity: 'success',
        message: payload
      }
    }),
    closeAlert: (state) => ({
      ...state,
      alert: undefined
    }),
    openWebUserModal: (state, { payload }) => ({
      ...state,
      selectedWorker: payload,
      showWebUserModal: true
    }),
    closeWebUserModal: (state) => ({
      ...state,
      selectedWorker: undefined,
      showWebUserModal: false
    })
  }
});

export const {
  loadUsers,
  openOrganizationModal,
  closeOrganizationModal,
  openWorkerModal,
  closeWorkerModal,
  openDeleteOrganizationModal,
  closeDeleteOrganizationModal,
  openDeleteWorkerModal,
  closeDeleteWorkerModal,
  openProfileModal,
  closeProfileModal,
  openDeleteProfileModal,
  closeDeleteProfileModal,
  openUpdateProfileModal,
  closeUpdateProfileModal,
  closeAlert,
  openSuccessAlert,
  openErrorAlert,
  openOrganizationUploadModal,
  openWorkerUploadModal,
  closeOrganizationUploadModal,
  closeWorkerUploadModal,
  openWebUserModal,
  closeWebUserModal
} = usersSlice.actions;

export default usersSlice.reducer;
