import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import {
  ORGANIZATION_FRAGMENT,
  WORKER_FRAGMENT,
  PROFILE_FRAGMENT
} from '../fragments';
import useRouter from 'hooks/useRouter';
import { useRoles } from 'modules/authentication';

export const GET_WORKER_DETAILS = gql`
  query getWorkerDetails($id: Int!) {
    worker: organization_user_by_pk(id: $id) {
      ...workerFields
      organization {
        ...organizationFields
      }
      profile {
        ...profileFields
      }
      file {
        id
        name
        url
        user_id
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
  ${WORKER_FRAGMENT}
  ${PROFILE_FRAGMENT}
`;

export const useGetWorkerDetails = () => {
  const { users__administrator, users__organization } = useRoles();
  const { match } = useRouter();
  const { id } = match.params || {};
  const { data, error, loading } = useQuery(GET_WORKER_DETAILS, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        'x-hasura-role': users__administrator
          ? 'users__administrator'
          : users__organization
          ? 'users__organization'
          : undefined
      }
    }
  });
  return {
    data,
    error,
    loading
  };
};
