import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Drawer, Typography } from '@material-ui/core';
import { useSensorState } from 'modules/sensors';
import locale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import moment from 'moment';
import { useGetDatastream } from 'modules/sensors/graphql/queries/getDatastream';
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

function Filter({ open, onClose, datastream }) {
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const { data } = useGetDatastream(datastream.id, startDate, setEndDate);
  const handleStartDateChange = date => {
    setStartDate(date);
  };
  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleSubmit = event => {
    event.preventDefault();
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary">
      <form className={classes.root} onSubmit={handleSubmit}>
        <div className={classes.header}></div>
        <div className={classes.content}>
          <div className={classes.contentSection}>
            <div className={classes.contentSectionHeader}>
              <Typography variant="h5">Filtros</Typography>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="outlined"
                  label="Fecha inicio"
                  value={startDate}
                  onChange={handleStartDateChange}
                  format="dd/MM/yyyy"
                  fullWidth
                  cancelLabel="cancelar"
                  invalidDateMessage="Fecha incorrecta"
                />
              </div>
              <div className={classes.formGroup}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="outlined"
                  label="Fecha fin"
                  value={endDate}
                  onChange={handleEndDateChange}
                  format="dd/MM/yyyy"
                  fullWidth
                  invalidDateMessage="Fecha incorrecta"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Button
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
            onClick={onClose}>
            Aplicar
          </Button>
        </div>
      </form>
    </Drawer>
  );
}

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Filter;
