import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  Divider,
  CardHeader
} from '@material-ui/core';
import { modules } from 'utils/roles';
import Alert from 'components/Alert';
import { useUsersDispatch } from 'modules/users';
import ConfirmUpdate from '../ConfirmUpdate';
import { useRoles } from 'modules/authentication';

const useStyles = makeStyles(theme => ({
  root: {},
  title: { padding: theme.spacing(2) },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  actions: { justifyContent: 'flex-end' }
}));

const Roles = React.memo(
  ({ profile = {}, accesses = [], permissions = [] }) => {
    const classes = useStyles();
    const { users__administrator, users__update } = useRoles();
    const [selectedAccesses, setSelectedAccesses] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [edited, setEdited] = useState(false);
    const [updated, setUpdated] = useState(false);
    const { openUpdateProfileModal } = useUsersDispatch();

    const resetData = () => {
      setEdited(false);
      let initialAccesses = profile.accesses
        ? profile.accesses.map(({ role }) => role)
        : [];
      let initialPermissions = profile.permissions
        ? profile.permissions.map(({ role }) => role)
        : [];
      setSelectedAccesses(initialAccesses);
      setSelectedPermissions(initialPermissions);
    };

    useEffect(() => {
      resetData();
    }, []);

    const handleChangePermissions = (event, module) => {
      event.preventDefault();
      if (users__administrator) {
        setEdited(true);
        let id = Number(event?.target?.value);
        if (event.target.checked) {
          setSelectedPermissions(prevState => [
            ...prevState,
            {
              id,
              module,
              type: 'permission',
              __typename: 'organization_user_role'
            }
          ]);
        } else {
          setSelectedPermissions(prevState =>
            prevState.filter(role => role.id !== id)
          );
        }
      }
    };

    const handleChangeAccesses = (event, module) => {
      event.preventDefault();
      if (users__administrator) {
        setEdited(true);
        let accesses = selectedAccesses?.filter(role => role.module !== module);
        let id = Number(event.target[event.target.selectedIndex].value);
        id !== 0
          ? setSelectedAccesses([
              {
                id,
                module,
                type: 'access',
                __typename: 'organization_user_role'
              },
              ...accesses
            ])
          : setSelectedAccesses([...accesses]);
      }
    };

    const handleSubmit = event => {
      event.preventDefault();
      openUpdateProfileModal({ profile, roles: selectedRoles });
    };

    useEffect(() => {
      setSelectedRoles([
        ...selectedPermissions.map(role => role.id),
        ...selectedAccesses.map(role => role.id)
      ]);
    }, [selectedPermissions, selectedAccesses]);

    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={6} wrap="wrap">
          {Object.keys(modules).map(module => {
            return (
              <Grid
                key={modules[module]?.title}
                className={classes.item}
                item
                md={4}
                sm={6}
                xs={12}>
                <Card className={classes.root}>
                  <CardHeader title={modules[module]?.title} />
                  <Divider />
                  <CardContent>
                    <TextField
                      fullWidth
                      label="Rol"
                      placeholder="Sin acceso"
                      onChange={event => handleChangeAccesses(event, module)}
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={
                        selectedAccesses?.find(role => role.module === module)
                          ?.id
                      }>
                      <option value={''}>Sin acceso</option>
                      {accesses
                        .filter(role => role.module === module)
                        .map(({ id, name }) => (
                          <option key={id} value={id}>
                            {modules[module]?.roles[name]}
                          </option>
                        ))}
                    </TextField>
                    {permissions
                      .filter(role => role.module === module)
                      .map(({ id, name }) => (
                        <FormControlLabel
                          key={id}
                          control={
                            <Checkbox
                              color="primary"
                              checked={selectedPermissions?.some(
                                role => role.id === id
                              )}
                              name={name}
                              onChange={event =>
                                handleChangePermissions(event, module)
                              }
                              value={id}
                            />
                          }
                          label={modules[module]?.roles[name]}
                        />
                      ))}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        {users__administrator && edited && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: 20
            }}>
            <Button color="primary" variant="none" onClick={() => resetData()}>
              Cancelar
            </Button>

            <Button color="primary" type="submit" variant="contained">
              Actualizar
            </Button>
          </div>
        )}
        <ConfirmUpdate
          fireOnClose={() => {
            setUpdated(true);
            setEdited(false);
          }}
        />
        <Alert
          show={updated}
          fireOnClose={() => setUpdated(false)}
          message="Los permisos han sido actualizados correctamente."
          severity="success"
        />
      </form>
    );
  }
);

export default Roles;
