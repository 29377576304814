import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_INVENTORY_ITEM_DETAILS } from '../queries';

export const DELETE_INVENTORY_FILE = gql`
  mutation deleteInventoryFile($id: Int!, $inventoryId: Int) {
    delete_file(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const useDeleteInventoryFile = () => {
  let [mutate] = useMutation(DELETE_INVENTORY_FILE);
  return ({ id, inventoryId }) => {
    return mutate({
      variables: { id },
      refetchQueries: [
        {
          query: GET_INVENTORY_ITEM_DETAILS,
          variables: { id: inventoryId }
        }
      ]
    });
  };
};
