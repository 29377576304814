import React from 'react';
import {
  Divider,
  Grid,
  InputAdornment,
  TextField,
  colors,
  Typography
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import useClasses from 'hooks/useClasses';

const styles = theme => ({
  header: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  divider: {
    marginBottom: theme.spacing(3),
    backgroundColor: colors.grey[300]
  },
  grid: {
    paddingBottom: theme.spacing(2)
  }
});

const Environment = () => {
  const classes = useClasses(styles);
  const { values, handleChange } = useFormikContext();
  const {
    de_tipo_posicion,
    de_tipo_superficie,
    de_ancho_acera_m,
    de_tipo_vial,
    de_distancia_fachada_m,
    de_tipo_riego,
    de_marco_plantacion,
    de_entorno_calzada,
    de_dist_ejes_alineacion,
    de_protector,
    de_protector_estado,
    dd_altura_primera_rama_m,
    dd_altura_total_m,
    dd_diam_copa_cm,
    dd_perimetro_tronco_cm
  } = values.vegetacion_inventariables[0].arbol_inventariables[0] || {};
  return (
    <div>
      <Typography variant="h5" className={classes.header}>
        Entorno
      </Typography>

      <Grid
        container
        spacing={3}
        alignItems="center"
        justify="center"
        className={classes.grid}>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Posición"
            name="vegetacion_inventariables[0].arbol_inventariables[0].de_tipo_posicion"
            onChange={handleChange}
            value={de_tipo_posicion}
            variant="outlined">
            {de_tipo_posicion}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Ancho acera"
            name="vegetacion_inventariables[0].arbol_inventariables[0].de_ancho_acera_m"
            onChange={handleChange}
            value={de_ancho_acera_m}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>
            }}>
            {de_ancho_acera_m}
          </TextField>
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Vial"
            name="vegetacion_inventariables[0].arbol_inventariables[0].de_tipo_vial"
            onChange={handleChange}
            value={de_tipo_vial}
            variant="outlined">
            {de_tipo_vial}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Distancia fachada"
            name="vegetacion_inventariables[0].arbol_inventariables[0].de_distancia_fachada_m"
            onChange={handleChange}
            value={de_distancia_fachada_m}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>
            }}>
            {de_distancia_fachada_m}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Tipo riego"
            name="vegetacion_inventariables[0].arbol_inventariables[0].de_tipo_riego"
            onChange={handleChange}
            value={de_tipo_riego}
            variant="outlined">
            {de_tipo_riego}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Marco plantación"
            name="vegetacion_inventariables[0].arbol_inventariables[0].de_marco_plantacion"
            onChange={handleChange}
            value={de_marco_plantacion}
            variant="outlined">
            {de_marco_plantacion}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Entorno calzada"
            name="vegetacion_inventariables[0].arbol_inventariables[0].de_entorno_calzada"
            onChange={handleChange}
            value={de_entorno_calzada}
            variant="outlined">
            {de_entorno_calzada}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Distancia ejes alineación"
            name="vegetacion_inventariables[0].arbol_inventariables[0].de_dist_ejes_alineacion"
            onChange={handleChange}
            value={de_dist_ejes_alineacion}
            variant="outlined">
            {de_dist_ejes_alineacion}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Protector"
            name="vegetacion_inventariables[0].arbol_inventariables[0].de_protector"
            onChange={handleChange}
            value={de_protector}
            variant="outlined">
            {de_protector}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Estado protector"
            name="vegetacion_inventariables[0].arbol_inventariables[0].de_protector_estado"
            onChange={handleChange}
            value={de_protector_estado}
            variant="outlined">
            {de_protector_estado}
          </TextField>
        </Grid>
      </Grid>
      <Typography variant="h5" className={classes.header}>
        Dendrometría
      </Typography>

      <Grid container spacing={3} alignItems="center" justify="center">
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Perímetro tronco"
            name="vegetacion_inventariables[0].arbol_inventariables[0].dd_perimetro_tronco_cm"
            onChange={handleChange}
            value={dd_perimetro_tronco_cm}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>
            }}>
            {dd_perimetro_tronco_cm}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Diámetro copa"
            name="vegetacion_inventariables[0].arbol_inventariables[0].dd_diam_copa_cm"
            onChange={handleChange}
            value={dd_diam_copa_cm}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>
            }}>
            {dd_diam_copa_cm}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Altura primera rama"
            name="vegetacion_inventariables[0].arbol_inventariables[0].dd_altura_primera_rama_m"
            onChange={handleChange}
            value={dd_altura_primera_rama_m}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>
            }}>
            {dd_altura_primera_rama_m}
          </TextField>
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Altura total"
            name="vegetacion_inventariables[0].arbol_inventariables[0].dd_altura_total_m"
            onChange={handleChange}
            value={dd_altura_total_m}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>
            }}>
            {dd_altura_total_m}
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
};

export default Environment;
