import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import { useGetUsers } from 'modules/users';

const priorities = [
  { id: 'low', name: 'Baja' },
  { id: 'medium', name: 'Media' },
  { id: 'high', name: 'Alta' }
];

const states = [
  { id: 'toDo', name: 'Pendiente' },
  { id: 'inProgress', name: 'En progreso' },
  { id: 'done', name: 'Completado' }
];

const Summary = () => {
  const { values, handleChange, setFieldValue } = useFormikContext();
  const { data } = useGetUsers();
  const {
    priority,
    state,
    due_to_date,
    organization_id,
    title,
    description
  } = values;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Título"
            name="title"
            onChange={handleChange}
            value={title}
            variant="outlined">
            {title}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Prioridad"
            name="priority"
            onChange={handleChange}
            select
            SelectProps={{ native: true }}
            value={priority}
            variant="outlined"
            InputLabelProps={{ shrink: Boolean(priority) }}>
            <option value={''}></option>
            {priorities.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Estado"
            name="state"
            onChange={handleChange}
            select
            SelectProps={{ native: true }}
            value={state}
            variant="outlined"
            InputLabelProps={{ shrink: Boolean(state) }}>
            <option value={''}></option>
            {states.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            label="Vencimiento"
            fullWidth
            inputVariant="outlined"
            value={due_to_date}
            format="dd/MM/yyyy"
            onChange={(newDate, value) =>
              setFieldValue('due_to_date', newDate, false)
            }
            cancelLabel="cancelar"
            invalidDateMessage="Fecha incorrecta"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Organización"
            name="organization_id"
            onChange={handleChange}
            select
            SelectProps={{ native: true }}
            value={organization_id}
            variant="outlined"
            InputLabelProps={{ shrink: Boolean(organization_id) }}>
            <option value={''} />
            {data &&
              data.organizations.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Descripción"
            name="description"
            value={description}
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={2}>
            {description}
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
};

export default Summary;
