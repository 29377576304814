import gql from 'graphql-tag';

export const SURFACE_FRAGMENT = gql`
  fragment surfaceFields on surface {
    id
    area
    perimeter
    slope
    surface_type_id
    surface_type {
      id
      name
    }
  }
`;
