import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  GET_ISSUES,
  GET_ISSUES_BY_INVENTORY_ITEM,
  GET_ISSUES_BY_STATE
} from '../queries';
import { useIssuesDispatch } from 'modules/issues';

export const UPDATE_ISSUE_STATE = gql`
  mutation updateIssue($id: Int!, $state: String) {
    update_issue(where: { id: { _eq: $id } }, _set: { state: $state }) {
      affected_rows
    }
  }
`;

export const useUpdateIssueState = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(UPDATE_ISSUE_STATE, {
    onCompleted: () =>
      openSuccessAlert('Incidencia actualizada correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });

  return args => {
    const { id, state } = args;
    return mutate({
      variables: {
        id,
        state
      },
      refetchQueries: [
        { query: GET_ISSUES },
        { query: GET_ISSUES_BY_STATE },
        {
          query: GET_ISSUES_BY_INVENTORY_ITEM
        }
      ]
    });
  };
};
