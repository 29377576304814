import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import useRouter from 'hooks/useRouter';

export const ADD_MESSAGE = gql`
  mutation addMessage($chatId: Int, $content: String!, $senderId: Int!) {
    insert_message(
      objects: [{ chat_id: $chatId, content: $content, sender_id: $senderId }]
    ) {
      affected_rows
    }
  }
`;

export const useAddMessage = () => {
  let [mutate] = useMutation(ADD_MESSAGE);
  const { match } = useRouter();
  return ({ chatId, content, senderId }) => {
    return mutate({
      variables: {
        chatId: match?.params?.id,
        content,
        senderId
      }
    });
  };
};
