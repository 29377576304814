import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import geojsonArea from '@mapbox/geojson-area';
import { useInventoryDispatch } from 'modules/inventory/hooks';

export const ADD_AREA = gql`
  mutation addArea($objects: [area_insert_input!]!) {
    insert_area(objects: $objects) {
      area: returning {
        id
      }
    }
  }
`;

export const useAddArea = () => {
  const { openSuccessAlert, openErrorAlert } = useInventoryDispatch();
  let [mutate, { data, loading, error }] = useMutation(ADD_AREA, {
    onCompleted: () => openSuccessAlert('Área creada correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });
  return {
    addArea: ({
      name,
      location,
      ground_type_id,
      description,
      surface_m2,
      location_type,
      typology,
      topography
    }) =>
      mutate({
        variables: {
          objects: {
            name,
            ground_type_id: ground_type_id || null,
            location_type,
            typology,
            topography,
            description,
            surface_m2:
              surface_m2 === 0
                ? Math.round(geojsonArea.geometry(location))
                : surface_m2,
            location: {
              ...location,
              crs: {
                type: 'name',
                properties: {
                  name: 'urn:ogc:def:crs:EPSG::4326'
                }
              }
            }
          }
        }
      }),
    data,
    error,
    loading
  };
};
