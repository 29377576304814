import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_USERS } from '../queries';

export const UPDATE_WORKER = gql`
  mutation updateWorker(
    $id: Int!
    $name: String!
    $surname: String
    $external_id: String
    $email: String
    $phone: String
    $organization_id: Int
    $profile_id: Int
  ) {
    update_organization_user(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        surname: $surname
        external_id: $external_id
        organization_id: $organization_id
        profile_id: $profile_id
        email: $email
        phone: $phone
      }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateWorker = () => {
  let [mutate] = useMutation(UPDATE_WORKER);

  return args => {
    const {
      id,
      name,
      surname,
      external_id,
      organization_id,
      profile_id,
      email,
      phone
    } = args;
    return mutate({
      variables: {
        id,
        name,
        surname,
        external_id,
        organization_id,
        profile_id,
        email,
        phone
      },
      refetchQueries: [{ query: GET_USERS }]
    });
  };
};
