import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import {
  REPORT_FRAGMENT,
  TASK_FRAGMENT,
  MAINTENANCE_FRAGMENT
} from '../fragments';
import useRouter from 'hooks/useRouter';
import { useRoles } from 'modules/authentication';

export const GET_TASK_DETAILS = gql`
  query getTaskDetails($id: Int!) {
    task: work_order_task_by_pk(id: $id) {
      ...taskFields
      maintenance {
        ...maintenanceFields
      }
      reports: task_reports {
        ...reportFields
      }
    }
  }
  ${TASK_FRAGMENT}
  ${MAINTENANCE_FRAGMENT}
  ${REPORT_FRAGMENT}
`;

export const useGetTaskDetails = () => {
  const { match } = useRouter();
  const { maintenances__administrator, maintenances__solver } = useRoles();
  const { taskId } = match.params || {};
  const { data, error, loading } = useQuery(GET_TASK_DETAILS, {
    variables: { id: taskId },
    context: {
      headers: {
        'x-hasura-role': maintenances__administrator
          ? 'maintenances__administrator'
          : maintenances__solver
          ? 'maintenances__solver'
          : undefined
      }
    }
  });
  return {
    data,
    error,
    loading
  };
};
