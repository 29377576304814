import { useState, useEffect } from 'react';
import { getAuthHeader, setAuthHeader } from '../api';

export const useToken = () => {
  const [token, setToken] = useState(() => {
    const token = getAuthHeader();
    if (token) return JSON.parse(token);
    return null;
  });

  useEffect(() => {
    if (token) {
      setAuthHeader(JSON.stringify(token));
    } else {
      localStorage.removeItem('Authorization');
    }
  }, [token]);
  return [token, setToken];
};
