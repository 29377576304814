import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_CHAT_LIST } from '../queries/getChatList';
import { GET_CHAT_USERS } from '../queries/getChatUsers';

export const ADD_CHAT = gql`
  mutation addChat($userId: Int!, $currentUserId: Int!) {
    insert_chat(
      objects: [
        {
          owner_id: null
          chat_users: {
            data: [{ user_id: $userId }, { user_id: $currentUserId }]
          }
        }
      ]
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`;

export const useAddChat = () => {
  let [mutate] = useMutation(ADD_CHAT);
  return ({ userId, currentUserId }) => {
    return mutate({
      variables: {
        userId,
        currentUserId
      },
      refetchQueries: [
        { query: GET_CHAT_LIST, variables: { id: currentUserId } },
        { query: GET_CHAT_USERS, variables: { id: currentUserId } }
      ]
    });
  };
};
