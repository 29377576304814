import React from 'react';
import { colors, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import HelpIcon from '@material-ui/icons/Help';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import WarningIcon from '@material-ui/icons/Warning';

export const states = {
  toDo: {
    name: 'Pendiente',
    color: colors.red[600]
  },
  inProgress: {
    name: 'En progreso',
    color: colors.yellow[700]
  },
  done: {
    name: 'Completado',
    color: colors.green[600]
  }
};

export const priorities = {
  low: {
    name: 'Baja',
    color: colors.green[600]
  },
  medium: {
    name: 'Media',
    color: colors.yellow[700]
  },
  high: {
    name: 'Alta',
    color: colors.red[600]
  }
};

export const stateColors = {
  toDo: colors.red[600],
  inProgress: colors.yellow[700],
  done: colors.green[600]
};

export const priorityColors = {
  low: colors.green[600],
  medium: colors.yellow[700],
  high: colors.red[600]
};

export const renderStateIcon = state => {
  switch (state) {
    case 'done':
      return <DoneIcon />;
      break;
    case 'toDo':
      return <PriorityHighIcon />;
      break;
    case 'inProgress':
      return <QueryBuilderIcon />;
      break;
    default:
      return <HelpIcon />;
  }
};

export function parseState(value) {
  switch (value) {
    case 'toDo':
      return 'Pendiente';
    case 'inProgress':
      return 'En progreso';
    case 'done':
      return 'Completado';
    default:
      return 'Desconocido';
  }
}

export function parsePriority(value) {
  switch (value) {
    case 'low':
      return 'Baja';
    case 'medium':
      return 'Media';
    case 'high':
      return 'Alta';
    default:
      return 'Desconocido';
  }
}

export function getPercentageDone(tasks = 0, done = 0, state = 'toDo') {
  return state === 'done' ? 100 : tasks ? Math.round((done * 100) / tasks) : 0;
}

const getLowPriority = value => (
  <Tooltip title="Baja">
    <WarningIcon htmlColor={priorityColors[value]} />
  </Tooltip>
);

const getMediumPriority = value => (
  <Tooltip title="Media">
    <WarningIcon htmlColor={priorityColors[value]} />
  </Tooltip>
);

const getHighPriority = value => (
  <Tooltip title="Alta">
    <WarningIcon htmlColor={priorityColors[value]} />
  </Tooltip>
);

export function getPriority(value) {
  if (value === 'low') {
    return getLowPriority(value);
  }
  if (value === 'medium') {
    return getMediumPriority(value);
  }
  if (value === 'high') {
    return getHighPriority(value);
  }
  return null;
}
