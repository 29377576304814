import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useRoles } from 'modules/authentication';

const GET_FURNITURE_TYPES = gql`
  query getFurnitureTypes {
    furnitureTypes: subtipo_mobil_urban {
      id
      name: nombre
    }
  }
`;

export const useGetFurnitureTypes = () => {
  const { inventory__administrator } = useRoles();
  const { data, error, loading } = useQuery(GET_FURNITURE_TYPES, {
    context: {
      headers: {
        'x-hasura-role': inventory__administrator
          ? 'inventory__administrator'
          : undefined
      }
    }
  });
  return { data, error, loading };
};
