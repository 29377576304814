import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import Message from './Message';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  inner: {
    height: 1000,
    padding: theme.spacing(1),
    overflow: 'auto'
  }
}));

function Messages({ messages = [], className }) {
  const classes = useStyles();
  const [message, setMessage] = useState(messages);

  useEffect(() => {
    setMessage(messages);
  }, [messages]);

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.inner}>
        <PerfectScrollbar>
          {message.map(m => {
            return (
              <Message
                key={m.id}
                message={m} //
              />
            );
          })}
        </PerfectScrollbar>
      </div>
    </div>
  );
}

export default Messages;
