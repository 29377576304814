import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { PROFILE_FRAGMENT } from '../fragments';
import useRouter from 'hooks/useRouter';

export const GET_PROFILES_BY_ORGANIZATION = gql`
  query getProfilesByOrganization($id: Int!) {
    selectedProfiles: profile(
      where: {
        organization_profile_associations: { organization_id: { _eq: $id } }
      }
    ) {
      ...profileFields
    }
    profiles: profile {
      ...profileFields
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const useGetProfilesByOrganization = (organizationId = 0) => {
  const { match } = useRouter();
  const { id } = match.params || {};
  const { data, error, loading } = useQuery(GET_PROFILES_BY_ORGANIZATION, {
    variables: { id: id ? id : organizationId },
    fetchPolicy: 'cache-and-network'
  });
  return {
    data,
    error,
    loading
  };
};
