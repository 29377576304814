import gql from 'graphql-tag';

export const PROFILE_FRAGMENT = gql`
  fragment profileFields on profile {
    id
    name
    created_at
    updated_at
  }
`;
