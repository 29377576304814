import React from 'react';
import { colors, Tooltip } from '@material-ui/core';
import Label from 'components/Label';
import WarningIcon from '@material-ui/icons/Warning';

export const states = {
  toDo: {
    name: 'Pendiente',
    color: colors.red[600]
  },
  done: {
    name: 'Completado',
    color: colors.green[600]
  }
};

export const priorities = {
  low: {
    name: 'Baja',
    color: colors.green[600]
  },
  medium: {
    name: 'Media',
    color: colors.yellow[700]
  },
  high: {
    name: 'Alta',
    color: colors.red[600]
  }
};

export function renderState(state = 'toDo') {
  return (
    <Label color={states[state]?.color} variant="outlined">
      {states[state]?.name}
    </Label>
  );
}

export function renderPriority(priority = 'low') {
  return (
    <Tooltip title={priorities[priority].name}>
      <WarningIcon htmlColor={priorities[priority].color} />
    </Tooltip>
  );
}

export function getPercentageDone(tasks = 0, done = 0, state = 'toDo') {
  return state === 'done' ? 100 : tasks ? Math.round((done * 100) / tasks) : 0;
}
