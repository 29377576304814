import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { ISSUE_FRAGMENT } from '../fragments';
import useRouter from 'hooks/useRouter';

export const GET_ISSUES_BY_INVENTORY_ITEM = gql`
  query($inventoryItemId: Int) {
    issues: issue(
      where: { resource_id: { _eq: $inventoryItemId } }
      order_by: { id: desc }
    ) {
      ...issueFields
    }
  }
  ${ISSUE_FRAGMENT}
`;

export const useGetIssuesByInventoryItem = inventoryItemId => {
  const { data, error, loading } = useQuery(GET_ISSUES_BY_INVENTORY_ITEM, {
    variables: { inventoryItemId },
    fetchPolicy: 'cache-and-network'
  });
  return {
    data,
    loading,
    error
  };
};
