import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_INVENTORY } from '../queries';
import { useInventoryDispatch } from 'modules/inventory/hooks';

export const ADD_INVENTORY_ITEM = gql`
  mutation addInventoryItem($objects: [elemento_inventario_insert_input!]!) {
    insert_elemento_inventario(objects: $objects) {
      returning {
        id
      }
    }
  }
`;
export const useAddInventoryItem = () => {
  const { openSuccessAlert, openErrorAlert } = useInventoryDispatch();
  let [mutate, { data, loading, error }] = useMutation(ADD_INVENTORY_ITEM, {
    onCompleted: () => openSuccessAlert('Elemento creado correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });

  return {
    addInventoryItem: (args) =>
      mutate({
        variables: {
          objects: args.map(
            ({
              inventoryItemType,
              name,
              districtId,
              neighborhoodId,
              managementUnitId,
              address,
              observations,
              location,
              vegetacion_inventariables,
              mobiliario_urbanos,
              surfaces,
              playgrounds
            }) => {
              const { area, perimeter, slope, surface_type_id } = surfaces[0];
              const {
                electric,
                integration,
                hydraulic,
                furnitureTypeId,
                manufacturer,
                model,
                installationDate,
                lastRepairDate
              } = mobiliario_urbanos[0];
              const {
                manufacturer: playgroundManufacturer,
                model: playgroundModel,
                age,
                security_area,
                fall_height,
                dimensions,
                weight
              } = playgrounds[0];
              const {
                vegetationType,
                commonName,
                species,
                plantingDate,
                lastUpdateDate,
                arbol_inventariables,
                arbusto_inventariables,
                tapiz_inventariables
              } = vegetacion_inventariables[0];
              const { shape } = arbusto_inventariables[0];
              const { carpetType } = tapiz_inventariables[0];
              const {
                alcorque_deter_pavimento,
                alcorque_estado,
                alcorque_tipo,
                ca_estado,
                ca_tipo,
                clasificacion_riesgo,
                dd_altura_primera_rama_m,
                dd_altura_total_m,
                dd_diam_copa_cm,
                dd_perimetro_tronco_cm,
                de_ancho_acera_m,
                de_dist_ejes_alineacion,
                de_distancia_fachada_m,
                de_entorno_calzada,
                de_marco_plantacion,
                de_protector,
                de_protector_estado,
                de_tipo_posicion,
                de_tipo_riego,
                de_tipo_superficie,
                de_tipo_vial,
                e_base_huecos_cm,
                e_copa_chupones_cm,
                e_copa_ramas_secas_percent,
                e_tronco_inclinacion_grad,
                fecha_ult_poda,
                nivel_riesgo,
                od_edad_relativa,
                od_estructura,
                od_origen_specie,
                od_procedencia,
                od_vigor_vitalidad,
                tutor_estado,
                tutor_tipo,
                valoracion,
                tipo_hoja
              } = arbol_inventariables[0];
              return {
                inventoryItemType,
                name,
                districtId,
                neighborhoodId,
                managementUnitId,
                address,
                observations,
                location,
                surfaces:
                  inventoryItemType === 'surface'
                    ? {
                        data: [
                          {
                            area,
                            perimeter,
                            slope,
                            surface_type_id
                          }
                        ]
                      }
                    : undefined,
                mobiliario_urbanos:
                  inventoryItemType === 'furniture'
                    ? {
                        data: [
                          {
                            electric,
                            integration,
                            hydraulic,
                            furnitureTypeId,
                            manufacturer,
                            model,
                            installationDate,
                            lastRepairDate
                          }
                        ]
                      }
                    : undefined,
                playgrounds:
                  inventoryItemType === 'playground'
                    ? {
                        data: [
                          {
                            manufacturer: playgroundManufacturer,
                            model: playgroundModel,
                            age,
                            security_area,
                            fall_height,
                            dimensions,
                            weight
                          }
                        ]
                      }
                    : undefined,
                vegetacion_inventariables:
                  inventoryItemType === 'vegetation'
                    ? {
                        data: [
                          {
                            vegetationType,
                            commonName,
                            species,
                            plantingDate,
                            lastUpdateDate,
                            arbusto_inventariables:
                              vegetationType === 'Arbusto'
                                ? {
                                    data: [
                                      {
                                        shape
                                      }
                                    ]
                                  }
                                : undefined,
                            tapiz_inventariables:
                              vegetationType === 'Superficie vegetal'
                                ? {
                                    data: [
                                      {
                                        carpetType
                                      }
                                    ]
                                  }
                                : undefined,
                            palmera_inventariables:
                              vegetationType === 'Palmera'
                                ? {
                                    data: [
                                      {
                                        tipo_hoja
                                      }
                                    ]
                                  }
                                : undefined,
                            arbol_inventariables:
                              vegetationType === 'Árbol'
                                ? {
                                    data: [
                                      {
                                        alcorque_deter_pavimento,
                                        alcorque_estado,
                                        alcorque_tipo,
                                        ca_estado,
                                        ca_tipo,
                                        clasificacion_riesgo,
                                        dd_altura_primera_rama_m,
                                        dd_altura_total_m,
                                        dd_diam_copa_cm,
                                        dd_perimetro_tronco_cm,
                                        de_ancho_acera_m,
                                        de_dist_ejes_alineacion,
                                        de_distancia_fachada_m,
                                        de_entorno_calzada,
                                        de_marco_plantacion,
                                        de_protector,
                                        de_protector_estado,
                                        de_tipo_posicion,
                                        de_tipo_riego,
                                        de_tipo_superficie,
                                        de_tipo_vial,
                                        e_base_huecos_cm,
                                        e_copa_chupones_cm,
                                        e_copa_ramas_secas_percent,
                                        e_tronco_inclinacion_grad,
                                        fecha_ult_poda,
                                        nivel_riesgo,
                                        od_edad_relativa,
                                        od_estructura,
                                        od_origen_specie,
                                        od_procedencia,
                                        od_vigor_vitalidad,
                                        tutor_estado,
                                        tutor_tipo,
                                        valoracion
                                      }
                                    ]
                                  }
                                : undefined
                          }
                        ]
                      }
                    : undefined
              };
            }
          )
        },
        refetchQueries: [
          { query: GET_INVENTORY, variables: { limit: 10, offset: 0 } }
        ]
      }),
    data,
    loading,
    error
  };
};
