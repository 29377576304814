import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_USERS } from '../queries';

export const DELETE_WORKER = gql`
  mutation deleteWorker($id: Int) {
    delete_organization_user(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const useDeleteWorker = () => {
  let [mutate] = useMutation(DELETE_WORKER);
  return ({ id }) => {
    return mutate({
      variables: { id },
      refetchQueries: [{ query: GET_USERS }]
    });
  };
};
