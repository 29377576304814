import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { ISSUE_FRAGMENT } from '../fragments';
import useRouter from 'hooks/useRouter';

export const GET_ISSUE_DETAILS = gql`
  query getIssueDetails($id: Int!) {
    issue: issue_by_pk(id: $id) {
      ...issueFields
    }
  }
  ${ISSUE_FRAGMENT}
`;

export const useGetIssueDetails = () => {
  const { match } = useRouter();
  const { id } = match.params || {};
  const { data, error, loading } = useQuery(GET_ISSUE_DETAILS, {
    variables: { id }
  });
  return {
    data,
    error,
    loading
  };
};
