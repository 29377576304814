import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar } from '@material-ui/core';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import PortableWifiOffOutlinedIcon from '@material-ui/icons/PortableWifiOffOutlined';
import { useSensorState } from 'modules/sensors';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 120,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  error: {
    minHeight: 120,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  avatar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.success.main,
    height: 48,
    width: 48
  },
  errorAvatar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.error.main,
    height: 48,
    width: 48
  }
}));


const Status = ({ datastreams = [] }) => {
  const classes = useStyles();
  //harcode until fix
  //TODO
  return (datastreams.length > 0 ? (
    <Card className={classes.root}>
      <div>
        <Typography
          color="inherit"
          component="h3"
          gutterBottom
          variant="overline">
          Estado
        </Typography>
        <div className={classes.details}>
          <Typography color="inherit" variant="h3">
            Activo
          </Typography>
        </div>
      </div>
      <Avatar className={classes.avatar}>
        <TrackChangesIcon />
      </Avatar>
    </Card>
  ) : (
    <Card className={classes.error}>
      <div>
        <Typography
          color="inherit"
          component="h3"
          gutterBottom
          variant="overline">
          Estado
        </Typography>
        <div className={classes.details}>
          <Typography color="inherit" variant="h3">
            Error
          </Typography>
        </div>
      </div>
      <Avatar className={classes.errorAvatar}>
        <PortableWifiOffOutlinedIcon />
      </Avatar>
    </Card>
  )
  )
};

export default Status;
