export const surfaceWithIssuesLayer = {
  id: 'surfaceWithIssues',
  type: 'fill',
  paint: {
    'fill-color': '#FF4742',
    'fill-opacity': 0.5
  }
};

export const surfaceWithIssuesOutlineLayer = {
  id: 'surfaceWithIssuesOutline',
  source: 'surfaceWithIssues',
  type: 'line',
  paint: {
    'line-width': 1,
    'line-color': '#FF4742'
  }
};

export const selectedSurfaceWithIssuesLayer = {
  id: 'selectedSurfaceWithIssues',
  source: 'surfaceWithIssues',
  type: 'fill',
  paint: {
    'fill-color': '#FF4742',
    'fill-opacity': 0.5
  }
};

export const selectedSurfaceWithIssuesOutlineLayer = {
  id: 'selectedSurfaceWithIssuesOutline',
  source: 'surfaceWithIssues',
  type: 'line',
  paint: {
    'line-width': 1.5,
    'line-color': '#FF4742'
  }
};

export const pointsWithIssuesLayer = {
  id: 'pointsWithIssues',
  type: 'circle',
  paint: {
    'circle-color': '#FF4742',
    'circle-radius': {
      base: 1.75,
      stops: [
        [12, 2],
        [22, 180]
      ]
    },
    'circle-stroke-width': 0,
    'circle-stroke-color': '#fff'
  }
};

export const selectedPointWithIssuesLayer = {
  id: 'selectedPointWithIssues',
  source: 'pointsWithIssues',
  type: 'circle',
  paint: {
    'circle-color': '#FF4742',
    'circle-radius': {
      base: 1.75,
      stops: [
        [12, 2],
        [22, 180]
      ]
    },
    'circle-stroke-width': 3,
    'circle-stroke-color': '#fff'
  }
};
