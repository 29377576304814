import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { MAINTENANCE_FRAGMENT } from '../fragments';
import { useInfo } from 'modules/authentication';

export const GET_MAINTENANCES = gql`
  query getMaintenances($orgId: Int!) {
    maintenances: maintenance(
      where: { organization_id: { _eq: $orgId } }
      order_by: { id: desc }
    ) {
      ...maintenanceFields
    }
    toDo: maintenance(
      where: { organization_id: { _eq: $orgId }, 
               state: { _eq: "toDo" } }
      order_by: { id: desc }
    ) {
      ...maintenanceFields
    }
    done: maintenance(
      where: {  organization_id: { _eq: $orgId },
                state: { _eq: "done" } }
      order_by: { id: desc }
    ) {
      ...maintenanceFields
    }
  }
  ${MAINTENANCE_FRAGMENT}
`;

export const useGetMaintenances = () => {
  const { orgId } = useInfo();
  const { data, error, loading } = useQuery(GET_MAINTENANCES, { variables: { orgId } });

  return {
    data,
    error,
    loading
  };
};

