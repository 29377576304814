import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_USERS } from '../queries';

export const ADD_WORKER = gql`
  mutation addWorker($objects: [organization_user_insert_input!]!) {
    __typename
    insert_organization_user(objects: $objects) {
      affected_rows
    }
  }
`;

export const useAddWorker = () => {
  let [mutate] = useMutation(ADD_WORKER);

  return ({
    name,
    surname,
    external_id,
    organization_id,
    profile_id,
    email,
    phone
  }) => {
    return mutate({
      variables: {
        objects: {
          name,
          surname,
          external_id,
          organization_id,
          profile_id,
          email,
          phone,
          settings: { data: { country: 'España' } }
        }
      },
      refetchQueries: [{ query: GET_USERS }]
    });
  };
};
