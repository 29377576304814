import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_INVENTORY } from '../queries';
import { useInventoryDispatch } from 'modules/inventory/hooks';
import { useHistory } from 'react-router-dom';

export const DELETE_INVENTORY_ITEM = gql`
  mutation deleteInventoryItem($id: Int) {
    delete_elemento_inventario(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const useDeleteInventoryItem = () => {
  const { openSuccessAlert, openErrorAlert } = useInventoryDispatch();
  const history = useHistory();
  let [mutate, { error }] = useMutation(DELETE_INVENTORY_ITEM, {
    onCompleted: () => {
      openSuccessAlert('Elemento eliminado correctamente.');
      history.push('/inventory', { updated: true });
    },
    onError: () => openErrorAlert(error?.message)
  });
  return ({ id }) => {
    return mutate({
      variables: { id },
      refetchQueries: [
        { query: GET_INVENTORY, variables: { limit: 10, offset: 0 } }
      ]
    });
  };
};
