import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_INVENTORY, GET_INVENTORY_ITEM_DETAILS } from '../queries';
import { useInventoryDispatch } from 'modules/inventory/hooks';

export const UPDATE_INVENTORY_ITEM = gql`
  mutation updateInventoryItem(
    $id: Int!
    $address: String
    $addressNumber: Int
    $districtId: Int
    $externalId: String
    $inventoryItemType: String
    $location: geometry
    $managementUnitId: Int
    $name: String
    $neighborhoodId: Int
    $observations: String
    $vegetationType: String
    $commonName: String
    $species: String
    $plantingDate: timestamp
    $lastUpdateDate: timestamp
    $electric: Boolean
    $furnitureTypeId: Int
    $hydraulic: Boolean
    $integration: Boolean
    $installationDate: timestamp
    $lastRepairDate: timestamp
    $manufacturer: String
    $model: String
    $shape: String
    $carpetType: String
    $alcorque_deter_pavimento: String
    $alcorque_estado: String
    $alcorque_tipo: String
    $ca_estado: String
    $ca_tipo: String
    $clasificacion_riesgo: String
    $dd_altura_primera_rama_m: Int
    $dd_altura_total_m: Int
    $dd_diam_copa_cm: Int
    $dd_perimetro_tronco_cm: Int
    $de_ancho_acera_m: Int
    $de_dist_ejes_alineacion: String
    $de_distancia_fachada_m: Int
    $de_entorno_calzada: String
    $de_marco_plantacion: String
    $de_protector: String
    $de_protector_estado: String
    $de_tipo_posicion: String
    $de_tipo_riego: String
    $de_tipo_superficie: String
    $de_tipo_vial: String
    $e_base_huecos_cm: Int
    $e_copa_chupones_cm: Int
    $e_copa_ramas_secas_percent: Int
    $e_tronco_inclinacion_grad: Int
    $fecha_ult_poda: timestamp
    $nivel_riesgo: String
    $od_catalogacion: String
    $od_edad_relativa: String
    $od_estructura: String
    $od_origen_specie: String
    $od_procedencia: String
    $od_vigor_vitalidad: String
    $tutor_estado: String
    $tutor_tipo: String
    $valoracion: String
    $area: Int
    $perimeter: Int
    $slope: Int
    $surface_type_id: Int
    $age: Int
    $security_area: Int
    $fall_height: Int
    $dimensions: String
    $weight: Int
  ) {
    update_elemento_inventario(
      where: { id: { _eq: $id } }
      _set: {
        address: $address
        addressNumber: $addressNumber
        districtId: $districtId
        externalId: $externalId
        inventoryItemType: $inventoryItemType
        location: $location
        managementUnitId: $managementUnitId
        name: $name
        neighborhoodId: $neighborhoodId
        observations: $observations
      }
    ) {
      affected_rows
    }
    update_mobiliario_urbano(
      where: { id: { _eq: $id } }
      _set: {
        electric: $electric
        furnitureTypeId: $furnitureTypeId
        hydraulic: $hydraulic
        integration: $integration
        installationDate: $installationDate
        lastRepairDate: $lastRepairDate
        manufacturer: $manufacturer
        model: $model
      }
    ) {
      affected_rows
    }
    update_playground(
      where: { id: { _eq: $id } }
      _set: {
        manufacturer: $manufacturer
        model: $model
        age: $age
        security_area: $security_area
        fall_height: $fall_height
        dimensions: $dimensions
        weight: $weight
      }
    ) {
      affected_rows
    }
    update_vegetacion_inventariable(
      where: { id: { _eq: $id } }
      _set: {
        vegetationType: $vegetationType
        commonName: $commonName
        species: $species
        plantingDate: $plantingDate
        lastUpdateDate: $lastUpdateDate
      }
    ) {
      affected_rows
    }
    update_arbusto_inventariable(
      where: { id: { _eq: $id } }
      _set: { shape: $shape }
    ) {
      affected_rows
    }
    update_tapiz_inventariable(
      where: { id: { _eq: $id } }
      _set: { carpetType: $carpetType }
    ) {
      affected_rows
    }
    update_arbol_inventariable(
      where: { id: { _eq: $id } }
      _set: {
        alcorque_deter_pavimento: $alcorque_deter_pavimento
        alcorque_estado: $alcorque_estado
        alcorque_tipo: $alcorque_tipo
        ca_estado: $ca_estado
        ca_tipo: $ca_tipo
        clasificacion_riesgo: $clasificacion_riesgo
        dd_altura_primera_rama_m: $dd_altura_primera_rama_m
        dd_altura_total_m: $dd_altura_total_m
        dd_diam_copa_cm: $dd_diam_copa_cm
        dd_perimetro_tronco_cm: $dd_perimetro_tronco_cm
        de_ancho_acera_m: $de_ancho_acera_m
        de_dist_ejes_alineacion: $de_dist_ejes_alineacion
        de_distancia_fachada_m: $de_distancia_fachada_m
        de_entorno_calzada: $de_entorno_calzada
        de_marco_plantacion: $de_marco_plantacion
        de_protector: $de_protector
        de_protector_estado: $de_protector_estado
        de_tipo_posicion: $de_tipo_posicion
        de_tipo_riego: $de_tipo_riego
        de_tipo_superficie: $de_tipo_superficie
        de_tipo_vial: $de_tipo_vial
        e_base_huecos_cm: $e_base_huecos_cm
        e_copa_chupones_cm: $e_copa_chupones_cm
        e_copa_ramas_secas_percent: $e_copa_ramas_secas_percent
        e_tronco_inclinacion_grad: $e_tronco_inclinacion_grad
        fecha_ult_poda: $fecha_ult_poda
        nivel_riesgo: $nivel_riesgo
        od_catalogacion: $od_catalogacion
        od_edad_relativa: $od_edad_relativa
        od_estructura: $od_estructura
        od_origen_specie: $od_origen_specie
        od_procedencia: $od_procedencia
        od_vigor_vitalidad: $od_vigor_vitalidad
        tutor_estado: $tutor_estado
        tutor_tipo: $tutor_tipo
        valoracion: $valoracion
      }
    ) {
      affected_rows
    }
    update_surface(
      where: { id: { _eq: $id } }
      _set: {
        area: $area
        perimeter: $perimeter
        slope: $slope
        surface_type_id: $surface_type_id
      }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateInventoryItem = () => {
  const { openSuccessAlert, openErrorAlert } = useInventoryDispatch();
  let [mutate, { data, loading, error }] = useMutation(UPDATE_INVENTORY_ITEM, {
    onCompleted: () => openSuccessAlert('Elemento actualizado correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });

  return (args) => {
    const {
      id,
      inventoryItemType,
      name,
      districtId,
      neighborhoodId,
      managementUnitId,
      address,
      observations,
      location,
      mobiliario_urbanos,
      vegetacion_inventariables,
      surfaces,
      playgrounds
    } = args;
    return mutate({
      variables: {
        id,
        inventoryItemType,
        name,
        districtId,
        neighborhoodId,
        managementUnitId,
        address,
        observations,
        location,
        ...(inventoryItemType === 'furniture' && mobiliario_urbanos[0]),
        subtipo_mobil_urban: undefined,
        ...(inventoryItemType === 'surface' && surfaces[0]),
        surface_type: undefined,
        ...(inventoryItemType === 'playground' && playgrounds[0]),
        ...(inventoryItemType === 'vegetation' && vegetacion_inventariables[0]),
        arbol_inventariables: undefined,
        arbusto_inventariables: undefined,
        tapiz_inventariables: undefined,
        palmera_inventariables: undefined,
        ...(inventoryItemType === 'vegetation' &&
          vegetacion_inventariables[0].vegetationType === 'Arbusto' &&
          vegetacion_inventariables[0].arbusto_inventariables[0]),
        ...(inventoryItemType === 'vegetation' &&
          vegetacion_inventariables[0].vegetationType === 'Palmera' &&
          vegetacion_inventariables[0].palmera_inventariables[0]),
        ...(inventoryItemType === 'vegetation' &&
          vegetacion_inventariables[0].vegetationType ===
            'Superficie vegetal' &&
          vegetacion_inventariables[0].tapiz_inventariables[0]),
        ...(inventoryItemType === 'vegetation' &&
          vegetacion_inventariables[0].vegetationType === 'Árbol' &&
          vegetacion_inventariables[0].arbol_inventariables[0]),
        __typename: undefined
      },
      refetchQueries: [
        { query: GET_INVENTORY },
        {
          query: GET_INVENTORY_ITEM_DETAILS,
          variables: { id }
        }
      ]
    });
  };
};
