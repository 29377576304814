import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { WORK_ORDER_FRAGMENT, TASK_FRAGMENT } from '../fragments';
import { WORKER_FRAGMENT } from 'modules/users/graphql/fragments';
import useRouter from 'hooks/useRouter';

export const GET_WORK_ORDER_DETAILS = gql`
  query getWorkOrderDetails($id: Int!) {
    workOrder: work_order_by_pk(id: $id) {
      ...workOrderFields
      tasks: work_order_tasks(order_by: { id: asc }) {
        ...taskFields
        organization_user {
          ...workerFields
        }
      }
      done: work_order_tasks_aggregate(where: { state: { _eq: "done" } }) {
        aggregate {
          count
        }
      }
    }
  }
  ${WORK_ORDER_FRAGMENT}
  ${TASK_FRAGMENT}
  ${WORKER_FRAGMENT}
`;

export const useGetWorkOrderDetails = () => {
  const { match } = useRouter();
  const { workOrderId } = match.params || {};
  const { data, error, loading } = useQuery(GET_WORK_ORDER_DETAILS, {
    variables: { id: workOrderId }
  });
  return {
    data,
    error,
    loading
  };
};
