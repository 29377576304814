import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActions,
  Avatar as MaterialAvatar,
  Typography,
  Button
} from '@material-ui/core';
import { useUsersDispatch } from 'modules/users';

const useStyles = makeStyles(theme => ({
  root: { height: '100%', paddingBottom: 0, display: 'flex' },
  content: {
    cursor: 'pointer',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlgin: 'center'
  },
  name: {
    marginTop: theme.spacing(2)
  },
  avatar: {
    height: 200,
    width: 200
  }
}));

const Avatar = ({ user = {}, className }) => {
  const classes = useStyles();
  const { name, surname, file, profile } = user;
  const { openWorkerUploadModal } = useUsersDispatch();
  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent
        className={classes.content}
        onClick={() => openWorkerUploadModal({ worker: user, file })}>
        <MaterialAvatar className={classes.avatar} src={file?.url} />
        <Typography className={classes.name} gutterBottom variant="h4">
          {`${name} ${surname}`}
        </Typography>
        <Typography color="textSecondary" variant="body1">
          {profile?.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Avatar;
