import * as React from 'react';
import { PureComponent } from 'react';
import { Marker } from 'react-map-gl';
import renderIcon from './icons';

// Important for perf: the markers never change, avoid rerender when the map viewport changes
export default class Pin extends PureComponent {
  render() {
    const { inventoryItem } = this.props;
    return (
      <Marker
        longitude={inventoryItem.location.coordinates[0]}
        latitude={inventoryItem.location.coordinates[1]}>
        {renderIcon({
          feature: inventoryItem,
          type:
            inventoryItem.vegetacion_inventariables?.[0]?.vegetationType ||
            inventoryItem.inventoryItemType
        })}
      </Marker>
    );
  }
}
