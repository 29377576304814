import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_USERS, GET_ORGANIZATION_DETAILS } from '../queries';
import { GET_PROFILES_BY_ORGANIZATION } from '../queries/getProfilesByOrganization';

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization(
    $id: Int!
    $description: String
    $name: String
    $code: String
    $email: String
    $profiles: [organization_profile_association_insert_input!]!
  ) {
    delete_organization_profile_association(
      where: { organization_id: { _eq: $id } }
    ) {
      affected_rows
    }
    insert_organization_profile_association(objects: $profiles) {
      affected_rows
    }
    update_organization(
      where: { id: { _eq: $id } }
      _set: {
        description: $description
        name: $name
        code: $code
        email: $email
      }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateOrganization = () => {
  let [mutate] = useMutation(UPDATE_ORGANIZATION);

  return (args) => {
    const { id, description, name, profiles, code, email } = args;
    return mutate({
      variables: {
        id,
        description,
        name,
        code,
        email,
        profiles:
          profiles &&
          profiles.map((profile) => ({
            organization_id: id,
            profile_id: profile.id
          }))
      },
      refetchQueries: [
        { query: GET_USERS },
        { query: GET_ORGANIZATION_DETAILS, variables: { id } },
        { query: GET_PROFILES_BY_ORGANIZATION, variables: { id } }
      ]
    });
  };
};
