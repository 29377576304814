import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Tooltip
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AddIcon from '@material-ui/icons/Add';
import InventoryUploadModal from 'views/InventoryUploadModal';
import { useInventoryDispatch } from 'modules/inventory';
import Slider from './Slider';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0,
    width: '100%',
    height: '100%'
  },
  data: { fontWeight: 600 },
  avatar: {
    cursor: 'pointer'
  },
  text: {
    textOverflow: 'ellipsis'
  },
  item: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  }
}));

const Images = ({ inventoryItem }) => {
  const classes = useStyles();
  const { files } = inventoryItem || {};
  const { openInventoryUploadModal } = useInventoryDispatch();

  return (
    <Card className={classes.root}>
      <CardHeader
        title="Imágenes"
        action={
          <Tooltip title="Añadir imagen">
            <IconButton
              size="small"
              onClick={() =>
                openInventoryUploadModal({
                  inventoryItem
                })
              }>
              <AddIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Divider />
      <CardContent className={classes.content}>
        {files?.length > 0 && (
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Slider files={files} />
          </PerfectScrollbar>
        )}
      </CardContent>
      <InventoryUploadModal />
    </Card>
  );
};

export default Images;
