import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { CHAT_FRAGMENT } from '../fragments';
import useRouter from 'hooks/useRouter';

export const GET_MESSAGES_BY_CHAT = gql`
query getMessagesByChat($chatId: Int!) {
    messages: message(where: { chat_id: { _eq: $chatId } }) {
      id
      chat_id
      sender: organization_user {
        id
        name
      }
      content
      created_at
    }
  }
  ${CHAT_FRAGMENT}
`;

export const useGetMessagesByChat = chatId => {
  const { match } = useRouter();
  const { id } = match.params || {};
  const { data, error, loading } = useQuery(GET_MESSAGES_BY_CHAT, {
    variables: {
       chatId: chatId ? chatId : id 
      },
    fetchPolicy: 'cache-and-network',
    pollInterval: 500
  });
  return {
    data,
    error,
    loading
  };
};
