import { useState } from 'react';

export default function useTabs(initialTab = '') {
  const [currentTab, setCurrentTab] = useState(initialTab);
  const handleTabs = (event, value) => {
    event.preventDefault();
    setCurrentTab(value);
  };
  return [currentTab, handleTabs];
}
