import React, { useEffect } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button
} from '@material-ui/core';
import { Formik } from 'formik';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import FileUpload from './FileUpload';
import FileEdit from './FileEdit';
import {
  useIssuesMutation,
  useIssuesState,
  useIssuesDispatch
} from 'modules/issues';
import Loader from 'components/Loader';

const IssueUploadModal = () => {
  const classes = useClasses(styles);
  const { closeIssueUploadModal, uploadImage } = useIssuesDispatch();
  const { addFiles, updateIssueFile } = useIssuesMutation();
  const {
    selectedIssue,
    selectedFile,
    showIssueUploadModal,
    issueUpload,
    issueUploadLoading: loading
  } = useIssuesState();

  useEffect(() => {
    if (!selectedFile && issueUpload && selectedIssue) {
      addFiles({
        files: issueUpload,
        issueId: selectedIssue.id
      }).then(() => closeIssueUploadModal());
    }
    if (issueUpload && selectedFile) {
      updateIssueFile({
        file: { id: selectedFile.id, ...issueUpload[0] },
        issueId: selectedFile.issue_id
      }).then(() => closeIssueUploadModal());
    }
  }, [issueUpload, selectedIssue, selectedFile]);

  if (!selectedIssue) return null;
  return (
    <Modal onClose={closeIssueUploadModal} open={showIssueUploadModal}>
      <Formik
        initialValues={{ files: [] }}
        onSubmit={(values, { setSubmitting }) => {
          uploadImage(values.files);
          setSubmitting(false);
        }}>
        {({ handleSubmit, values }) => (
          <Card className={classes.root}>
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardHeader
                title={
                  <Typography component="h1" variant="h3">
                    {selectedFile ? 'Editar imagen' : 'Nueva imagen'}
                  </Typography>
                }
                className={classes.header}
              />

              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                {loading ? (
                  <Loader />
                ) : selectedFile ? (
                  <FileEdit />
                ) : (
                  <FileUpload />
                )}
              </CardContent>
              <Divider className={classes.divider} />
              <CardActions className={classes.actions}>
                <Button onClick={closeIssueUploadModal}>Cancelar</Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  disabled={loading || !values.files.length > 0}>
                  Enviar
                </Button>
              </CardActions>
            </form>
          </Card>
        )}
      </Formik>
    </Modal>
  );
};

export default IssueUploadModal;
