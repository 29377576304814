import gql from 'graphql-tag';
import { useSubscription } from 'react-apollo';
import { CHAT_FRAGMENT } from '../fragments';
import useRouter from 'hooks/useRouter';

export const GET_CHATS = gql`
  subscription getChats($chatId: Int!, $userId: Int!) {
    messages: message(where: { chat_id: { _eq: $id } }) {
      id
      chat_id
      sender: organization_user {
        id
        name
      }
      content
      created_at
    }
    chats: chat(
      order_by: [{ messages_aggregate: { max: { created_at: desc } } }]
    ) {
      ...chatFields
      users: chat_users(where: { user_id: { _neq: $userId } }) {
        organization_user {
          id
          name
          surname
        }
      }
    }
  }
  ${CHAT_FRAGMENT}
`;

export const useGetChats = (chatId = 0, userId = 0) => {
  const { match } = useRouter();
  const { id } = match.params || {};
  const { data, error, loading } = useSubscription(GET_CHATS, {
    variables: { chatId: chatId ? chatId : id, userId }
  });
  return {
    data,
    error,
    loading
  };
};
