import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  GET_ISSUES,
  GET_ISSUES_BY_INVENTORY_ITEM,
  GET_ISSUES_BY_STATE
} from '../queries';
import { useIssuesDispatch } from 'modules/issues';

export const UPDATE_ISSUE = gql`
  mutation updateIssue(
    $id: Int!
    $title: String
    $description: String
    $date: timestamp
    $solved_at: timestamp
    $issuer_user_id: Int
    $processed_by_user_id: Int
    $resource_id: Int
    $state: String
  ) {
    update_issue(
      where: { id: { _eq: $id } }
      _set: {
        title: $title
        description: $description
        date: $date
        solved_at: $solved_at
        issuer_user_id: $issuer_user_id
        processed_by_user_id: $processed_by_user_id
        resource_id: $resource_id
        state: $state
      }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateIssue = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(UPDATE_ISSUE, {
    onCompleted: () =>
      openSuccessAlert('Incidencia actualizada correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });

  return args => {
    const {
      id,
      title,
      description,
      date,
      solved_at,
      issuer_user_id,
      processed_by_user_id,
      resource_id,
      state
    } = args;

    return mutate({
      variables: {
        id,
        title,
        description,
        date,
        issuer_user_id,
        processed_by_user_id,
        resource_id,
        state,
        solved_at
      },
      refetchQueries: [
        { query: GET_ISSUES },
        { query: GET_ISSUES_BY_STATE },
        {
          query: GET_ISSUES_BY_INVENTORY_ITEM,
          variables: { inventoryItemId: resource_id }
        }
      ]
    });
  };
};
