import React from 'react';
import {
  colors,
  Tooltip,
  IconButton,
  Tabs,
  Divider,
  Tab
} from '@material-ui/core';
import {
  useMaintenancesDispatch,
  useGetMaintenances
} from 'modules/maintenances';
import ConfirmDelete from './ConfirmDelete';
import MaintenanceModal from 'views/MaintenanceModal';
import View from 'components/View';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import useTabs from 'hooks/useTabs';
import ToDo from './ToDo';
import Done from './Done';
import Calendar from './Calendar';
import { useRoles } from 'modules/authentication';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  tabs: {
    fontWeight: 600,
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const Maintenances = () => {
  const classes = useStyles();
  const { maintenances__insert } = useRoles();
  const { openMaintenanceModal } = useMaintenancesDispatch();
  const { data, loading, error } = useGetMaintenances();
  const [currentTab, handleTabs] = useTabs('toDo');
  const { maintenances__administrator, maintenances__solver } = useRoles();

  const tabs = [
    { value: 'toDo', label: 'Pendiente' },
    { value: 'done', label: 'Completado' },
    { value: 'calendar', label: 'Calendario' }
  ];

  return (
    <View
      title="Mantenimientos"
      loading={loading}
      error={error}
      actions={null/*
        maintenances__insert && (
          <Tooltip title="Nuevo mantenimiento">
            <IconButton onClick={() => openMaintenanceModal()} size="small">
              <AddIcon />
            </IconButton>
          </Tooltip>
        )
        */}>
      <Tabs
        className={classes.tabs}
        onChange={handleTabs}
        scrollButtons="auto"
        value={currentTab}
        variant="scrollable"
        indicatorColor="primary">
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {currentTab === 'toDo' && <ToDo maintenances={data?.toDo} />}
        {currentTab === 'done' && <Done maintenances={data?.done} />}
        {currentTab === 'calendar' && (
          <Calendar maintenances={data?.maintenances} />
        )}
      </div>
      <ConfirmDelete />
      <MaintenanceModal />
    </View>
  );
};

export default Maintenances;
