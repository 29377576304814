import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSensor: undefined,
  showSensorModal: false,
  showDeleteSensorModal: false,
  selectedDatastream: undefined,
  alert: undefined
};

const sensorsSlice = createSlice({
  name: 'sensors',
  initialState,
  reducers: {
    loadSensors: () => initialState,
    selectSensor: (state, { payload }) => ({
      ...state,
      selectedSensor: payload
    }),
    openSensorModal: (state, { payload }) => ({
      ...state,
      selectedSensor: payload,
      showSensorModal: true
    }),
    closeSensorModal: state => ({
      ...state,
      selectedSensor: undefined,
      showSensorModal: false
    }),
    openDeleteSensorModal: (state, { payload }) => ({
      ...state,
      selectedSensor: payload,
      showDeleteSensorModal: true
    }),
    closeDeleteSensorModal: state => ({
      ...state,
      selectedSensor: undefined,
      showDeleteSensorModal: false
    }),
    selectDatastream: (state, { payload }) => ({
      ...state,
      selectedDatastream: payload
    }),
    openErrorAlert: (state, { payload }) => ({
      ...state,
      alert: {
        severity: 'error',
        message: payload
      }
    }),
    openSuccessAlert: (state, { payload }) => ({
      ...state,
      alert: {
        severity: 'success',
        message: payload
      }
    }),
    closeAlert: state => ({
      ...state,
      alert: undefined
    })
  }
});

export const {
  loadSensors,
  selectSensor,
  openSensorModal,
  closeSensorModal,
  openDeleteSensorModal,
  closeDeleteSensorModal,
  selectDatastream,
  openErrorAlert,
  openSuccessAlert,
  closeAlert
} = sensorsSlice.actions;

export default sensorsSlice.reducer;
