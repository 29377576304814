import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { SENSOR_FRAGMENT } from '../fragments';
import { INVENTORY_ITEM_FRAGMENT } from 'modules/inventory/graphql/fragments';
import { useRoles } from 'modules/authentication';
import { useState } from 'react';
import useFilters from 'hooks/useFilters';

const types = {
  all: { inventoryItemType: { _eq: 'sensor' } },
  airTemperature: {
    inventoryItemType: { _eq: 'sensor' },
    _and: {
      sensor: { datastreams: { observation: { _eq: 'air_temperature' } } }
    }
  },
  relativeHumidity: {
    inventoryItemType: { _eq: 'sensor' },
    _and: {
      sensor: { datastreams: { observation: { _eq: 'relative_humidity' } } }
    }
  },
  airPressure: {
    inventoryItemType: { _eq: 'sensor' },
    _and: {
      sensor: { datastreams: { observation: { _eq: 'air_pressure' } } }
    }
  },
  waterTemperature: {
    inventoryItemType: { _eq: 'sensor' },
    _and: {
      sensor: { datastreams: { observation: { _eq: 'water_temperature' } } }
    }
  }
};
export const GET_SENSORS = gql`
  query getSensors(
    $date: timestamp
    $limit: Int
    $offset: Int
    $where: elemento_inventario_bool_exp
    $orderBy: [elemento_inventario_order_by!]
  ) {
    sensorsCount: elemento_inventario_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    sensors: elemento_inventario(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: $where
    ) {
      ...inventoryItemFields
      sensor {
        ...sensorFields
        status: datastreams(
          where: { observation_values: { time: { _gte: $date } } }
        ) {
          id
        }
      }
    }
  }
  ${INVENTORY_ITEM_FRAGMENT}
  ${SENSOR_FRAGMENT}
`;

export const useGetSensors = (type = 'all') => {
  const [data, setData] = useState([]);
  const {
    count,
    setCount,
    limit,
    offset,
    nextPage,
    page,
    previousPage,
    hasNext,
    hasPrevious,
    where,
    search,
    handleSearch,
    orderBy,
    handleOrderBy
  } = useFilters({
    filters: ['name', 'address'],
    search: '',
    orderBy: { id: 'desc' }
  });
  const date = new Date().toDateString();
  const { sensors__administrator } = useRoles();
  const { loading, error } = useQuery(GET_SENSORS, {
    variables: {
      date,
      limit,
      offset,
      where: { ...where, ...types[type] },
      orderBy
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      setData(response.sensors);
      setCount(response.sensorsCount.aggregate.count);
    },
    context: {
      headers: {
        'x-hasura-role': sensors__administrator
          ? 'sensors__administrator'
          : undefined
      }
    }
  });
  return {
    data,
    error,
    count,
    offset,
    limit,
    page,
    loading,
    nextPage,
    previousPage,
    hasNext,
    hasPrevious,
    search,
    handleSearch,
    orderBy,
    handleOrderBy
  };
};
