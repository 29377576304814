import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { useIssuesDispatch } from 'modules/issues';
import { useMapDispatch } from 'modules/map';
import useRouter from 'hooks/useRouter';
import { useRoles } from 'modules/authentication';

const Actions = ({ issue }) => {
  const { openIssueModal, openDeleteIssueModal } = useIssuesDispatch();
  const { selectFeature } = useMapDispatch();
  const { history } = useRouter();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const { issues__update, issues__delete } = useRoles();

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <Tooltip title="Acciones">
        <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <MenuItem component={Link} to={issue && `/issues/${issue.id}`}>
          <ListItemText primary="Ver" />
        </MenuItem>
        {issues__update && (
          <MenuItem
            onClick={() => {
              openIssueModal(issue);
              setOpenMenu(false);
            }}>
            <ListItemText primary="Editar" />
          </MenuItem>
        )}
        {issues__delete && (
          <MenuItem
            onClick={() => {
              openDeleteIssueModal(issue);
              setOpenMenu(false);
            }}>
            <ListItemText primary="Eliminar" />
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            issue.elemento_inventario &&
              selectFeature(issue.elemento_inventario);
            history.push('/global');
          }}>
          <ListItemText primary="Ubicación" />
        </MenuItem>
      </Menu>
    </>
  );
};

Actions.propTypes = {
  className: PropTypes.string
};

export default Actions;
