import React, { useEffect, useState } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button,
  Tabs,
  Tab
} from '@material-ui/core';
import { Formik } from 'formik';
import Location from './Location';
import { useInventoryState, useInventoryDispatch } from 'modules/inventory';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import { useUpdateArea, useAddArea } from 'modules/inventory';
import Alert from 'components/Alert';
import * as Yup from 'yup';
import useRouter from 'hooks/useRouter';
import Summary from './Summary';

const schema = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  location: Yup.object().required('Campo requerido')
});

const initialValues = {
  name: '',
  ground_type: undefined,
  topography: null,
  typology: null,
  location_type: null,
  description: '',
  location: {
    coordinates: [0, 0]
  },
  surface_m2: 0
};

const tabs = [
  { value: 'summary', label: 'General' },
  { value: 'location', label: 'Localización' }
];

const AreaModal = () => {
  const { showAreaModal, selectedArea } = useInventoryState();
  const classes = useClasses(styles);
  const { updateArea, error: errorUpdating } = useUpdateArea();
  const { addArea, data: dataAdded, error: errorAdding } = useAddArea();
  const { closeAreaModal } = useInventoryDispatch();
  const { history } = useRouter();
  const [currentTab, setCurrentTab] = useState('summary');

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleClose = () => {
    setCurrentTab('summary');
    closeAreaModal();
  };

  useEffect(() => {
    if (dataAdded) {
      history.push(`inventory/areas/${dataAdded.insert_area.area[0].id}`);
    }
  }, [dataAdded, history]);

  return (
    <>
      <Modal onClose={handleClose} open={Boolean(showAreaModal)}>
        <Card className={classes.root}>
          <Formik
            initialValues={selectedArea ? selectedArea : initialValues}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              selectedArea ? updateArea({ ...values }) : addArea({ ...values });
              setSubmitting(false);
              handleClose();
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              isValid,
              handleSubmit
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <CardHeader
                  title={
                    <Typography component="h1" variant="h3">
                      {selectedArea ? 'Editar área' : 'Nueva área'}
                    </Typography>
                  }
                  className={classes.header}
                />
                <Tabs
                  className={classes.tabs}
                  onChange={handleTabsChange}
                  scrollButtons="auto"
                  value={currentTab}
                  variant="scrollable"
                  indicatorColor="primary">
                  {tabs.map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
                </Tabs>
                <Divider className={classes.divider} />
                <CardContent className={classes.content}>
                  <CardContent className={classes.content}>
                    {currentTab === 'summary' && <Summary />}
                    {currentTab === 'location' && <Location />}
                  </CardContent>
                </CardContent>
                <Divider className={classes.divider} />
                <CardActions className={classes.actions}>
                  <Button onClick={handleClose}>Cancelar</Button>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={!isValid}>
                    {selectedArea ? 'Actualizar' : 'Guardar'}
                  </Button>
                </CardActions>
              </form>
            )}
          </Formik>
        </Card>
      </Modal>
      <Alert
        show={errorUpdating || errorAdding}
        message={errorUpdating?.message || errorAdding?.message}
        severity="error"
      />
    </>
  );
};

export default AreaModal;
