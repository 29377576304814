import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import Map from './Map';
import { useGetSettings } from 'modules/map';
import Loader from 'components/Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  }
}));

const Location = () => {
  const classes = useStyles();
  const { data, loading } = useGetSettings();
  if (loading) return <Loader />;
  return (
    <Paper className={classes.root}>
      <Map
        longitude={data?.settings[0].location?.coordinates[0]}
        latitude={data?.settings[0].location?.coordinates[1]}
      />
    </Paper>
  );
};

export default Location;
