import {
  useAddOrganization,
  useUpdateOrganization,
  useDeleteOrganization,
  useAddWorker,
  useDeleteWorker,
  useUpdateWorker,
  useUpdatePassword,
  useAddProfile,
  useDeleteProfile,
  useUpdateRolesByProfile,
  useUpdateSettings,
  useAddWorkerAvatar,
  useUpdateWorkerAvatar,
  useAddOrganizationAvatar,
  useUpdateOrganizationAvatar,
  useUpdateProfile
} from '../graphql/mutations';

export const useUsersMutation = () => {
  const addOrganization = useAddOrganization();
  const updateOrganization = useUpdateOrganization();
  const deleteOrganization = useDeleteOrganization();
  const addWorker = useAddWorker();
  const updateWorker = useUpdateWorker();
  const deleteWorker = useDeleteWorker();
  const updatePassword = useUpdatePassword();
  const addProfile = useAddProfile();
  const updateProfile = useUpdateProfile();
  const deleteProfile = useDeleteProfile();
  const updateRolesByProfile = useUpdateRolesByProfile();
  const updateSettings = useUpdateSettings();
  const addWorkerAvatar = useAddWorkerAvatar();
  const updateWorkerAvatar = useUpdateWorkerAvatar();
  const addOrganizationAvatar = useAddOrganizationAvatar();
  const updateOrganizationAvatar = useUpdateOrganizationAvatar();
  return {
    addOrganization,
    updateOrganization,
    deleteOrganization,
    addWorker,
    updateWorker,
    deleteWorker,
    updatePassword,
    addProfile,
    deleteProfile,
    updateProfile,
    updateRolesByProfile,
    updateSettings,
    addWorkerAvatar,
    updateWorkerAvatar,
    addOrganizationAvatar,
    updateOrganizationAvatar
  };
};
