import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button
} from '@material-ui/core';
import {
  useInventoryState,
  useInventoryDispatch,
  useDeleteArea
} from 'modules/inventory';
import useRouter from 'hooks/useRouter';
import Alert from 'components/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    height: 200
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ConfirmDelete = () => {
  const classes = useStyles();
  const { showDeleteAreaModal, selectedArea } = useInventoryState();
  const { deleteArea, data, error } = useDeleteArea();
  const { closeDeleteAreaModal } = useInventoryDispatch();
  const { history } = useRouter();
  if (!showDeleteAreaModal) {
    return null;
  }

  return (
    <>
      <Modal onClose={closeDeleteAreaModal} open={showDeleteAreaModal}>
        <Card className={classes.root}>
          <CardHeader title="¿Eliminar área?" />
          <Divider />
          <CardContent>
            <Typography variant="body1">
              Si elimina la área seleccionada, no podrá recuperarla más tarde.
            </Typography>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button onClick={closeDeleteAreaModal}>Cancelar</Button>
            <Button
              color="secondary"
              onClick={async () => {
                await deleteArea({ id: selectedArea.id });
                closeDeleteAreaModal();
                history.push('/global');
              }}
              variant="contained">
              Sí, eliminar
            </Button>
          </CardActions>
        </Card>
      </Modal>
      <Alert
        show={data}
        message="Área eliminada correctamente"
        severity="success"
      />
      <Alert show={error} message={error?.message} severity="error" />
    </>
  );
};

export default ConfirmDelete;
