import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { VEGETATION_FRAGMENT } from '../fragments';
import useFilters from 'hooks/useFilters';
import { useState } from 'react';

const location = (geometry) => ({ location: { _st_within: geometry } });
const withIssues = { issues: { state: { _eq: 'toDo' } } };

export const GET_INVENTORY_BY_AREA = gql`
  query getInventoryByArea(
    $where: elemento_inventario_bool_exp
    $orderBy: [elemento_inventario_order_by!]
    $limit: Int
    $offset: Int
  ) {
    inventoryCount: elemento_inventario_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    inventory: elemento_inventario(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: $where
    ) {
      id
      inventoryItemType
      name
      districtId
      address
      neighborhoodId
      managementUnitId
      observations
      location
      created_at
      updated_at
      vegetacion_inventariables {
        ...vegetationFields
      }
      issues: issues_aggregate(where: { state: { _eq: "toDo" } }) {
        aggregate {
          count
        }
      }
    }
  }
  ${VEGETATION_FRAGMENT}
`;

export const useGetInventoryByArea = (geometry = {}, options = {}) => {
  const { noLimit } = options;
  const [data, setData] = useState([]);
  const [isIssued, setIsIssued] = useState(false);
  const toggleIssues = () => {
    setIsIssued(!isIssued);
  };
  const {
    count,
    setCount,
    limit,
    offset,
    nextPage,
    page,
    previousPage,
    hasNext,
    hasPrevious,
    where,
    search,
    handleSearch,
    orderBy,
    handleOrderBy
  } = useFilters({
    filters: ['name', 'address'],
    search: '',
    orderBy: { id: 'asc' }
  });
  const { loading, error } = useQuery(GET_INVENTORY_BY_AREA, {
    variables: {
      where: { ...where, ...location(geometry), ...(isIssued && withIssues) },
      limit: !noLimit ? limit : undefined,
      offset: !noLimit ? offset : undefined,
      orderBy: orderBy
    },
    onCompleted: (response) => {
      setData(response.inventory);
      setCount(response.inventoryCount.aggregate.count);
    },
    fetchPolicy: 'cache-and-network'
  });

  return {
    data,
    error,
    count,
    offset,
    limit,
    page,
    loading,
    nextPage,
    previousPage,
    hasNext,
    hasPrevious,
    search,
    handleSearch,
    orderBy,
    handleOrderBy,
    toggleIssues
  };
};
