import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useInventoryState } from 'modules/inventory';
import { inventoryTypes } from 'utils/inventory';

const Summary = () => {
  const { values, handleChange, touched, errors } = useFormikContext();
  const { inventoryItemTypes, selectedInventoryItem } = useInventoryState();
  const { inventoryItemType, name, address, observations } = values;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Nombre"
          name="name"
          value={name}
          onChange={handleChange}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Tipo"
          name="inventoryItemType"
          type="text"
          select
          SelectProps={{ native: true }}
          value={inventoryItemType}
          disabled={Boolean(selectedInventoryItem)}
          onChange={handleChange}
          variant="outlined"
          helperText={touched.inventoryItemType ? errors.inventoryItemType : ''}
          error={
            touched.inventoryItemType && Boolean(errors.inventoryItemType)
          }>
          <option value={undefined}></option>
          {inventoryItemTypes.map(type => (
            <option key={type} value={type}>
              {inventoryTypes[type]}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Dirección"
          name="address"
          value={address}
          onChange={handleChange}
          variant="outlined">
          {address}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Observaciones"
          name="observations"
          value={observations}
          onChange={handleChange}
          variant="outlined"
          multiline
          rows={2}
        />
      </Grid>
    </Grid>
  );
};

export default Summary;
