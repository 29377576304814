import {
  useAddInventoryItem,
  useUpdateInventoryItem,
  useDeleteInventoryItem,
  useAddInventoryFiles,
  useDeleteInventoryFile,
  useUpdateInventoryFile,
  useAddArea,
  useUpdateArea,
  useDeleteArea
} from '../graphql/mutations';

export const useInventoryMutation = () => {
  const addInventoryItem = useAddInventoryItem();
  const updateInventoryItem = useUpdateInventoryItem();
  const deleteInventoryItem = useDeleteInventoryItem();
  const addInventoryFiles = useAddInventoryFiles();
  const deleteInventoryFile = useDeleteInventoryFile();
  const updateInventoryFile = useUpdateInventoryFile();
  const addArea = useAddArea();
  const updateArea = useUpdateArea();
  const deleteArea = useDeleteArea();
  return {
    addInventoryItem,
    updateInventoryItem,
    deleteInventoryItem,
    addInventoryFiles,
    deleteInventoryFile,
    updateInventoryFile,
    addArea,
    updateArea,
    deleteArea
  };
};
