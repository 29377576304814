import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { CHAT_FRAGMENT } from '../fragments';
import useRouter from 'hooks/useRouter';
import { useInfo } from 'modules/authentication';

export const GET_CHAT_DETAILS = gql`
  query getChatDetails($id: Int!, $userId: Int!) {
    chat: chat_by_pk(id: $id) {
      ...chatFields
      users: chat_users(where: { user_id: { _neq: $userId } }) {
        organization_user {
          id
          name
          surname
          file {
            id
            name
            url
          }
        }
      }
    }
  }
  ${CHAT_FRAGMENT}
`;

export const useGetChatDetails = () => {
  const { match } = useRouter();
  const { userId } = useInfo();
  const { id } = match.params || {};
  const { data, error, loading } = useQuery(GET_CHAT_DETAILS, {
    variables: { id, userId },
    fetchPolicy: 'cache-and-network'
  });
  return {
    data,
    error,
    loading
  };
};
