export default theme => ({
  root: { height: '100%' },
  data: {},
  content: {
    padding: 0,
    width: '100%'
  },
  inner: {
    minWidth: 1150,
    width: '100%'
  },

  tableHeader: {
    fontWeight: 600,
    background: '#FAFAFA'
  },
  tablePagination: {
    justifyContent: 'flex-end',
    padding: 0
  }
});
