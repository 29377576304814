import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_PROFILES, GET_USERS } from '../queries';
import { useInfo } from 'modules/authentication';

export const DELETE_PROFILE = gql`
  mutation deleteProfile($profileId: Int) {
    delete_profile_role_association(
      where: { profile_id: { _eq: $profileId } }
    ) {
      affected_rows
    }
    delete_profile(where: { id: { _eq: $profileId } }) {
      affected_rows
    }
  }
`;

export const useDeleteProfile = () => {
  let [mutate] = useMutation(DELETE_PROFILE);
  const { orgId } = useInfo();
  return ({ profileId }) => {
    return mutate({
      variables: { profileId },
      refetchQueries: [
        { query: GET_PROFILES, variables: { limit: 10, offset: 0 } },
        {
          query: GET_USERS,
          variables: { organizationId: orgId, limit: 10, offset: 0 }
        }
      ]
    });
  };
};
