import React from 'react';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { inventoryTypes } from 'utils/inventory';
import Actions from './Actions';

export default [
  {
    Header: 'Nombre',
    accessor: (data) => data,
    Cell: ({ cell: { value } }) => (
      <Link
        color="inherit"
        component={RouterLink}
        to={`/inventory/${value.id}`}
        variant="h6">
        {value.name}
      </Link>
    )
  },
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Tipo',
    accessor: (data) => inventoryTypes[data.inventoryItemType]
  },
  {
    Header: 'Subtipo',
    accessor: (row) =>
      row.vegetacion_inventariables?.[0]?.vegetationType ||
      row.mobiliario_inventariables?.[0]?.subtype
  },
  {
    Header: 'Dirección',
    accessor: 'address',
    Cell: ({ cell: { value } }) => value
  },
  {
    Header: '',
    id: 'actions',
    accessor: (data) => data,
    Cell: ({ cell: { value } }) => <Actions data={value} />
  }
];
