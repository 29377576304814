import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_WORK_ORDERS_BY_ISSUE } from '../queries';
import { useIssuesDispatch } from 'modules/issues';

export const DELETE_WORK_ORDER = gql`
  mutation deleteWorkOrder($id: Int, $issueId: Int) {
    delete_work_order(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const useDeleteWorkOrder = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(DELETE_WORK_ORDER, {
    onCompleted: () =>
      openSuccessAlert('Orden de trabajo eliminada correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });
  return ({ id, issueId }) => {
    return mutate({
      variables: { id },
      refetchQueries: [
        {
          query: GET_WORK_ORDERS_BY_ISSUE,
          variables: { issueId }
        }
      ]
    });
  };
};
