import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { ORGANIZATION_FRAGMENT } from '../fragments';
import { useRoles } from 'modules/authentication';
import usePagination from 'hooks/usePagination';

export const GET_ORGANIZATIONS = gql`
  query getOrganizations($organizationId: Int, $limit: Int, $offset: Int) {
    organizations: organization(
      limit: $limit
      offset: $offset
      order_by: { id: asc }
    ) {
      ...organizationFields
    }
    organizationsCount: organization_aggregate {
      aggregate {
        count
      }
    }
    profiles: profile(
      where: {
        organization_profile_associations: {
          organization_id: { _eq: $organizationId }
        }
      }
    ) {
      id
      name
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const useGetOrganizations = (organizationId) => {
  const {
    limit,
    offset,
    setLimit,
    setOffset,
    handleNext,
    handlePrevious,
    setCount,
    resetPagination
  } = usePagination();
  const { users__administrator, users__organization } = useRoles();
  const query = useQuery(GET_ORGANIZATIONS, {
    variables: { organizationId, limit, offset },
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        'x-hasura-role': users__administrator
          ? 'users__administrator'
          : users__organization
            ? 'users__organization'
            : undefined
      }
    }
  });
  return {
    limit,
    offset,
    setLimit,
    setOffset,
    handleNext,
    handlePrevious,
    setCount,
    resetPagination,
    ...query
  };
};

