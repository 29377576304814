import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { useMaintenancesDispatch } from 'modules/maintenances';
import { useRoles } from 'modules/authentication';

const Actions = ({ maintenance }) => {
  const {
    openMaintenanceModal,
    openDeleteMaintenanceModal
  } = useMaintenancesDispatch();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const { maintenances__update, maintenances__delete } = useRoles();

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <Tooltip title="Acciones">
        <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <MenuItem
          component={Link}
          to={maintenance && `/maintenances/${maintenance.id}`}>
          <ListItemText primary="Ver" />
        </MenuItem>
        {maintenances__update && (
          <MenuItem
            onClick={() => {
              openMaintenanceModal(maintenance);
              setOpenMenu(false);
            }}>
            <ListItemText primary="Editar" />
          </MenuItem>
        )}
        {maintenances__delete && (
          <MenuItem
            onClick={() => {
              openDeleteMaintenanceModal(maintenance);
              setOpenMenu(false);
            }}>
            <ListItemText primary="Eliminar" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

Actions.propTypes = {
  className: PropTypes.string
};

export default Actions;
