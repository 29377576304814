import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const GET_INVENTORY_AREAS = gql`
  query getAreas {
    districts: area(
      where: { area_type: { name: { _eq: "Unidad administrativa" } } }
    ) {
      id
      name
      area_type {
        id
        name
      }
      ground_type_id
      ground_type {
        id
        name
      }
    }
    neighborhoods: area(where: { area_type: { name: { _eq: "Barrio" } } }) {
      id
      name
      area_type {
        id
        name
      }
      ground_type_id
      ground_type {
        id
        name
      }
    }
  }
`;

export const useGetInventoryAreas = () => {
  const { data, error, loading } = useQuery(GET_INVENTORY_AREAS);
  return { data, error, loading };
};
