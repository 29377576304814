import React from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';
import Link from 'components/Link';
import useClasses from 'hooks/useClasses';

const styles = (theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 }
});

const Features = (props) => {
  const classes = useClasses(styles);
  const { organization, external_id, email, phone, password } = props;
  return (
    <Card className={classes.root}>
      <CardHeader title="Datos" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Organizacion</TableCell>
              <TableCell className={classes.data}>
                {organization ? (
                  <Link
                    title={organization.name}
                    to={`/users/organizations/${organization.id}`}
                  />
                ) : (
                  'Desconocido'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell className={classes.data}>
                {external_id ? external_id : 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell className={classes.data}>
                {email ? email : 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Teléfono</TableCell>
              <TableCell className={classes.data}>
                {phone ? phone : 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Acceso web</TableCell>
              <TableCell className={classes.data}>
                {password ? 'Sí' : 'No'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Features;
