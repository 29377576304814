import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  GET_MAINTENANCES,
  GET_MAINTENANCES_BY_INVENTORY_ITEM
} from '../queries';

export const DELETE_MAINTENANCE = gql`
  mutation deleteMaintenance($id: Int!, $inventoryItemId: Int) {
    delete_maintenance(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const useDeleteMaintenance = () => {
  let [mutate] = useMutation(DELETE_MAINTENANCE);
  return ({ id, inventoryItemId }) => {
    return mutate({
      variables: { id },
      refetchQueries: [
        { query: GET_MAINTENANCES },
        {
          query: GET_MAINTENANCES_BY_INVENTORY_ITEM,
          variables: { inventoryItemId }
        }
      ]
    });
  };
};
