import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { useInventoryDispatch } from 'modules/inventory';
import { useIssuesDispatch } from 'modules/issues';
import { useMapDispatch } from 'modules/map';
import { useRoles } from 'modules/authentication';
import { useMaintenancesDispatch } from 'modules/maintenances';

const FeatureActions = ({ feature }) => {
  const {
    inventory__update,
    issues__insert,
    maintenances__insert
  } = useRoles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const { openInventoryItemModal } = useInventoryDispatch();
  const { openIssueModal } = useIssuesDispatch();
  const { openMaintenanceModal } = useMaintenancesDispatch();
  const { selectFeature } = useMapDispatch();
  const handleMenuOpen = () => {
    setOpenMenu(true);
  };
  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <Tooltip title="Acciones">
        <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <MenuItem
          component={Link}
          to={`${
            feature.inventoryItemType === 'sensor' ? 'sensors' : 'inventory'
          }/${feature?.id}`}>
          <ListItemText primary="Ver" />
        </MenuItem>
        {inventory__update && (
          <MenuItem
            onClick={() => {
              openInventoryItemModal(feature);
              setOpenMenu(false);
            }}>
            <ListItemText primary="Editar" />
          </MenuItem>
        )}
        {issues__insert && (
          <MenuItem
            onClick={() => {
              selectFeature(feature);
              openIssueModal();
              setOpenMenu(false);
            }}>
            <ListItemText primary="Incidencia" />
          </MenuItem>
        )}
        {maintenances__insert && (
          <MenuItem
            onClick={() => {
              selectFeature(feature);
              openMaintenanceModal();
              setOpenMenu(false);
            }}>
            <ListItemText primary="Mantenimiento" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

FeatureActions.propTypes = {
  className: PropTypes.string
};

export default FeatureActions;
