import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_USERS, GET_SETTINGS_BY_USER } from '../queries';
import { useInfo } from 'modules/authentication';

export const UPDATE_SETTINGS = gql`
  mutation updateWorker(
    $userId: Int!
    $name: String!
    $surname: String
    $phone: String
    $email: String
    $city: String
    $country: String
    $default_location: geometry
  ) {
    update_organization_user(
      where: { id: { _eq: $userId } }
      _set: { name: $name, surname: $surname, phone: $phone, email: $email }
    ) {
      affected_rows
    }
    update_settings(
      where: { organization_user_id: { _eq: $userId } }
      _set: {
        city: $city
        country: $country
        default_location: $default_location
      }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateSettings = () => {
  const { userId } = useInfo();
  let [mutate] = useMutation(UPDATE_SETTINGS);

  return ({ name, surname, phone, email, default_location, city, country }) => {
    return mutate({
      variables: {
        userId,
        name,
        surname,
        phone,
        email,
        default_location,
        city,
        country
      },
      refetchQueries: [
        { query: GET_USERS },
        { query: GET_SETTINGS_BY_USER, variables: { userId } }
      ]
    });
  };
};
