import React from 'react';
import { colors, Tabs, Divider, Tab } from '@material-ui/core';
import ConfirmDelete from './ConfirmDelete';
import View from 'components/View';
import { makeStyles } from '@material-ui/styles';
import useTabs from 'hooks/useTabs';
import Table from 'components/Table';
import {
  useGetWorkOrders,
  useIssuesDispatch,
  useIssuesState
} from 'modules/issues';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import Actions from './Actions';
import { renderState, renderPriority } from 'utils/maintenances';
import WorkOrderModal from 'views/WorkOrderModal';
import Alert from 'components/Alert';
import Avatar from 'components/Avatar';
const columns = [
  {
    Header: 'Título',
    accessor: data => data,
    Cell: ({ cell: { value } }) => (
      <Link
        color="inherit"
        component={RouterLink}
        to={`/issues/${value.issue_id}/workorders/${value.id}`}
        variant="h6">
        {value.title}
      </Link>
    )
  },

  {
    Header: 'ID',
    accessor: row => `${row.id}`
  },

  {
    Header: 'Prioridad',
    accessor: 'priority',
    Cell: ({ cell: { value } }) => renderPriority(value)
  },
  {
    Header: 'Organización',
    accessor: 'organization',
    Cell: ({ cell: { value } }) => (
      <Avatar name={value?.name} url={value?.file?.url} />
    )
  },

  {
    Header: 'Vencimiento',
    accessor: 'due_to_date',
    Cell: ({ cell: { value } }) =>
      value ? moment(value).format('DD/MM/YYYY') : ''
  },
  {
    Header: 'Estado',
    accessor: 'state',
    Cell: ({ cell: { value } }) => renderState(value)
  },
  {
    Header: '',
    id: 'actions',
    accessor: data => data,
    Cell: ({ cell: { value } }) => <Actions workOrder={value} />
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  tabs: {
    fontWeight: 600,
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const Maintenances = () => {
  const classes = useStyles();
  const { data, loading, error, ...rest } = useGetWorkOrders();
  const [currentTab, handleTabs] = useTabs('toDo');
  const tabs = [
    { value: 'toDo', label: 'Pendiente' },
    { value: 'done', label: 'Completado' }
  ];
  const { closeAlert } = useIssuesDispatch();
  const { alert } = useIssuesState();
  return (
    <View title="Órdenes de trabajo">
      <Tabs
        className={classes.tabs}
        onChange={handleTabs}
        scrollButtons="auto"
        value={currentTab}
        variant="scrollable"
        indicatorColor="primary">
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {currentTab === 'toDo' && (
          <Table
            data={data?.toDo}
            count={data?.toDoCount?.aggregate.count}
            columns={columns}
            {...rest}
          />
        )}
        {currentTab === 'done' && (
          <Table
            data={data?.done}
            count={data?.doneCount?.aggregate.count}
            columns={columns}
            {...rest}
          />
        )}
      </div>
      <ConfirmDelete />
      <WorkOrderModal />
      <Alert
        show={alert}
        message={alert?.message}
        severity={alert?.severity}
        fireOnClose={() => closeAlert()}
      />
    </View>
  );
};

export default Maintenances;
