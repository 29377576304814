import React from 'react';
import PalmTree from 'assets/icons/PalmTree';
import Tree from 'assets/icons/Tree';
import Sensor from 'assets/icons/Sensor';
import Playground from 'assets/icons/Playground';
import Surface from 'assets/icons/Vegetation';
import Furniture from 'assets/icons/Furniture';
import Vegetation from 'assets/icons/Vegetation';

function renderIcons({ type, feature, onClick = () => {} }) {
  const icons = {
    vegetation: <Vegetation onClick={() => onClick(feature)} />,
    Arbusto: <Vegetation onClick={() => onClick(feature)} />,
    Árbol: <Tree onClick={() => onClick(feature)} />,
    Palmera: <PalmTree onClick={() => onClick(feature)} />,
    sensor: <Sensor onClick={() => onClick(feature)} />,
    'Superficie vegetal': <Surface onClick={() => onClick(feature)} />,
    playground: <Playground onClick={() => onClick(feature)} />,
    furniture: <Furniture onClick={() => onClick(feature)} />
  };
  return icons[type] ? (
    icons[type]
  ) : (
    <Vegetation onClick={() => onClick(feature)} />
  );
}

export default renderIcons;
