import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_ORGANIZATION_DETAILS } from '../queries';
import { useUsersDispatch } from 'modules/users/hooks';

export const UPDATE_ORGANIZATION_AVATAR = gql`
  mutation updateOrganizationAvatar($id: Int!, $url: String, $name: String) {
    update_file(where: { id: { _eq: $id } }, _set: { url: $url, name: $name }) {
      affected_rows
    }
  }
`;

export const useUpdateOrganizationAvatar = () => {
  const { openSuccessAlert, openErrorAlert } = useUsersDispatch();
  let [mutate, { data, error, loading }] = useMutation(
    UPDATE_ORGANIZATION_AVATAR,
    {
      onCompleted: () => openSuccessAlert('Avatar actualizado correctamente.'),
      onError: () => openErrorAlert(error?.message)
    }
  );

  return ({ file, organizationId }) => {
    return mutate({
      variables: {
        id: file.id,
        url: file.url,
        name: file.name
      },
      refetchQueries: [
        {
          query: GET_ORGANIZATION_DETAILS,
          variables: { id: organizationId }
        }
      ]
    });
  };
};
