import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableCell,
  TableBody,
  TableRow
} from '@material-ui/core';
import { useGetInventoryItemDetails } from 'modules/inventory/graphql';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  },
  data: {
    fontWeight: 600
  }
}));

const Environment = () => {
  const classes = useStyles();
  const { data } = useGetInventoryItemDetails();
  const {
    de_tipo_posicion,
    de_tipo_superficie,
    de_ancho_acera_m,
    de_tipo_vial,
    de_distancia_fachada_m,
    de_tipo_riego,
    de_marco_plantacion,
    de_entorno_calzada,
    de_dist_ejes_alineacion
  } = data.inventoryItem.vegetacion_inventariables[0].arbol_inventariables[0];
  return (
    <Card className={classes.root}>
      <CardHeader title="Entorno" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Posición</TableCell>
              <TableCell className={classes.data}>{de_tipo_posicion}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Superficie</TableCell>
              <TableCell className={classes.data}>
                {de_tipo_superficie}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ancho acera</TableCell>
              <TableCell className={classes.data}>{`${de_ancho_acera_m ||
                ''} m`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vial</TableCell>
              <TableCell className={classes.data}>{de_tipo_vial}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Distancia fachada</TableCell>
              <TableCell className={classes.data}>{`${de_distancia_fachada_m ||
                ''} m`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Riego</TableCell>
              <TableCell className={classes.data}>{de_tipo_riego}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Marco plantación</TableCell>
              <TableCell className={classes.data}>
                {de_marco_plantacion}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Entorno calzada</TableCell>
              <TableCell className={classes.data}>
                {de_entorno_calzada}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Distancia ejes alineación</TableCell>
              <TableCell className={classes.data}>
                {de_dist_ejes_alineacion}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Environment;
