import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  GET_WORK_ORDERS_BY_ISSUE,
  GET_ISSUES_BY_STATE,
  GET_ISSUES
} from '../queries';
import { useIssuesDispatch } from 'modules/issues';

export const DELETE_WORK_ORDERS_BY_ISSUE = gql`
  mutation deleteWorkOrdersByIssue($id: Int, $state: String!) {
    update_issue(where: { id: { _eq: $id } }, _set: { state: $state }) {
      affected_rows
    }
  }
`;

export const useDeleteWorkOrdersByIssue = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(DELETE_WORK_ORDERS_BY_ISSUE, {
    onCompleted: () =>
      openSuccessAlert('Incidencia actualizada correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });
  return ({ id = 0, state = 'toDo' }) => {
    return mutate({
      variables: { id, state },
      refetchQueries: [
        {
          query: GET_WORK_ORDERS_BY_ISSUE,
          variables: { issueId: id }
        },
        { query: GET_ISSUES },
        { query: GET_ISSUES_BY_STATE }
      ]
    });
  };
};
