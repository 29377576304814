import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { WORK_ORDER_FRAGMENT, TASK_FRAGMENT } from '../fragments';
import { WORKER_FRAGMENT } from 'modules/users/graphql/fragments';

export const GET_WORK_ORDERS_BY_ISSUE = gql`
  query($issueId: Int) {
    workOrders: work_order(
      where: { issue_id: { _eq: $issueId } }
      order_by: { id: desc }
    ) {
      ...workOrderFields
      tasks: work_order_tasks {
        ...taskFields
        organization_user {
          ...workerFields
        }
      }
      done: work_order_tasks_aggregate(where: { state: { _eq: "done" } }) {
        aggregate {
          count
        }
      }
    }
  }
  ${WORK_ORDER_FRAGMENT}
  ${TASK_FRAGMENT}
  ${WORKER_FRAGMENT}
`;

export const useGetWorkOrdersByIssue = issueId => {
  const { data, error, loading } = useQuery(GET_WORK_ORDERS_BY_ISSUE, {
    variables: { issueId }
  });
  return {
    data,
    loading,
    error
  };
};
