import React from 'react';
import { Grid, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { useFormikContext } from 'formik';
import {
  observations as observationTypes,
  parseObservation,
  datastreams
} from 'utils/sensors';

const Summary = () => {
  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    touched
  } = useFormikContext();
  const { name, address, observations, sensor } = values;
  const updateDatastreams = (event, checked, observation) => {
    checked
      ? setFieldValue('sensor', {
          datastreams: [...sensor.datastreams, datastreams[observation]]
        })
      : setFieldValue('sensor', {
          datastreams: sensor.datastreams.filter(
            (datastream) => datastream.observation !== observation
          )
        });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nombre"
            name="name"
            value={name}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          {observationTypes.map((observation) => (
            <FormControlLabel
              key={observation}
              style={{
                paddingLeft: '1rem'
              }}
              control={
                <Checkbox
                  color="primary"
                  checked={
                    sensor?.datastreams &&
                    sensor.datastreams.length > 0 &&
                    sensor.datastreams.some(
                      (datastream) => datastream.observation === observation
                    )
                  }
                  onChange={(event, checked) => {
                    updateDatastreams(event, checked, observation);
                  }}
                />
              }
              label={parseObservation(observation)}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Dirección"
            name="address"
            value={address}
            onChange={handleChange}
            variant="outlined">
            {address}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Observaciones"
            name="observations"
            value={observations}
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={2}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Summary;
