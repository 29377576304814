import gql from 'graphql-tag';

export const PLAYGROUND_FRAGMENT = gql`
  fragment playgroundFields on playground {
    id
    manufacturer
    model
    age
    security_area
    fall_height
    dimensions
    weight
  }
`;
