import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_INVENTORY_ITEM_DETAILS } from '../queries';

export const ADD_INVENTORY_FILES = gql`
  mutation addInventoryFile($objects: [file_insert_input!]!) {
    insert_file(objects: $objects) {
      affected_rows
    }
  }
`;

export const useAddInventoryFiles = () => {
  let [mutate] = useMutation(ADD_INVENTORY_FILES);
  return ({ files = [], inventoryId }) => {
    return mutate({
      variables: {
        objects: files.map(file => ({
          ...file,
          inventory_id: inventoryId
        }))
      },
      refetchQueries: [
        {
          query: GET_INVENTORY_ITEM_DETAILS,
          variables: { id: inventoryId }
        }
      ]
    });
  };
};
