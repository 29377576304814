import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Tooltip,
  colors,
  Avatar
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import addImagesImg from 'assets/images/add_images.svg';
import { bytesToSize } from 'utils/files';
import { useFormikContext } from 'formik';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px solid ${theme.palette.files}`,
    borderRadius: '4px',
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 130,
    padding: theme.spacing(2)
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 280
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const FileUpload = () => {
  const { setFieldValue } = useFormikContext();
  const classes = useStyles();
  const [files, setFiles] = useState([]);

  const handleDrop = useCallback(acceptedFiles => {
    setFiles(prevFiles => [...prevFiles].concat(acceptedFiles));
  }, []);

  const handleRemove = (event, index) => {
    event.preventDefault();
    setFiles(files.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setFieldValue('files', files);
  }, [files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });

  return (
    <div className={classes.root}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive
        })}
        {...getRootProps()}>
        <input {...getInputProps()} />
        <div>
          <img
            alt="Añadir una imagen"
            className={classes.image}
            src={addImagesImg}
          />
        </div>
        <div>
          <Typography gutterBottom variant="h3">
            Añadir imagen
          </Typography>
          <Typography
            className={classes.info}
            color="textSecondary"
            variant="body1">
            Suelte la imagen o haga click para navegar por su equipo
          </Typography>
        </div>
      </div>
      {files.length > 0 && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, index) => {
                return (
                  <ListItem divider={index < files.length - 1} key={index}>
                    <ListItemAvatar>
                      <Avatar alt="imagen" src={URL.createObjectURL(file)}>
                        <FileCopyIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={file.name}
                      primaryTypographyProps={{ variant: 'h5' }}
                      secondary={bytesToSize(file.size)}
                    />
                    <Tooltip title="Eliminar">
                      <IconButton
                        edge="end"
                        onClick={event => handleRemove(event, index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                );
              })}
            </List>
          </PerfectScrollbar>
        </>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  className: PropTypes.string
};

export default FileUpload;
