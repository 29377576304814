import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Typography
} from '@material-ui/core';
import { useGetChatUsers, useChatMutation } from 'modules/chat';
import useRouter from 'hooks/useRouter';
import { useInfo } from 'modules/authentication';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 280
  },
  root: {
    backgroundColor: theme.palette.common.white
  },
  list: {
    padding: theme.spacing(1, 3)
  },
  listItemText: {
    marginRight: theme.spacing(1)
  },
  lastActivity: {
    whiteSpace: 'nowrap'
  }
}));

function ChatBar({ className, open, onClose }) {
  const classes = useStyles();
  const { userId } = useInfo();
  const { history } = useRouter();
  const { data } = useGetChatUsers(1);
  const { addChat } = useChatMutation();
  let existingChatUsers;
  if (data) {
    existingChatUsers = data.chats?.map(chat => ({
      ...chat.existingChatUsers[0].organization_user,
      chatId: chat.id
    }));
  }

  const selectUser = user => {
    let selectedUserId = user.id;
    let found = existingChatUsers.find(user => user.id === selectedUserId);
    if (found) {
      history.push(`/chat/${found.chatId}`);
    } else {
      addChat({
        userId: selectedUserId,
        currentUserId: userId
      }).then(({ data: { insert_chat } }) =>
        history.push(`/chat/${insert_chat?.returning[0]?.id}`)
      );
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      elevation={1}
      open={open}
      onClose={onClose}
      variant="temporary">
      <div className={clsx(classes.root, className)}>
        {data.organizations?.map(organization => (
          <List
            key={organization.id}
            className={classes.list}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {organization.name}
              </ListSubheader>
            }>
            {organization.workers.map(worker => (
              <ListItem
                disableGutters
                key={worker.id}
                onClick={() => {
                  selectUser(worker);
                  onClose();
                }}>
                <ListItemText
                  className={classes.listItemText}
                  disableTypography
                  primary={
                    <Typography
                      component={RouterLink}
                      display="block"
                      noWrap
                      to="/chat"
                      variant="h6">
                      {`${worker.name} ${worker.surname}`}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        ))}
      </div>
    </Drawer>
  );
}

ChatBar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default ChatBar;
