import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar, Tooltip } from '@material-ui/core';
import { parseState, renderStateIcon, stateColors } from 'utils/issues';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    height: 120,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  avatar: {
    backgroundColor: props => stateColors[props.state],
    color: theme.palette.common.white,
    height: 48,
    width: 48
  }
}));

const State = ({ state, processor, date }) => {
  const classes = useStyles({ state });
  return (
    <Card className={classes.root}>
      <div>
        <Typography component="h3" gutterBottom variant="overline">
          Estado
        </Typography>
        <div className={classes.details}>
          <Typography variant="h4">{parseState(state)}</Typography>
        </div>
      </div>

      <Tooltip
        title={`Procesado por ${processor?.name} ${processor?.surname}`}
        disableHoverListener={state !== 'done'}>
        <Avatar className={classes.avatar}>{renderStateIcon(state)}</Avatar>
      </Tooltip>
    </Card>
  );
};

export default State;
