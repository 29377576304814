import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useRoles } from 'modules/authentication';

export const DELETE_AREA = gql`
  mutation deleteArea($areaId: Int) {
    delete_area(where: { id: { _eq: $areaId } }) {
      affected_rows
    }
  }
`;

export const useDeleteArea = () => {
  const { inventory__delete } = useRoles();
  let [deleteArea, { data, loading, error }] = useMutation(DELETE_AREA);
  return {
    deleteArea: ({ id }) =>
      deleteArea({
        variables: { areaId: id },
        context: {
          headers: {
            'x-hasura-role': inventory__delete ? 'inventory__delete' : 'user'
          }
        }
      }),
    data,
    loading,
    error
  };
};
