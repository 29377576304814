import gql from 'graphql-tag';

export const MESSAGE_FRAGMENT = gql`
  fragment messageFields on message {
    id
    chat_id
    sender: organization_user {
      id
      name
      surname
    }
    content
    created_at
  }
`;
