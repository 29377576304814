import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_USERS } from '../queries';
import useRouter from 'hooks/useRouter';

export const ADD_ORGANIZATION = gql`
  mutation addOrganization($objects: [organization_insert_input!]!) {
    insert_organization(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const useAddOrganization = () => {
  const { history } = useRouter();
  let [mutate] = useMutation(ADD_ORGANIZATION, {
    onCompleted: (data) =>
      history.push(
        `users/organizations/${data?.insert_organization.returning[0].id}`
      ),
    onError: () => history.push('/users')
  });

  return ({ description, name, profiles, code, email }) => {
    return mutate({
      variables: {
        objects: {
          description,
          name,
          code,
          email,
          organization_profile_associations: {
            data: profiles.map(({ id }) => ({ profile_id: id }))
          }
        }
      },
      refetchQueries: [{ query: GET_USERS }]
    });
  };
};
