import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider
} from '@material-ui/core';
import Type from './Type';
import Subtype from './Subtype';
import Map from '../Map';
import Features from './Features';
import Images from '../Images';

const Vegetation = ({ inventoryItem }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xl={6} xs={12}>
        <Grid container spacing={4}>
          <Grid item lg={6} xs={12}>
            <Type />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Subtype name={inventoryItem.name} />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Features data={inventoryItem} />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Images inventoryItem={inventoryItem} />
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Observaciones" />
              <Divider />
              <CardContent>
                <Typography variant="body1">
                  {inventoryItem.observations}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={6} xs={12}>
        <Map inventoryItem={inventoryItem} />
      </Grid>
    </Grid>
  );
};

export default Vegetation;
