import gql from 'graphql-tag';
import { WORK_ORDER_FRAGMENT } from './workOrder';
import {
  VEGETATION_FRAGMENT,
  TREE_FRAGMENT,
  BUSH_FRAGMENT,
  CARPET_FRAGMENT,
  FURNITURE_FRAGMENT,
  SURFACE_FRAGMENT,
  PLAYGROUND_FRAGMENT
} from 'modules/inventory/graphql/fragments';
export const ISSUE_FRAGMENT = gql`
  fragment issueFields on issue {
    date
    description
    id
    issuer_user_id
    location
    processed_by_user_id
    resource_id
    solved_at
    state
    title
    work_orders_aggregate {
      aggregate {
        count
      }
    }
    workOrdersToDo: work_orders(where: { state: { _eq: "toDo" } }) {
      ...workOrderFields
    }
    workOrdersInProgress: work_orders(where: { state: { _eq: "inProgress" } }) {
      ...workOrderFields
    }
    workOrdersDone: work_orders(where: { state: { _eq: "done" } }) {
      ...workOrderFields
    }
    elemento_inventario {
      id
      name
      location
      inventoryItemType
      areaByUdAdminId {
        id
        name
      }
      areaByUdGestionId {
        id
        name
      }
      area {
        id
        name
      }
      address
      addressNumber
      vegetacion_inventariables {
        ...vegetationFields
        arbusto_inventariables {
          ...bushFields
        }
        tapiz_inventariables {
          ...carpetFields
        }
        arbol_inventariables {
          ...treeFields
        }
        palmera_inventariables {
          tipo_hoja
        }
      }
      mobiliario_urbanos {
        ...furnitureFields
      }
      surfaces {
        ...surfaceFields
      }
      playgrounds {
        ...playgroundFields
      }
    }
    organization_user {
      external_id
      name
      surname
      file {
        id
        name
        url
      }
    }
    organizationUserByProcessedByUserId {
      external_id
      name
      surname
    }
    files(order_by: { id: desc }) {
      id
      name
      url
      size
      issue_id
    }
  }
  ${WORK_ORDER_FRAGMENT}
  ${VEGETATION_FRAGMENT}
  ${FURNITURE_FRAGMENT}
  ${TREE_FRAGMENT}
  ${BUSH_FRAGMENT}
  ${CARPET_FRAGMENT}
  ${SURFACE_FRAGMENT}
  ${PLAYGROUND_FRAGMENT}
`;
