import { useDispatch } from 'react-redux';
import * as actions from '../redux';

export function useSensorsDispatch() {
  const dispatch = useDispatch();
  return {
    loadSensors: () => dispatch(actions.loadSensors()),
    selectSensor: payload => dispatch(actions.selectSensor(payload)),
    openSensorModal: payload => dispatch(actions.openSensorModal(payload)),
    closeSensorModal: () => dispatch(actions.closeSensorModal()),
    openDeleteSensorModal: payload =>
      dispatch(actions.openDeleteSensorModal(payload)),
    closeDeleteSensorModal: () => dispatch(actions.closeDeleteSensorModal()),
    selectDatastream: payload => dispatch(actions.selectDatastream(payload)),
    openErrorAlert: payload => dispatch(actions.openErrorAlert(payload)),
    openSuccessAlert: payload => dispatch(actions.openSuccessAlert(payload)),
    closeAlert: () => dispatch(actions.closeAlert())
  };
}
