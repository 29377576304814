import React from 'react';
import { useToken } from './useToken';

export const TokenContext = React.createContext(null);
export const UpdateTokenContext = React.createContext(() => {});

export const Authentication = ({ children }) => {
  const [token, setToken] = useToken();
  return (
    <TokenContext.Provider value={token}>
      <UpdateTokenContext.Provider value={setToken}>
        {children}
      </UpdateTokenContext.Provider>
    </TokenContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(TokenContext);
};

export const useUpdateAuth = () => {
  return React.useContext(UpdateTokenContext);
};
export const useLogOut = () => {
  const updateJWT = React.useContext(UpdateTokenContext);
  return () => updateJWT(null);
};

export const useInfo = () => {
  const { info } = useAuth();
  const userId = info['https://hasura.io/jwt/claims']['x-hasura-user-id'];
  const orgId = info['https://hasura.io/jwt/claims']['x-hasura-org-id'];
  return {
    userId,
    orgId
  };
};

export default Authentication;
