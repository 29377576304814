import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';
import moment from 'moment';
import { parsePriority } from 'utils/issues';
import Link from 'components/Link';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 300,
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 }
}));

const Features = props => {
  const classes = useStyles();
  const { organization, priority, issue, due_to_date } = props;
  return (
    <Card className={classes.root}>
      <CardHeader title="Datos" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Prioridad</TableCell>
              <TableCell className={classes.data}>
                {priority ? `${parsePriority(priority)}` : 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Incidencia</TableCell>
              <TableCell className={classes.data}>
                {issue ? (
                  <Link title={issue.title} to={`/issues/${issue.id}`} />
                ) : (
                  'Desconocido'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Elemento</TableCell>
              <TableCell className={classes.data}>
                {issue && issue.elemento_inventario ? (
                  <Link
                    title={issue.elemento_inventario.name}
                    to={`/inventory/${issue.elemento_inventario.id}`}
                  />
                ) : (
                  'Desconocido'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Organización</TableCell>
              <TableCell className={classes.data}>
                {organization ? `${organization.name}` : 'Desconocido'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Vencimiento</TableCell>
              <TableCell className={classes.data}>
                {' '}
                {due_to_date
                  ? moment(due_to_date).format('DD/MM/YYYY')
                  : 'Desconocido'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Features;
