import React from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button
} from '@material-ui/core';
import { Formik } from 'formik';
import {
  useIssuesState,
  useIssuesDispatch,
  useIssuesMutation
} from 'modules/issues';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import Summary from './Summary';

const initialValues = {
  title: '',
  description: '',
  state: 'toDo',
  due_to_date: null,
  in_charge_user_id: null
};

const TaskModal = ({ workOrder }) => {
  const classes = useClasses(styles);
  const { showTaskModal, selectedTask } = useIssuesState();
  const { addTask, updateTask } = useIssuesMutation();
  const { closeTaskModal } = useIssuesDispatch();

  const handleClose = () => {
    closeTaskModal();
  };

  return (
    <Modal onClose={handleClose} open={showTaskModal}>
      <Card className={classes.root}>
        <Formik
          initialValues={
            selectedTask
              ? selectedTask
              : {
                  ...initialValues,
                  workOrderId: workOrder?.id
                }
          }
          onSubmit={(values, { setSubmitting }) => {
            selectedTask ? updateTask({ ...values }) : addTask([{ ...values }]);
            setSubmitting(false);
            handleClose();
          }}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardHeader
                title={
                  <Typography component="h1" variant="h3">
                    {selectedTask ? 'Editar tarea' : 'Nueva tarea'}
                  </Typography>
                }
                className={classes.header}
              />

              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                <Summary organizationId={workOrder.organization_id} />
              </CardContent>
              <Divider className={classes.divider} />
              <CardActions className={classes.actions}>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained">
                  {selectedTask ? 'Actualizar' : 'Guardar'}
                </Button>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

export default TaskModal;
