import React from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';
import useClasses from 'hooks/useClasses';

const styles = (theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 }
});

const Features = (props) => {
  const classes = useClasses(styles);
  const { email, description, code } = props;
  return (
    <Card className={classes.root}>
      <CardHeader title="Datos" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell className={classes.data}>
                {code ? code : 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell className={classes.data}>
                {email ? email : 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Descripción</TableCell>
              <TableCell className={classes.data}>{description}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Features;
