import React from 'react';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useGetGroundTypes } from 'modules/inventory';

const TOPOGRAPHY = ['Llana', 'Pendiente', 'Desnivel', 'Aterrazado'];
const TYPOLOGY = [
  'Avenida',
  'Jardín',
  'Paseo',
  'Zona Deportiva',
  'Rotonda',
  'Isleta',
  'Zona residencial',
  'Museo',
  'Centro de salud',
  'Otra'
];
const LOCATION_TYPES = ['Urbano', 'Periurnbano', 'Extraurbano'];

const Summary = () => {
  const { data } = useGetGroundTypes();
  const { values, handleChange, errors, touched } = useFormikContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Nombre"
          value={values.name}
          onChange={handleChange}
          name="name"
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Suelo"
          name="ground_type_id"
          onChange={handleChange}
          select
          SelectProps={{ native: true }}
          value={values.ground_type_id}
          variant="outlined"
          InputLabelProps={{ shrink: Boolean(values.ground_type_id) }}>
          <option></option>
          {data?.groundTypes?.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Topografía"
          name="topography"
          onChange={handleChange}
          select
          SelectProps={{ native: true }}
          value={values.topography}
          variant="outlined"
          InputLabelProps={{ shrink: Boolean(values.topography) }}>
          <option></option>
          {TOPOGRAPHY.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Tipología"
          name="typology"
          onChange={handleChange}
          select
          SelectProps={{ native: true }}
          value={values.typology}
          variant="outlined"
          InputLabelProps={{ shrink: Boolean(values.typology) }}>
          <option></option>
          {TYPOLOGY.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Tipo localización"
          name="location_type"
          onChange={handleChange}
          select
          SelectProps={{ native: true }}
          value={values.location_type}
          variant="outlined"
          InputLabelProps={{ shrink: Boolean(values.location_type) }}>
          <option></option>
          {LOCATION_TYPES.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Superficie"
          value={values.surface_m2}
          onChange={handleChange}
          name="surface_m2"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">m2</InputAdornment>
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Descripción"
          value={values.description}
          onChange={handleChange}
          name="description"
          variant="outlined"
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default Summary;
