import { useDispatch } from 'react-redux';
import * as slices from '../redux';

export function useChatDispatch() {
  const dispatch = useDispatch();
  return {
    loadChat: () => dispatch(slices.loadChat()),
    selectChat: payload => dispatch(slices.selectChat(payload)),
    openDeleteChatModal: payload =>
      dispatch(slices.openDeleteChatModal(payload)),
    closeDeleteChatModal: () => dispatch(slices.closeDeleteChatModal())
  };
}
