import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CardContent,
  Divider
} from '@material-ui/core';
import Actions from './Actions';
const useStyles = makeStyles(theme => ({
  root: {
    width: 360,
    position: 'absolute',
    top: 10,
    right: 10,
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 }
}));

const LocationPanel = ({ feature = {} }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title="Localización" action={<Actions feature={feature} />} />
      <Divider />
      <CardContent className={classes.content}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Dirección</TableCell>
              <TableCell className={classes.data}>
                {feature?.address || 'Desconocido'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default LocationPanel;
