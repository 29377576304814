import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 300,
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 }
}));

const Features = props => {
  const classes = useStyles();
  const {
    manufacturer,
    model,
    installationDate,
    lastRepairDate
  } = props.mobiliario_urbanos[0];
  return (
    <Card className={classes.root}>
      <CardHeader title="Datos" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell className={classes.data}>{props.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fabricante</TableCell>
              <TableCell className={classes.data}>{manufacturer}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Modelo</TableCell>
              <TableCell className={classes.data}>{model}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Instalación</TableCell>
              <TableCell className={classes.data}>
                {installationDate
                  ? moment(installationDate).format('DD/MM/YYYY')
                  : 'Sin fecha registrada'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ultima reparación</TableCell>
              <TableCell className={classes.data}>
                {' '}
                {lastRepairDate
                  ? moment(lastRepairDate).format('DD/MM/YYYY')
                  : 'Sin fecha registrada'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Features;
