import React from 'react';
import { Typography, LinearProgress } from '@material-ui/core';
import gradients from 'utils/gradients';
import useClasses from 'hooks/useClasses';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  progress: {
    margin: theme.spacing(0, 1),
    flexGrow: 1
  },
  avatar: {
    backgroundImage: gradients.orange,
    height: 48,
    width: 48
  }
});

const Progress = ({ value = 40 }) => {
  const classes = useClasses(styles);

  return (
    <div className={classes.root}>
      <Typography variant="body1">{value}%</Typography>
      <LinearProgress
        className={classes.progress}
        value={value}
        variant="determinate"
      />
    </div>
  );
};

export default Progress;
