import React, { useState } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button
} from '@material-ui/core';
import { Formik } from 'formik';
import {
  useIssuesState,
  useIssuesDispatch,
  useIssuesMutation
} from 'modules/issues';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import Summary from './Summary';

const initialValues = {
  title: '',
  description: '',
  priority: 'low',
  state: 'toDo',
  due_to_date: null,
  organization_id: '',
  processed_by_user_id: 1,
  issue_id: null
};

const WorkOrderModal = () => {
  const classes = useClasses(styles);

  const {
    showWorkOrderModal,
    selectedWorkOrder,
    selectedIssue
  } = useIssuesState();
  const { addWorkOrder, updateWorkOrder } = useIssuesMutation();
  const { closeWorkOrderModal } = useIssuesDispatch();

  const handleClose = () => {
    closeWorkOrderModal();
  };

  return (
    <Modal onClose={handleClose} open={showWorkOrderModal}>
      <Card className={classes.root}>
        <Formik
          initialValues={
            selectedWorkOrder
              ? selectedWorkOrder
              : {
                  ...initialValues,
                  issue_id: selectedIssue && selectedIssue.id
                }
          }
          onSubmit={(values, { setSubmitting }) => {
            selectedWorkOrder
              ? updateWorkOrder({ ...values })
              : addWorkOrder([{ ...values }]);
            setSubmitting(false);
            handleClose();
          }}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardHeader
                title={
                  <Typography component="h1" variant="h3">
                    {selectedWorkOrder
                      ? 'Editar orden de trabajo'
                      : 'Nueva orden de trabajo'}
                  </Typography>
                }
                className={classes.header}
              />

              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                <Summary />
              </CardContent>
              <Divider className={classes.divider} />
              <CardActions className={classes.actions}>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained">
                  {selectedWorkOrder ? 'Actualizar' : 'Guardar'}
                </Button>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

export default WorkOrderModal;
