import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Inventory from 'views/Inventory';
import Sensors from 'views/Sensors';
import SensorDetails from 'views/SensorDetails';
import Global from 'views/Global';
import Settings from 'views/Settings';
import Issues from 'views/Issues';
import InventoryDetails from 'views/InventoryDetails';
import IssueDetails from 'views/IssueDetails';
import WorkOrderDetails from 'views/WorkOrderDetails';
import Users from 'views/Users';
import TaskDetails from 'views/TaskDetails';
import WorkerDetails from 'views/WorkerDetails';
import OrganizationDetails from 'views/OrganizationDetails';
import ProfileDetails from 'views/ProfileDetails';
import Chat from 'views/Chat';
import Maintenances from 'views/Maintenances';
import MaintenanceDetails from 'views/MaintenanceDetails';
import MaintenanceTaskDetails from 'views/MaintenanceTaskDetails';
import AuthRoute from './AuthRoute';
import { useRoles } from 'modules/authentication';
import AreaDetails from 'views/AreaDetails';
import WorkOrders from 'views/WorkOrders';

const Routes = () => {
  const {
    issues__administrator,
    issues__solver,
    workorders__administrator,
    maintenances__administrator,
    maintenances__solver,
    chat__administrator,
    chat__organization,
    chat__worker,
    inventory__administrator,
    sensors__administrator,
    users__administrator,
    users__organization
  } = useRoles();
  return (
    <Switch>
      <Redirect exact from="/" to="/global" />
      <Route exact path="/global" component={Global} />
      <AuthRoute
        exact
        path="/inventory"
        component={Inventory}
        auth={[inventory__administrator]}
      />
      <AuthRoute
        exact
        path="/inventory/:id"
        component={InventoryDetails}
        auth={[inventory__administrator]}
      />
      <Route exact path="/inventory/areas/:areaId" component={AreaDetails} />
      <AuthRoute
        exact
        path="/sensors"
        component={Sensors}
        auth={[sensors__administrator]}
      />
      <AuthRoute
        path="/sensors/:id"
        component={SensorDetails}
        auth={[sensors__administrator]}
      />
      <AuthRoute
        exact
        path="/issues"
        component={Issues}
        auth={[issues__administrator, issues__solver]}
      />
      <AuthRoute
        exact
        path="/issues/:id"
        component={IssueDetails}
        auth={[issues__administrator, issues__solver]}
      />
      <AuthRoute
        exact
        path="/issues/:id/workorders/:workOrderId"
        component={WorkOrderDetails}
        auth={[issues__administrator, workorders__administrator]}
      />
      <AuthRoute
        exact
        path="/issues/:id/workorders/:workOrderId/tasks/:taskId"
        component={TaskDetails}
        auth={[issues__administrator, workorders__administrator]}
      />
      <AuthRoute
        exact
        path="/workorders"
        component={WorkOrders}
        auth={[issues__administrator, workorders__administrator]}
      />
      <AuthRoute
        exact
        path="/users"
        component={Users}
        auth={[users__administrator, users__organization]}
      />
      <AuthRoute
        exact
        path="/users/workers/:id"
        component={WorkerDetails}
        auth={[users__administrator, users__organization]}
      />
      <AuthRoute
        exact
        path="/users/organizations/:id"
        component={OrganizationDetails}
        auth={[users__administrator, users__organization]}
      />
      <AuthRoute
        exact
        path="/users/profiles/:profileId"
        component={ProfileDetails}
        auth={[users__administrator]}
      />
      <AuthRoute
        exact
        path="/chat"
        component={Chat}
        auth={[chat__administrator, chat__organization, chat__worker]}
      />
      <AuthRoute
        path="/chat/:id"
        component={Chat}
        auth={[chat__administrator, chat__organization, chat__worker]}
      />
      <AuthRoute
        exact
        path="/maintenances"
        component={Maintenances}
        auth={[maintenances__administrator, maintenances__solver]}
      />

      <AuthRoute
        exact
        path="/maintenances/:maintenanceId"
        component={MaintenanceDetails}
        auth={[maintenances__administrator, maintenances__solver]}
      />
      <AuthRoute
        exact
        path="/maintenances/:maintenanceId/tasks/:taskId"
        component={MaintenanceTaskDetails}
        auth={[maintenances__administrator, maintenances__solver]}
      />
      <Route exact path="/settings" render={() => <Settings />} />
    </Switch>
  );
};

export default Routes;
