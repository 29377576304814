import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Link as MaterialLink,
  Tooltip,
  Typography,
  IconButton
} from '@material-ui/core';
import Actions from '../../../Actions';
import Link from 'components/Link';
import { Link as RouterLink } from 'react-router-dom';
import PostAddIcon from '@material-ui/icons/PostAdd';
const useStyles = makeStyles(theme => ({
  root: {
    outline: 'none',
    marginBottom: theme.spacing(2)
  },
  header: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  isDragging: {},
  content: {
    paddingTop: 0
  },
  stats: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flexGrow: 1
  },
  workOrders: {
    color: theme.palette.icon,
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  comments: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center'
  },
  id: {},
  progress: {
    marginTop: theme.spacing(2)
  },
  toDo: {
    color: theme.palette.error.main,
    fontSize: '1.2rem'
  },
  inProgress: {
    color: theme.palette.warning.main,
    fontSize: '1.2rem'
  },
  done: {
    color: theme.palette.success.main,
    fontSize: '1.2rem'
  }
}));

const ListItem = ({ issue, provided, snapshot, className, style }) => {
  const classes = useStyles();
  return (
    <Card
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={clsx(
        classes.root,
        {
          [classes.isDragging]: snapshot.isDragging
        },
        className
      )}
      style={{ ...style, ...provided.draggableProps.style }}>
      <CardHeader
        action={<Actions issue={issue} />}
        subheaderTypographyProps={{ variant: 'overline' }}
        title={
          <Typography variant="body2">
            <MaterialLink
              color="textPrimary"
              component={RouterLink}
              to={`/issues/${issue.id}`}
              variant="h6">
              {issue.title}
            </MaterialLink>
          </Typography>
        }
      />
      <CardContent className={classes.content}>
        <div className={classes.stats}>
          <Typography
            className={classes.id}
            color="textSecondary"
            variant="body1">
            {`#${issue.id}`}
          </Typography>

          {issue.work_orders_aggregate.aggregate.count > 0 && (
            <div className={classes.workOrders}>
              {issue.workOrdersToDo.map(({ id, title }) => (
                <Tooltip title={title} key={id}>
                  <IconButton
                    component={RouterLink}
                    to={`/issues/${issue.id}/workorders/${id}`}
                    size="small">
                    <PostAddIcon className={classes.toDo} />
                  </IconButton>
                </Tooltip>
              ))}
              {issue.workOrdersInProgress.map(({ id, title }) => (
                <Tooltip title={title} key={id}>
                  <IconButton
                    component={RouterLink}
                    to={`/issues/${issue.id}/workorders/${id}`}
                    size="small">
                    <PostAddIcon className={classes.inProgress} />
                  </IconButton>
                </Tooltip>
              ))}
              {issue.workOrdersDone.map(({ id, title }) => (
                <Tooltip title={title} key={id}>
                  <IconButton
                    component={RouterLink}
                    to={`/issues/${issue.id}/workorders/${id}`}
                    size="small">
                    <PostAddIcon className={classes.done} />
                  </IconButton>
                </Tooltip>
              ))}
            </div>
          )}

          <div className={classes.flexGrow} />
          <Typography
            className={classes.date}
            color="textSecondary"
            variant="body2">
            {moment(issue.date).format('DD/MM/YYYY')}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

ListItem.defaultProps = {
  style: {},
  onOpen: () => {}
};

export default ListItem;
