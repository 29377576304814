import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import WarningIcon from '@material-ui/icons/Warning';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import GroupIcon from '@material-ui/icons/Group';
import ChatIcon from '@material-ui/icons/Chat';
import SidebarNav from './SidebarNav';
import BuildIcon from '@material-ui/icons/Build';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { useRoles } from 'modules/authentication';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2),
    overflow: 'hidden'
  }
}));

const Sidebar = props => {
  const {
    issues__administrator,
    issues__solver,
    workorders__administrator,
    maintenances__administrator,
    maintenances__solver,
    chat__administrator,
    chat__organization,
    chat__worker,
    inventory__administrator,
    sensors__administrator,
    users__administrator,
    users__organization
  } = useRoles();
  const { open, variant, onClose } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Vista global',
      href: '/global',
      icon: <DashboardIcon />
    },
    {
      title: 'Sensores',
      href: '/sensors',
      auth: [sensors__administrator],
      icon: <TrackChangesIcon />
    },
    {
      title: 'Inventario',
      href: '/inventory',
      auth: [inventory__administrator],
      icon: <AllInboxIcon />
    },
    {
      title: 'Incidencias',
      href: '/issues',
      auth: [issues__administrator, issues__solver],
      icon: <WarningIcon />
    },
    {
      title: 'Órdenes de trabajo',
      href: '/workorders',
      auth: [issues__administrator, workorders__administrator],
      icon: <LibraryBooksIcon />
    },
    {
      title: 'Mantenimientos',
      href: '/maintenances',
      auth: [maintenances__administrator, maintenances__solver],
      icon: <BuildIcon />
    },
    {
      title: 'Chat',
      href: '/chat',
      auth: [chat__administrator, chat__organization, chat__worker],
      icon: <ChatIcon />
    },
    {
      title: 'Usuarios',
      href: '/users',
      auth: [users__administrator, users__organization],
      icon: <GroupIcon />
    },
    {
      title: 'Configuración',
      href: '/settings',
      icon: <SettingsIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div className={classes.root}>
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
