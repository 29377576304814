import React from 'react';
import Table from 'components/Table';
import Link from 'components/Link';
import Actions from './Actions';
import { getInitials } from 'utils/users';
import { Avatar } from '@material-ui/core';
import { useInfo } from 'modules/authentication';
import { useGetWorkersByOrganization, useGetUsers, useGetUserById } from 'modules/users';

const columns = [
  {
    Header: 'Nombre',
    accessor: (data) => data,
    Cell: ({ cell: { value } }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{ height: 50, width: 50, marginRight: 10 }}
          src={value.file?.url}>
          {getInitials(`${value.name} ${value.surname}`)}
        </Avatar>
        <div>
          <Link
            to={`/users/workers/${value.id}`}
            title={`${value.name} ${value.surname || ''}`}
          />
          <div>{value.email}</div>
        </div>
      </div>
    )
  },
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Código',
    accessor: 'external_id'
  },
  {
    Header: 'Organización',
    accessor: 'organization.name'
  },
  {
    Header: 'Perfil',
    accessor: 'profile.name'
  },
  {
    Header: 'Acceso web',
    accessor: 'password',
    Cell: ({ cell: { value } }) => (value ? 'Sí' : 'No')
  },
  {
    Header: '',
    id: 'actions',
    accessor: (data) => data,
    Cell: ({ cell: { value } }) => <Actions worker={value} />
  }
];

const Workers = () => {
  const { userId, orgId } = useInfo();
  let workers = useGetWorkersByOrganization(orgId)?.data?.workers;
  let allWorkers = useGetUsers()?.data?.workers;
  const { data } = useGetUserById(userId);

  if (data?.organization_user) {
    // eslint-disable-next-line eqeqeq
    if (data?.organization_user[0].profile_id == 1) {
      workers.length = 0;
      workers = allWorkers;
    }
  }

  let workersCount = workers.length;

  return (
    <Table
      columns={columns}
      data={workers}
      count={workersCount}
    />
  );
};

export default Workers;
