import React from 'react';
import { Card, CardHeader, Divider, CardContent } from '@material-ui/core';
import {
  List,
  ListItem,
  ListItemText,
  Tooltip,
  IconButton,
  Typography
} from '@material-ui/core';
import Actions from './Actions';
import ConfirmDelete from './ConfirmDelete';
import useClasses from 'hooks/useClasses';
import AddIcon from '@material-ui/icons/Add';
import { useIssuesDispatch } from 'modules/issues';
import moment from 'moment';
import ReportModal from 'views/ReportModal';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0,
    width: '100%',
    height: '100%'
  },
  data: { fontWeight: 600 },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  arrowForwardIcon: {
    color: theme.palette.icon
  },
  toDo: {
    color: theme.palette.error.main
  },
  done: {
    color: theme.palette.success.main
  }
});

const Reports = ({ reports, task }) => {
  const classes = useClasses(styles);
  const { openReportModal } = useIssuesDispatch();
  return (
    <Card className={classes.root}>
      <CardHeader
        title="Reportes"
        action={
          <Tooltip title="Nuevo reporte">
            <IconButton size="small" onClick={() => openReportModal()}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Divider />
      <CardContent className={classes.content}>
        <List disablePadding>
          {reports && reports.length > 0 ? (
            reports.map((report, i) => (
              <ListItem
                className={classes.listItem}
                divider={i < reports.length}
                key={report.id}>
                <ListItemText
                  primary={report.notes}
                  primaryTypographyProps={{ variant: 'body1' }}
                  secondary={moment(report.date)
                    .startOf('hour')
                    .fromNow()}
                />
                <Actions report={report} />
              </ListItem>
            ))
          ) : (
            <div style={{ textAlign: 'center', padding: '3rem' }}>
              <Typography variant="subtitle2">
                Ningún reporte que mostrar
              </Typography>
            </div>
          )}
        </List>
      </CardContent>
      <ConfirmDelete />
      <ReportModal task={task} />
    </Card>
  );
};

export default Reports;
