import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import {
  VEGETATION_FRAGMENT,
  FURNITURE_FRAGMENT,
  INVENTORY_ITEM_FRAGMENT,
  TREE_FRAGMENT,
  BUSH_FRAGMENT,
  CARPET_FRAGMENT,
  SURFACE_FRAGMENT,
  PLAYGROUND_FRAGMENT
} from '../fragments';
import { useRoles } from 'modules/authentication';
import useFilters from 'hooks/useFilters';
import { useState } from 'react';

export const GET_INVENTORY = gql`
  query getInventory(
    $limit: Int
    $offset: Int
    $where: elemento_inventario_bool_exp
    $orderBy: [elemento_inventario_order_by!]
  ) {
    inventoryCount: elemento_inventario_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    inventory: elemento_inventario(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: $where
    ) {
      ...inventoryItemFields
      vegetacion_inventariables {
        ...vegetationFields
        arbusto_inventariables {
          ...bushFields
        }
        tapiz_inventariables {
          ...carpetFields
        }
        arbol_inventariables {
          ...treeFields
        }
      }
      mobiliario_urbanos {
        ...furnitureFields
      }
      playgrounds {
        ...playgroundFields
      }
    }
  }
  ${INVENTORY_ITEM_FRAGMENT}
  ${VEGETATION_FRAGMENT}
  ${FURNITURE_FRAGMENT}
  ${TREE_FRAGMENT}
  ${BUSH_FRAGMENT}
  ${CARPET_FRAGMENT}
  ${SURFACE_FRAGMENT}
  ${PLAYGROUND_FRAGMENT}
`;

const types = {
  all: { inventoryItemType: { _neq: 'sensor' } },
  furniture: { inventoryItemType: { _eq: 'furniture' } },
  vegetation: { inventoryItemType: { _eq: 'vegetation' } },
  playground: { inventoryItemType: { _eq: 'playground' } }
};

export const useGetInventory = (type = 'all') => {
  const { inventory__administrator } = useRoles();
  const [data, setData] = useState([]);
  const {
    count,
    setCount,
    limit,
    offset,
    nextPage,
    page,
    previousPage,
    hasNext,
    hasPrevious,
    where,
    search,
    handleSearch,
    orderBy,
    handleOrderBy
  } = useFilters({
    filters: ['name', 'address'],
    search: '',
    orderBy: { id: 'desc' }
  });

  const { loading, error } = useQuery(GET_INVENTORY, {
    variables: {
      limit,
      offset,
      where: { ...where, ...types[type] },
      orderBy
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      setData(response.inventory);
      setCount(response.inventoryCount.aggregate.count);
    },
    context: {
      headers: {
        'x-hasura-role': inventory__administrator
          ? 'inventory__administrator'
          : 'user'
      }
    }
  });
  return {
    data,
    error,
    count,
    offset,
    limit,
    page,
    loading,
    nextPage,
    previousPage,
    hasNext,
    hasPrevious,
    search,
    handleSearch,
    orderBy,
    handleOrderBy
  };
};
