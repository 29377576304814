import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import useRouter from 'hooks/useRouter';
import { ROLE_FRAGMENT } from '../fragments';

export const GET_PROFILE_DETAILS = gql`
  query getProfileDetails($profileId: Int!) {
    profile: profile_by_pk(id: $profileId) {
      id
      name
      permissions: profile_role_associations(
        where: { organization_user_role: { type: { _eq: "permission" } } }
      ) {
        role: organization_user_role {
          ...roleFields
        }
      }
      accesses: profile_role_associations(
        where: { organization_user_role: { type: { _eq: "access" } } }
      ) {
        role: organization_user_role {
          ...roleFields
        }
      }
    }
    permissions: organization_user_role(
      where: { type: { _eq: "permission" } }
    ) {
      ...roleFields
    }
    accesses: organization_user_role(where: { type: { _eq: "access" } }) {
      ...roleFields
    }
  }
  ${ROLE_FRAGMENT}
`;

export const useGetProfileDetails = () => {
  const { match } = useRouter();
  const { profileId } = match.params || {};
  const { data, error, loading } = useQuery(GET_PROFILE_DETAILS, {
    variables: { profileId },
    fetchPolicy: 'cache-and-network'
  });
  return {
    data,
    error,
    loading
  };
};
