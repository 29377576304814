import gql from 'graphql-tag';

export const VEGETATION_FRAGMENT = gql`
  fragment vegetationFields on vegetacion_inventariable {
    vegetationType
    commonName
    species
    plantingDate
    lastUpdateDate
  }
`;
