import React from 'react';
import Link from 'components/Link';
import Table from 'components/Table';

const columns = [
  {
    Header: 'Título',
    accessor: data => data,
    Cell: ({ cell: { value } }) => (
      <Link
        to={`/issues/${value?.workOrder?.issue?.id}/workorders/${value.id}/tasks/${value.id}`}
        title={value.title}
      />
    )
  },
  {
    Header: 'Orden de trabajo',
    accessor: data => data,
    Cell: ({ cell: { value } }) => (
      <Link
        to={`/issues/${value?.workOrder?.issue?.id}/workorders/${value?.workOrder?.id}`}
        title={value.title}
        variant="body1"
      />
    )
  },
  {
    Header: 'Incidencia',
    accessor: data => data?.workOrder?.issue,
    Cell: ({ cell: { value } }) => (
      <Link to={`/issues/${value?.id}`} title={value?.title} variant="body1" />
    )
  },
  {
    Header: 'Inventario',
    accessor: data => data?.workOrder?.issue?.inventoryItem,
    Cell: ({ cell: { value } }) => (
      <Link
        to={`/inventory/${value?.id}`}
        title={value?.name}
        variant="body1"
      />
    )
  }
];
const Tasks = ({ tasks }) => {
  if (!tasks) return null;
  return <Table title="Tareas pendientes" data={tasks} columns={columns} />;
};

export default Tasks;
