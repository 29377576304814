import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_SENSORS } from '../queries';
import { useRoles } from 'modules/authentication';
import { useSensorsDispatch } from 'modules/sensors/hooks';

export const UPDATE_SENSOR = gql`
  mutation updateSensor(
    $id: Int!
    $address: String
    $addressNumber: Int
    $districtId: Int
    $externalId: String
    $inventoryItemType: String
    $location: geometry
    $managementUnitId: Int
    $name: String
    $neighborhoodId: Int
    $observations: String
  ) {
    update_elemento_inventario(
      where: { id: { _eq: $id } }
      _set: {
        address: $address
        addressNumber: $addressNumber
        districtId: $districtId
        externalId: $externalId
        inventoryItemType: $inventoryItemType
        location: $location
        managementUnitId: $managementUnitId
        name: $name
        neighborhoodId: $neighborhoodId
        observations: $observations
      }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateSensor = () => {
  const { sensors__update } = useRoles();
  const { openSuccessAlert, openErrorAlert } = useSensorsDispatch();
  let [mutate, { data, loading, error }] = useMutation(UPDATE_SENSOR, {
    onCompleted: () => openSuccessAlert('Sensor actualizado correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });
  const date = new Date().toDateString();

  return {
    updateSensor: ({
      id,
      inventoryItemType,
      name,
      districtId,
      neighborhoodId,
      managementUnitId,
      address,
      observations,
      location
    }) =>
      mutate({
        variables: {
          id,
          inventoryItemType,
          name,
          districtId,
          neighborhoodId,
          managementUnitId,
          address,
          observations,
          location
        },
        refetchQueries: [{ query: GET_SENSORS, variables: { date } }],
        context: {
          headers: {
            'x-hasura-role': sensors__update ? 'sensors__update' : undefined
          }
        }
      }),
    data,
    loading,
    error
  };
};
