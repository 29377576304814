import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1)
  }
}));

const Header = ({ title, subtitle, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid alignItems="center" container justify="space-between" spacing={3}>
        <Grid item>
          <Typography component="h1" gutterBottom variant="h3">
            {title}
          </Typography>
          {subtitle && (
            <Typography component="h2" gutterBottom variant="overline">
              {subtitle}
            </Typography>
          )}
        </Grid>
        <Grid item alignItems="center">
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
