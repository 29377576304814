import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Map from './Map';
import {
  useMapDispatch,
  useMapState,
  useGetMap,
  useGetSettings
} from 'modules/map';
import SensorModal from 'views/SensorModal';
import InventoryModal from 'views/InventoryModal';
import IssueModal from 'views/IssueModal';
import View from 'components/View';
import MaintenanceModal from 'views/MaintenanceModal';
import { useInventoryDispatch } from 'modules/inventory';
import { Tooltip, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AreaModal from 'views/AreaModal';
import { useRoles } from 'modules/authentication';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    margin: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const Global = () => {
  const classes = useStyles();
  const { inventory__insert } = useRoles();
  const { data, loading, error } = useGetSettings();
  const { setLayers } = useMapDispatch();
  const { openAreaModal } = useInventoryDispatch();
  const { layers } = useMapState();
  const [openFilter, setOpenFilter] = useState(false);

  const handleFilter = (event, activeLayers) => {
    event.preventDefault();
    setLayers(activeLayers);
    setOpenFilter(false);
  };

  return (
    <View
      title="Vista global"
      loading={loading}
      error={error}
      actions={
        inventory__insert && (
          <Tooltip title="Añadir área">
            <IconButton onClick={() => openAreaModal()} size="small">
              <AddIcon />
            </IconButton>
          </Tooltip>
        )
      }>
      <Map
        location={data?.settings[0].location}
        className={classes.content}
        onCloseFilter={handleFilter}
        onOpenFilter={() => setOpenFilter(true)}
        onFilter={handleFilter}
        activeLayers={layers}
        open={openFilter}
      />
      <SensorModal />
      <InventoryModal />
      <IssueModal />
      <MaintenanceModal />
      <AreaModal />
    </View>
  );
};

export default Global;
