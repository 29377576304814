import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, colors } from '@material-ui/core';
import View from 'components/View';
import Loader from 'components/Loader';
import { useGetTaskDetails } from 'modules/issues/';
import Actions from './Actions';
import ConfirmDelete from './ConfirmDelete';
import Summary from './Summary';
import TaskModal from 'views/TaskModal';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const TaskDetails = () => {
  const classes = useStyles();
  const taskDetails = useGetTaskDetails();
  const { data, loading } = taskDetails;

  return (
    <View
      title="Tarea"
      subtitle={
        !loading && data && data.task ? `#${data.task.id}` : 'Cargando...'
      }
      actions={<Actions task={!loading && data && data.task} />}>
      <Divider className={classes.divider} />
      {loading && <Loader />}
      {data && (
        <div className={classes.content}>
          <Summary task={data.task} />
          <TaskModal workOrder={data?.task?.workOrder} />
        </div>
      )}
      <ConfirmDelete />
    </View>
  );
};

export default TaskDetails;
