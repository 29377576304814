import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_TASK_DETAILS } from '../queries';
import { useIssuesDispatch } from 'modules/issues';

export const ADD_REPORT = gql`
  mutation addReport($objects: [task_report_insert_input!]!) {
    insert_task_report(objects: $objects) {
      affected_rows
    }
  }
`;

export const useAddReport = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(ADD_REPORT, {
    onCompleted: () => openSuccessAlert('Reporte creado correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });

  return args => {
    return mutate({
      variables: {
        objects: args.map(({ notes, hours, date, taskId }) => {
          return {
            notes,
            hours,
            date,
            task_id: taskId
          };
        })
      },
      refetchQueries: [
        {
          query: GET_TASK_DETAILS,
          variables: { id: args[0].taskId }
        }
      ]
    });
  };
};
