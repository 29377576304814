import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, colors, Grid } from '@material-ui/core';
import View from 'components/View';
import Inventory from './Inventory';
import Map from './Map';
import Actions from './Actions';
import ConfirmDelete from './ConfirmDelete';
import {
  useGetAreaDetails,
  useInventoryState,
  useInventoryDispatch
} from 'modules/inventory';
import AreaModal from 'views/AreaModal';
import Alert from 'components/Alert';
import Summary from './Summary';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const AreaDetails = () => {
  const classes = useStyles();
  const { data, loading, error } = useGetAreaDetails();
  const { alert } = useInventoryState();
  const { closeAlert } = useInventoryDispatch();
  return (
    <View
      title="Área"
      subtitle={data ? `${data.area.name}` : 'Cargando...'}
      actions={<Actions area={data?.area} />}
      loading={loading}
      error={error}>
      <Divider className={classes.divider} />
      <Grid container className={classes.content} spacing={3}>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Summary {...data?.area} />
            </Grid>
            <Grid item xs={12}>
              <Inventory location={data?.area?.location} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Map location={data?.area?.location} inventory={data?.inventory} />
        </Grid>
      </Grid>
      <AreaModal />
      <ConfirmDelete />
      <Alert
        show={alert}
        message={alert?.message}
        severity={alert?.severity}
        fireOnClose={closeAlert}
      />
    </View>
  );
};

export default AreaDetails;
