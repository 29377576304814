import React from 'react';
import Table from 'components/Table';
import Label from 'components/Label';
import { Link } from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';

import Actions from './Actions';
import moment from 'moment';
import { parseState, stateColors } from 'utils/issues';
import Avatar from 'components/Avatar';

export default [
  {
    Header: 'Título',
    accessor: data => data,
    Cell: ({ cell: { value } }) => (
      <Link
        color="inherit"
        component={RouterLink}
        to={`/issues/${value.id}`}
        variant="h6">
        {value.title}
      </Link>
    )
  },
  {
    Header: 'ID',
    accessor: row => `${row.id}`
  },
  {
    Header: 'Informador',
    accessor: 'organization_user',
    Cell: ({ cell: { value } }) => (
      <Avatar
        name={`${value?.name} ${value?.surname}`}
        url={value?.file?.url}
      />
    )
  },
  {
    Header: 'Aviso',
    accessor: 'date',
    Cell: ({ cell: { value } }) => moment(value).format('DD/MM/YYYY')
  },
  {
    Header: 'Completado',
    accessor: 'solved_at',
    Cell: ({ cell: { value } }) =>
      value ? moment(value).format('DD/MM/YYYY') : ''
  },
  {
    Header: 'Estado',
    accessor: 'state',
    Cell: ({ cell: { value } }) => (
      <Label color={stateColors[value]} variant="outlined">
        {parseState(value)}
      </Label>
    )
  },

  {
    Header: '',
    id: 'actions',
    accessor: data => data,
    Cell: ({ cell: { value } }) => <Actions issue={value} />
  }
];
