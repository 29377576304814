import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import useClasses from 'hooks/useClasses';
import moment from 'moment';
import Label from 'components/Label';
import { useGetIssuesByInventoryItem } from 'modules/issues/graphql';
import Loader from 'components/Loader';
import useRouter from 'hooks/useRouter';
import { parseState, stateColors } from 'utils/issues';
const styles = theme => ({
  root: {
    height: 'calc(100% - 100px)',
    padding: theme.spacing(0),
    marginTop: theme.spacing(2)
  },
  filterButton: {
    position: 'absolute',
    top: 3,
    right: 0,
    margin: 10,
    backgroundColor: '#fff'
  },
  filterIcon: {
    marginRight: theme.spacing(0)
  },
  placeholder: {
    textAlign: 'center',
    padding: theme.spacing(3, 0, 0, 0)
  }
});

const Issues = props => {
  const classes = useClasses(styles);
  const { data, loading } = useGetIssuesByInventoryItem(props.id);
  const { history } = useRouter();
  return (
    <List>
      {loading && <Loader />}
      {data && data.issues && data.issues.length > 0 ? (
        data.issues.map(issue => {
          const { title, created_at, state, id } = issue;
          return (
            <ListItem
              button
              divider
              key={id}
              onClick={() => history.push(`/issues/${id}`)}>
              <ListItemText
                className={classes.listItemText}
                primary={title}
                primaryTypographyProps={{ variant: 'h6', noWrap: true }}
                secondary={moment(created_at).format('DD/MM/YYYY')}
              />
              <Label color={stateColors[state]} variant="outlined">
                {parseState(state)}
              </Label>
            </ListItem>
          );
        })
      ) : (
        <div className={classes.placeholder}>
          <Typography variant="subtitle2">
            Ninguna indicencia que mostrar
          </Typography>
        </div>
      )}
    </List>
  );
};

export default Issues;
