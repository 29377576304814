import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { KeyboardDatePicker as DatePicker } from '@material-ui/pickers';
import { useFormikContext } from 'formik';

const vegetationTypes = [
  'Seto',
  'Masa Arbustiva',
  'Arbusto',
  'Árbol',
  'Palmera',
  'Superficie vegetal'
];
const Vegetation = () => {
  const { values, handleChange, setFieldValue } = useFormikContext();
  const {
    vegetationType,
    commonName,
    species,
    plantingDate,
    lastUpdateDate
  } = values?.vegetacion_inventariables?.[0];
  return (
    <Grid container spacing={3} alignItems="center" justify="center">
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Subtipo"
          name="vegetacion_inventariables[0].vegetationType"
          onChange={handleChange}
          select
          SelectProps={{ native: true }}
          value={vegetationType}
          variant="outlined">
          <option key="empty" value={undefined}></option>
          {vegetationTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Nombre común"
          name="vegetacion_inventariables[0].commonName"
          onChange={handleChange}
          value={commonName}
          variant="outlined">
          {commonName}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Nombre científico"
          name="vegetacion_inventariables[0].species"
          onChange={handleChange}
          value={species}
          variant="outlined">
          {species}
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <DatePicker
          label="Plantación"
          fullWidth
          inputVariant="outlined"
          value={plantingDate}
          format="dd/MM/yyyy"
          onChange={(date, value) =>
            setFieldValue(
              'vegetacion_inventariables[0].plantingDate',
              date,
              false
            )
          }
          cancelLabel="cancelar"
          invalidDateMessage="Fecha incorrecta"
        />
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          label="Última modificación"
          fullWidth
          inputVariant="outlined"
          value={lastUpdateDate}
          format="dd/MM/yyyy"
          onChange={(date, value) =>
            setFieldValue(
              'vegetacion_inventariables[0].lastUpdateDate',
              date,
              false
            )
          }
          cancelLabel="cancelar"
          invalidDateMessage="Fecha incorrecta"
        />
      </Grid>
    </Grid>
  );
};

export default Vegetation;
