import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  IconButton,
  Avatar,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar as MaterialToolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import useRouter from 'hooks/useRouter';
import img from 'assets/images/avatar.png';
import Link from 'components/Link';
import { useGetChatDetails, useChatDispatch } from 'modules/chat';
import { openDeleteChatModal } from 'modules/chat/redux';
import { getInitials } from 'utils/users';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white
  },
  backButton: {
    marginRight: theme.spacing(2),
    '@media (min-width: 864px)': {
      display: 'none'
    }
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  activity: {
    display: 'flex',
    alignItems: 'center'
  },
  statusBullet: {
    marginRight: theme.spacing(1)
  },
  search: {
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    flexBasis: 300,
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 auto'
    }
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon
  },
  searchInput: {
    flexGrow: 1
  }
}));

function Toolbar({ className }) {
  const classes = useStyles();
  const moreRef = useRef(null);
  const { history } = useRouter();
  const [openMenu, setOpenMenu] = useState(false);
  const { data } = useGetChatDetails();
  const { openDeleteChatModal } = useChatDispatch();
  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <MaterialToolbar className={clsx(classes.root, className)}>
      <Tooltip title="Atrás">
        <IconButton
          className={classes.backButton}
          component={RouterLink}
          edge="start"
          to="/chat">
          <KeyboardBackspaceIcon />
        </IconButton>
      </Tooltip>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
        <div className={classes.user}>
          <Avatar
            alt="avatar"
            src={data?.chat?.users[0]?.organization_user?.file?.url}>
            {getInitials(
              `${data?.chat?.users[0]?.organization_user?.name} ${data?.chat?.users[0]?.organization_user?.surname}`
            )}
          </Avatar>
          <div
            style={{
              flex: '1 1 auto',
              padding: '0.5rem'
            }}>
            <Link
              to={`/users/workers/${data?.chat?.users[0]?.organization_user?.id}`}
              variant="h5"
              color="textPrimary"
              underline="none"
              title={`${data?.chat?.users[0]?.organization_user?.name ||
                ''} ${data?.chat?.users[0]?.organization_user?.surname || ''}`}
            />
          </div>
        </div>

        <Tooltip placement="right" title="Opciones">
          <IconButton onClick={handleMenuOpen} ref={moreRef}>
            <MoreIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        anchorEl={moreRef.current}
        keepMounted
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}>
        <MenuItem
          onClick={() =>
            history.push(
              `/users/workers/${data?.chat?.users[0]?.organization_user?.id}`
            )
          }>
          <ListItemText primary="Ver perfil" />
        </MenuItem>
        <MenuItem>
          <ListItemText
            primary="Eliminar chat"
            onClick={() => openDeleteChatModal(data?.chat)}
          />
        </MenuItem>
      </Menu>
    </MaterialToolbar>
  );
}

export default Toolbar;
