import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button
} from '@material-ui/core';
import { useChatState, useChatDispatch, useChatMutation } from 'modules/chat';
import useRouter from 'hooks/useRouter';
import { useInfo } from 'modules/authentication';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    height: 200
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ConfirmDelete = () => {
  const classes = useStyles();
  const { history } = useRouter();
  const { userId } = useInfo();
  const { showDeleteChatModal, selectedChat } = useChatState();
  const { deleteChat } = useChatMutation();
  const { closeDeleteChatModal } = useChatDispatch();

  if (!showDeleteChatModal) {
    return null;
  }

  return (
    <Modal onClose={closeDeleteChatModal} open={showDeleteChatModal}>
      <Card className={classes.root}>
        <CardHeader title="¿Eliminar chat?" />
        <Divider />
        <CardContent>
          <Typography variant="body1">
            Si elimina el chat seleccionado, se perderán los mensajes enviados.
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button onClick={closeDeleteChatModal}>Cancelar</Button>
          <Button
            color="secondary"
            onClick={async () => {
              await deleteChat({
                chatId: selectedChat.id,
                userId
              });
              closeDeleteChatModal();
              history.push(`/chat`);
            }}
            variant="contained">
            Sí, eliminar
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ConfirmDelete;
