import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { ORGANIZATION_FRAGMENT } from '../fragments';

export const GET_ORGANIZATIONS_BY_ID = gql`
  query getOrganizationsById($orgId: Int!) {
    organization(
      where: { id: { _eq: $orgId } }
      order_by: { id: asc }
    ) {
      ...organizationFields
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;


export const useGetOrganizationById = (orgId) => {
  const { data, error, loading } = useQuery(GET_ORGANIZATIONS_BY_ID, {
    variables: {
      orgId
    }
  });

  return {
    data,
    error,
    loading
  };
};
