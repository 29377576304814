import React from 'react';
import theme from 'theme';
import { VegetationPin, SurfacePin, FurniturePin } from 'components/Pins';

export const inventoryTypes = {
  furniture: 'Mobiliario urbano',
  playground: 'Parque infantil',
  vegetation: 'Vegetación',
  sensor: 'Sensor'
};

export const inventorySubtypes = {
  furniture: 'Mobiliario urbano',
  playground: 'Parque infantil',
  vegetation: 'Vegetación',
  sensor: 'Sensor'
};

export function getInventoryItemColor(value) {
  switch (value) {
    case 'furniture':
      return theme.palette.grey;
    case 'vegetation':
      return theme.palette.text.primary;
    case 'surface':
      return theme.palette.primary.main;
    default:
      return theme.palette.secondary.main;
  }
}

export const renderPin = (type) => {
  switch (type) {
    case 'furniture':
      return <FurniturePin />;
    case 'vegetation':
      return <VegetationPin />;
    case 'surface':
      return <SurfacePin />;
    default:
      return <FurniturePin />;
  }
};

export const parseImportedData = (array = [], callback) => {
  const dataToImport = array.filter((_, index) => index !== 0);
  callback(dataToImport);
};

export const inventoryTypesToParse = {
  mobiliario: 'furniture',
  juego: 'playground',
  sensor: 'sensor',
  vegetación: 'vegetation',
  vegetacion: 'vegetation',
  superficie: 'surface'
};

export const inventorySubtypesToParse = {
  árbol: 'Árbol',
  arbol: 'Árbol',
  palmera: 'Palmera',
  superficie: 'Superficie Vegetal',
  arbusto: 'Arbusto'
};

export const parseType = (type = '') => {
  const cleanType = type.toLowerCase();
  return inventoryTypesToParse[cleanType];
};

export const parseSubtype = (subtype = '') => {
  if (subtype) {
    const cleanSubtype = subtype.toLowerCase();
    return inventorySubtypesToParse[cleanSubtype];
  }
};
