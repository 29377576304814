import gql from 'graphql-tag';

export const TASK_FRAGMENT = gql`
  fragment taskFields on work_order_task {
    id
    description
    due_to_date
    in_charge_user_id
    state
    title
    maintenance_id
  }
`;
