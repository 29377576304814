import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_PROFILES, GET_USERS } from '../queries';
import { useInfo } from 'modules/authentication';

export const ADD_PROFILE = gql`
  mutation addProfile($objects: [profile_insert_input!]!) {
    insert_profile(objects: $objects) {
      affected_rows
    }
  }
`;

export const useAddProfile = () => {
  let [mutate] = useMutation(ADD_PROFILE);
  const { orgId } = useInfo();
  return ({ name }) => {
    return mutate({
      variables: { objects: { name } },
      refetchQueries: [
        { query: GET_PROFILES, variables: { limit: 10, offset: 0 } },
        {
          query: GET_USERS,
          variables: { organizationId: orgId, limit: 10, offset: 0 }
        }
      ]
    });
  };
};
