import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  content: {
    padding: '1rem',
    flexGrow: 1
  }
}));

const Description = ({ description }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title="Descripción" />
      <Divider />
      <CardContent className={classes.content}>
        <Typography variant="body1">{description}</Typography>
      </CardContent>
    </Card>
  );
};

export default Description;
