import React from 'react';
import OpacityIcon from '@material-ui/icons/Opacity';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

export function parseObservation(observation) {
  switch (observation) {
    case 'water_temperature':
      return 'Temperatura del agua';
    case 'relative_humidity':
      return 'Humedad relativa';
    case 'air_temperature':
      return 'Temperatura';
    case 'air_pressure':
      return 'Presión atmosférica';
    default:
      return 'Desconocido';
  }
}

export function getObservationIcon(observation) {
  switch (observation) {
    case 'water_temperature':
      return <OpacityIcon />;
    case 'air_pressure':
      return <TrackChangesIcon />;
    case 'relative_humidity':
      return <TrackChangesIcon />;
    case '"air_temperature':
      return <TrackChangesIcon />;
    default:
      return <TrackChangesIcon />;
  }
}

export function getObservations(datastreams) {
  return Array.isArray(datastreams)
    ? datastreams
        ?.map(datastream => parseObservation(datastream.observation))
        .join(', ')
    : null;
}

export function getLastUpdate(datastreams) {
  const maxDate =
    Array.isArray(datastreams) &&
    Math.max.apply(
      null,
      datastreams.map(
        datastream =>
          datastream.observation_values.length > 0 &&
          new Date(datastream.observation_values[0].time)
      )
    );

  return maxDate ? new Date(maxDate).toLocaleString() : 'Desconocido';
}

export const observations = [
  'air_temperature',
  'relative_humidity',
  'air_pressure',
  'water_temperature'
];

export const datastreams = {
  air_temperature: {
    observation: 'air_temperature',
    unit: 'degree Celsius'
  },
  relative_humidity: {
    observation: 'relative_humidity',
    unit: '%'
  },
  air_pressure: { observation: 'air_pressure', unit: 'mba' },
  water_temperature: {
    observation: 'water_temperature',
    unit: 'degree Celsius'
  }
};

export function parseUnit(unit = '') {
  switch (unit.toLowerCase()) {
    case 'degree celsius':
      return '°';
    default:
      return unit;
  }
}
