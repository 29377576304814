import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
  Popover,
  CardHeader,
  Divider,
  colors,
  ListItem,
  List,
  ListItemText
} from '@material-ui/core';
import { useGetSettingsByUser } from 'modules/users';
import { useLogOut } from 'modules/authentication';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    maxWidth: '100%'
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center'
  }
}));

const Settings = ({ anchorEl, onClose, ...rest }) => {
  const classes = useStyles();
  const { data } = useGetSettingsByUser();
  const logOut = useLogOut();
  return (
    <Popover
      {...rest}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}>
      <div className={classes.root}>
        <CardHeader
          title={`${data?.settings[0]?.user?.name} ${data?.settings[0]?.user?.surname}`}
          subheader={data?.settings[0]?.user?.email}
        />
        <Divider />

        <List className={classes.root} disablePadding>
          <ListItem
            component={RouterLink}
            className={classes.listItem}
            onClick={onClose}
            to={`/settings`}>
            <ListItemText
              primary={'Configuración'}
              primaryTypographyProps={{ variant: 'body1' }}
            />
          </ListItem>
          <ListItem component={RouterLink} to="/" className={classes.listItem}>
            <ListItemText
              primary={'Cerrar sesión'}
              primaryTypographyProps={{ variant: 'body1' }}
              onClick={logOut}
            />
          </ListItem>
        </List>
      </div>
    </Popover>
  );
};

export default Settings;
