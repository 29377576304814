import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_MAINTENANCE_DETAILS } from '../queries';

export const UPDATE_TASK = gql`
  mutation updateTask(
    $id: Int!
    $title: String
    $description: String
    $due_to_date: timestamp
    $in_charge_user_id: Int
    $state: String
  ) {
    update_work_order_task(
      where: { id: { _eq: $id } }
      _set: {
        title: $title
        description: $description
        due_to_date: $due_to_date
        in_charge_user_id: $in_charge_user_id
        state: $state
      }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateTask = () => {
  let [mutate] = useMutation(UPDATE_TASK);

  return args => {
    const {
      id,
      title,
      description,
      due_to_date,
      in_charge_user_id,
      state,
      maintenance_id
    } = args;
    return mutate({
      variables: {
        id,
        title,
        description,
        due_to_date,
        in_charge_user_id,
        state
      },
      refetchQueries: [
        {
          query: GET_MAINTENANCE_DETAILS,
          variables: { id: maintenance_id }
        }
      ]
    });
  };
};
