import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 120,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
}));

const Title = ({ title }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Typography component="h3" gutterBottom variant="overline">
        Título
      </Typography>
      <div className={classes.details}>
        <Typography variant="h5">{title || 'Desconocido'}</Typography>
      </div>
    </Card>
  );
};

export default Title;
