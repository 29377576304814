import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableCell,
  TableBody,
  TableRow
} from '@material-ui/core';
import { useGetInventoryItemDetails } from 'modules/inventory/graphql';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  },
  data: {
    fontWeight: 600
  }
}));

const State = () => {
  const classes = useStyles();
  const { data } = useGetInventoryItemDetails();
  const {
    e_copa_ramas_secas_percent,
    e_copa_chupones_cm,
    e_tronco_inclinacion_grad,
    e_base_huecos_cm,
    de_protector,
    de_protector_estado
  } = data.inventoryItem.vegetacion_inventariables[0].arbol_inventariables[0];
  return (
    <Card className={classes.root}>
      <CardHeader title="Estado" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Ramas secas copa</TableCell>
              <TableCell
                className={classes.data}>{`${e_copa_ramas_secas_percent ||
                ''} %`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Chupones copa</TableCell>
              <TableCell className={classes.data}>{`${e_copa_chupones_cm ||
                ''} cm`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Inclinación tronco</TableCell>
              <TableCell
                className={classes.data}>{`${e_tronco_inclinacion_grad ||
                ''} grados`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Huecos base</TableCell>
              <TableCell className={classes.data}>{`${e_base_huecos_cm ||
                ''} cm`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Elementos de protección</TableCell>
              <TableCell className={classes.data}>{de_protector}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Estado</TableCell>
              <TableCell className={classes.data}>
                {de_protector_estado}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default State;
