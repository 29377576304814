import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider
} from '@material-ui/core';
import img from 'assets/images/banco.jpg';
import Title from './Title';
import State from './State';
import Features from './Features';
import Images from './Images';
import Map from './Map';

const useStyles = makeStyles(theme => ({
  root: { display: 'flex', flexWrap: 'wrap', height: '100%' },
  column: {
    display: 'flex',
    flexFirection: 'column'
  },
  media: {
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100%'
  },
  item: {
    maxHeight: 420
  }
}));

const Summary = ({ issue }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item md={6} xs={12}>
        <Grid container spacing={4}>
          <Grid item lg={6} xs={12}>
            <Title title={issue.title} />
          </Grid>
          <Grid item lg={6} xs={12}>
            <State
              state={issue.state}
              processor={issue.organizationUserByProcessedByUserId}
              date={issue.solved_at}
            />
          </Grid>
          <Grid item lg={6} xs={12} className={classes.item}>
            <Features {...issue} />
          </Grid>
          <Grid item lg={6} xs={12} className={classes.item}>
            <Images issue={issue} />
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Descripción" />
              <Divider />
              <CardContent style={{ padding: '1rem' }}>
                <Typography variant="body1">{issue.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Map feature={issue.elemento_inventario} />
      </Grid>
    </Grid>
  );
};

export default Summary;
