import React, { useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const parseMessage = message => {
  if (message === 'GraphQL error: Your current role is not in allowed roles') {
    return 'La configuración de tu perfil no incluye el acceso a esta sección.';
  }
  return message;
};
export const Alert = ({
  message = 'Error al contactar con el servidor. Consulte con el administrador.',
  show = false,
  fireOnClose = () => {},
  severity = 'error'
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    fireOnClose();
  };

  useEffect(() => {
    show && setOpen(true);
  }, [show]);

  if (!show) return null;
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity}>
        {parseMessage(message)}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;
