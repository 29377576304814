import React, { useRef, useState } from 'react';
import {
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useUsersDispatch } from 'modules/users';
import { useRoles } from 'modules/authentication';

const Actions = () => {
  const {
    users__insert,
    users__organization,
    users__administrator
  } = useRoles();
  const {
    openOrganizationModal,
    openWorkerModal,
    openProfileModal
  } = useUsersDispatch();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };
  if (!users__insert) return null;
  return (
    <>
      <Tooltip title="Añadir">
        <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        {users__administrator && users__insert && (
          <MenuItem
            onClick={() => {
              openOrganizationModal({ organization: undefined });
              setOpenMenu(false);
            }}>
            <ListItemText primary="Organización" />
          </MenuItem>
        )}
        {(users__administrator || users__organization) && users__insert && (
          <MenuItem
            onClick={() => {
              openWorkerModal();
              setOpenMenu(false);
            }}>
            <ListItemText primary="Trabajador" />
          </MenuItem>
        )}
        {users__administrator && users__insert && (
          <MenuItem
            onClick={() => {
              openProfileModal();
              setOpenMenu(false);
            }}>
            <ListItemText primary="Perfil" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default Actions;
