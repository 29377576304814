import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Routes from './routes';
import { loadMap } from 'modules/map/redux';
import 'assets/scss/index.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { loadInventory } from 'modules/inventory/redux';
import { loadIssues } from 'modules/issues/redux';
import { useChatDispatch } from 'modules/chat';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from 'layout';

function App() {
  const dispatch = useDispatch();
  const { loadChat } = useChatDispatch();

  useEffect(() => {
    dispatch(loadMap());
  }, []);
  useEffect(() => {}, []);
  useEffect(() => {
    dispatch(loadInventory());
    dispatch(loadIssues());
    loadChat();
  }, []);

  return (
    <Router>
      <Layout>
        <Routes />
      </Layout>
    </Router>
  );
}

export default App;
