import { useDispatch } from 'react-redux';
import * as slices from '../redux';

export function useUsersDispatch() {
  const dispatch = useDispatch();
  return {
    loadUsers: () => dispatch(slices.loadUsers()),
    openOrganizationModal: (payload) =>
      dispatch(slices.openOrganizationModal(payload)),
    closeOrganizationModal: () => dispatch(slices.closeOrganizationModal()),
    openWorkerModal: (payload) => dispatch(slices.openWorkerModal(payload)),
    closeWorkerModal: () => dispatch(slices.closeWorkerModal()),
    openDeleteOrganizationModal: (payload) =>
      dispatch(slices.openDeleteOrganizationModal(payload)),
    closeDeleteOrganizationModal: () =>
      dispatch(slices.closeDeleteOrganizationModal()),
    openDeleteWorkerModal: (payload) =>
      dispatch(slices.openDeleteWorkerModal(payload)),
    closeDeleteWorkerModal: () => dispatch(slices.closeDeleteWorkerModal()),
    openProfileModal: (payload) => dispatch(slices.openProfileModal(payload)),
    closeProfileModal: () => dispatch(slices.closeProfileModal()),
    openDeleteProfileModal: (payload) =>
      dispatch(slices.openDeleteProfileModal(payload)),
    closeDeleteProfileModal: () => dispatch(slices.closeDeleteProfileModal()),
    openUpdateProfileModal: (payload) =>
      dispatch(slices.openUpdateProfileModal(payload)),
    closeUpdateProfileModal: () => dispatch(slices.closeUpdateProfileModal()),
    openErrorAlert: (payload) => dispatch(slices.openErrorAlert(payload)),
    closeAlert: () => dispatch(slices.closeAlert()),
    openSuccessAlert: (payload) => dispatch(slices.openSuccessAlert(payload)),
    openWorkerUploadModal: (payload) =>
      dispatch(slices.openWorkerUploadModal(payload)),
    closeWorkerUploadModal: () => dispatch(slices.closeWorkerUploadModal()),
    openOrganizationUploadModal: (payload) =>
      dispatch(slices.openOrganizationUploadModal(payload)),
    closeOrganizationUploadModal: () =>
      dispatch(slices.closeOrganizationUploadModal()),
    openWebUserModal: (payload) => dispatch(slices.openWebUserModal(payload)),
    closeWebUserModal: () => dispatch(slices.closeWebUserModal())
  };
}
