import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_WORK_ORDERS_BY_ISSUE } from '../queries';
import { useIssuesDispatch } from 'modules/issues';
import { useInfo } from 'modules/authentication';

export const UPDATE_WORK_ORDER = gql`
  mutation updateWorkOrder(
    $id: Int!
    $title: String
    $description: String
    $priority: String
    $state: String
    $organization_id: Int
    $due_to_date: timestamp
  ) {
    update_work_order(
      where: { id: { _eq: $id } }
      _set: {
        title: $title
        description: $description
        priority: $priority
        state: $state
        organization_id: $organization_id
        due_to_date: $due_to_date
      }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateWorkOrder = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  const { orgId } = useInfo();
  let [mutate, { error }] = useMutation(UPDATE_WORK_ORDER, {
    onCompleted: () =>
      openSuccessAlert('Orden de trabajo actualizada correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });
  return (args) => {
    const {
      id,
      priority,
      state,
      organization_id,
      issue_id,
      title,
      description,
      due_to_date
    } = args;
    return mutate({
      variables: {
        id,
        priority,
        title,
        description,
        state,
        organization_id: organization_id || orgId,
        due_to_date
      },
      refetchQueries: [
        {
          query: GET_WORK_ORDERS_BY_ISSUE,
          variables: { issueId: issue_id }
        }
      ]
    });
  };
};
