import React, { useState, useEffect } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button,
  Tabs,
  Tab
} from '@material-ui/core';
import { Formik } from 'formik';
import Location from './Location';
import Summary from './Summary';
import { useSensorsState, useSensorsDispatch } from 'modules/sensors';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import * as Yup from 'yup';
import {
  useAddSensor,
  useUpdateSensor
} from 'modules/sensors/graphql/mutations';
import useRouter from 'hooks/useRouter';

const initialValues = {
  inventoryItemType: 'sensor',
  name: '',
  observations: '',
  address: '',
  location: {
    coordinates: [0, 0]
  },
  sensor: {
    datastreams: []
  }
};

const tabs = [
  { value: 'summary', label: 'General' },
  { value: 'location', label: 'Localización' }
];

const schema = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  location: Yup.object().required('Campo requerido')
});

const SensorModal = () => {
  const classes = useClasses(styles);
  const { showSensorModal, selectedSensor } = useSensorsState();
  const { addSensor, data } = useAddSensor();
  const { updateSensor } = useUpdateSensor();
  const [currentTab, setCurrentTab] = useState('summary');
  const { closeSensorModal } = useSensorsDispatch();
  const { history } = useRouter();

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleClose = () => {
    setCurrentTab('summary');
    closeSensorModal();
  };

  useEffect(() => {
    if (data) {
      history.push(`sensors/${data.insert_sensor.returning[0].id}`);
    }
  }, [data, history]);

  return (
    <Modal onClose={handleClose} open={showSensorModal}>
      <Card className={classes.root}>
        <Formik
          initialValues={selectedSensor ? selectedSensor : initialValues}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            selectedSensor
              ? updateSensor({ ...values })
              : addSensor([{ ...values }]);
            setSubmitting(false);
            handleClose();
          }}>
          {({ handleSubmit, isValid }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardHeader
                title={
                  <Typography component="h1" variant="h3">
                    {selectedSensor ? 'Editar sensor' : 'Nuevo sensor'}
                  </Typography>
                }
                className={classes.header}
              />
              <Tabs
                className={classes.tabs}
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={currentTab}
                variant="scrollable"
                indicatorColor="primary">
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                {currentTab === 'summary' && <Summary />}
                {currentTab === 'location' && <Location />}
              </CardContent>
              <Divider className={classes.divider} />
              <CardActions className={classes.actions}>
                <Button onClick={handleClose}>Cancelar</Button>

                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!isValid}
                  variant="contained">
                  {selectedSensor ? 'Actualizar' : 'Guardar'}
                </Button>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

export default SensorModal;
