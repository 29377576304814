import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_USERS } from '../queries';
import useRouter from 'hooks/useRouter';
import { useInfo } from 'modules/authentication';

export const DELETE_ORGANIZATION = gql`
  mutation deleteOrganization($id: Int) {
    delete_organization(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const useDeleteOrganization = () => {
  const { history } = useRouter();
  const { orgId } = useInfo();
  let [mutate] = useMutation(DELETE_ORGANIZATION, {
    onCompleted: () => history.push('/users'),
    onError: () => history.push('/users')
  });
  return ({ id }) => {
    return mutate({
      variables: { id },
      refetchQueries: [
        {
          query: GET_USERS,
          variables: {
            organizationId: orgId,
            limit: 10,
            offset: 0
          }
        }
      ]
    });
  };
};
