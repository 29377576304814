import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Grid, TextField } from '@material-ui/core';
import Map from './Map';
import PolygonMap from './PolygonMap';
import { useFormikContext } from 'formik';
import Loader from 'components/Loader';
import { useGetSettings } from 'modules/map';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  map: {
    height: '400px'
  }
}));

const Location = () => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const handleChange = (event) => {
    setFieldValue(event.target.name, event.target.value);
    if (event.target.name === 'latitude') {
      setFieldValue('location', {
        type: 'Point',
        crs: {
          type: 'name',
          properties: {
            name: 'urn:ogc:def:crs:EPSG::4326'
          }
        },
        coordinates: [values.location.coordinates[0], event.target.value]
      });
    } else {
      setFieldValue('location', {
        type: 'Point',
        crs: {
          type: 'name',
          properties: {
            name: 'urn:ogc:def:crs:EPSG::4326'
          }
        },
        coordinates: [event.target.value, values.location.coordinates[1]]
      });
    }
  };
  const { data, loading } = useGetSettings();
  useEffect(() => {
    setFieldValue('longitude', data?.settings[0].location?.coordinates[0]);
    setFieldValue('latitude', data?.settings[0].location?.coordinates[1]);
  }, [data, setFieldValue]);
  if (loading) return <Loader />;
  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Longitud"
          value={values.longitude}
          name="longitude"
          variant="outlined"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Latitud"
          value={values.latitude}
          name="latitude"
          variant="outlined"
          onChange={handleChange}
        />
  </Grid>
      <Grid item xs={12} className={classes.map}>
        <Map
          longitude={data?.settings[0].location?.coordinates[0]}
          latitude={data?.settings[0].location?.coordinates[1]}
        />
      </Grid>
    </Grid>
  );
};

export default Location;
