import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { WORK_ORDER_FRAGMENT } from '../fragments';
import usePagination from 'hooks/usePagination';
import { useInfo } from 'modules/authentication';

export const GET_WORK_ORDERS = gql`
  query getWorkOrders($limit: Int!, $offset: Int!, $orgId: Int!) {
    toDoCount: work_order_aggregate(where: { state: { _eq: "toDo" } }) {
      aggregate {
        count
      }
    }
    doneCount: work_order_aggregate(where: { state: { _eq: "done" }, organization_id: { _eq: $orgId } }) {
      aggregate {
        count
      }
    }
    toDo: work_order(
      limit: $limit
      offset: $offset
      where: { state: { _eq: "toDo" }, 
               organization_id: { _eq: $orgId }   }
      order_by: { id: desc }
    ) {
      ...workOrderFields
    }
    done: work_order(
      limit: $limit
      offset: $offset
      where: { state: { _eq: "done" }, 
               organization_id: { _eq: $orgId } }
      order_by: { id: desc }
    ) {
      ...workOrderFields
    }
  }
  ${WORK_ORDER_FRAGMENT}
`;

export const useGetWorkOrders = () => {
  const { orgId } = useInfo();

  const {
    limit,
    offset,
    setLimit,
    setOffset,
    handleNext,
    handlePrevious,
    setCount,
    resetPagination
  } = usePagination();

  const query = useQuery(GET_WORK_ORDERS, {
    variables: { limit, offset, orgId },

    fetchPolicy: 'cache-and-network'
  });
  return {
    limit,
    offset,
    setLimit,
    setOffset,
    handleNext,
    handlePrevious,
    setCount,
    resetPagination,
    ...query
  };
};
