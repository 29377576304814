import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';

const Bush = () => {
  const { values, handleChange } = useFormikContext();
  const { shape } =
    values.vegetacion_inventariables[0].arbusto_inventariables[0] || {};
  return (
    <Grid container spacing={3} alignItems="center" justify="center">
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Forma"
          name="vegetacion_inventariables[0].arbusto_inventariables[0].shape"
          onChange={handleChange}
          value={shape}
          variant="outlined">
          {shape}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default Bush;
