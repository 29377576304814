import React from 'react';
import {
  colors,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import useClasses from 'hooks/useClasses';

const styles = theme => ({
  header: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  divider: {
    marginBottom: theme.spacing(3),
    backgroundColor: colors.grey[300]
  },
  grid: {
    paddingBottom: theme.spacing(2)
  }
});
const State = () => {
  const classes = useClasses(styles);
  const { values, handleChange } = useFormikContext();
  const {
    e_copa_ramas_secas_percent,
    e_copa_chupones_cm,
    e_tronco_inclinacion_grad,
    e_base_huecos_cm
  } = values.vegetacion_inventariables[0].arbol_inventariables[0] || {};
  return (
    <div>
      <Typography variant="h5" className={classes.header}>
        Copa
      </Typography>

      <Grid
        container
        spacing={3}
        alignItems="center"
        justify="center"
        className={classes.grid}>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Ramas secas"
            name="vegetacion_inventariables[0].arbol_inventariables[0].e_copa_ramas_secas_percent"
            onChange={handleChange}
            value={e_copa_ramas_secas_percent}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}>
            {e_copa_ramas_secas_percent}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Chupones"
            name="vegetacion_inventariables[0].arbol_inventariables[0].e_copa_chupones_cm"
            onChange={handleChange}
            value={e_copa_chupones_cm}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>
            }}>
            {e_copa_chupones_cm}
          </TextField>
        </Grid>
      </Grid>
      <Typography variant="h5" className={classes.header}>
        Tronco
      </Typography>
      <Grid container spacing={3} alignItems="center" justify="center">
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Inclinación"
            name="vegetacion_inventariables[0].arbol_inventariables[0].e_tronco_inclinacion_grad"
            onChange={handleChange}
            value={e_tronco_inclinacion_grad}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">grados</InputAdornment>
              )
            }}>
            {e_tronco_inclinacion_grad}
          </TextField>
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Huecos"
            name="vegetacion_inventariables[0].arbol_inventariables[0].e_base_huecos_cm"
            onChange={handleChange}
            value={e_base_huecos_cm}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>
            }}>
            {e_base_huecos_cm}
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
};

export default State;
