import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_MAINTENANCE_DETAILS } from '../queries';

export const DELETE_TASK = gql`
  mutation deleteTask($id: Int, $maintenanceId: Int) {
    delete_work_order_task(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const useDeleteTask = () => {
  let [mutate] = useMutation(DELETE_TASK);
  return ({ id, maintenanceId }) => {
    return mutate({
      variables: { id },
      refetchQueries: [
        {
          query: GET_MAINTENANCE_DETAILS,
          variables: { id: maintenanceId }
        }
      ]
    });
  };
};
