import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider
} from '@material-ui/core';
import img from 'assets/images/banco.jpg';
import Title from './Title';
import State from './State';
import Features from './Features';
import Reports from './Reports';

const useStyles = makeStyles(theme => ({
  root: { display: 'flex', flexWrap: 'wrap', height: '100%' },
  column: {
    display: 'flex',
    flexFirection: 'column'
  },
  media: {
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100%'
  }
}));

const Summary = ({ task }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item lg={6} xs={12}>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Title title={task.title} />
          </Grid>
          <Grid item md={6} xs={12}>
            <State state={task.state} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Features {...task} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Card style={{ height: '100%' }}>
              <CardHeader title="Instrucciones" />
              <Divider />
              <CardContent style={{ padding: '1rem', flexGrow: 1 }}>
                <Typography variant="body1">{task.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Reports reports={task.reports} task={task} />
      </Grid>
    </Grid>
  );
};

export default Summary;
