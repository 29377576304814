import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  GET_MAINTENANCES,
  GET_MAINTENANCES_BY_INVENTORY_ITEM
} from '../queries';
import { useInfo } from 'modules/authentication/hooks/useAuth';
export const ADD_MAINTENANCE = gql`
  mutation addMaintenance($objects: [maintenance_insert_input!]!) {
    insert_maintenance(objects: $objects) {
      affected_rows
    }
  }
`;

export const useAddMaintenance = () => {
  const { userId, orgId } = useInfo();
  let [mutate] = useMutation(ADD_MAINTENANCE);

  return (args) => {
    return mutate({
      variables: {
        objects: args.map(
          ({
            priority,
            state,
            organization_id,
            resource_id,
            description,
            title,
            scheduled_at,
            type
          }) => {
            return {
              priority,
              state,
              organization_id: organization_id || orgId,
              created_by_user_id: userId,
              resource_id,
              description,
              title,
              scheduled_at,
              type
            };
          }
        )
      },
      refetchQueries: [
        { query: GET_MAINTENANCES },
        {
          query: GET_MAINTENANCES_BY_INVENTORY_ITEM,
          variables: { inventoryItemId: args[0].resource_id }
        }
      ]
    });
  };
};
