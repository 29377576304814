import React from 'react';

const Vegetation = (props) => {
  return (
    <svg width="50" height="50" viewBox="0 0 256 256" fill="none" {...props}>
      <path
        d="M166.238 231.593C166.238 231.593 159.235 119.925 244.735 69.718C247.329 68.195 249.642 72.587 247.887 75.707C233.048 102.081 201.295 169.862 225.621 231.75L166.238 231.593Z"
        fill="#EACF32"
      />
      <path
        d="M130.248 231.498C130.248 231.498 123.266 88.655 209.662 25.074C212.142 23.249 214.711 27.257 213.196 30.598C198.961 61.989 164.659 150.801 189.631 231.654L130.248 231.498Z"
        fill="#CCC033"
      />
      <path
        d="M115.7 231.46C115.7 231.46 123.183 119.83 37.9 69.172C35.313 67.635 32.981 72.015 34.723 75.144C49.448 101.596 80.91 169.544 56.317 231.303L115.7 231.46Z"
        fill="#EACF32"
      />
      <path
        d="M151.689 231.555C151.689 231.555 159.286 88.75 73.164 24.714C70.692 22.876 68.105 26.87 69.606 30.219C83.706 61.684 117.625 150.677 92.306 231.398L151.689 231.555Z"
        fill="#99AF17"
      />
      <path
        d="M124.227 115.246C123.006 115.246 121.858 114.495 121.412 113.282C118.331 104.922 114.853 96.918 111.073 89.492C103.717 75.039 94.942 62.248 84.994 51.474C83.87 50.257 83.946 48.359 85.163 47.235C86.38 46.11 88.278 46.187 89.402 47.404C99.724 58.583 108.814 71.827 116.42 86.77C120.306 94.405 123.88 102.626 127.042 111.208C127.615 112.763 126.819 114.487 125.264 115.06C124.921 115.186 124.571 115.246 124.227 115.246Z"
        fill="#CCC033"
      />
      <path
        d="M155.748 231.498C155.748 231.498 150.742 129.026 201.706 59.033C205.945 47.232 210.058 37.518 213.196 30.598C214.711 27.257 212.143 23.249 209.662 25.074C123.266 88.654 130.248 231.498 130.248 231.498L189.631 231.655C189.624 231.633 189.618 231.61 189.611 231.588L155.748 231.498Z"
        fill="#99AF17"
      />
      <path
        d="M115.403 231.455C115.403 231.455 109.623 170.975 179.362 143.846C181.478 143.023 183.368 145.403 181.937 147.092C169.844 161.364 143.973 198.049 163.86 231.583L115.403 231.455Z"
        fill="#EACF32"
      />
      <path
        d="M150.083 225.547C148.795 225.547 147.604 224.711 147.213 223.415C141.62 204.928 144.701 184.656 156.371 163.161C157.162 161.705 158.983 161.167 160.439 161.956C161.895 162.747 162.434 164.568 161.644 166.024C150.767 186.057 147.843 204.782 152.955 221.677C153.435 223.263 152.538 224.937 150.953 225.417C150.663 225.505 150.37 225.547 150.083 225.547Z"
        fill="#FCDA4F"
      />
      <path
        d="M103.532 231.424C103.532 231.424 109.647 154.092 39.333 119.35C37.314 118.353 35.206 120.514 36.433 122.329C47.957 139.38 75.688 187.599 55.075 231.296L103.532 231.424Z"
        fill="#CCC033"
      />
      <path
        d="M73.075 231.296C90.243 194.902 73.877 155.375 61.269 133.327C54.938 128.203 47.68 123.474 39.333 119.349C37.314 118.352 35.207 120.513 36.433 122.328C47.957 139.379 75.688 187.598 55.075 231.295L103.532 231.423C103.532 231.423 103.533 231.402 103.535 231.376L73.075 231.296Z"
        fill="#99AF17"
      />
      <path
        d="M74.164 231.346C74.164 231.346 80.204 170.897 10.583 143.401C8.47101 142.567 6.57101 144.937 7.99401 146.633C20.025 160.969 45.738 197.79 25.707 231.218L74.164 231.346Z"
        fill="#CCC033"
      />
      <path
        d="M43.707 231.218C60.852 202.607 44.487 171.513 31.871 154.156C25.704 150.226 18.659 146.589 10.583 143.4C8.47101 142.566 6.57101 144.936 7.99401 146.632C20.025 160.968 45.738 197.789 25.707 231.217L74.164 231.345C74.164 231.345 74.166 231.328 74.168 231.297L43.707 231.218Z"
        fill="#99AF17"
      />
      <path
        d="M90.535 231.378C90.535 231.378 84.753 154.014 155.215 119.644C157.238 118.657 159.337 120.83 158.102 122.638C146.505 139.628 118.567 187.7 138.992 231.506L90.535 231.378Z"
        fill="#FCDA4F"
      />
      <path
        d="M108.535 231.377C108.535 231.377 104.05 171.21 151.322 133.424C153.833 129.091 156.19 125.44 158.103 122.638C159.337 120.83 157.239 118.657 155.216 119.644C84.754 154.014 90.536 231.378 90.536 231.378L138.993 231.506C138.986 231.49 138.979 231.474 138.972 231.459L108.535 231.377Z"
        fill="#EACF32"
      />
      <path
        d="M70.846 231.337L85.622 122.863C85.943 120.506 89.513 120.404 89.987 122.738L112.085 231.446L70.846 231.337Z"
        fill="#FCDA4F"
      />
      <path
        d="M196.238 231.593C196.238 231.593 191.82 161.093 232.894 106.941C238.144 93.933 243.692 83.164 247.887 75.707C249.642 72.587 247.329 68.195 244.735 69.718C159.235 119.925 166.238 231.593 166.238 231.593L225.621 231.75C225.611 231.724 225.601 231.697 225.591 231.671L196.238 231.593Z"
        fill="#CCC033"
      />
      <path
        d="M87.197 227.104C93.2379 227.104 98.135 222.207 98.135 216.166C98.135 210.125 93.2379 205.228 87.197 205.228C81.1561 205.228 76.259 210.125 76.259 216.166C76.259 222.207 81.1561 227.104 87.197 227.104Z"
        fill="#EACF32"
      />
      <path
        d="M69.802 128.551C75.8429 128.551 80.74 123.654 80.74 117.613C80.74 111.572 75.8429 106.675 69.802 106.675C63.7611 106.675 58.864 111.572 58.864 117.613C58.864 123.654 63.7611 128.551 69.802 128.551Z"
        fill="#CCC033"
      />
    </svg>
  );
};

export const SelectedVegetation = (props) => (
  <svg width="50" height="50" viewBox="0 0 256 256" fill="none" {...props}>
    <ellipse cx="128" cy="237" rx="128" ry="15" fill="#249A77" />
    <path
      d="M166.238 231.593C166.238 231.593 159.235 119.925 244.735 69.718C247.329 68.195 249.642 72.587 247.887 75.707C233.048 102.081 201.295 169.862 225.621 231.75L166.238 231.593Z"
      fill="#EACF32"
    />
    <path
      d="M130.248 231.498C130.248 231.498 123.266 88.655 209.662 25.074C212.142 23.249 214.711 27.257 213.196 30.598C198.961 61.989 164.659 150.801 189.631 231.654L130.248 231.498Z"
      fill="#CCC033"
    />
    <path
      d="M115.7 231.46C115.7 231.46 123.183 119.83 37.8999 69.172C35.3129 67.635 32.9809 72.015 34.7229 75.144C49.4479 101.596 80.9099 169.544 56.3169 231.303L115.7 231.46Z"
      fill="#EACF32"
    />
    <path
      d="M151.689 231.555C151.689 231.555 159.286 88.75 73.164 24.714C70.692 22.876 68.105 26.87 69.606 30.219C83.706 61.684 117.625 150.677 92.306 231.398L151.689 231.555Z"
      fill="#99AF17"
    />
    <path
      d="M124.227 115.246C123.006 115.246 121.858 114.495 121.412 113.282C118.331 104.922 114.853 96.918 111.073 89.492C103.717 75.039 94.942 62.248 84.994 51.474C83.87 50.257 83.946 48.359 85.163 47.235C86.38 46.11 88.278 46.187 89.402 47.404C99.724 58.583 108.814 71.827 116.42 86.77C120.306 94.405 123.88 102.626 127.042 111.208C127.615 112.763 126.819 114.487 125.264 115.06C124.921 115.186 124.571 115.246 124.227 115.246Z"
      fill="#CCC033"
    />
    <path
      d="M155.748 231.498C155.748 231.498 150.742 129.026 201.706 59.033C205.945 47.232 210.058 37.518 213.196 30.598C214.711 27.257 212.143 23.249 209.662 25.074C123.266 88.654 130.248 231.498 130.248 231.498L189.631 231.655C189.624 231.633 189.618 231.61 189.611 231.588L155.748 231.498Z"
      fill="#99AF17"
    />
    <path
      d="M115.403 231.455C115.403 231.455 109.623 170.975 179.362 143.846C181.478 143.023 183.368 145.403 181.937 147.092C169.844 161.364 143.973 198.049 163.86 231.583L115.403 231.455Z"
      fill="#EACF32"
    />
    <path
      d="M150.083 225.547C148.795 225.547 147.604 224.711 147.213 223.415C141.62 204.928 144.701 184.656 156.371 163.161C157.162 161.705 158.983 161.167 160.439 161.956C161.895 162.747 162.434 164.568 161.644 166.024C150.767 186.057 147.843 204.782 152.955 221.677C153.435 223.263 152.538 224.937 150.953 225.417C150.663 225.505 150.37 225.547 150.083 225.547Z"
      fill="#FCDA4F"
    />
    <path
      d="M103.532 231.424C103.532 231.424 109.647 154.092 39.333 119.35C37.314 118.353 35.206 120.514 36.433 122.329C47.957 139.38 75.688 187.599 55.075 231.296L103.532 231.424Z"
      fill="#CCC033"
    />
    <path
      d="M73.075 231.296C90.243 194.902 73.877 155.375 61.269 133.327C54.938 128.203 47.68 123.474 39.333 119.349C37.314 118.352 35.207 120.513 36.433 122.328C47.957 139.379 75.688 187.598 55.075 231.295L103.532 231.423C103.532 231.423 103.533 231.402 103.535 231.376L73.075 231.296Z"
      fill="#99AF17"
    />
    <path
      d="M74.164 231.346C74.164 231.346 80.204 170.897 10.583 143.401C8.47098 142.567 6.57098 144.937 7.99398 146.633C20.025 160.969 45.738 197.79 25.707 231.218L74.164 231.346Z"
      fill="#CCC033"
    />
    <path
      d="M43.707 231.218C60.852 202.607 44.487 171.513 31.871 154.156C25.704 150.226 18.659 146.589 10.583 143.4C8.47098 142.566 6.57098 144.936 7.99398 146.632C20.025 160.968 45.738 197.789 25.707 231.217L74.164 231.345C74.164 231.345 74.166 231.328 74.168 231.297L43.707 231.218Z"
      fill="#99AF17"
    />
    <path
      d="M90.535 231.378C90.535 231.378 84.753 154.014 155.215 119.644C157.238 118.657 159.337 120.83 158.102 122.638C146.505 139.628 118.567 187.7 138.992 231.506L90.535 231.378Z"
      fill="#FCDA4F"
    />
    <path
      d="M108.535 231.377C108.535 231.377 104.05 171.21 151.322 133.424C153.833 129.091 156.19 125.44 158.103 122.638C159.337 120.83 157.239 118.657 155.216 119.644C84.7539 154.014 90.5359 231.378 90.5359 231.378L138.993 231.506C138.986 231.49 138.979 231.474 138.972 231.459L108.535 231.377Z"
      fill="#EACF32"
    />
    <path
      d="M70.8459 231.337L85.6219 122.863C85.9429 120.506 89.5129 120.404 89.9869 122.738L112.085 231.446L70.8459 231.337Z"
      fill="#FCDA4F"
    />
    <path
      d="M196.238 231.593C196.238 231.593 191.82 161.093 232.894 106.941C238.144 93.933 243.692 83.164 247.887 75.707C249.642 72.587 247.329 68.195 244.735 69.718C159.235 119.925 166.238 231.593 166.238 231.593L225.621 231.75C225.611 231.724 225.601 231.697 225.591 231.671L196.238 231.593Z"
      fill="#CCC033"
    />
    <path
      d="M87.197 227.104C93.2379 227.104 98.135 222.207 98.135 216.166C98.135 210.125 93.2379 205.228 87.197 205.228C81.1561 205.228 76.259 210.125 76.259 216.166C76.259 222.207 81.1561 227.104 87.197 227.104Z"
      fill="#EACF32"
    />
    <path
      d="M69.802 128.551C75.8429 128.551 80.74 123.654 80.74 117.613C80.74 111.572 75.8429 106.675 69.802 106.675C63.7611 106.675 58.864 111.572 58.864 117.613C58.864 123.654 63.7611 128.551 69.802 128.551Z"
      fill="#CCC033"
    />
  </svg>
);
export default Vegetation;
