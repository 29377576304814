import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, colors } from '@material-ui/core';
import General from './General';
import View from 'components/View';
import {
  useGetSettingsByUser,
  useUsersDispatch,
  useUsersState
} from 'modules/users';
import WorkerUploadModal from 'views/WorkerUploadModal';
import Alert from 'components/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  tabs: {
    fontWeight: 600,
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const Settings = () => {
  const classes = useStyles();
  const { data, loading, error } = useGetSettingsByUser();
  const { alert } = useUsersState();
  const { closeAlert } = useUsersDispatch();
  return (
    <View title={'Configuración'} loading={loading} error={error}>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <General settings={data?.settings[0]} />
      </div>
      <Alert
        show={alert}
        message={alert?.message}
        severity={alert?.severity}
        fireOnClose={() => closeAlert()}
      />
      <WorkerUploadModal />
    </View>
  );
};

export default Settings;
