import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography } from '@material-ui/core';
import Progress from 'components/Progress';
import { getPercentageDone } from 'utils/issues';

const useStyles = makeStyles(theme => ({
  root: {
    height: 120,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center'
  },
  details: {
    width: '100%',
    flexGrow: 1
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: 48,
    width: 48
  }
}));

const State = props => {
  const classes = useStyles();
  const { tasks, done } = props;
  if (!tasks && !done) return null;
  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <Typography component="h3" gutterBottom variant="overline">
          Estado
        </Typography>
        <div>
          <Progress
            value={getPercentageDone(tasks.length, done.aggregate.count)}
          />
        </div>
      </div>
    </Card>
  );
};

export default State;
