import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { ISSUE_FRAGMENT } from '../fragments';
import { useInfo } from 'modules/authentication';
import { useGetWorkersByOrganization } from 'modules/users';

export const GET_ISSUES = gql`
  query getIssues {
    issues: issue(order_by: { id: desc }) {
      ...issueFields
    }
    toDo: issue(where: { state: { _eq: "toDo" } }, order_by: { id: desc }) {
      ...issueFields
    }
    inProgress: issue(
      where: { state: { _eq: "inProgress" } }
      order_by: { id: desc }
    ) {
      ...issueFields
    }
    done: issue(where: { state: { _eq: "done" } }, order_by: { id: desc }) {
      ...issueFields
    }
  }
  ${ISSUE_FRAGMENT}
`;

export const useGetIssues = () => {
  const { orgId } = useInfo();
  let ids = useGetWorkersByOrganization(orgId).data?.workers?.map(function (a) { return a.id; });

  let { data, error, loading } = useQuery(GET_ISSUES);

  for (let i = 0; i < data?.done?.length; i++) {
    if (!(ids.includes(data.done[i].issuer_user_id))) {
      data.done.splice(i, 1);
    }
  }

  for (let i = 0; i < data?.toDo?.length; i++) {
    if (!(ids.includes(data.toDo[i].issuer_user_id))) {
      data.toDo.splice(i, 1);
    }
  }

  for (let i = 0; i < data?.inProgress?.length; i++) {
    if (!(ids.includes(data.inProgress[i].issuer_user_id))) {
      data.inProgress.splice(i, 1);
    }
  }

  if (data?.issues)
    data.issues.length = 0;

  return {
    data,
    error,
    loading
  };
};
