import React from 'react';
import Table from 'components/Table';
import Label from 'components/Label';
import { Link } from '@material-ui/core';
import { useGetMaintenancesByInventoryItem } from 'modules/maintenances';
import { Link as RouterLink } from 'react-router-dom';
import Actions from './Actions';
import moment from 'moment';
import { parseState, stateColors } from 'utils/issues';
import { renderState, renderPriority } from 'utils/maintenances';

const columns = [
  {
    Header: 'Título',
    accessor: data => data,
    Cell: ({ cell: { value } }) => (
      <Link
        color="inherit"
        component={RouterLink}
        to={`/maintenances/${value.id}`}
        variant="h6">
        {value.title}
      </Link>
    )
  },

  {
    Header: 'ID',
    accessor: row => `${row.id}`
  },

  {
    Header: 'Prioridad',
    accessor: 'priority',
    Cell: ({ cell: { value } }) => renderPriority(value)
  },
  {
    Header: 'Organización',
    accessor: row => row?.organization?.name || ''
  },

  {
    Header: 'Programado',
    accessor: 'scheduled_at',
    Cell: ({ cell: { value } }) =>
      value ? moment(value).format('DD/MM/YYYY') : ''
  },
  {
    Header: 'Estado',
    accessor: 'state',
    Cell: ({ cell: { value } }) => renderState(value)
  },
  {
    Header: '',
    id: 'actions',
    accessor: data => data,
    Cell: ({ cell: { value } }) => <Actions maintenance={value} />
  }
];

const Maintenances = props => {
  const { data, loading } = useGetMaintenancesByInventoryItem(props.id);
  return (
    <Table columns={columns} data={data?.maintenances} loading={loading} />
  );
};

export default Maintenances;
