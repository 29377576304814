import { combineReducers } from 'redux';
import sensorsReducer from 'modules/sensors/redux';
import mapReducer from 'modules/map/redux';
import inventoryReducer from 'modules/inventory/redux';
import issuesReducer from 'modules/issues/redux';
import usersReducer from 'modules/users/redux';
import chatReducer from 'modules/chat/redux';
import maintenancesReducer from 'modules/maintenances/redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
  map: mapReducer,
  sensors: sensorsReducer,
  inventory: inventoryReducer,
  issues: issuesReducer,
  users: usersReducer,
  chat: chatReducer,
  maintenances: maintenancesReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()]
});

export default store;
