import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import { states } from 'utils/issues';

const Summary = () => {
  const { values, handleChange, setFieldValue } = useFormikContext();
  const { title, description, state, date, solved_at } = values;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Título"
            name="title"
            value={title}
            onChange={handleChange}
            variant="outlined">
            {title}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            label="Aviso"
            fullWidth
            inputVariant="outlined"
            value={date}
            format="dd/MM/yyyy"
            onChange={(newDate, value) => setFieldValue('date', newDate, false)}
            cancelLabel="cancelar"
            invalidDateMessage="Fecha incorrecta"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Estado"
            name="state"
            onChange={handleChange}
            select
            SelectProps={{ native: true }}
            value={state}
            variant="outlined"
            InputLabelProps={{ shrink: Boolean(state) }}>
            <option value={''}></option>
            {Object.keys(states).map(id => (
              <option key={id} value={id}>
                {states[id].name}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="Completado"
            fullWidth
            inputVariant="outlined"
            value={solved_at}
            disabled={state !== 'done'}
            format="dd/MM/yyyy"
            onChange={(newDate, value) =>
              setFieldValue('solved_at', newDate, false)
            }
            cancelLabel="cancelar"
            invalidDateMessage="Fecha incorrecta"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Descripción"
            name="description"
            value={description}
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={2}>
            {description}
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
};

export default Summary;
