import React from 'react';
import Placeholder from './Placeholder';
import useClasses from 'hooks/useClasses';
import {
  Card,
  CardContent,
  Divider,
  TablePagination,
  TableHead,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableRow,
  CardActions,
  CardHeader
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect
} from 'react-table';
import matchSorter from 'match-sorter';
import DefaultColumnFilter from './DefaultColumnFilter';
import styles from './styles';
import Loader from 'components/Loader';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleBackButtonClick = (event) => {
    onChangePage('previous');
  };

  const handleNextButtonClick = (event) => {
    onChangePage('next');
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

const Table = ({
  title,
  columns,
  data = [],
  count = 1,
  action,
  loading,
  limit = 10,
  offset = 0,
  setLimit = () => {},
  handlePrevious = () => {},
  handleNext = () => {},
  nextPage,
  previousPage
}) => {
  const classes = useClasses(styles);
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      minWidth: 20,
      width: 150,
      maxWidth: 500
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 }
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const handleChangePage = (action) => {
    if (action === 'next') {
      handleNext(limit);
      nextPage();
    }
    if (action === 'previous') {
      handlePrevious(limit);
      previousPage();
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  return (
    <div className={classes.root}>
      <Card>
        {title && <CardHeader title={title} action={action} />}
        <Divider />
        <CardContent className={classes.content}>
          <MaterialTable {...getTableProps()} size="small">
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      size="medium"
                      {...column.getHeaderProps()}
                      className={classes.tableHeader}>
                      {column.render('Header')}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {page?.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()} hover>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </MaterialTable>
          {loading && data.length < 1 && <Loader />}
          {!loading && data.length < 1 && <Placeholder />}
        </CardContent>
        {data.length > 0 && (
          <CardActions className={classes.tablePagination}>
            <TablePagination
              component="div"
              count={count}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={offset / limit}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to === -1 ? count : to} de ${count}`
              }
              labelRowsPerPage=""
              rowsPerPage={limit}
              rowsPerPageOptions={[]}
              ActionsComponent={TablePaginationActions}
            />
          </CardActions>
        )}
      </Card>
    </div>
  );
};

export default Table;

export const NewTable = ({
  title,
  columns,
  data = [],
  count = 1,
  action,
  loading,
  limit = 10,
  offset,
  setLimit = () => {},
  handlePrevious = () => {},
  handleNext = () => {}
}) => {
  const classes = useClasses(styles);

  const handleChangePage = (action) => {
    if (action === 'next') {
      handleNext(limit);
    }
    if (action === 'previous') {
      handlePrevious(limit);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };
  return (
    <div className={classes.root}>
      <Card>
        {title && <CardHeader title={title} action={action} />}
        {title && <Divider />}
        <CardContent className={classes.content}>
          <MaterialTable size="small">
            <TableHead>
              <TableRow>
                {columns.map(({ Header }) => (
                  <TableCell size="medium" className={classes.tableHeader}>
                    {Header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row) => {
                return (
                  <TableRow hover>
                    {columns.map(({ accessor, Cell }) => {
                      return <TableCell>{Cell || row[accessor]}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </MaterialTable>
          {loading && data.length < 1 && <Loader />}
          {!loading && data.length < 1 && <Placeholder />}
        </CardContent>
        {data.length > 0 && (
          <CardActions className={classes.tablePagination}>
            <TablePagination
              component="div"
              count={count}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={offset / limit}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to === -1 ? count : to} de ${count}`
              }
              labelRowsPerPage=""
              rowsPerPage={limit}
              rowsPerPageOptions={[]}
              ActionsComponent={TablePaginationActions}
            />
          </CardActions>
        )}
      </Card>
    </div>
  );
};
