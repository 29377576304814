import { createSlice } from '@reduxjs/toolkit';
import { uploadAWS } from 'utils/files';
const initialState = {
  showIssueModal: false,
  showDeleteIssueModal: false,
  selectedIssue: undefined,
  showWorkOrderModal: false,
  selectedWorkOrder: undefined,
  showDeleteWorkOrderModal: false,
  selectedTask: undefined,
  showTaskModal: false,
  showDeleteTaskModal: false,
  openReportModal: false,
  closeReportModal: false,
  openDeleteReportModal: false,
  closeDeleteReportModal: false,
  showIssueUploadModal: false,
  issueUploadLoading: false,
  issueUploadError: null,
  issueUpload: null,
  selectedFile: undefined,
  alert: undefined
};

const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    loadIssues: () => initialState,
    selectIssue: (state, { payload }) => ({
      ...state,
      selectedIssue: payload
    }),
    selectWorkOrder: (state, { payload }) => ({
      ...state,
      selectedWorkOrder: payload
    }),
    openIssueModal: (state, { payload }) => ({
      ...state,
      selectedIssue: payload,
      showIssueModal: true
    }),
    closeIssueModal: state => ({
      ...state,
      selectedIssue: undefined,
      showIssueModal: false
    }),
    openDeleteIssueModal: (state, { payload }) => ({
      ...state,
      selectedIssue: payload,
      showDeleteIssueModal: true
    }),
    closeDeleteIssueModal: state => ({
      ...state,
      selectedIssue: undefined,
      showDeleteIssueModal: false
    }),
    openWorkOrderModal: (state, { payload }) => ({
      ...state,
      selectedWorkOrder: payload.workOrder,
      selectedIssue: payload.issue,
      showWorkOrderModal: true
    }),
    closeWorkOrderModal: state => ({
      ...state,
      selectedWorkOrder: undefined,
      selectedIssue: undefined,
      showWorkOrderModal: false
    }),
    openDeleteWorkOrderModal: (state, { payload }) => ({
      ...state,
      selectedWorkOrder: payload,
      showDeleteWorkOrderModal: true
    }),
    closeDeleteWorkOrderModal: state => ({
      ...state,
      selectedWorkOrder: undefined,
      showDeleteWorkOrderModal: false
    }),
    openTaskModal: (state, { payload }) => ({
      ...state,
      selectedTask: payload,
      showTaskModal: true
    }),
    closeTaskModal: state => ({
      ...state,
      selectedTask: undefined,
      showTaskModal: false
    }),
    openDeleteTaskModal: (state, { payload }) => ({
      ...state,
      selectedTask: payload,
      showDeleteTaskModal: true
    }),
    closeDeleteTaskModal: state => ({
      ...state,
      selectedTask: undefined,
      showDeleteTaskModal: false
    }),
    openReportModal: (state, { payload }) => ({
      ...state,
      selectedReport: payload,
      showReportModal: true
    }),
    closeReportModal: state => ({
      ...state,
      selectedReport: undefined,
      showReportModal: false
    }),
    openDeleteReportModal: (state, { payload }) => ({
      ...state,
      selectedReport: payload,
      showDeleteReportModal: true
    }),
    closeDeleteReportModal: state => ({
      ...state,
      selectedReport: undefined,
      showDeleteReportModal: false
    }),
    openIssueUploadModal: (state, { payload }) => ({
      ...state,
      selectedIssue: payload.issue,
      selectedFile: payload.file,
      showIssueUploadModal: true
    }),
    closeIssueUploadModal: state => ({
      ...state,
      selectedIssue: undefined,
      selectedFile: undefined,
      showIssueUploadModal: false,
      issueUpload: null
    }),
    issueUploadSuccess: (state, { payload }) => ({
      ...state,
      issueUploadLoading: false,
      issueUploadError: null,
      issueUpload: payload
    }),
    issueUploadLoading: (state, { payload }) => ({
      ...state,
      issueUploadLoading: true,
      issueUploadError: null,
      issueUpload: null
    }),
    issueUploadError: (state, { payload }) => ({
      ...state,
      issueUploadLoading: false,
      issueUploadError: payload,
      issueUpload: null
    }),
    openErrorAlert: (state, { payload }) => ({
      ...state,
      alert: {
        severity: 'error',
        message: payload
      }
    }),
    openSuccessAlert: (state, { payload }) => ({
      ...state,
      alert: {
        severity: 'success',
        message: payload
      }
    }),
    closeAlert: state => ({
      ...state,
      alert: undefined
    })
  }
});

export const {
  selectIssue,
  loadIssues,
  openIssueModal,
  closeIssueModal,
  openDeleteIssueModal,
  closeDeleteIssueModal,
  selectWorkOrder,
  openWorkOrderModal,
  closeWorkOrderModal,
  openDeleteWorkOrderModal,
  closeDeleteWorkOrderModal,
  openTaskModal,
  closeTaskModal,
  openDeleteTaskModal,
  closeDeleteTaskModal,
  openReportModal,
  closeReportModal,
  openDeleteReportModal,
  closeDeleteReportModal,
  openIssueUploadModal,
  closeIssueUploadModal,
  issueUploadSuccess,
  issueUploadLoading,
  issueUploadError,
  openErrorAlert,
  openSuccessAlert,
  closeAlert
} = issuesSlice.actions;

export default issuesSlice.reducer;

export const uploadImage = (files = []) => async dispatch => {
  try {
    dispatch(issueUploadLoading());
    const upload = await Promise.all(files.map(file => uploadAWS(file)));
    dispatch(issueUploadSuccess(upload));
  } catch (error) {
    dispatch(issueUploadError(error.toString()));
  }
};
