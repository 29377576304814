import React, { useState, useRef, useEffect } from 'react';
import ReactMapGL, { FullscreenControl } from 'react-map-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import Geocoder from 'react-map-gl-geocoder';
import { Editor, EditorModes, DrawPolygonMode } from 'react-map-gl-draw';
import { useFormikContext } from 'formik';
import { getFeatureStyle, getEditHandleStyle } from './styles';
import { Tooltip } from '@material-ui/core';
import { useInventoryState } from 'modules/inventory';
import { useGetSettings } from 'modules/map';
import Loader from 'components/Loader';

const drawStyle = {
  position: 'absolute',
  top: 60,
  left: 10
};

const PolygonMap = () => {
  const { setFieldValue, values } = useFormikContext();
  const { selectedArea } = useInventoryState();
  const { data, loading } = useGetSettings();
  const mapRef = useRef();
  const editorRef = useRef();
  const initialViewport = {
    width: 400,
    height: 400,
    longitude:
      values.name ? values.location.coordinates[0][0][0] : data.settings[0].location.coordinates[0][0][0],
      //((((values.location || {}).coordinates || [])[0] || [])[0] || [])[0] ||
    latitude:
      //((((values.location || {}).coordinates || [])[0] || [])[0] || [])[1] ||
      values.name ? values.location.coordinates[0][0][1] : data.settings[0].location.coordinates[0][0][1],
    zoom: 15,
    bearing: 0,
    pitch: 0
  };
  const [viewport, setViewport] = useState(initialViewport);
  const [mode, setMode] = useState(null);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null);
  const [polygons, setPolygons] = useState(values?.location?.coordinates?.[0]);

  const handleViewportChange = (viewport) => {
    setViewport((prevState) => ({ ...prevState, ...viewport }));
  };

  const handleGeocoderViewportChange = (viewport) => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };

    return handleViewportChange({
      ...viewport,
      ...geocoderDefaultOverrides
    });
  };

  const updateViewport = (viewport) => {
    setViewport(viewport);
  };

  const onSelect = (options) => {
    setSelectedFeatureIndex(options && options.selectedFeatureIndex);
  };

  const onDelete = () => {
    const selectedIndex = selectedFeatureIndex;
    if (selectedIndex !== null && selectedIndex >= 0) {
      editorRef.current.deleteFeatures(selectedIndex);
    }
  };

  const onUpdate = (options) => {
    if (options.editType === 'addFeature') {
      setMode(new DrawPolygonMode());
    }
  };

  const loadFeatures = () => {
    let feature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [values?.location?.coordinates[0]]
      },
      properties: {
        renderType: 'Polygon'
      }
    };
    selectedArea && editorRef.current.addFeatures(feature);
    values?.location && setPolygons([values.location.coordinates[0]]);
  };

  useEffect(() => {
    let data = (editorRef.current && editorRef.current.getFeatures()) || [];
    let newPolygons = data.map((polygon) => polygon.geometry.coordinates[0]);
    newPolygons.length > 0 && setPolygons(newPolygons);
  }, [editorRef.current && editorRef.current.getFeatures()]);

  useEffect(() => {
    let geometry = {
      type: 'Polygon',
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:EPSG::4326'
        }
      },
      coordinates: polygons
    };
    if (polygons) {
      setFieldValue('surface_m2', 0);
      setFieldValue('location', geometry);
    }
  }, [polygons, setFieldValue]);

  const renderDrawTools = () => {
    return (
      <div style={drawStyle}>
        <div className="mapboxgl-ctrl-group mapboxgl-ctrl">
          <Tooltip title="Dibujar área" placement="right">
            <button
              type="button"
              className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
              onClick={() => setMode(new DrawPolygonMode())}
            />
          </Tooltip>
          <Tooltip title="Eliminar área" placement="right">
            <button
              type="button"
              className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
              onClick={onDelete}
            />
          </Tooltip>
        </div>
      </div>
    );
  };
  if (loading) return <Loader />;
  return (
    <ReactMapGL
      {...viewport}
      ref={mapRef}
      width="100%"
      height="100%"
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
      onViewportChange={updateViewport}
      click
      onLoad={loadFeatures}>
      <Editor
        ref={editorRef}
        style={{ width: '100%', height: '100%' }}
        clickRadius={12}
        mode={mode}
        onSelect={onSelect}
        onUpdate={onUpdate}
        editHandleShape={'circle'}
        featureStyle={getFeatureStyle}
        editHandleStyle={getEditHandleStyle}
      />
      {renderDrawTools()}
      <div style={{ position: 'absolute', right: 10, top: 10 }}>
        <FullscreenControl container={document.querySelector('root')} />
      </div>
      <Geocoder
        mapRef={mapRef}
        onViewportChange={handleGeocoderViewportChange}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
        position="top-left"
        placeholder={'Buscar dirección'}
      />
    </ReactMapGL>
  );
};

export default PolygonMap;