import gql from 'graphql-tag';

export const TREE_FRAGMENT = gql`
  fragment treeFields on arbol_inventariable {
    alcorque_deter_pavimento
    alcorque_estado
    alcorque_tipo
    ca_estado
    ca_tipo
    clasificacion_riesgo
    dd_altura_primera_rama_m
    dd_altura_total_m
    dd_diam_copa_cm
    dd_perimetro_tronco_cm
    de_ancho_acera_m
    de_dist_ejes_alineacion
    de_distancia_fachada_m
    de_entorno_calzada
    de_marco_plantacion
    de_protector
    de_protector_estado
    de_tipo_posicion
    de_tipo_riego
    de_tipo_superficie
    de_tipo_vial
    e_base_huecos_cm
    e_copa_chupones_cm
    e_copa_ramas_secas_percent
    e_tronco_inclinacion_grad
    fecha_ult_poda
    nivel_riesgo
    od_catalogacion
    od_edad_relativa
    od_estructura
    od_origen_specie
    od_procedencia
    od_vigor_vitalidad
    tutor_estado
    tutor_tipo
    valoracion
  }
`;
