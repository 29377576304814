import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { WORKER_FRAGMENT } from '../fragments';

export const GET_USER_BY_ID = gql`
  query getUserById($userId: Int!) {
    organization_user(
      where: { id: { _eq: $userId } }
    ) {
      ...workerFields
    }
  }
  ${WORKER_FRAGMENT}
`;


export const useGetUserById = (userId) => {
    const { data, error, loading } = useQuery(GET_USER_BY_ID, {
        variables: {
            userId
        }
    });

    return {
        data,
        error,
        loading
    };
};
