import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector';
import { createStructuredSelector } from 'reselect';

const showIssueModalSelector = state => state.issues.showIssueModal;
const showDeleteIssueModalSelector = state => state.issues.showDeleteIssueModal;
const selectedIssueSelector = state => state.issues.selectedIssue;
const selectedWorkOrderSelector = state => state.issues.selectedWorkOrder;
const showWorkOrderModalSelector = state => state.issues.showWorkOrderModal;
const showDeleteWorkOrderModalSelector = state =>
  state.issues.showDeleteWorkOrderModal;
const selectedTaskSelector = state => state.issues.selectedTask;
const showTaskModalSelector = state => state.issues.showTaskModal;
const showDeleteTaskModalSelector = state => state.issues.showDeleteTaskModal;
const selectedReportSelector = state => state.issues.selectedReport;
const showReportModalSelector = state => state.issues.showReportModal;
const showDeleteReportModalSelector = state =>
  state.issues.showDeleteReportModal;
const showIssueUploadModalSelector = state => state.issues.showIssueUploadModal;
const issueUploadLoadingSelector = state => state.issues.issueUploadLoading;
const issueUploadErrorSelector = state => state.issues.issueUploadError;
const issueUploadSelector = state => state.issues.issueUpload;
const selectedFileSelector = state => state.issues.selectedFile;
const alertSelector = state => state.issues.alert;

const selectors = createStructuredSelector({
  showIssueModal: showIssueModalSelector,
  showDeleteIssueModal: showDeleteIssueModalSelector,
  selectedIssue: selectedIssueSelector,
  selectedWorkOrder: selectedWorkOrderSelector,
  showWorkOrderModal: showWorkOrderModalSelector,
  showDeleteWorkOrderModal: showDeleteWorkOrderModalSelector,
  showTaskModal: showTaskModalSelector,
  selectedTask: selectedTaskSelector,
  showDeleteTaskModal: showDeleteTaskModalSelector,
  selectedReport: selectedReportSelector,
  showReportModal: showReportModalSelector,
  showDeleteReportModal: showDeleteReportModalSelector,
  showIssueUploadModal: showIssueUploadModalSelector,
  issueUploadLoading: issueUploadLoadingSelector,
  issueUploadError: issueUploadErrorSelector,
  issueUpload: issueUploadSelector,
  selectedFile: selectedFileSelector,
  alert: alertSelector
});

export function useIssuesState() {
  return useShallowEqualSelector(selectors);
}
