import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($userId: Int!, $password: String!) {
    update_organization_user(
      where: { id: { _eq: $userId } }
      _set: { password: $password }
    ) {
      affected_rows
    }
  }
`;

export const useUpdatePassword = () => {
  let [mutate] = useMutation(UPDATE_PASSWORD);
  return ({ userId, password }) => {
    return mutate({
      variables: {
        userId,
        password
      }
    });
  };
};
