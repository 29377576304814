import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button
} from '@material-ui/core';
import {
  useInventoryState,
  useInventoryDispatch,
  useInventoryMutation
} from 'modules/inventory';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    height: 200
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ConfirmDelete = () => {
  const classes = useStyles();
  const {
    showDeleteInventoryItemModal,
    selectedInventoryItem
  } = useInventoryState();
  const { deleteInventoryItem } = useInventoryMutation();
  const { closeDeleteInventoryItemModal } = useInventoryDispatch();
  if (!showDeleteInventoryItemModal) {
    return null;
  }

  return (
    <Modal
      onClose={closeDeleteInventoryItemModal}
      open={showDeleteInventoryItemModal}>
      <Card className={classes.root}>
        <CardHeader title="¿Eliminar elemento?" />
        <Divider />
        <CardContent>
          <Typography variant="body1">
            Si elimina el elemento seleccionado, no podrá recuperarlo más tarde.
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button onClick={closeDeleteInventoryItemModal}>Cancelar</Button>
          <Button
            color="secondary"
            onClick={async () => {
              await deleteInventoryItem({ id: selectedInventoryItem.id });
              closeDeleteInventoryItemModal();
            }}
            variant="contained">
            Sí, eliminar
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ConfirmDelete;
