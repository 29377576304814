import { colors } from '@material-ui/core';

export default (theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 900,
    minHeight: 560,
    overflowY: 'hidden',
    maxHeight: '100%',
    maxWidth: '100%'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  header: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(2),
    fontSize: '24px'
  },
  actions: {
    padding: theme.spacing(2),
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  tabs: {
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(3)
  }
});
