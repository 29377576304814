import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Typography
} from '@material-ui/core';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import gradients from 'utils/gradients';
import LoginForm from './LoginForm';
import img from 'assets/images/login.jpg';
import { useLogin } from 'modules/authentication/hooks/useLogin';
import loginIcon from 'assets/images/icono-login.png'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2)
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4)
  },
  icon: {
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  loginForm: {
    marginTop: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  person: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

function Login() {
  const classes = useStyles();
  const [credentials, setCredentials] = React.useState({});
  const response = useLogin(credentials.email, credentials.password);
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <img src={loginIcon} alt={loginIcon} className={classes.icon} />
          <Typography gutterBottom variant="h3">
            Wegreenspaces
          </Typography>
          <Typography variant="subtitle2">
            Iniciar sesión en la plataforma
          </Typography>
          <LoginForm
            login={setCredentials}
            loading={response.loading}
            error={response.error}
          />
        </CardContent>
        <CardMedia className={classes.media} image={img} title="login-cover">
          {/*<Typography color="inherit" variant="subtitle1">
            Plataforma web para la gestión de zonas verdes.
          </Typography>*/}
        </CardMedia>
      </Card>
    </div>
  );
}

export default Login;
