import React from 'react';
import JWTDecoder from 'jwt-decode';
import { useUpdateAuth } from './useAuth';
import { login } from '../api';

const responser = async (requester, updater) => {
  try {
    updater({ loading: true, data: null, error: null });
    const response = await requester();
    if (response.code) {
      updater({ error: response, data: null, loading: false });
    } else {
      updater({ data: response, error: null, loading: false });
    }
  } catch (error) {
    updater({
      data: null,
      error: {
        code: 500,
        message: error.message || 'Unknown error in API Login'
      },
      loading: false
    });
  }
};
export const apiReducer = (state, action) => ({ ...state, ...action });
export const apiInitialValue = { data: null, loading: false, error: null };
export const useAPIReducer = (
  reducer = apiReducer,
  initialValue = apiInitialValue
) => React.useReducer(reducer, initialValue);

export const useLoginRequest = (dispatch) => {
  const updateAuth = useUpdateAuth();
  const setState = React.useCallback(
    (state) => {
      if (state?.data && state?.data?.access_token) {
        const info = JWTDecoder(state.data.access_token);
        updateAuth({
          info,
          token: state.data.access_token,
          refresh: state.data.refresh_token
        });
      }
      dispatch(state);
    },
    [updateAuth]
  );
  return React.useCallback(
    (email, password) => {
      email && password && responser(() => login(email, password), setState);
    },
    [setState]
  );
};

export const useLogin = (email, password) => {
  const [state, setState] = useAPIReducer();
  const login = useLoginRequest(setState);
  React.useEffect(() => {
    login(email, password);
  }, [email, password, login]);
  return state;
};

export const useApiLoginRequest = (dispatch) => {
  const updateAuth = useUpdateAuth();
  const setState = React.useCallback(
    (state) => {
      if (state.data && state.data.access_token) {
        const user = JWTDecoder(state.data.access_token);
        updateAuth({ info: user, token: state.data.access_token });
      }
      dispatch(state);
    },
    [dispatch, updateAuth]
  );
  return React.useCallback(
    (email, password) => {
      email && password && responser(() => login(email, password), setState);
    },
    [setState]
  );
};
