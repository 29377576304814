import gql from 'graphql-tag';

export const ORGANIZATION_FRAGMENT = gql`
  fragment organizationFields on organization {
    description
    id
    name
    code
    email
    organization_users {
      id
    }
    file {
      id
      name
      url
    }
  }
`;
