import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  GET_ISSUES,
  GET_ISSUES_BY_INVENTORY_ITEM,
  GET_ISSUES_BY_STATE
} from '../queries';
import { useIssuesDispatch } from 'modules/issues/hooks';

export const DELETE_ISSUE = gql`
  mutation deleteIssue($id: Int!, $inventoryItemId: Int) {
    delete_work_order(where: { issue_id: { _eq: $id } }) {
      affected_rows
    }
    delete_issue(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const useDeleteIssue = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(DELETE_ISSUE, {
    onCompleted: () => openSuccessAlert('Incidencia eliminada correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });
  return ({ id, inventoryItemId }) => {
    return mutate({
      variables: { id },
      refetchQueries: [
        { query: GET_ISSUES },
        { query: GET_ISSUES_BY_STATE },
        {
          query: GET_ISSUES_BY_INVENTORY_ITEM,
          variables: { inventoryItemId }
        }
      ]
    });
  };
};
