import gql from 'graphql-tag';

export const WORKER_FRAGMENT = gql`
  fragment workerFields on organization_user {
    external_id
    id
    name
    surname
    email
    password
    phone
    organization_id
    organization {
      description
      id
      name
    }
    file {
      id
      name
      url
    }
    profile_id
    profile {
      id
      name
    }
    tasks: work_order_tasks(where: { state: { _eq: "toDo" } }) {
      id
      title
      due_to_date
      workOrder: work_order {
        id
        title
        issue {
          id
          title
          inventoryItem: elemento_inventario {
            id
            name
          }
        }
      }
    }
  }
`;
