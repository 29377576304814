import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import Summary from './Summary';
import Issues from './Issues';
import Datastreams from './Datastreams';
import SensorModal from 'views/SensorModal';
import Loader from 'components/Loader';
import View from 'components/View';
import { useGetSensorDetails } from 'modules/sensors/graphql/queries/getSensorDetails';
import { useSensorsDispatch, useSensorsState } from 'modules/sensors';
import Actions from './Actions';
import IssueModal from 'views/IssueModal';
import ConfirmDelete from './ConfirmDelete';
import { useRoles } from 'modules/authentication';
import { checkAuth } from 'utils/roles';
import Maintenances from './Maintenances';
import MaintenanceModal from 'views/MaintenanceModal';
import Alert from 'components/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    height: '100%'
  },
  tabs: {
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const SensorDetails = () => {
  const classes = useStyles();
  const {
    issues__solver,
    issues__administrator,
    maintenances__solver,
    maintenances__administrator
  } = useRoles();
  const [currentTab, setCurrentTab] = useState('summary');
  const { data, loading, error } = useGetSensorDetails();
  const { alert } = useSensorsState();
  const { selectSensor, closeAlert } = useSensorsDispatch();

  useEffect(() => {
    data && selectSensor(data.sensor);
  }, [data]);

  const tabs = [
    { value: 'summary', label: 'Información' },
    { value: 'data', label: 'Datos' },
    {
      value: 'issues',
      label: 'Incidencias',
      auth: [issues__solver, issues__administrator]
    },
    {
      value: 'maintenances',
      label: 'Mantenimientos',
      auth: [maintenances__solver, maintenances__administrator]
    }
  ];

  const handleTabsChange = (event, value) => {
    event.preventDefault();
    setCurrentTab(value);
  };

  return (
    <View
      title="Sensor"
      loading={loading}
      error={error}
      subtitle={data ? `${data.sensor.name}` : 'Cargando...'}
      actions={<Actions sensor={!loading && data && data.sensor} />}>
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={currentTab}
        variant="scrollable"
        indicatorColor="primary">
        {tabs
          .filter(tab => checkAuth(tab?.auth))
          .map(tab => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
      </Tabs>
      <Divider className={classes.divider} />

      <div className={classes.content}>
        {loading && <Loader />}
        {data && currentTab === 'summary' && <Summary sensor={data.sensor} />}
        {data && currentTab === 'data' && (
          <Datastreams datastreams={data.sensor.sensor.datastreams} />
        )}
        {data && currentTab === 'issues' && <Issues {...data.sensor} />}
        {currentTab === 'maintenances' && <Maintenances {...data?.sensor} />}
      </div>
      <Alert
        show={alert}
        message={alert?.message}
        severity={alert?.severity}
        fireOnClose={() => closeAlert()}
      />
      <SensorModal />
      <IssueModal />
      <MaintenanceModal />
      <ConfirmDelete />
    </View>
  );
};

export default SensorDetails;
