import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Input, Paper, Tooltip } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useChatMutation } from 'modules/chat';
import { useInfo } from 'modules/authentication';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2)
  },
  paper: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5, 2)
  },
  input: {
    width: '100%'
  },
  divider: {
    width: 1,
    height: 24
  },
  fileInput: {
    display: 'none'
  }
}));

const MessageBox = ({ className }) => {
  const classes = useStyles();
  const { addMessage } = useChatMutation();
  const fileInputRef = useRef(null);
  const [message, setMessage] = useState('');
  const { userId } = useInfo();


  const handleChange = event => {
    event.persist();
    setMessage(event.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      message && addMessage({ content: message, senderId: userId})
      setMessage('');
    }
  };

  const handleAttach = () => {
    fileInputRef.current.click();
  };
  

  return (
    <div className={clsx(classes.root, className)}>
      <Paper className={classes.paper} elevation={1}>
        <Input
          className={classes.input}
          disableUnderline
          onChange={handleChange}
          placeholder="Nuevo mensaje"
          value={message}
          onKeyDown={handleKeyDown}
        />
      </Paper>
      <Tooltip title="Enviar mensaje">
        <IconButton
          color={message.length > 0 ? 'primary' : 'default'}
          onClick={() => {
            message && addMessage({ content: message, senderId: userId})
            setMessage('');
          }}>
          <SendIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Enviar archivo">
        <IconButton edge="end" onClick={handleAttach}>
          <AttachFileIcon />
        </IconButton>
      </Tooltip>
      <input className={classes.fileInput} ref={fileInputRef} type="file" />
    </div>
  );
};

export default MessageBox;
