import React from 'react';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useGetSurfaceTypes } from 'modules/inventory';

const Surface = () => {
  const { values, handleChange } = useFormikContext();
  const { area, perimeter, slope, surface_type_id } = values?.surfaces[0] || {};
  const { data } = useGetSurfaceTypes();
  return (
    <Grid container spacing={3} alignItems="center" justify="center">
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Tipo"
          name="surfaces[0].surface_type_id"
          onChange={handleChange}
          select
          SelectProps={{ native: true }}
          value={surface_type_id}
          variant="outlined"
          InputLabelProps={{ shrink: Boolean(surface_type_id) }}>
          <option value={''}></option>
          {data?.surfaceTypes?.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Superficie"
          name="surfaces[0].area"
          onChange={handleChange}
          value={area}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">m2</InputAdornment>
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Perímetro"
          name="surfaces[0].perimeter"
          onChange={handleChange}
          value={perimeter}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Pendiente"
          name="surfaces[0].slope"
          onChange={handleChange}
          value={slope}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Surface;
