import React from 'react';
import Carousel from './Carousel';
import { useInventoryDispatch } from 'modules/inventory';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    width: '100%',
    height: '290px',
    objectFit: 'scale-down',
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.7,
      cursor: 'pointer'
    }
  }
}));

const Image = ({ file }) => {
  const classes = useStyles();
  const { openInventoryUploadModal } = useInventoryDispatch();
  return (
    <img
      className={classes.image}
      alt={`image-${file.id}`}
      src={file.url}
      onMouseDown={() =>
        openInventoryUploadModal({
          inventoryItem: { id: file.inventory_id },
          file
        })
      }
    />
  );
};

const Slider = ({ files = [] }) => {
  return (
    <Carousel animation="fade" indicators={true} autoPlay={false}>
      {files?.map(file => {
        return <Image file={file} />;
      })}
    </Carousel>
  );
};

export default Slider;
