import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Divider
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { useIssuesDispatch } from 'modules/issues';
import { useRoles } from 'modules/authentication';

const Actions = ({ workOrder }) => {
  const {
    openWorkOrderModal,
    openDeleteWorkOrderModal,
    openTaskModal
  } = useIssuesDispatch();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const { issues__update, issues__delete, issues__insert } = useRoles();

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };
  if (!issues__update && !issues__delete && !issues__insert) {
    return null;
  }
  return (
    <>
      <Tooltip title="Acciones">
        <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        {issues__insert && (
          <MenuItem
            onClick={() => {
              openTaskModal();
              setOpenMenu(false);
            }}>
            <ListItemText primary="Tarea" />
          </MenuItem>
        )}
        {(issues__update || issues__delete) && issues__insert && <Divider />}
        {issues__update && (
          <MenuItem
            onClick={() => {
              openWorkOrderModal({ workOrder });
              setOpenMenu(false);
            }}>
            <ListItemText primary="Editar" />
          </MenuItem>
        )}
        {issues__delete && (
          <MenuItem
            onClick={() => {
              openDeleteWorkOrderModal(workOrder);
              setOpenMenu(false);
            }}>
            <ListItemText primary="Eliminar" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

Actions.propTypes = {
  className: PropTypes.string
};

export default Actions;
