import React from 'react';
import Table from 'components/Table';
import Label from 'components/Label';
import { colors, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useGetWorkOrdersByIssue } from 'modules/issues/graphql';
import Loader from 'components/Loader';
import moment from 'moment';
import { parseState, parsePriority, getPercentageDone } from 'utils/issues';
import Actions from './Actions';
import ConfirmDelete from './ConfirmDelete';
import Progress from 'components/Progress';

const columns = [
  {
    Header: 'Título',
    accessor: data => data,
    Cell: ({ cell: { value } }) => (
      <Link
        color="inherit"
        component={RouterLink}
        to={`/issues/${value.issue_id}/workorders/${value.id}`}
        variant="h6">
        {value.title}
      </Link>
    )
  },

  {
    Header: 'ID',
    accessor: row => `${row.id}`
  },
  {
    Header: 'Estado',
    accessor: data => data,
    Cell: ({ cell: { value } }) => (
      <Progress
        value={getPercentageDone(
          value.tasks.length,
          value.done.aggregate.count
        )}
      />
    )
  },
  {
    Header: 'Prioridad',
    accessor: 'priority',
    Cell: ({ cell: { value } }) =>
      value ? parsePriority(value) : 'Desconocido'
  },
  {
    Header: 'Organización',
    accessor: row => `${row.organization.name}`
  },

  {
    Header: 'Vencimiento',
    accessor: 'due_to_date',
    Cell: ({ cell: { value } }) =>
      value ? moment(value).format('DD/MM/YYYY') : ''
  },

  {
    Header: '',
    id: 'actions',
    accessor: data => data,
    Cell: ({ cell: { value } }) => <Actions workOrder={value} />
  }
];

const WorkOrders = ({ issueId }) => {
  const { data, loading } = useGetWorkOrdersByIssue(issueId);
  return (
    <>
      {loading && <Loader />}
      {data && <Table columns={columns} data={data.workOrders} />}
      <ConfirmDelete />
    </>
  );
};

export default WorkOrders;
