import React, { useState, useEffect } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button,
  Tabs,
  Tab
} from '@material-ui/core';
import { Formik } from 'formik';
import Location from './Location';
import Summary from './Summary';
import Furniture from './Furniture';
import Playground from './Playground';
import Vegetation from './Vegetation';
import Surface from './Surface';
import { SpecificData, State } from './Tree';
import {
  useInventoryState,
  useInventoryDispatch,
  useInventoryMutation,
  useAddInventoryItem
} from 'modules/inventory';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import Bush from './Bush';
import Carpet from './Carpet';
import useRouter from 'hooks/useRouter';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  inventoryItemType: Yup.string().required('Campo requerido')
});

const surfaceValues = {
  area: null,
  perimeter: null,
  slope: null,
  surface_type_id: null
};

const bushValues = {
  shape: ''
};
const carpetValues = {
  carpetType: ''
};
const treeValues = {
  dd_altura_primera_rama_m: null,
  dd_altura_total_m: null,
  dd_diam_copa_cm: null,
  dd_perimetro_tronco_cm: null
};
const vegetationValues = {
  vegetationType: '',
  commonName: '',
  species: '',
  plantingDate: null,
  lastUpdateDate: null,
  arbol_inventariables: [{ ...treeValues }],
  arbusto_inventariables: [{ ...bushValues }],
  tapiz_inventariables: [{ ...carpetValues }]
};

const furnitureValues = {
  furnitureTypeId: undefined,
  integration: false,
  electric: false,
  hydraulic: false,
  manufacturer: '',
  model: '',
  installationDate: null,
  lastRepairDate: null
};

const playgroundValues = {
  manufacturer: '',
  model: '',
  age: null,
  security_area: null,
  fall_height: null,
  dimensions: null,
  weight: null
};

const initialValues = {
  inventoryItemType: '',
  name: '',
  district: undefined,
  neighborhood: undefined,
  observations: '',
  address: '',
  location: {
    coordinates: [0, 0]
  },
  mobiliario_urbanos: [
    {
      ...furnitureValues
    }
  ],
  vegetacion_inventariables: [
    {
      ...vegetationValues
    }
  ],
  surfaces: [
    {
      ...surfaceValues
    }
  ],
  playgrounds: [
    {
      ...playgroundValues
    }
  ]
};

const tabs = [
  { value: 'summary', label: 'General' },
  { value: 'furniture', label: 'Mobiliario urbano' },
  { value: 'vegetation', label: 'Vegetación' },
  { value: 'surface', label: 'Superficie' },
  { value: 'playground', label: 'Parque infantil' },
  {
    value: 'bush',
    label: 'Arbusto',
    type: 'Arbusto'
  },
  {
    value: 'carpet',
    label: 'Superficie vegetal',
    type: 'Superficie vegetal'
  },
  {
    value: 'treeData',
    label: 'Árbol / Datos específicos',
    type: 'Árbol'
  },
  {
    value: 'treeState',
    label: 'Árbol / Estado',
    type: 'Árbol'
  },
  { value: 'location', label: 'Localización' },
  { value: 'images', label: 'Imágenes' }
];

const initialTabs = ['summary', 'location'];

const InventoryModal = () => {
  const { showInventoryItemModal, selectedInventoryItem } = useInventoryState();
  const classes = useClasses(styles);
  const { history } = useRouter();
  const [currentTab, setCurrentTab] = useState('summary');
  const { updateInventoryItem } = useInventoryMutation();
  const { addInventoryItem, data } = useAddInventoryItem();
  const { closeInventoryItemModal } = useInventoryDispatch();
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleClose = () => {
    setCurrentTab('summary');
    closeInventoryItemModal();
  };

  useEffect(() => {
    if (data) {
      history.push(
        `inventory/${data.insert_elemento_inventario.returning[0].id}`
      );
    }
  }, [data, history]);

  return (
    <Modal onClose={handleClose} open={showInventoryItemModal}>
      <Card className={classes.root}>
        <Formik
          initialValues={
            selectedInventoryItem ? selectedInventoryItem : initialValues
          }
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            selectedInventoryItem
              ? updateInventoryItem({ ...values })
              : addInventoryItem([{ ...values }]);
            setSubmitting(false);
            handleClose();
          }}>
          {({ values, handleSubmit, isValid }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardHeader
                title={
                  <Typography component="h1" variant="h3">
                    {selectedInventoryItem
                      ? 'Editar elemento'
                      : 'Nuevo elemento'}
                  </Typography>
                }
                className={classes.header}
              />

              <Tabs
                className={classes.tabs}
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={currentTab}
                variant="scrollable"
                indicatorColor="primary">
                {tabs
                  .filter(
                    (tab) =>
                      initialTabs.includes(tab.value) ||
                      values.inventoryItemType === tab.value ||
                      (values.vegetacion_inventariables.length > 0 &&
                        values.vegetacion_inventariables[0].vegetationType ===
                          tab.type)
                  )
                  .map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
              </Tabs>
              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                {currentTab === 'summary' && <Summary />}
                {currentTab === 'location' && <Location />}
                {currentTab === 'furniture' && <Furniture />}
                {currentTab === 'playground' && <Playground />}
                {currentTab === 'vegetation' && <Vegetation />}
                {currentTab === 'surface' && <Surface />}
                {currentTab === 'treeData' && <SpecificData />}
                {currentTab === 'treeState' && <State />}
                {currentTab === 'bush' && <Bush />}
                {currentTab === 'carpet' && <Carpet />}
              </CardContent>
              <Divider className={classes.divider} />
              <CardActions className={classes.actions}>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  disabled={!isValid}>
                  {selectedInventoryItem ? 'Actualizar' : 'Guardar'}
                </Button>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

export default InventoryModal;
