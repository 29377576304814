import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Avatar
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddIcon from '@material-ui/icons/Add';
import { bytesToSize } from 'utils/files';
import IssueUploadModal from 'views/IssueUploadModal';
import { useIssuesDispatch } from 'modules/issues';
import { truncateString } from 'utils/strings';
import Actions from './Actions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0,
    width: '100%',
    height: '100%'
  },
  data: { fontWeight: 600 },
  avatar: {
    cursor: 'pointer'
  },
  text: {
    textOverflow: 'ellipsis'
  },
  item: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  }
}));

const Images = ({ issue }) => {
  const classes = useStyles();
  const { files } = issue;
  const { openIssueUploadModal } = useIssuesDispatch();
  return (
    <Card className={classes.root}>
      <CardHeader
        title="Imágenes"
        action={
          <Tooltip title="Añadir imagen">
            <IconButton
              size="small"
              onClick={() => openIssueUploadModal({ issue })}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Divider />
      {files?.length > 0 && (
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <List className={classes.list} disablePadding>
            {files.map((file, index) => {
              const { name, url, size } = file;
              return (
                <ListItem
                  divider={index < files.length}
                  key={index}
                  className={classes.item}>
                  <ListItemAvatar>
                    <Avatar
                      alt="imagen"
                      src={url}
                      className={classes.avatar}
                      onClick={() =>
                        openIssueUploadModal({
                          issue: { id: file.issue_id },
                          file
                        })
                      }>
                      <FileCopyIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={truncateString(name, 20)}
                    primaryTypographyProps={{
                      variant: 'h5'
                    }}
                    className={classes.text}
                    secondary={bytesToSize(size || 0)}
                  />
                  <Actions file={file} />
                </ListItem>
              );
            })}
          </List>
        </PerfectScrollbar>
      )}
      <IssueUploadModal />
    </Card>
  );
};

export default Images;
