import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import { useGetWorkersByOrganization } from 'modules/users';

const states = [
  { id: 'toDo', name: 'Pendiente' },
  { id: 'done', name: 'Completado' }
];

const Summary = ({ organizationId }) => {
  const { values, handleChange, setFieldValue } = useFormikContext();
  const { data } = useGetWorkersByOrganization(organizationId);
  const { state, due_to_date, in_charge_user_id, title, description } = values;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Título"
            name="title"
            onChange={handleChange}
            value={title}
            variant="outlined">
            {title}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Estado"
            name="state"
            onChange={handleChange}
            select
            SelectProps={{ native: true }}
            value={state}
            variant="outlined"
            InputLabelProps={{ shrink: Boolean(state) }}>
            <option value={''}></option>
            {states.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            label="Vencimiento"
            fullWidth
            inputVariant="outlined"
            value={due_to_date}
            format="dd/MM/yyyy"
            onChange={(newDate, value) =>
              setFieldValue('due_to_date', newDate, false)
            }
            cancelLabel="cancelar"
            invalidDateMessage="Fecha incorrecta"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label={
              organizationId
                ? 'Responsable'
                : 'Asigne una organización al mantenimiento para seleccionar un responsable'
            }
            name="in_charge_user_id"
            onChange={handleChange}
            disabled={!organizationId}
            select
            SelectProps={{ native: true }}
            value={in_charge_user_id}
            variant="outlined"
            InputLabelProps={{ shrink: Boolean(in_charge_user_id) }}>
            <option value={''} />
            {data &&
              data.workers.map(({ id, name, surname }) => (
                <option key={id} value={id}>
                  {`${name} ${surname}`}
                </option>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Descripción"
            name="description"
            value={description}
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={2}>
            {description}
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
};

export default Summary;
