import React from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import Chart from './Chart';
import { parseUnit } from 'utils/sensors';
const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: 550,
    minWidth: 500
  },
  chart: {
    height: '100%'
  }
}));

const DataChart = ({ datastream }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader title="Evolución" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
              data={datastream.observation_values
                .map(value => value.result)
                .reverse()}
              labels={datastream.observation_values
                .map(value => moment(value.time).format('hh:mm'))
                .reverse()}
              unit={parseUnit(datastream.unit)}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

export default DataChart;
