import React from 'react';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import { useFormikContext } from 'formik';

const Summary = () => {
  const { values, handleChange } = useFormikContext();
  const { hours, notes } = values;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Tiempo empleado"
            name="hours"
            onChange={handleChange}
            value={hours}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">horas</InputAdornment>
              )
            }}
            variant="outlined">
            {hours}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Observaciones"
            name="notes"
            value={notes}
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={4}>
            {notes}
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
};

export default Summary;
