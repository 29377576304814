import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar } from '@material-ui/core';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import DeckIcon from '@material-ui/icons/Deck';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 120,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: 48,
    width: 48
  }
}));

const Type = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <div>
        <Typography component="h3" gutterBottom variant="overline">
          Tipo
        </Typography>
        <div className={classes.details}>
          <Typography variant="h3">Parque infantil</Typography>
        </div>
      </div>
      <Avatar className={classes.avatar}>
        <DeckIcon />
      </Avatar>
    </Card>
  );
};

export default Type;
