import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link, colors } from '@material-ui/core';
import { useInfo } from 'modules/authentication';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  authUser: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& $body': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  },
  inner: {
    padding: theme.spacing(1),
    display: 'flex',
    maxWidth: 500
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  body: {
    backgroundColor: colors.grey[100],
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2)
  },
  content: {
    marginTop: theme.spacing(1)
  },
  image: {
    marginTop: theme.spacing(2),
    height: 'auto',
    width: 380,
    maxWidth: '100%'
  },
  footer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

function Message({ message, className }) {
  const classes = useStyles();
  const { userId } = useInfo();
  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.authUser]: message.sender.id === Number(userId)
        },
        className
      )}>
      <div className={classes.inner}>
        <div>
          <div className={classes.body}>
            <div>
              <Link
                color="inherit"
                component={RouterLink}
                to={`/users/workers/${message.sender.id}`}
                variant="h6">
                {message.sender.id === Number(userId)
                  ? 'Yo'
                  : message.sender.name}
              </Link>
            </div>
            <div className={classes.content}>
              {message.contentType === 'image' ? (
                <img
                  alt="Archivo adjunto"
                  className={classes.image}
                  src={message.content}
                />
              ) : (
                <Typography color="inherit" variant="body1">
                  {message.content}
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.footer}>
            <Typography className={classes.time} variant="body2">
              {moment(message.created_at).fromNow()}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Message;
