import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useInfo } from 'modules/authentication';
import { GET_PROFILES, GET_USERS } from '../queries';

export const UPDATE_PROFILE = gql`
  mutation updateProfile($id: Int!, $name: String!) {
    update_profile(where: { id: { _eq: $id } }, _set: { name: $name }) {
      affected_rows
    }
  }
`;

export const useUpdateProfile = () => {
  let [mutate] = useMutation(UPDATE_PROFILE);
  const { orgId } = useInfo();
  return ({ id, name }) => {
    return mutate({
      variables: {
        id,
        name
      },
      refetchQueries: [
        { query: GET_PROFILES, variables: { limit: 10, offset: 0 } },
        {
          query: GET_USERS,
          variables: { organizationId: orgId, limit: 10, offset: 0 }
        }
      ]
    });
  };
};
