import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const GET_GROUND_TYPES = gql`
  query getGroundTypes {
    groundTypes: ground_type {
      id
      name
    }
  }
`;

export const useGetGroundTypes = () => {
  const { data, error, loading } = useQuery(GET_GROUND_TYPES, {});
  return { data, error, loading };
};
