import {
  useDeleteIssue,
  useAddIssue,
  useUpdateIssue,
  useUpdateIssueState,
  useAddWorkOrder,
  useUpdateWorkOrder,
  useDeleteWorkOrder,
  useDeleteWorkOrdersByIssue,
  useAddTask,
  useUpdateTask,
  useDeleteTask,
  useAddReport,
  useUpdateReport,
  useDeleteReport,
  useAddFiles,
  useDeleteIssueFile,
  useUpdateIssueFile
} from '../graphql/mutations';

export const useIssuesMutation = () => {
  const addIssue = useAddIssue();
  const updateIssue = useUpdateIssue();
  const updateIssueState = useUpdateIssueState();
  const deleteIssue = useDeleteIssue();
  const addWorkOrder = useAddWorkOrder();
  const updateWorkOrder = useUpdateWorkOrder();
  const deleteWorkOrder = useDeleteWorkOrder();
  const deleteWorkOrdersByIssue = useDeleteWorkOrdersByIssue();
  const addTask = useAddTask();
  const updateTask = useUpdateTask();
  const deleteTask = useDeleteTask();
  const addReport = useAddReport();
  const updateReport = useUpdateReport();
  const deleteReport = useDeleteReport();
  const addFiles = useAddFiles();
  const deleteIssueFile = useDeleteIssueFile();
  const updateIssueFile = useUpdateIssueFile();
  return {
    addIssue,
    updateIssue,
    updateIssueState,
    deleteIssue,
    addWorkOrder,
    updateWorkOrder,
    deleteWorkOrder,
    deleteWorkOrdersByIssue,
    addTask,
    updateTask,
    deleteTask,
    addReport,
    updateReport,
    deleteReport,
    addFiles,
    deleteIssueFile,
    updateIssueFile
  };
};
