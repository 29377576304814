import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Drawer,
  FormControlLabel,
  Typography,
  Checkbox,
  Table,
  TableRow,
  TableCell
} from '@material-ui/core';
import Sensor from 'assets/icons/Sensor';
import Furniture from 'assets/icons/Furniture';
import Playground from 'assets/icons/Playground';
import Vegetation from 'assets/icons/Vegetation';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import AreaIcon from '@material-ui/icons/CropFree';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(4, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex',
    flexDirection: 'column'
  },
  filter: {
    paddingLeft: theme.spacing(2)
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  cell: {
    borderBottom: 'none'
  }
}));

const Filter = ({
  open,
  onClose,
  onFilter,
  className,
  activeLayers,
  ...rest
}) => {
  const classes = useStyles();
  const [layers, setLayers] = useState(activeLayers);

  const addLayer = (layer) => setLayers((prevState) => [...prevState, layer]);

  const removeLayer = (layer) =>
    setLayers(layers.filter((activeLayer) => activeLayer !== layer));

  const handleChange = (event) => {
    event.target.checked
      ? addLayer(event.target.value, layers)
      : removeLayer(event.target.value, layers);
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={(event) => onFilter(event, layers)}
      open={open}
      variant="temporary">
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={(event) => onFilter(event, layers)}>
        <div className={classes.content}>
          <div className={classes.contentSection}>
            <div className={classes.contentSectionHeader}>
              <Typography variant="h4">Capas</Typography>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <div className={classes.fieldGroup}>
                  <Table>
                    <TableRow>
                      <TableCell className={classes.cell}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="sensors"
                              checked={layers.includes('sensors')}
                              onChange={handleChange}
                            />
                          }
                          label="Sensores"
                        />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Sensor />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cell}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="furniture"
                              checked={layers.includes('furniture')}
                              onChange={handleChange}
                            />
                          }
                          label="Mobiliario urbano"
                        />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Furniture />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cell}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="playground"
                              checked={layers.includes('playground')}
                              onChange={handleChange}
                            />
                          }
                          label="Parques infantiles"
                        />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Playground />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cell}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="vegetation"
                              checked={layers.includes('vegetation')}
                              onChange={handleChange}
                            />
                          }
                          label="Vegetación"
                        />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Vegetation />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cell}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="areas"
                              checked={layers.includes('areas')}
                              onChange={handleChange}
                            />
                          }
                          label="Áreas"
                        />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <AreaIcon htmlColor="#1DA1F2" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cell}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="issues"
                              checked={layers.includes('issues')}
                              onChange={handleChange}
                            />
                          }
                          label="Incidencias"
                        />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <PriorityHighIcon htmlColor="#FF4742" />
                      </TableCell>
                    </TableRow>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Button
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
            onClick={(event) => onFilter(event, layers)}>
            Aplicar
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Filter;
