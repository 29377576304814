import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_WORK_ORDER_DETAILS } from '../queries';
import { useIssuesDispatch } from 'modules/issues/hooks';

export const ADD_TASK = gql`
  mutation addTask($objects: [work_order_task_insert_input!]!) {
    insert_work_order_task(objects: $objects) {
      affected_rows
    }
  }
`;

export const useAddTask = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(ADD_TASK, {
    onCompleted: () => openSuccessAlert('Tarea creada correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });

  return args => {
    return mutate({
      variables: {
        objects: args.map(
          ({
            description,
            due_to_date,
            in_charge_user_id,
            state,
            title,
            workOrderId
          }) => {
            return {
              description,
              due_to_date,
              in_charge_user_id,
              state,
              title,
              work_order_id: workOrderId
            };
          }
        )
      },
      refetchQueries: [
        {
          query: GET_WORK_ORDER_DETAILS,
          variables: { id: args[0].workOrderId }
        }
      ]
    });
  };
};
