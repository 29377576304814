import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_TASK_DETAILS } from '../queries';
import { useIssuesDispatch } from 'modules/issues';

export const DELETE_REPORT = gql`
  mutation deleteReport($id: Int, $workOrderId: Int) {
    delete_task_report(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const useDeleteReport = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(DELETE_REPORT, {
    onCompleted: () => openSuccessAlert('Reporte eliminado correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });

  return ({ id, taskId }) => {
    return mutate({
      variables: { id },
      refetchQueries: [
        {
          query: GET_TASK_DETAILS,
          variables: { id: taskId }
        }
      ]
    });
  };
};
