import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useGetOrganizations } from 'modules/users';

const Summary = () => {
  const { values, handleChange } = useFormikContext();
  const {
    name,
    surname,
    external_id,
    organization_id,
    profile_id,
    email,
    phone
  } = values;
  const { data } = useGetOrganizations(organization_id || 0);

  //Para que no se puedan crear superadmins desde la web
  if (data?.profiles) {
    for (let i = 0; i < data.profiles.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (data?.profiles[i].id == 1) {
        data.profiles.splice(i, 1)
      }
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Nombre"
            name="name"
            value={name}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Apellidos"
            name="surname"
            value={surname}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Código"
            name="external_id"
            value={external_id}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Teléfono"
            name="phone"
            value={phone}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={email}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Organización"
            name="organization_id"
            onChange={handleChange}
            select
            SelectProps={{ native: true }}
            value={organization_id}
            variant="outlined"
            InputLabelProps={{ shrink: Boolean(organization_id) }}>
            <option value={null} />
            {data?.organizations.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Perfil"
            name="profile_id"
            onChange={handleChange}
            disabled={!Boolean(organization_id)}
            select
            SelectProps={{ native: true }}
            value={profile_id}
            variant="outlined"
            InputLabelProps={{ shrink: Boolean(profile_id) }}>
            <option value={null} />
            {data?.profiles.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
};

export default Summary;
