import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import geojsonArea from '@mapbox/geojson-area';
import { GET_AREA_DETAILS } from '../queries/getAreaDetails';
import { useInventoryDispatch } from 'modules/inventory/hooks';

export const UPDATE_AREA = gql`
  mutation updateArea($input: area_set_input, $areaId: Int!) {
    update_area(where: { id: { _eq: $areaId } }, _set: $input) {
      affected_rows
    }
  }
`;

export const useUpdateArea = () => {
  const { openSuccessAlert, openErrorAlert } = useInventoryDispatch();
  let [mutate, { data, loading, error }] = useMutation(UPDATE_AREA, {
    onCompleted: () => openSuccessAlert('Área actualizada correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });
  return {
    updateArea: ({
      id,
      name,
      location,
      ground_type_id,
      description,
      surface_m2,
      location_type,
      typology,
      topography
    }) =>
      mutate({
        variables: {
          areaId: id,
          input: {
            name,
            ground_type_id: ground_type_id || null,
            location_type,
            typology,
            topography,
            description,
            surface_m2:
              surface_m2 === 0
                ? Math.round(geojsonArea.geometry(location))
                : surface_m2,
            location: {
              ...location,
              crs: {
                type: 'name',
                properties: {
                  name: 'urn:ogc:def:crs:EPSG::4326'
                }
              }
            }
          }
        },
        refetchQueries: [
          {
            query: GET_AREA_DETAILS,
            variables: { areaId: id }
          }
        ]
      }),
    data,
    error,
    loading
  };
};
