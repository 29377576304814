import { useDispatch } from 'react-redux';
import * as slices from '../redux';

export function useInventoryDispatch() {
  const dispatch = useDispatch();
  return {
    openInventoryItemModal: (payload) =>
      dispatch(slices.openInventoryItemModal(payload)),
    closeInventoryItemModal: () => dispatch(slices.closeInventoryItemModal()),
    openDeleteInventoryItemModal: (payload) =>
      dispatch(slices.openDeleteInventoryItemModal(payload)),
    closeDeleteInventoryItemModal: () =>
      dispatch(slices.closeDeleteInventoryItemModal()),
    openInventoryUploadModal: (payload) =>
      dispatch(slices.openInventoryUploadModal(payload)),
    closeInventoryUploadModal: () =>
      dispatch(slices.closeInventoryUploadModal()),
    openAreaModal: (payload) => dispatch(slices.openAreaModal(payload)),
    closeAreaModal: () => dispatch(slices.closeAreaModal()),
    openDeleteAreaModal: (payload) =>
      dispatch(slices.openDeleteAreaModal(payload)),
    closeDeleteAreaModal: () => dispatch(slices.closeDeleteAreaModal()),
    openErrorAlert: (payload) => dispatch(slices.openErrorAlert(payload)),
    closeAlert: () => dispatch(slices.closeAlert()),
    openSuccessAlert: (payload) => dispatch(slices.openSuccessAlert(payload)),
    openConfirmTypeModal: (payload) =>
      dispatch(slices.openConfirmTypeModal(payload)),
    closeConfirmTypeModal: () => dispatch(slices.closeConfirmTypeModal())
  };
}
