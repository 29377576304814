import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MaterialLink } from '@material-ui/core';

const Link = ({ title = 'Link', to, variant = 'h6', ...props }) => {
  return (
    <MaterialLink
      {...props}
      color="inherit"
      component={RouterLink}
      to={to}
      variant={variant}>
      {title}
    </MaterialLink>
  );
};

export default Link;
