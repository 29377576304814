import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedFeature: undefined,
  selectedArea: undefined,
  viewport: {
    latitude: 37.981839,
    longitude: -1.126009,
    zoom: 15
  },
  layers: [
    'sensors',
    'furniture',
    'vegetation',
    'surface',
    'playground',
    'areas',
    'issues',
    'pointsWithIssues',
    'surfaceWithIssues'
  ]
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    loadMap: () => initialState,
    selectFeature: (state, { payload }) => ({
      ...state,
      selectedFeature: payload
    }),
    removeFeature: state => ({
      ...state,
      selectedFeature: undefined
    }),
    selectArea: (state, { payload }) => ({
      ...state,
      selectedArea: payload
    }),
    removeArea: state => ({
      ...state,
      selectedArea: undefined
    }),
    setViewport: (state, { payload }) => ({
      ...state,
      viewport: payload
    }),
    setLayers: (state, { payload }) => ({
      ...state,
      layers: payload
    }),
    addLayer: (state, { payload }) => ({
      ...state,
      layers: [...state.layers, payload]
    }),
    removeLayer: (state, { payload }) => ({
      ...state,
      layers: state.layers.filter(layer => layer !== payload)
    })
  }
});

export const {
  loadMap,
  addLayer,
  removeLayer,
  setLayers,
  setViewport,
  selectFeature,
  removeFeature,
  selectArea,
  removeArea
} = mapSlice.actions;

export default mapSlice.reducer;
