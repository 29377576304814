import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useInfo } from 'modules/authentication';
import { SETTINGS_FRAGMENT, WORKER_FRAGMENT } from '../fragments';

export const GET_SETTINGS_BY_USER = gql`
  query getSettingsByUser($userId: Int!) {
    settings(where: { organization_user_id: { _eq: $userId } }) {
      ...settingsFields
      user: organization_user {
        external_id
        id
        name
        surname
        email
        password
        phone
        organization_id
        file {
          id
          name
          url
          user_id
        }
        organization {
          id
          name
        }
      }
    }
  }
  ${SETTINGS_FRAGMENT}
  ${WORKER_FRAGMENT}
`;

export const useGetSettingsByUser = () => {
  const { userId } = useInfo();
  const { data, error, loading } = useQuery(GET_SETTINGS_BY_USER, {
    variables: {
      userId
    },
    fetchPolicy: 'cache-and-network'
  });
  return {
    data,
    error,
    loading
  };
};
