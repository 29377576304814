import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector';
import { createStructuredSelector } from 'reselect';

const viewportSelector = state => state.map.viewport;
const selectedFeatureSelector = state => state.map.selectedFeature;
const layersSelector = state => state.map.layers;
const selectedAreaSelector = state => state.map.selectedArea;

const selectors = createStructuredSelector({
  viewport: viewportSelector,
  selectedFeature: selectedFeatureSelector,
  selectedArea: selectedAreaSelector,
  layers: layersSelector
});

export function useMapState() {
  return useShallowEqualSelector(selectors);
}
