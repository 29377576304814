import React from 'react';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import { useFormikContext } from 'formik';
const Furniture = () => {
  const { values, handleChange } = useFormikContext();
  const {
    manufacturer,
    model,
    age,
    security_area,
    fall_height,
    dimensions,
    weight
  } = values.playgrounds[0];
  return (
    <Grid container spacing={3} alignItems="center" justify="center">
      <Grid item xs={12} lg={6}>
        <TextField
          fullWidth
          label="Fabricante"
          name="playgrounds[0].manufacturer"
          onChange={handleChange}
          value={manufacturer}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          fullWidth
          label="Modelo"
          name="playgrounds[0].model"
          onChange={handleChange}
          value={model}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          fullWidth
          label="Edad mínima"
          name="playgrounds[0].age"
          onChange={handleChange}
          value={age}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          fullWidth
          label="Área de seguridad"
          name="playgrounds[0].security_area"
          onChange={handleChange}
          value={security_area}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          fullWidth
          label="Altura de caída"
          name="playgrounds[0].fall_height"
          onChange={handleChange}
          value={fall_height}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          fullWidth
          label="Peso"
          name="playgrounds[0].weight"
          onChange={handleChange}
          value={weight}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Dimensiones"
          name="playgrounds[0].dimensions"
          onChange={handleChange}
          value={dimensions}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Furniture;
