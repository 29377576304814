import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { useUsersDispatch } from 'modules/users';
import { useRoles } from 'modules/authentication';

const Actions = ({ worker }) => {
  const {
    users__administrator,
    users__organization,
    users__update,
    users__delete
  } = useRoles();
  const { openWorkerModal, openDeleteWorkerModal } = useUsersDispatch();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };
  if (
    (!users__administrator || !users__organization) &&
    !users__update &&
    !users__delete
  )
    return null;
  return (
    <>
      <Tooltip title="Acciones">
        <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        {(users__administrator || users__organization) && (
          <MenuItem
            component={Link}
            to={worker && `/users/workers/${worker.id}`}>
            <ListItemText primary="Ver" />
          </MenuItem>
        )}
        {(users__administrator || users__organization) && users__update && (
          <MenuItem
            onClick={() => {
              openWorkerModal({ worker });
              setOpenMenu(false);
            }}>
            <ListItemText primary="Editar" />
          </MenuItem>
        )}
        {(users__administrator || users__organization) && users__delete && (
          <MenuItem
            onClick={() => {
              openDeleteWorkerModal(worker);
              setOpenMenu(false);
            }}>
            <ListItemText primary="Eliminar" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

Actions.propTypes = {
  className: PropTypes.string
};

export default Actions;
