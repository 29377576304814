import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import DataChart from './DataChart';
import CurrentValue from './CurrentValue';
import SensorType from './SensorType';
import DataTable from './DataTable';
import { useGetDatastream } from 'modules/sensors/graphql/queries/getDatastream';
import Loader from 'components/Loader';
import { useSensorsDispatch } from 'modules/sensors';

const useStyles = makeStyles(theme => ({
  root: { width: '100%' }
}));

const Datastreams = ({ datastreams }) => {
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const { selectDatastream } = useSensorsDispatch();
  const { data, loading } = useGetDatastream(datastreams[count].id);
  useEffect(() => {
    data && selectDatastream(data.datastream);
  }, [data]);
  return (
    <div className={classes.root}>
      {loading && <Loader />}
      {!loading && data && (
        <Grid container spacing={4}>
          <Grid item lg={6} xs={12}>
            <Grid container spacing={4}>
              <Grid item lg={6} xs={12}>
                <SensorType
                  datastream={data.datastream}
                  currentObservation={count}
                  observations={datastreams.map((datastream, index) => ({
                    index,
                    observation: datastream.observation
                  }))}
                  onChange={setCount}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <CurrentValue datastream={data.datastream} />
              </Grid>
              <Grid item xs={12}>
                <DataTable datastream={data.datastream} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12}>
            <DataChart datastream={data.datastream} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Datastreams;
