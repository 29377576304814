import React, { useState, useRef, useEffect } from 'react';
import ReactMapGL, {
  Marker,
  GeolocateControl,
  FullscreenControl
} from 'react-map-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import Geocoder from 'react-map-gl-geocoder';
import { useFormikContext } from 'formik';
import { renderPin } from 'utils/inventory';
import renderIcons from 'components/Markers/icons';

const geolocateStyle = {
  position: 'absolute',
  top: 50,
  left: 0,
  margin: 10
};

const Map = ({ longitude = -1.13386, latitude = 37.99319 }) => {
  const { setFieldValue, values } = useFormikContext();
  const mapRef = useRef();
  const initialViewport = {
    latitude: 0, //values.location ? values.location.coordinates[1] : latitude,
    longitude: 0, //values.location ? values.location.coordinates[0] : longitude,
    zoom: 17,
    bearing: 0,
    pitch: 0
  };

  const initialMarker = {
    latitude: initialViewport.latitude,
    longitude: initialViewport.longitude
  };

  const [viewport, setViewport] = useState(initialViewport);
  const [marker, setMarker] = useState(initialMarker);
  const [events, setEvents] = useState({});

  const handleViewportChange = (viewport) => {
    setViewport((prevState) => ({ ...prevState, ...viewport }));
  };

  const handleGeocoderViewportChange = (viewport) => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };

    return handleViewportChange({
      ...viewport,
      ...geocoderDefaultOverrides
    });
  };

  const updateViewport = (viewport) => {
    setViewport(viewport);
  };

  const logDragEvent = (name, event) => {
    setEvents((prevState) => ({
      ...prevState,
      [name]: event.lngLat
    }));
  };

  const onMarkerDragStart = (event) => {
    logDragEvent('onDragStart', event);
  };

  const onMarkerDrag = (event) => {
    logDragEvent('onDrag', event);
  };

  const onMarkerDragEnd = (event) => {
    logDragEvent('onDragEnd', event);
    setMarker({
      longitude: event.lngLat[0],
      latitude: event.lngLat[1]
    });
    setFieldValue('longitude', event.lngLat[0]);
    setFieldValue('latitude', event.lngLat[1]);
  };

  useEffect(() => {
    setFieldValue('location', {
      type: 'Point',
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:EPSG::4326'
        }
      },
      coordinates: [marker.longitude, marker.latitude]
    });
  }, [marker, setFieldValue]);

  return (
    <ReactMapGL
      {...viewport}
      ref={mapRef}
      width="100%"
      height="100%"
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
      onViewportChange={updateViewport}
      click>
      <GeolocateControl
        style={geolocateStyle}
        positionOptions={{ enableHighAccuracy: true }}
        trackUserLocation={true}
      />
      <Marker
        longitude={marker.longitude}
        latitude={marker.latitude}
        offsetTop={-20}
        offsetLeft={-10}
        draggable
        onDragStart={onMarkerDragStart}
        onDrag={onMarkerDrag}
        onDragEnd={onMarkerDragEnd}>
        {renderIcons({
          type:
            values.vegetacion_inventariables?.[0]?.vegetationType ||
            values.inventoryItemType
        })}
      </Marker>
      <div style={{ position: 'absolute', right: 10, top: 10 }}>
        <FullscreenControl container={document.querySelector('root')} />
      </div>
      <Geocoder
        mapRef={mapRef}
        onViewportChange={handleGeocoderViewportChange}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
        position="top-left"
        placeholder={'Buscar dirección'}
      />
    </ReactMapGL>
  );
};

export default Map;
