import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import {
  ORGANIZATION_FRAGMENT,
  WORKER_FRAGMENT,
  PROFILE_FRAGMENT
} from '../fragments';
import useRouter from 'hooks/useRouter';
import { useRoles } from 'modules/authentication';
import usePagination from 'hooks/usePagination';

export const GET_ORGANIZATION_DETAILS = gql`
  query getOrganizationDetails($id: Int!, $limit: Int, $offset: Int) {
    organization: organization_by_pk(id: $id) {
      ...organizationFields
      file {
        id
        url
        name
      }
      profiles: organization_profile_associations {
        profile {
          id
          name
        }
      }
      workers: organization_users(limit: $limit, offset: $offset) {
        ...workerFields
        profile {
          ...profileFields
        }
      }
      workersCount: organization_users_aggregate {
        aggregate {
          count
        }
      }
    }
    profiles: profile(
      where: {
        organization_profile_associations: { organization_id: { _eq: $id } }
      }
    ) {
      ...profileFields
    }
  }
  ${ORGANIZATION_FRAGMENT}
  ${WORKER_FRAGMENT}
  ${PROFILE_FRAGMENT}
`;

export const useGetOrganizationDetails = () => {
  const { users__administrator, users__organization } = useRoles();
  const {
    limit,
    offset,
    setLimit,
    setOffset,
    handleNext,
    handlePrevious,
    setCount,
    resetPagination
  } = usePagination();
  const { match } = useRouter();
  const { id } = match.params || {};
  const query = useQuery(GET_ORGANIZATION_DETAILS, {
    variables: {
      id,
      limit: limit || 10,
      offset: offset || 0
    },
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        'x-hasura-role': users__administrator
          ? 'users__administrator'
          : users__organization
          ? 'users__organization'
          : undefined
      }
    }
  });
  return {
    ...query,
    limit,
    offset,
    setLimit,
    setOffset,
    handleNext,
    handlePrevious,
    setCount,
    resetPagination
  };
};
