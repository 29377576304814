/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField } from '@material-ui/core';
import { Alert } from 'components/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: { marginTop: theme.spacing(3) },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%'
  }
}));

const LoginForm = ({ login, error, loading }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    login({ email, password });
    setShowError(true);
  };

  return (
    <form className={classes.root} noValidate onSubmit={handleSubmit}>
      <div className={classes.fields}>
        <TextField
          fullWidth
          variant="outlined"
          id="email"
          label="Correo electrónico"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={event => {
            setEmail(event.target.value);
            setShowError(false);
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={event => {
            setPassword(event.target.value);
            setShowError(false);
          }}
        />
      </div>
      <Button
        className={classes.submitButton}
        color="primary"
        type="submit"
        size="large"
        variant="contained"
        disabled={loading}>
        {loading ? <CircularProgress size={26} /> : 'Iniciar sesión'}
      </Button>
      <Alert message={error?.message} show={showError && error} />
    </form>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string
};

export default LoginForm;
