import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Divider
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { useInventoryDispatch } from 'modules/inventory';
import { useMapDispatch } from 'modules/map';
import useRouter from 'hooks/useRouter';
import { useIssuesDispatch } from 'modules/issues';
import { useMaintenancesDispatch } from 'modules/maintenances';
import { useRoles } from 'modules/authentication';

const Actions = ({ data }) => {
  const {
    issues__insert,
    maintenances__insert,
    inventory__update,
    inventory__delete
  } = useRoles();
  const {
    openInventoryItemModal,
    openDeleteInventoryItemModal
  } = useInventoryDispatch();
  const { openIssueModal } = useIssuesDispatch();
  const { openMaintenanceModal } = useMaintenancesDispatch();
  const { selectFeature } = useMapDispatch();
  const { history } = useRouter();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };
  if (
    !issues__insert &&
    !maintenances__insert &&
    !inventory__update &&
    !inventory__delete
  )
    return null;
  return (
    <>
      <Tooltip title="Acciones">
        <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        {issues__insert && (
          <MenuItem
            onClick={() => {
              selectFeature(data);
              openIssueModal();
              setOpenMenu(false);
            }}>
            <ListItemText primary="Incidencia" />
          </MenuItem>
        )}
        {maintenances__insert && (
          <MenuItem
            onClick={() => {
              selectFeature(data);
              openMaintenanceModal();
              setOpenMenu(false);
            }}>
            <ListItemText primary="Mantenimiento" />
          </MenuItem>
        )}
        {(inventory__update || inventory__delete) && <Divider />}
        {inventory__update && (
          <MenuItem
            onClick={() => {
              openInventoryItemModal(data);
              setOpenMenu(false);
            }}>
            <ListItemText primary="Editar" />
          </MenuItem>
        )}
        {inventory__delete && (
          <MenuItem
            onClick={() => {
              openDeleteInventoryItemModal(data);
              setOpenMenu(false);
            }}>
            <ListItemText primary="Eliminar" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

Actions.propTypes = {
  className: PropTypes.string
};

export default Actions;
