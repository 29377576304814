import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, colors } from '@material-ui/core';
import View from 'components/View';
import {
  useGetOrganizationDetails,
  useUsersDispatch,
  useUsersState
} from 'modules/users';
import Actions from './Actions';
import ConfirmDelete from './ConfirmDelete';
import Summary from './Summary';
import OrganizationModal from 'views/OrganizationModal';
import OrganizationUploadModal from 'views/OrganizationUploadModal';
import WorkerModal from 'views/WorkerModal';
import Alert from 'components/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const OrganizationDetails = () => {
  const classes = useStyles();
  const { data, loading } = useGetOrganizationDetails();
  const { alert } = useUsersState();
  const { closeAlert } = useUsersDispatch();
  return (
    <View
      title="Organización"
      loading={loading}
      subtitle={data?.organization ? `#${data.organization.id}` : 'Cargando...'}
      actions={<Actions {...data} />}>
      <Divider className={classes.divider} />
      {data && (
        <div className={classes.content}>
          <Summary organization={data?.organization} />
        </div>
      )}
      <ConfirmDelete />
      <OrganizationModal />
      <WorkerModal />
      <OrganizationUploadModal />
      <Alert
        show={alert}
        message={alert?.message}
        severity={alert?.severity}
        fireOnClose={() => closeAlert()}
      />
    </View>
  );
};

export default OrganizationDetails;
