import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography } from '@material-ui/core';
import { getLastUpdate } from 'utils/sensors';

const useStyles = makeStyles(theme => ({
  root: {
    height: 120,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 48,
    width: 48
  }
}));

const LastUpdate = ({ datastreams }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <div>
        <Typography component="h3" gutterBottom variant="overline">
          Ultima actualización
        </Typography>
        <div className={classes.details}>
          <Typography variant="h4">{getLastUpdate(datastreams)}</Typography>
        </div>
      </div>
    </Card>
  );
};

LastUpdate.propTypes = {
  className: PropTypes.string
};

export default LastUpdate;
