import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import img from 'assets/images/banco.jpg';
import Features from './Features';
import Tasks from './Tasks';
import Profile from './Profile';
const useStyles = makeStyles(theme => ({
  root: { display: 'flex', flexWrap: 'wrap', height: '100%' }
}));

const Summary = ({ worker }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item lg={6} xs={12}>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Profile worker={worker} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Features {...worker} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Tasks tasks={worker?.tasks} />
      </Grid>
    </Grid>
  );
};

export default Summary;
