import React, { useState } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Button,
  Grid,
  TextField
} from '@material-ui/core';
import { Formik } from 'formik';
import readXlsxFile from 'read-excel-file';
import useClasses from 'hooks/useClasses';
import styles from './styles';
import {
  useInventoryDispatch,
  useInventoryState,
  exportInventory
} from 'modules/inventory';
import { inventoryTypes, parseImportedData } from 'utils/inventory';
import * as Yup from 'yup';
import FileUpload from './FileUpload';
import Loader from 'components/Loader';
import { useImportInventory } from 'modules/inventory/graphql/mutations/importIntentory';

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Campo requerido')
});

const ConfirmType = () => {
  const classes = useClasses(styles);
  const {
    showConfirmTypeModal,
    importSubmitted,
    exportSubmitted,
    inventoryItemTypes
  } = useInventoryState();
  const {
    closeConfirmTypeModal,
    openSuccessAlert,
    openErrorAlert
  } = useInventoryDispatch();
  const { importInventory } = useImportInventory();
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    closeConfirmTypeModal();
  };

  return (
    <Modal onClose={handleClose} open={showConfirmTypeModal}>
      <Card className={classes.root}>
        <Formik
          initialValues={{ type: '', file: null }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (values.type && importSubmitted) {
              readXlsxFile(values.file)
                .then((data) => {
                  setLoading(true);
                  parseImportedData(data, importInventory);
                })
                .finally(() => setLoading(false))
                .catch((error) => setLoading(false));
            }
            if (values.type && exportSubmitted) {
              exportInventory({
                type: values.type,
                callback: openSuccessAlert,
                errorCallback: openErrorAlert
              });
            }
            handleClose();
          }}>
          {({
            values: { type },
            handleSubmit,
            handleChange,
            touched,
            errors
          }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardHeader
                title={
                  <Typography component="h1" variant="h3">
                    {importSubmitted ? 'Importación' : 'Exportación'}
                  </Typography>
                }
                className={classes.header}
              />
              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Tipo"
                        name="type"
                        type="text"
                        select
                        SelectProps={{ native: true }}
                        value={type}
                        onChange={handleChange}
                        variant="outlined"
                        helperText={touched.type ? errors.type : ''}
                        error={touched.type && Boolean(errors.type)}>
                        <option value={undefined}></option>
                        {inventoryItemTypes.map((type) => (
                          <option key={type} value={type}>
                            {inventoryTypes[type]}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {importSubmitted && (
                      <Grid item xs={12}>
                        {!loading ? <FileUpload /> : <Loader />}
                      </Grid>
                    )}
                  </Grid>
                </div>
              </CardContent>
              <Divider className={classes.divider} />
              <CardActions className={classes.actions}>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  disabled={!type}>
                  {'Enviar'}
                </Button>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

export default ConfirmType;
