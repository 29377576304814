import React, { useRef } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useInventoryDispatch, useInventoryMutation } from 'modules/inventory';

const Actions = ({ file }) => {
  const { closeInventoryUploadModal } = useInventoryDispatch();
  const { deleteInventoryFile } = useInventoryMutation();

  const handleClick = () => {
    deleteInventoryFile({
      id: file.id,
      inventoryId: file.inventory_id
    });
    closeInventoryUploadModal();
  };

  return (
    <Tooltip title="Eliminar">
      <IconButton onClick={handleClick} size="small">
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

export default Actions;
