import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useRoles } from 'modules/authentication';

export const GET_ORGANIZATIONS = gql`
  query getOrganizations {
    organizations: organization(order_by: { id: asc }) {
      id
      name
    }
  }
`;

export const useGetOrganizations = () => {
  const { maintenances__administrator, maintenances__solver } = useRoles();
  const { data, error, loading } = useQuery(GET_ORGANIZATIONS, {
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        'x-hasura-role': maintenances__administrator
          ? 'maintenances__administrator'
          : maintenances__solver
          ? 'maintenances__solver'
          : undefined
      }
    }
  });
  return {
    data,
    error,
    loading
  };
};
