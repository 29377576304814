import {
  useAddSensor,
  useUpdateSensor,
  useDeleteSensor
} from '../graphql/mutations';

export const useSensorsMutation = () => {
  const addSensor = useAddSensor();
  const updateSensor = useUpdateSensor();
  const deleteSensor = useDeleteSensor();

  return {
    addSensor,
    updateSensor,
    deleteSensor
  };
};
