import gql from 'graphql-tag';
import { MESSAGE_FRAGMENT } from './message';

export const CHAT_FRAGMENT = gql`
  fragment chatFields on chat {
    id
    name
    picture
    owner_id
    created_at
    messages(order_by: [{ created_at: desc }], limit: 2) {
      ...messageFields
    }
  }
  ${MESSAGE_FRAGMENT}
`;
