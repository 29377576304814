import React from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';
import moment from 'moment';
import Link from 'components/Link';
import useClasses from 'hooks/useClasses';

const styles = theme => ({
  root: {
    minHeight: 300,
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 }
});

const Features = props => {
  const classes = useClasses(styles);
  const { organization_user, workOrder, due_to_date } = props;
  const { issue } = workOrder || {};
  return (
    <Card className={classes.root}>
      <CardHeader title="Datos" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Orden de trabajo</TableCell>
              <TableCell className={classes.data}>
                {workOrder ? (
                  <Link
                    title={workOrder.title}
                    to={`/issues/${issue.id}/workorders/${workOrder.id}`}
                  />
                ) : (
                  'Desconocido'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Incidencia</TableCell>
              <TableCell className={classes.data}>
                {issue ? (
                  <Link title={issue.title} to={`/issues/${issue.id}`} />
                ) : (
                  'Desconocido'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Elemento</TableCell>
              <TableCell className={classes.data}>
                {issue && issue.elemento_inventario ? (
                  <Link
                    title={issue.elemento_inventario.name}
                    to={`/inventory/${issue.elemento_inventario.id}`}
                  />
                ) : (
                  'Desconocido'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Responsable</TableCell>
              <TableCell className={classes.data}>
                {organization_user
                  ? `${organization_user.name} ${organization_user.surname}`
                  : 'Desconocido'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Vencimiento</TableCell>
              <TableCell className={classes.data}>
                {' '}
                {due_to_date
                  ? moment(due_to_date).format('DD/MM/YYYY')
                  : 'Desconocido'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Features;
