import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Divider
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { useUsersDispatch } from 'modules/users';
import { useRoles } from 'modules/authentication';

const Actions = ({ organization = {}, profiles = [] }) => {
  const {
    users__update,
    users__delete,
    users__insert,
    users__administrator,
    users__organization
  } = useRoles();
  const {
    openOrganizationModal,
    openDeleteOrganizationModal,
    openWorkerModal
  } = useUsersDispatch();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };
  if (
    !organization ||
    (typeof organization === 'object' && Object.entries(organization) === 0) ||
    (!users__update && !users__delete)
  )
    return null;
  return (
    <>
      <Tooltip title="Acciones">
        <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        {users__insert && (users__organization || users__administrator) && (
          <MenuItem onClick={() => openWorkerModal({ organization })}>
            <ListItemText primary="Trabajador" />
          </MenuItem>
        )}
        {users__insert && (users__organization || users__administrator) && (
          <Divider />
        )}
        {users__update && (
          <MenuItem
            onClick={() => {
              openOrganizationModal({ organization, profiles });
              setOpenMenu(false);
            }}>
            <ListItemText primary="Editar" />
          </MenuItem>
        )}
        {users__delete && (
          <MenuItem
            onClick={() => {
              openDeleteOrganizationModal(organization);
              setOpenMenu(false);
            }}>
            <ListItemText primary="Eliminar" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

Actions.propTypes = {
  className: PropTypes.string
};

export default Actions;
