import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { useIssuesDispatch, useIssuesMutation } from 'modules/issues';
const Actions = ({ file }) => {
  const { openIssueUploadModal } = useIssuesDispatch();
  const { deleteIssueFile } = useIssuesMutation();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <Tooltip title="Acciones">
        <IconButton onClick={handleMenuOpen} ref={moreRef} size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <MenuItem component={Link} href={file.url} download={file.name}>
          Descargar
        </MenuItem>
        <MenuItem
          onClick={() => {
            openIssueUploadModal({ issue: { id: file.issue_id }, file });
            setOpenMenu(false);
          }}>
          <ListItemText primary="Editar" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteIssueFile({ id: file.id, issueId: file.issue_id });
            setOpenMenu(false);
          }}>
          <ListItemText primary="Eliminar" />
        </MenuItem>
      </Menu>
    </>
  );
};

Actions.propTypes = {
  className: PropTypes.string
};

export default Actions;
