import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingTop: theme.spacing(2),
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

function Placeholder({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="subtitle2">No hay datos que mostrar</Typography>
    </div>
  );
}

Placeholder.propTypes = {
  className: PropTypes.string
};

export default Placeholder;
