import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_SENSORS } from '../queries';
import { useRoles } from 'modules/authentication';
import { useSensorsDispatch } from 'modules/sensors';

export const DELETE_SENSOR = gql`
  mutation deleteSensor($id: Int!) {
    delete_elemento_inventario(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const useDeleteSensor = () => {
  const { sensors__delete } = useRoles();
  const { openSuccessAlert, openErrorAlert } = useSensorsDispatch();
  let [mutate, { data, loading, error }] = useMutation(DELETE_SENSOR, {
    onCompleted: () => openSuccessAlert('Sensor eliminado correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });
  const date = new Date().toDateString();
  return ({ id }) => {
    return mutate({
      variables: { id },
      refetchQueries: [
        { query: GET_SENSORS, variables: { date, limit: 10, offset: 0 } }
      ],
      context: {
        headers: {
          'x-hasura-role': sensors__delete && 'sensors__delete'
        }
      }
    });
  };
};
