import React from 'react';
import img from 'assets/images/organization.jpg';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Avatar, Typography } from '@material-ui/core';
import { useUsersDispatch } from 'modules/users';

const useStyles = makeStyles(theme => ({
  root: { height: '100%' },
  content: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlgin: 'center'
  },
  name: {
    padding: theme.spacing(1)
  },
  organization: {
    padding: theme.spacing(0)
  },
  avatar: {
    height: 200,
    width: 200
  }
}));

const Profile = ({ organization = {} }) => {
  const classes = useStyles();
  const { openOrganizationUploadModal } = useUsersDispatch();
  return (
    <Card className={classes.root}>
      <CardContent
        className={classes.content}
        onClick={() =>
          openOrganizationUploadModal({
            organization,
            file: organization?.file
          })
        }>
        <Avatar className={classes.avatar} src={organization?.file?.url} />
        <Typography className={classes.name} gutterBottom variant="h3">
          {organization?.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Profile;
