import React, { useState, useEffect, useRef } from 'react';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './styles.scss';
import { Paper, IconButton } from '@material-ui/core';
import LayersIcon from '@material-ui/icons/Layers';
import { makeStyles } from '@material-ui/styles';
import MapGL, {
  LinearInterpolator,
  FlyToInterpolator,
  GeolocateControl,
  Source,
  Layer,
  WebMercatorViewport
} from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';
import {
  sensorLayer,
  selectedSensorLayer,
  vegetationLayer,
  selectedVegetationLayer,
  furnitureLayer,
  selectedFurnitureLayer,
  useMapState,
  useGetAreas,
  useGetInventory,
  surfaceLayer,
  surfaceOutlineLayer,
  selectedSurfaceOutlineLayer,
  selectedSurfaceLayer,
  surfaceWithIssuesLayer,
  surfaceWithIssuesOutlineLayer,
  selectedSurfaceWithIssuesOutlineLayer,
  selectedSurfaceWithIssuesLayer,
  areasLayer,
  areasOutlineLayer,
  selectedAreaLayer,
  selectedAreaOutlineLayer,
  playgroundLayer,
  selectedPlaygroundLayer,
  pointsWithIssuesLayer,
  selectedPointWithIssuesLayer
} from 'modules/map';
import { useInterval } from 'hooks/useInterval';
import bbox from '@turf/bbox';
import Alert from 'components/Alert';
import FeaturePanel from './FeaturePanel';
import AreaPanel from './AreaPanel';
import { useFormikContext } from 'formik';
import Markers from 'components/Markers';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    position: 'relative'
  },
  filterButton: {
    borderRadius: '4px',
    boxShadow: 'rgba(0,0,0,.1) 0 0 0 2px',
    position: 'absolute',
    top: 3,
    right: 0,
    margin: 10,
    backgroundColor: '#fff'
  },
  filterIcon: {
    marginRight: theme.spacing(0)
  }
}));

const geolocateStyle = {
  position: 'absolute',
  top: 3,
  left: 0,
  margin: 10
};

const initialViewport = {
  longitude: -1.13386,
  latitude: 37.99319,
  zoom: 16,
  bearing: 0,
  pitch: 0
};

const Map = ({ longitude, latitude }) => {
  const abortController = new AbortController();
  const { signal } = abortController;
  const classes = useStyles();
  const [bounds, setBounds] = useState([]);
  const [zoom, setZoom] = useState(18);
  const [event, setEvent] = useState({});
  const [updatedBounds, setUpdatedBounds] = useState([
    [-1.1277449396128816, 37.9912732799813],
    [-1.1277449396128816, 37.99346834253949],
    [-1.1337649860359988, 37.99346834253949],
    [-1.1337649860359988, 37.9912732799813],
    [-1.1337649860359988, 37.9912732799813],
  ]);
  const mapRef = useRef(null);
  const [viewport, setViewport] = useState({
    ...initialViewport,
    latitude,
    longitude
  });
  const { selectedFeature, selectedArea, layers } = useMapState();
  const [feature, setFeature] = useState();
  const [area, setArea] = useState();
  const [sensorSource, setSensorSource] = useState({});
  const [vegetationSource, setVegetationSource] = useState({});
  const [furnitureSource, setFurnitureSource] = useState({});
  const [playgroundSource, setPlaygroundSource] = useState({});
  const [pointsWithIssuesSource, setPointsWithIssuesSource] = useState({});
  const [surfaceSource, setSurfaceSource] = useState({});
  const [surfaceWithIssuesSource, setSurfaceWithIssuesSource] = useState({});
  const [areasSource, setAreasSource] = useState({});
  const { data: areasData } = useGetAreas(updatedBounds);
  const { data, error } = useGetInventory(updatedBounds, 1, zoom, signal);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (feature) {
      setFieldValue('resource_id', feature.properties.id);
    }
  }, [feature, setFieldValue]);
  
  useInterval(() => {
    setUpdatedBounds(bounds);
  }, 400);

  useEffect(() => {
    abortController.abort();
  }, [bounds]);

  useEffect(() => {
    if (data) {
      setSensorSource(
        data.sensors
          .filter(({ issuesCount }) => issuesCount.aggregate.count < 1)
          .map((item) => ({
            marker: true,
            layer: { id: item.inventoryItemType },
            properties: { ...item },
            type: 'Feature',
            geometry: item.location
          }))
      );
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setVegetationSource(
        data.vegetation
          .filter(({ issuesCount }) => issuesCount.aggregate.count < 1)
          .map((item) => ({
            marker: true,
            layer: { id: item.inventoryItemType },
            properties: {
              id: item.id,
              type: 'vegetation',
              subtype: item.vegetacion_inventariables?.[0]?.vegetationType
            },
            type: 'Feature',
            geometry: item.location
          }))
      );
    }
  }, [data]);

  useEffect(() => {
    data &&
      setFurnitureSource(
        data.furniture
          .filter(({ issuesCount }) => issuesCount.aggregate.count < 1)
          .map((item) => ({
            marker: true,
            layer: { id: item.inventoryItemType },
            properties: { ...item },
            type: 'Feature',
            geometry: item.location
          }))
      );
  }, [data]);

  useEffect(() => {
    data &&
      setPlaygroundSource(
        data.playground
          .filter(({ issuesCount }) => issuesCount.aggregate.count < 1)
          .map((item) => ({
            marker: true,
            layer: { id: item.inventoryItemType },
            properties: { ...item },
            type: 'Feature',
            geometry: item.location
          }))
      );
  }, [data]);

  useEffect(() => {
    data &&
      setSurfaceSource({
        type: 'FeatureCollection',
        features: data.surface
          .filter(({ issuesCount }) => issuesCount.aggregate.count < 1)
          .map((item) => ({
            properties: { id: item.id },
            type: 'Feature',
            geometry: item.location
          }))
      });
  }, [data]);

  useEffect(() => {
    if (data) {
      setPointsWithIssuesSource({
        type: 'FeatureCollection',
        features: data.pointsWithIssues.map((item) => ({
          properties: { id: item.id },
          type: 'Feature',
          geometry: item.location
        }))
      });
      setSurfaceWithIssuesSource({
        type: 'FeatureCollection',
        features: data.surfaceWithIssues.map((item) => ({
          properties: { id: item.id },
          type: 'Feature',
          geometry: item.location
        }))
      });
    }
  }, [data]);

  useEffect(() => {
    areasData &&
      setAreasSource({
        type: 'FeatureCollection',
        features: areasData.areas.map((item) => ({
          properties: { id: item.id },
          type: 'Feature',
          geometry: item.location
        }))
      });
  }, [areasData]);

  const [furnitureFilter, setFurnitureFilter] = useState([
    '==',
    'id',
    'selectedFurniture'
  ]);
  const [vegetationFilter, setVegetationFilter] = useState([
    '==',
    'id',
    'selectedVegetation'
  ]);
  const [playgroundFilter, setPlaygroundFilter] = useState([
    '==',
    'id',
    'selectedPlayground'
  ]);
  const [pointsWithIssuesFilter, setPointsWithIssuesFilter] = useState([
    '==',
    'id',
    'selectedPointWithIssues'
  ]);
  const [surfaceFilter, setSurfaceFilter] = useState([
    '==',
    'id',
    'selectedSurface'
  ]);
  const [surfaceWithIssuesFilter, setSurfaceWithIssuesFilter] = useState([
    '==',
    'id',
    'selectedSurfaceWithIssues'
  ]);
  const [sensorFilter, setSensorFilter] = useState([
    '==',
    'id',
    'selectedSensor'
  ]);

  const [areasFilter, setAreasFilter] = useState(['==', 'id', 'selectedArea']);

  const handleViewportChange = (viewport) => {
    setViewport((prevState) => ({ ...prevState, ...viewport }));
  };

  const handleGeocoderViewportChange = (viewport) => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };

    return handleViewportChange({
      ...viewport,
      ...geocoderDefaultOverrides
    });
  };

  const goToViewport = ({ geometry }) => {
    const { type, coordinates } = geometry || {};
    setViewport({
      longitude:
        type === 'Polygon' ? coordinates[0][0][0] + 0.003 : coordinates[0],
      latitude: type === 'Polygon' ? coordinates[0][0][1] : coordinates[1],
      zoom: zoom >= 18 ? (type === 'Polygon' ? 18 : 20) : zoom,
      transitionInterpolator: new FlyToInterpolator({ speed: 1 }),
      transitionDuration: 1000
    });
  };

  const zoomToBounds = (area, event) => {
    if (area) {
      console.log(area);
      console.log(bbox(area));
      const [minLng, minLat, maxLng, maxLat] = bbox(area);
      const newViewport = new WebMercatorViewport(viewport);
      const { longitude, latitude } = newViewport.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat]
        ],
        {
         // padding: 20,
        }
      );
      setViewport({
        ...viewport,
        longitude,
        latitude,
        transitionInterpolator: new LinearInterpolator({
          around: [event.offsetCenter.x, event.offsetCenter.y]
        }),
        transitionDuration: 700
      });
    }
  };

  const resetFeatures = () => {
    setFeature(null);
    setArea(null);
  };

  const handleFeature = (feature) => {
    if (feature?.layer?.id === 'areas') {
      setArea(feature);
    } else {
      setFeature(feature);
    }
  };
  const handleClickMarker = (feature) => {
    handleFeature(feature);
  };

  const handleClickMap = (event) => {
    event.preventDefault();
    resetFeatures();
    setEvent(event);
    const { features } = event;
    const clickedFeature =
      features && features.find((f) => layers && layers.includes(f.layer.id));
    if (clickedFeature) {
      handleFeature(clickedFeature);
    } else {
      resetFeatures();
    }
  };
  useEffect(() => {
    if (selectedFeature && Object.entries(selectedFeature).length > 0) {
      setFeature({
        type: 'Feature',
        layer: {
          id: selectedFeature.inventoryItemType
        },
        geometry: selectedFeature.location,
        properties: {
          ...selectedFeature
        }
      });
    }
  }, [selectedFeature]);

  useEffect(() => {
    selectedArea &&
      Object.entries(selectedArea).length > 0 &&
      setArea({
        type: 'Feature',
        layer: {
          id: 'areas'
        },
        geometry: selectedArea.location,
        properties: {
          id: selectedArea.id
        }
      });
  }, [selectedArea]);

  useEffect(() => {
    if (feature) {
      goToViewport(feature);
      setVegetationFilter(['in', 'id', feature.properties.id]);
      setSurfaceFilter(['in', 'id', feature.properties.id]);
      setSurfaceWithIssuesFilter(['in', 'id', feature.properties.id]);
      setFurnitureFilter(['in', 'id', feature.properties.id]);
      setPlaygroundFilter(['in', 'id', feature.properties.id]);
      setPointsWithIssuesFilter(['in', 'id', feature.properties.id]);
      setSensorFilter(['in', 'id', feature.properties.id]);
    } else {
      setVegetationFilter(['in', 'id', '']);
      setSurfaceFilter(['in', 'id', '']);
      setSurfaceWithIssuesFilter(['in', 'id', '']);
      setPlaygroundFilter(['in', 'id', '']);
      setPointsWithIssuesFilter(['in', 'id', '']);
      setFurnitureFilter(['in', 'id', '']);
      setSensorFilter(['in', 'id', '']);
    }
  }, [feature]);

  useEffect(() => {
    if (area && !selectedArea) {
      zoomToBounds(area, event);
      setAreasFilter(['in', 'id', area.properties.id]);
    } else {
      setAreasFilter(['in', 'id', '']);
    }
  }, [area, event]);

  useEffect(() => {
    let swBound =
      mapRef?.current &&
      mapRef.current.getMap().getBounds().getSouthWest().toArray();

    let seBound =
      mapRef?.current &&
      mapRef.current.getMap().getBounds().getSouthEast().toArray();
    let neBound =
      mapRef?.current &&
      mapRef.current.getMap().getBounds().getNorthEast().toArray();

    let nwBound =
      mapRef?.current &&
      mapRef.current.getMap().getBounds().getNorthWest().toArray();

    let newBounds = mapRef?.current && [
      seBound,
      neBound,
      nwBound,
      swBound,
      seBound
    ];
    abortController.abort();
    mapRef && setBounds(newBounds);
  }, [viewport, mapRef]);

  useEffect(() => {
    let newZoom =
      mapRef?.current && mapRef.current.getMap().getZoom().toFixed(0);
    if (newZoom) {
      setZoom(Number(newZoom));
    }
  }, [viewport, mapRef]);

  return (
    <Paper className={classes.root}>
      {data && (
        <MapGL
          ref={mapRef}
          {...viewport}
          minZoom={5}
          width="100%"
          height="100%"
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          onViewportChange={handleViewportChange}
          onClick={handleClickMap}>
          <GeolocateControl
            style={geolocateStyle}
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />

          {layers.includes('areas') && (
            <Source type="geojson" data={areasSource}>
              <Layer {...areasLayer} />
              <Layer {...areasOutlineLayer} />
              <Layer {...selectedAreaLayer} filter={areasFilter} />
              <Layer {...selectedAreaOutlineLayer} filter={areasFilter} />
            </Source>
          )}
          {layers.includes('vegetation') && (
            <Markers
              data={vegetationSource}
              type="vegetation"
              onClick={handleClickMarker}
            />
          )}
          {layers.includes('furniture') && (
            <Markers
              data={furnitureSource}
              type="furniture"
              onClick={handleClickMarker}
            />
          )}
          {layers.includes('playground') && (
            <Markers
              data={playgroundSource}
              type="playground"
              onClick={handleClickMarker}
            />
          )}

          {layers.includes('sensors') && (
            <Markers
              data={sensorSource}
              type="sensor"
              onClick={handleClickMarker}
            />
          )}
          {feature && <Markers data={[feature]} type="selected" />}
          {layers.includes('issues') && (
            <Source type="geojson" data={pointsWithIssuesSource}>
              <Layer {...pointsWithIssuesLayer} minzoom={14} />
              <Layer
                {...selectedPointWithIssuesLayer}
                filter={pointsWithIssuesFilter}
                minzoom={14}
              />
            </Source>
          )}
        </MapGL>
      )}

      {feature && Object.entries(feature).length > 0 && (
        <FeaturePanel
          feature={feature.properties.id}
          layer={feature?.layer?.id || feature.properties.inventoryItemType}
        />
      )}
      {area && Object.entries(area).length > 0 && area.layer && (
        <AreaPanel id={area.properties.id} geometry={area.geometry} />
      )}
      <Alert message={error?.message} show={error} />
    </Paper>
  );
};

export default Map;
