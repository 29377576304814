import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';
import moment from 'moment';
import { getPriority } from 'utils/issues';
import Link from 'components/Link';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 300,
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 }
}));

const Features = props => {
  const classes = useStyles();
  const {
    organization,
    informant,
    processor,
    priority,
    elemento_inventario,
    scheduled_at
  } = props;
  return (
    <Card className={classes.root}>
      <CardHeader title="Datos" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Prioridad</TableCell>
              <TableCell className={classes.data}>
                {priority ? getPriority(priority) : 'Desconocido'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Elemento</TableCell>
              <TableCell className={classes.data}>
                {elemento_inventario ? (
                  <Link
                    title={elemento_inventario.name}
                    to={
                      elemento_inventario.inventoryItemType === 'sensor'
                        ? `/sensors/${elemento_inventario.id}`
                        : `/inventory/${elemento_inventario.id}`
                    }
                  />
                ) : (
                  'Desconocido'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Organización</TableCell>
              <TableCell className={classes.data}>
                {organization ? (
                  <Link
                    title={organization.name}
                    to={`/users/organizations/${organization.id}`}
                  />
                ) : (
                  'Desconocido'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Informador</TableCell>
              <TableCell className={classes.data}>
                {informant ? (
                  <Link
                    title={`${informant.name} ${informant.surname}`}
                    to={`/users/workers/${informant.id}`}
                  />
                ) : (
                  'Desconocido'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Programado</TableCell>
              <TableCell className={classes.data}>
                {scheduled_at
                  ? moment(scheduled_at).format('DD/MM/YYYY')
                  : 'No'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Features;
