import React from 'react';
import PalmTree, { SelectedPalmTree } from 'assets/icons/PalmTree';
import Tree, { SelectedTree } from 'assets/icons/Tree';
import Sensor, { SelectedSensor } from 'assets/icons/Sensor';
import Playground, { SelectedPlayground } from 'assets/icons/Playground';
import Surface, { SelectedVegetation } from 'assets/icons/Vegetation';
import Furniture, { SelectedFurniture } from 'assets/icons/Furniture';
import Vegetation from 'assets/icons/Vegetation';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
const selectedIcons = {
  vegetation: <SelectedVegetation />,
  Arbusto: <SelectedVegetation />,
  Árbol: <SelectedTree />,
  Palmera: <SelectedPalmTree />,
  sensor: <SelectedSensor />,
  'Superficie vegetal': <SelectedVegetation />,
  playground: <SelectedPlayground />,
  furniture: <SelectedFurniture />
};

function renderIcons({ type, feature = {}, onClick = () => {} }) {
  const icons = {
    vegetation: <Vegetation onClick={() => onClick(feature)} />,
    Arbusto: <Vegetation onClick={() => onClick(feature)} />,
    Árbol: <Tree onClick={() => onClick(feature)} />,
    Palmera: <PalmTree onClick={() => onClick(feature)} />,
    sensor: <Sensor onClick={() => onClick(feature)} />,
    'Superficie vegetal': <Surface onClick={() => onClick(feature)} />,
    playground: <Playground onClick={() => onClick(feature)} />,
    furniture: <Furniture onClick={() => onClick(feature)} />,
    selected:
      selectedIcons[
        feature?.properties?.subtype ||
          feature?.properties?.type ||
          feature?.properties?.inventoryItemType
      ]
  };
  return <>{icons[type] ? icons[type] : null}</>;
}

export default renderIcons;
