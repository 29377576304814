import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  GET_MAINTENANCES,
  GET_MAINTENANCES_BY_INVENTORY_ITEM
} from '../queries';

export const UPDATE_MAINTENANCE = gql`
  mutation updateMaintenance(
    $id: Int!
    $title: String
    $description: String
    $scheduled_at: timestamp
    $organization_id: Int
    $resource_id: Int
    $state: String
    $priority: String
    $type: String
  ) {
    update_maintenance(
      where: { id: { _eq: $id } }
      _set: {
        title: $title
        description: $description
        scheduled_at: $scheduled_at
        organization_id: $organization_id
        resource_id: $resource_id
        state: $state
        priority: $priority
        type: $type
      }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateMaintenance = () => {
  let [mutate] = useMutation(UPDATE_MAINTENANCE);

  return args => {
    const {
      id,
      priority,
      state,
      organization_id,
      resource_id,
      description,
      title,
      scheduled_at,
      type
    } = args;
    return mutate({
      variables: {
        id,
        priority,
        state,
        organization_id,
        resource_id,
        description,
        title,
        scheduled_at,
        type
      },
      refetchQueries: [
        { query: GET_MAINTENANCES },
        {
          query: GET_MAINTENANCES_BY_INVENTORY_ITEM,
          variables: { inventoryItemId: resource_id }
        }
      ]
    });
  };
};
