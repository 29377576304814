import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, colors } from '@material-ui/core';
import View from 'components/View';
import {
  useGetWorkerDetails,
  useUsersState,
  useUsersDispatch
} from 'modules/users';
import Actions from './Actions';
import ConfirmDelete from './ConfirmDelete';
import Summary from './Summary';
import WorkerModal from 'views/WorkerModal';
import WorkerUploadModal from 'views/WorkerUploadModal';
import Alert from 'components/Alert';
import WebUserModal from './WebUserModal';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const WorkerDetails = () => {
  const classes = useStyles();
  const { data, loading } = useGetWorkerDetails();
  const { alert } = useUsersState();
  const { closeAlert } = useUsersDispatch();
  return (
    <View
      title="Trabajador"
      loading={loading}
      subtitle={data?.worker && `#${data.worker.id}`}
      actions={<Actions worker={data?.worker} />}>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Summary worker={data?.worker} />
      </div>
      <ConfirmDelete />
      <WorkerModal />
      <WorkerUploadModal />
      <WebUserModal />
      <Alert
        show={alert}
        message={alert?.message}
        severity={alert?.severity}
        fireOnClose={() => closeAlert()}
      />
    </View>
  );
};

export default WorkerDetails;
