import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Divider
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 300,
    height: '100%',
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 }
}));

const Features = (props) => {
  const classes = useStyles();
  const {
    commonName,
    species,
    plantingDate,
    lastUpdateDate
  } = props.data.vegetacion_inventariables[0];
  return (
    <Card className={classes.root}>
      <CardHeader title="Datos" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Nombre común</TableCell>
              <TableCell className={classes.data}>{commonName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Nombre científico</TableCell>
              <TableCell className={classes.data}>{species}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Plantación</TableCell>
              <TableCell className={classes.data}>
                {plantingDate
                  ? moment(plantingDate).format('DD/MM/YYYY')
                  : 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ultima modificación</TableCell>
              <TableCell className={classes.data}>
                {' '}
                {lastUpdateDate
                  ? moment(lastUpdateDate).format('DD/MM/YYYY')
                  : 'Desconocido'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Features;
