import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_WORKER_DETAILS } from '../queries';
import { useUsersDispatch } from 'modules/users/hooks';

export const ADD_WORKER_AVATAR = gql`
  mutation addWorkerAvatar($objects: [file_insert_input!]!) {
    insert_file(objects: $objects) {
      affected_rows
    }
  }
`;

export const useAddWorkerAvatar = () => {
  const { openSuccessAlert, openErrorAlert } = useUsersDispatch();
  let [mutate, { data, error, loading }] = useMutation(ADD_WORKER_AVATAR, {
    onCompleted: () => openSuccessAlert('Avatar creado correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });
  return ({ file = {}, userId = 0 }) => {
    return mutate({
      variables: {
        objects: {
          ...file,
          user_id: userId
        }
      },
      refetchQueries: [
        {
          query: GET_WORKER_DETAILS,
          variables: { id: userId }
        }
      ]
    });
  };
};
