import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { CHAT_FRAGMENT } from '../fragments';
import {
  ORGANIZATION_FRAGMENT,
  WORKER_FRAGMENT
} from 'modules/users/graphql/fragments';
import { useInfo, useRoles } from 'modules/authentication';

export const GET_CHAT_USERS = gql`
  query getChatUsers($id: Int, $orgId: Int) {
    chats: chat(where: { chat_users: { user_id: { _eq: $id } } }) {
      id
      existingChatUsers: chat_users(where: { user_id: { _neq: $id } }) {
        user_id
        organization_user {
          id
          name
          surname
        }
      }
    }
    organizations: organization( where: { id: { _eq: $orgId } }
      order_by: { id: asc }) {
      ...organizationFields
      workers: organization_users(
        where: { id: { _neq: $id } }
        order_by: { id: asc }
      ) {
        ...workerFields
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
  ${WORKER_FRAGMENT}
  ${CHAT_FRAGMENT}
`;

export const useGetChatUsers = () => {
  const { userId, orgId } = useInfo();
  const { chat__worker, chat__administrator, chat__organization } = useRoles();
  const { data, error, loading } = useQuery(GET_CHAT_USERS, {
    variables: { id: userId, orgId },
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        'x-hasura-role': chat__administrator
          ? 'chat__administrator'
          : chat__organization
            ? 'chat__organization'
            : chat__worker
              ? 'chat__worker'
              : undefined
      }
    }
  });
  return {
    data,
    error,
    loading
  };
};

