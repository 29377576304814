import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_WORK_ORDER_DETAILS } from '../queries';
import { useIssuesDispatch } from 'modules/issues';

export const UPDATE_TASK = gql`
  mutation updateTask(
    $id: Int!
    $title: String
    $description: String
    $due_to_date: timestamp
    $in_charge_user_id: Int
    $state: String
  ) {
    update_work_order_task(
      where: { id: { _eq: $id } }
      _set: {
        title: $title
        description: $description
        due_to_date: $due_to_date
        in_charge_user_id: $in_charge_user_id
        state: $state
      }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateTask = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(UPDATE_TASK, {
    onCompleted: () => openSuccessAlert('Tarea actualizada correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });
  return args => {
    const {
      id,
      title,
      description,
      due_to_date,
      in_charge_user_id,
      state,
      work_order_id
    } = args;
    return mutate({
      variables: {
        id,
        title,
        description,
        due_to_date,
        in_charge_user_id,
        state
      },
      refetchQueries: [
        {
          query: GET_WORK_ORDER_DETAILS,
          variables: { id: args.work_order_id }
        }
      ]
    });
  };
};
