import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  ListItemText,
  CardHeader,
  Divider,
  CardContent,
  Card
} from '@material-ui/core';
import { useGetProfilesByOrganization } from 'modules/users';
import Alert from 'components/Alert';
import Loader from 'components/Loader';

const useStyles = makeStyles(theme => ({
  root: { height: '100%' },
  content: { padding: 0 },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  arrowForwardIcon: {
    color: theme.palette.icon
  }
}));

const Profiles = ({ profiles = [] }) => {
  const classes = useStyles();
  const { data, loading, error } = useGetProfilesByOrganization();
  if (loading) return <Loader />;
  if (error) return <Alert show={error} />;
  return (
    <Card className={classes.root}>
      <CardHeader title="Perfiles disponibles" />
      <Divider />
      <CardContent className={classes.content}>
        <List disablePadding>
          {profiles.map(({ profile }, index) => (
            <ListItem
              className={classes.listItem}
              divider={index < profiles.length}
              key={profile.id}>
              <ListItemText
                primary={profile.name}
                primaryTypographyProps={{ variant: 'body1' }}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default Profiles;
