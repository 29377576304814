import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import useClasses from 'hooks/useClasses';
import { useRoles } from 'modules/authentication';
import Link from 'components/Link';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '60px',
    right: '10px',
    maxWidth: '320px'
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
});

const Summary = props => {
  const { name, address, id, inventoryItemType } = props;
  const classes = useClasses(styles);
  const { sensors__administrator } = useRoles();
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Nombre</TableCell>
          <TableCell className={classes.data}>
            {sensors__administrator ? (
              <Link
                title={name}
                to={`${
                  inventoryItemType === 'sensor' ? 'sensors' : 'inventory'
                }/${id}`}
              />
            ) : (
              name
            )}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Dirección</TableCell>
          <TableCell className={classes.data}>
            {address || 'Desconocido'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default Summary;
