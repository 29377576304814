import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useGetProfilesByOrganization } from 'modules/users';
import Loader from 'components/Loader';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Summary = ({ organizationId = 0 }) => {
  const { data, loading } = useGetProfilesByOrganization(organizationId);
  const { values, handleChange, setFieldValue } = useFormikContext();
  const [tempProfiles, setTempProfiles] = useState([]);
  const { name, description, profiles, code, email } = values;
  useEffect(() => {
    let newProfiles = data
      ? data.profiles.filter(
          ({ id }) => !profiles.some((profile) => profile.id === id)
        )
      : [];
    setTempProfiles([...profiles, ...newProfiles]);
  }, [profiles, data]);

  if (loading) return <Loader />;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nombre"
            name="name"
            value={name}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Código"
            name="code"
            value={code}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={email}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Descripción"
            name="description"
            value={description}
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={3}>
            {description}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            value={profiles}
            name="profiles"
            options={tempProfiles}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => setFieldValue('profiles', value)}
            renderOption={(option, { selected }) => {
              return (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </React.Fragment>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  label="Perfiles"
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Summary;
