import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableCell,
  TableBody,
  TableRow
} from '@material-ui/core';
import { useGetInventoryItemDetails } from 'modules/inventory/graphql';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  },
  data: { fontWeight: 600 }
}));

const Dentrometry = () => {
  const classes = useStyles();
  const { data } = useGetInventoryItemDetails();
  const {
    dd_perimetro_tronco_cm,
    dd_diam_copa_cm,
    dd_altura_total_m,
    dd_altura_primera_rama_m
  } = data.inventoryItem.vegetacion_inventariables[0].arbol_inventariables[0];
  return (
    <Card className={classes.root}>
      <CardHeader title="Dentrometría" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Perímetro tronco</TableCell>
              <TableCell className={classes.data}>{`${dd_perimetro_tronco_cm ||
                ''} cm`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Diámetro copa</TableCell>
              <TableCell className={classes.data}>{`${dd_diam_copa_cm ||
                ''} cm`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Altura total</TableCell>
              <TableCell className={classes.data}>{`${dd_altura_total_m ||
                ''} m`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Altura primera rama</TableCell>
              <TableCell
                className={classes.data}>{`${dd_altura_primera_rama_m ||
                ''} m`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Dentrometry;
