import { useDispatch } from 'react-redux';
import * as slices from '../redux';

export function useMaintenancesDispatch() {
  const dispatch = useDispatch();
  return {
    loadMaintenances: () => dispatch(slices.loadMaintenances()),
    selectMaintenance: payload => dispatch(slices.selectMaintenance(payload)),
    openMaintenanceModal: payload =>
      dispatch(slices.openMaintenanceModal(payload)),
    closeMaintenanceModal: () => dispatch(slices.closeMaintenanceModal()),
    openDeleteMaintenanceModal: payload =>
      dispatch(slices.openDeleteMaintenanceModal(payload)),
    closeDeleteMaintenanceModal: () =>
      dispatch(slices.closeDeleteMaintenanceModal()),
    selectTask: payload => dispatch(slices.selectTask(payload)),
    openTaskModal: payload => dispatch(slices.openTaskModal(payload)),
    closeTaskModal: () => dispatch(slices.closeTaskModal()),
    openDeleteTaskModal: payload =>
      dispatch(slices.openDeleteTaskModal(payload)),
    closeDeleteTaskModal: () => dispatch(slices.closeDeleteTaskModal())
  };
}
