export const areasLayer = {
  id: 'areas',
  type: 'fill',
  paint: {
    'fill-color': '#1DA1F2',
    'fill-opacity': 0.1,
    'fill-antialias': true
  }
};
export const areasOutlineLayer = {
  id: 'areasOutline',
  source: 'areas',
  type: 'line',
  paint: {
    'line-width': 2,
    'line-color': '#1DA1F2'
  }
};

export const selectedAreaLayer = {
  id: 'selectedArea',
  source: 'areas',
  type: 'fill',
  paint: {
    'fill-color': '#FBB03B',
    'fill-opacity': 0.1,
    'fill-antialias': true
  }
};

export const selectedAreaOutlineLayer = {
  id: 'selectedAreaOutline',
  source: 'areas',
  type: 'line',
  paint: {
    'line-width': 2,
    'line-color': '#FBB03B'
  }
};
