import axios from 'axios';
import { getAuthHeader } from 'modules/authentication';

export const importInventory = async ({
  type,
  file,
  callback = () => {},
  errorCallback = () => {}
}) => {
  try {
    const token = JSON.parse(getAuthHeader())?.token;
    const formData = new FormData();
    formData.append('payload', file);
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/excel/' + type,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (response.status === 200) {
      callback('Elementos importados correctamente');
    } else {
      errorCallback('Error al importar los elementos.');
    }
  } catch (error) {
    errorCallback('Error al importar los elementos.');
  }
};

export const exportInventory = async ({
  type,
  callback = () => {},
  errorCallback = () => {}
}) => {
  try {
    const token = JSON.parse(getAuthHeader())?.token;

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + '/excel/' + type,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (response.status === 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download =
        'exportacion_inventario' + new Date().toISOString() + '.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
      callback('Elementos exportados correctamente');
    } else {
      errorCallback('Error al exportar los elementos.');
    }
  } catch (error) {
    errorCallback('Error al exportar los elementos.');
  }
};
