import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_WORKER_DETAILS } from '../queries';
import { useUsersDispatch } from 'modules/users/hooks';

export const UPDATE_WORKER_AVATAR = gql`
  mutation updateWorkerAvatar($id: Int!, $url: String, $name: String) {
    update_file(where: { id: { _eq: $id } }, _set: { url: $url, name: $name }) {
      affected_rows
    }
  }
`;

export const useUpdateWorkerAvatar = () => {
  const { openSuccessAlert, openErrorAlert } = useUsersDispatch();
  let [mutate, { data, error, loading }] = useMutation(UPDATE_WORKER_AVATAR, {
    onCompleted: () => openSuccessAlert('Avatar actualizado correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });

  return ({ file, userId }) => {
    return mutate({
      variables: {
        id: file.id,
        url: file.url,
        name: file.name
      },
      refetchQueries: [
        {
          query: GET_WORKER_DETAILS,
          variables: { id: userId }
        }
      ]
    });
  };
};
