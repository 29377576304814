import React, { useState } from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  CardHeader,
  Divider,
  Button
} from '@material-ui/core';
import useClasses from 'hooks/useClasses';
import SecurityModal from './SecurityModal';
import Alert from 'components/Alert';

const styles = theme => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 },
  password: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3)
  }
});

const Security = () => {
  const classes = useClasses(styles);
  const [showSecurityModal, setShowSecurityModal] = useState(false);
  const [showMessageSuccess, setShowMessageSuccess] = useState(null);
  const [showMessageError, setShowMessageError] = useState(null);
  return (
    <>
      <Card className={classes.root}>
        <CardHeader title="Seguridad" />
        <Divider />
        <CardContent className={classes.content}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Contraseña</TableCell>
                <TableCell>
                  <div className={classes.password}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setShowSecurityModal(true)}>
                      Actualizar
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
        <SecurityModal
          open={showSecurityModal}
          handleClose={() => setShowSecurityModal(false)}
          userId={1}
          setShowMessageSuccess={setShowMessageSuccess}
          setShowMessageError={setShowMessageError}
        />
      </Card>
      <Alert
        show={showMessageSuccess}
        message={showMessageSuccess}
        severity="success"
        fireOnClose={() => setShowMessageSuccess(null)}
      />
      <Alert
        show={showMessageError}
        message={showMessageError}
        severity="error"
        fireOnClose={() => setShowMessageError(null)}
      />
    </>
  );
};

export default Security;
