import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Grid
} from '@material-ui/core';
import { parseObservation } from 'utils/sensors';
import SearchBar from '../SearchBar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 48,
    width: 48
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  item: {
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const SensorType = ({
  datastream,
  currentObservation,
  observations,
  onChange
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Grid container>
        <Grid item xs={8}>
          <div>
            <Typography component="h6" gutterBottom variant="overline">
              Tipo
            </Typography>
            <div className={classes.details}>
              <FormControl className={classes.formControl}>
                <Select
                  value={currentObservation}
                  onChange={event => onChange(event.target.value)}>
                  {observations.map(({ observation, index }) => (
                    <MenuItem value={index}>
                      <Typography variant="h5">
                        {parseObservation(observation)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'stretch',
              height: '100%'
            }}>
            {/*<SearchBar datastream={datastream} />*/}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

SensorType.propTypes = {
  className: PropTypes.string
};

export default SensorType;
