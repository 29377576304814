import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_ISSUE_DETAILS } from '../queries';
import { useIssuesDispatch } from 'modules/issues';

export const ADD_FILES = gql`
  mutation addFile($objects: [file_insert_input!]!) {
    insert_file(objects: $objects) {
      affected_rows
    }
  }
`;

export const useAddFiles = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(ADD_FILES, {
    onCompleted: () => openSuccessAlert('Imágenes añadidas correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });
  return ({ files = [], issueId }) => {
    return mutate({
      variables: {
        objects: files.map(file => ({
          ...file,
          issue_id: issueId
        }))
      },
      refetchQueries: [
        {
          query: GET_ISSUE_DETAILS,
          variables: { id: issueId }
        }
      ]
    });
  };
};
