import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_ISSUE_DETAILS } from '../queries';
import { useIssuesDispatch } from 'modules/issues';

export const UPDATE_ISSUE_FILE = gql`
  mutation updateIssueFile($id: Int!, $url: String, $name: String) {
    update_file(where: { id: { _eq: $id } }, _set: { url: $url, name: $name }) {
      affected_rows
    }
  }
`;

export const useUpdateIssueFile = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(UPDATE_ISSUE_FILE, {
    onCompleted: () => openSuccessAlert('Imagen actualizada correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });

  return ({ file, issueId }) => {
    return mutate({
      variables: {
        id: file.id,
        url: file.url,
        name: file.name
      },
      refetchQueries: [
        {
          query: GET_ISSUE_DETAILS,
          variables: { id: issueId }
        }
      ]
    });
  };
};
