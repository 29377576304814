import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Tabs,
  Tab,
  Divider,
  colors,
  Tooltip,
  IconButton
} from '@material-ui/core';
import View from 'components/View';
import Board from './Board';
import ToDo from './ToDo';
import InProgress from './InProgress';
import Done from './Done';
import Loader from 'components/Loader';
import ConfirmDelete from './ConfirmDelete';
import IssueModal from 'views/IssueModal';
import {
  useIssuesDispatch,
  useGetIssues,
  useIssuesState
} from 'modules/issues';
import AddIcon from '@material-ui/icons/Add';
import useTabs from 'hooks/useTabs';
import { useRoles } from 'modules/authentication';
import Alert from 'components/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  tabs: {
    fontWeight: 600,
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const Issues = () => {
  const classes = useStyles();
  const { issues__insert } = useRoles();
  const [currentTab, handleTabs] = useTabs('board');
  const { data, loading, error } = useGetIssues();
  const { openIssueModal, closeAlert } = useIssuesDispatch();
  const { alert } = useIssuesState();
  const tabs = [
    { value: 'board', label: 'Tablero' },
    { value: 'toDo', label: 'Pendiente' },
    { value: 'inProgress', label: 'En progreso' },
    { value: 'done', label: 'Completado' }
  ];

  return (
    <View
      title={'Incidencias'}
      loading={loading}
      error={error}
      actions={null/*
        issues__insert && (
          <Tooltip title="Nueva incidencia">
            <IconButton onClick={() => openIssueModal()} size="small">
              <AddIcon />
            </IconButton>
          </Tooltip>
        )
        */}>
      <Tabs
        className={classes.tabs}
        onChange={handleTabs}
        scrollButtons="auto"
        value={currentTab}
        variant="scrollable"
        indicatorColor="primary">
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {loading && <Loader />}
        {data && currentTab === 'board' && <Board issues={data.issues} />}
        {data && currentTab === 'toDo' && <ToDo issues={data.toDo} />}
        {data && currentTab === 'inProgress' && (
          <InProgress issues={data.inProgress} />
        )}
        {data && currentTab === 'done' && <Done issues={data.done} />}
      </div>
      <IssueModal />
      <ConfirmDelete />
      <Alert
        show={alert}
        message={alert?.message}
        severity={alert?.severity}
        fireOnClose={() => closeAlert()}
      />
    </View>
  );
};

export default Issues;
