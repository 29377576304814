import {
  useAddMaintenance,
  useUpdateMaintenance,
  useDeleteMaintenance,
  useAddTask,
  useUpdateTask,
  useDeleteTask
} from '../graphql';

export const useMaintenancesMutation = () => {
  const addMaintenance = useAddMaintenance();
  const updateMaintenance = useUpdateMaintenance();
  const deleteMaintenance = useDeleteMaintenance();
  const addTask = useAddTask();
  const updateTask = useUpdateTask();
  const deleteTask = useDeleteTask();
  return {
    addMaintenance,
    updateMaintenance,
    deleteMaintenance,
    addTask,
    updateTask,
    deleteTask
  };
};
