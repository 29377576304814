import React, { useState } from 'react';
import { Divider, Tabs, Tab, Tooltip, IconButton } from '@material-ui/core';
import SensorModal from 'views/SensorModal';
import View from 'components/View';
import Table from 'components/Table';
import columns from './columns';
import {
  useGetSensors,
  useSensorsDispatch,
  useSensorsState
} from 'modules/sensors';
import AddIcon from '@material-ui/icons/Add';
import styles from './styles';
import useClasses from 'hooks/useClasses';
import ConfirmDelete from './ConfirmDelete';
import { useRoles } from 'modules/authentication';
import Alert from 'components/Alert';
import SearchInput from 'components/SearchInput';

const tabs = [
  { value: 'all', label: 'Todo' },
  { value: 'airTemperature', label: 'Temperatura' },
  { value: 'relativeHumidity', label: 'Humedad' },
  { value: 'airPressure', label: 'Presión' },
  { value: 'waterTemperature', label: 'Temperatura del agua' }
];

const Sensors = () => {
  const classes = useClasses(styles);
  const { sensors__insert } = useRoles();
  const { alert } = useSensorsState();
  const [currentTab, setCurrentTab] = useState('all');
  const {
    data,
    loading,
    error,
    count,
    search,
    handleSearch,
    ...tableProps
  } = useGetSensors(currentTab);
  const { openSensorModal, closeAlert } = useSensorsDispatch();

  const handleTabsChange = (event, value) => {
    event.preventDefault();
    setCurrentTab(value);
  };

  return (
    <View
      title="Sensores"
      error={error}
      actions={
        <>
          <SearchInput
            value={search}
            onChange={(event) => handleSearch(event.target.value)}
          />
          {sensors__insert && (
            <Tooltip title="Añadir sensor">
              <IconButton size="small" onClick={() => openSensorModal()}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      }>
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={currentTab}
        variant="scrollable"
        indicatorColor="primary">
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Table
          columns={columns}
          data={data}
          loading={loading}
          count={count}
          {...tableProps}
        />
      </div>
      <Alert
        show={alert}
        message={alert?.message}
        severity={alert?.severity}
        fireOnClose={() => closeAlert()}
      />
      <SensorModal />
      <ConfirmDelete />
    </View>
  );
};

export default Sensors;
