import React, { useState, useEffect } from 'react';
import MapGL, { Layer, Source, WebMercatorViewport } from 'react-map-gl';
import { areasLayer, areasOutlineLayer, useLayers } from 'modules/map';
import bbox from '@turf/bbox';
import Markers from 'components/Markers';
import { useGetInventoryByArea } from 'modules/inventory';

const Map = ({ location = {} }) => {
  const { data: inventory } = useGetInventoryByArea(location, {
    noLimit: true
  });
  const initialViewport = {
    longitude: 0,
    latitude: 0,
    zoom: 18,
    pitch: 0
  };

  const [viewport, setViewport] = useState(initialViewport);
  const updateViewport = (viewport) => {
    setViewport(viewport);
  };
  const zoomToBounds = (event, location) => {
    if (location) {
      const [minLng, minLat, maxLng, maxLat] = bbox(location);
      const newViewport = new WebMercatorViewport(viewport);
      const { longitude, latitude } = newViewport.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat]
        ],
        {
          //padding: 20,
          //offset: [0, -100]
        }
      );
      setViewport({
        ...viewport,
       longitude,
       latitude
      });
    }
  };

  const {
    area,
    setArea,
    sensors,
    setSensors,
    furniture,
    setFurniture,
    playground,
    setPlayground,
    vegetation,
    setVegetation
  } = useLayers();

  useEffect(() => {
    let newArea = {
      type: 'Feature',
      geometry: location
    };
    setArea(newArea);
  }, [location, setArea]);

  useEffect(() => {
    if (inventory?.length > 0) {
      setSensors(
        inventory
          .filter(({ inventoryItemType }) => inventoryItemType === 'sensor')
          .map((item) => ({
            properties: { id: item.id, type: 'sensor' },
            type: 'Feature',

            geometry: item.location
          }))
      );

      setVegetation(
        inventory
          .filter(({ inventoryItemType }) => inventoryItemType === 'vegetation')
          .map((item) => ({
            properties: {
              id: item.id,
              type: 'vegetation',
              subtype: item.vegetacion_inventariables?.[0]?.vegetationType
            },
            type: 'Feature',
            geometry: item.location
          }))
      );

      setFurniture(
        inventory
          .filter(({ inventoryItemType }) => inventoryItemType === 'furniture')
          .map((item) => ({
            properties: { id: item.id, type: 'furniture' },
            type: 'Feature',
            geometry: item.location
          }))
      );

      setPlayground(
        inventory
          .filter(({ inventoryItemType }) => inventoryItemType === 'playground')
          .map((item) => ({
            properties: { id: item.id, type: 'playground' },
            type: 'Feature',
            geometry: item.location
          }))
      );
    }
  }, [inventory, setPlayground, setVegetation, setFurniture, setSensors]);

  return (
    <MapGL
      {...viewport}
      style={{
        minHeight: 750,
        borderRadius: 4,
        boxShadow: 'rgba(63,63,68,.05) 0 0 0 1px,rgba(63,63,68,.15) 0 1px 3px 0'
      }}
      width="100%"
      height="100%"
      onLoad={(event) => zoomToBounds(event, location)}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
      onViewportChange={updateViewport}>
      <Source type="geojson" data={area}>
        <Layer {...areasLayer} />
        <Layer {...areasOutlineLayer} />
      </Source>
      <Markers data={sensors} type="sensor" />
      <Markers data={furniture} type="furniture" />
      <Markers data={vegetation} type="vegetation" />
      <Markers data={playground} type="playground" />
    </MapGL>
  );
};

export default Map;
