import React from 'react';
import Link from 'components/Link';
import Table from 'components/Table';
import Actions from './Actions';
import { IconButton, Tooltip, Avatar } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useUsersDispatch, useGetOrganizationDetails } from 'modules/users';
import { useRoles } from 'modules/authentication';
import { getInitials } from 'utils/users';

const columns = [
  {
    Header: 'Nombre',
    accessor: data => data,
    Cell: ({ cell: { value } }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{ height: 50, width: 50, marginRight: 10 }}
          src={value.file?.url}>
          {getInitials(`${value.name} ${value.surname}`)}
        </Avatar>
        <div>
          <Link
            to={`/users/workers/${value.id}`}
            title={`${value.name} ${value.surname}`}
          />
          <div>{value.email}</div>
        </div>
      </div>
    )
  },
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Código',
    accessor: 'external_id'
  },
  {
    Header: 'Perfil',
    accessor: 'profile.name'
  },
  {
    Header: '',
    id: 'actions',
    accessor: data => data,
    Cell: ({ cell: { value } }) => <Actions worker={value} />
  }
];

const Workers = ({ organization }) => {
  const { openWorkerModal } = useUsersDispatch();
  const { users__insert } = useRoles();
  const { data, loading, ...rest } = useGetOrganizationDetails();
  return (
    <Table
      title="Trabajadores"
      data={data?.organization?.workers}
      loading={loading}
      columns={columns}
      count={data?.organization?.workersCount?.aggregate.count}
      {...rest}
      action={
        users__insert && (
          <Tooltip title="Nuevo trabajador">
            <IconButton
              size="small"
              onClick={() => openWorkerModal({ organization })}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )
      }
    />
  );
};

export default Workers;
