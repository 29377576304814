import React from 'react';
import { colors } from '@material-ui/core';
import Link from 'components/Link';
import Actions from './Actions';
import Label from 'components/Label';
import { getObservations } from 'utils/sensors';

export default [
  {
    Header: 'Nombre',
    accessor: (data) => data,
    Cell: ({ cell: { value } }) => (
      <Link title={value.name} to={`/sensors/${value.id}`} />
    )
  },
  {
    Header: 'ID',
    accessor: 'id'
  },

  {
    Header: 'Observaciones',
    accessor: (data) => data,
    Cell: ({ cell: { value } }) => getObservations(value?.sensor?.datastreams)
  },
  {
    Header: 'Dirección',
    accessor: 'address'
  },
  {
    Header: 'Estado',
    accessor: (data) => data,
    Cell: ({ cell: { value } }) => (
      <Label
        color={
          value?.sensor?.status?.length > 0
            ? colors.green[700]
            //TODO harcode until fix 
            //: colors.red[700]
            : colors.green[700]
        }
        variant="outlined">
        {value?.sensor?.status?.length > 0 
          ? 'Activo' 
          // hardcode until fix
          //: 'Error'
          : 'Activo' 
        }
      </Label>
    )
  },
  {
    Header: '',
    id: 'actions',
    accessor: (data) => data,
    Cell: ({ cell: { value } }) => <Actions sensor={value} />
  }
];
