import { setContext } from 'apollo-link-context';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import {
  HttpLink,
  ApolloLink,
  split,
  ApolloClient,
  InMemoryCache
} from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { getAuthHeader, logOut } from 'modules/authentication/api';

const httpUri = process.env.REACT_APP_GRAPHQL_BACKEND_URL;
const wsUri = httpUri.replace(
  /^https?/,
  process.env.REACT_APP_ENV === 'dev' ? 'ws' : 'wss'
);

const httpLink = new HttpLink({
  uri: httpUri
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, extensions }) => {
        switch (extensions.code) {
          case 'invalid-jwt':
            /*
            const oldHeaders = operation.getContext().headers;
            const token = getAuthHeader();
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${token}`
              }
            });
            */
            logOut();
            // retry the request, returning the new observable
            return forward(operation);
          default:
            // default case
            console.log(message);
            console.log(extensions.code);
            return null;
          //logOut();
        }
      });
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: () => {
      const token = JSON.parse(getAuthHeader())?.token;
      return {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      };
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const token = JSON.parse(getAuthHeader())?.token;
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  };
});

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  errorLink.concat(authLink.concat(httpLink))
);

const link = ApolloLink.from([terminatingLink]);
const cache = new InMemoryCache();

export default new ApolloClient({
  link,
  cache,
  queryDeduplication: false
});
