import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useGetAreaDetails } from 'modules/map';
import Inventory from './Inventory';
import Loader from 'components/Loader';
import Alert from 'components/Alert';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '60px',
    right: '10px',
    minWidth: 300,
    maxWidth: '480px'
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 },
  actions: {
    justifyContent: 'flex-end'
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const AreaPanel = ({ id = 0, geometry }) => {
  const {
    data: {
      area = {},
      inventoryWithIssues = [],
      inventoryWithoutIssues = []
    } = {},
    loading,
    error
  } = useGetAreaDetails(id, geometry);
  const classes = useStyles();

  if (loading) return <Loader />;
  if (error) return <Alert error={error.message} show={error} />;
  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        title={`Área: ${area?.name}`}
        action={
          <Tooltip title="Ver más">
            <IconButton
              component={RouterLink}
              size="small"
              to={`inventory/areas/${area?.id}`}>
              <NavigateNextIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Divider />
      <CardContent className={classes.content}>
        <Inventory
          inventoryWithIssues={inventoryWithIssues}
          inventoryWithoutIssues={inventoryWithoutIssues}
        />
      </CardContent>
    </Card>
  );
};

export default AreaPanel;
