import axios from 'axios';
import { getAuthHeader } from 'modules/authentication';

export const uploadFile = async file => {
  const formData = new FormData();
  const token = JSON.parse(getAuthHeader())?.token;
  formData.append('picture', file);
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/documents`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
  );
  return {
    name: file.name,
    size: file.size,
    url: response.data
  };
};
