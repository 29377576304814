import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  GET_WORK_ORDERS_BY_ISSUE,
  GET_ISSUES,
  GET_ISSUES_BY_STATE,
  GET_ISSUES_BY_INVENTORY_ITEM
} from '../queries';
import { useIssuesDispatch } from 'modules/issues/hooks';
import { useInfo } from 'modules/authentication';
import { useHistory } from 'react-router-dom';

export const ADD_WORK_ORDER = gql`
  mutation addWorkOrder($objects: [work_order_insert_input!]!, $issueId: Int!) {
    insert_work_order(objects: $objects) {
      affected_rows
      returning {
        id
        issue_id
      }
    }
    update_issue(
      where: { id: { _eq: $issueId } }
      _set: { state: "inProgress" }
    ) {
      affected_rows
    }
  }
`;

export const useAddWorkOrder = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  const { orgId, userId } = useInfo();
  const history = useHistory();
  let [mutate, { error }] = useMutation(ADD_WORK_ORDER, {
    onCompleted: (data) => {
      openSuccessAlert('Orden de trabajo creada correctamente.');
      const { id, issue_id } = data.insert_work_order.returning[0];
      history.push(`/issues/${issue_id}/workorders/${id}`);
    },
    onError: () => openErrorAlert(error?.message)
  });
  return (args) => {
    return mutate({
      variables: {
        objects: args.map(
          ({
            title,
            description,
            issue_id,
            organization_id,
            priority,
            state,
            due_to_date
          }) => {
            return {
              title,
              description,
              issue_id,
              organization_id: organization_id || orgId,
              priority,
              processed_by_user_id: userId,
              state,
              due_to_date
            };
          }
        ),
        issueId: args[0].issue_id
      },
      refetchQueries: [
        {
          query: GET_WORK_ORDERS_BY_ISSUE,
          variables: { issueId: args[0].issue_id }
        },
        {
          query: GET_ISSUES
        },
        {
          query: GET_ISSUES_BY_STATE
        },
        {
          query: GET_ISSUES_BY_INVENTORY_ITEM
        }
      ]
    });
  };
};
