import React, { useState } from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Tooltip
} from '@material-ui/core';
import Link from 'components/Link';
import useClasses from 'hooks/useClasses';
import EditIcon from '@material-ui/icons/Edit';
import InformationModal from './InformationModal';
import Alert from 'components/Alert';

const styles = theme => ({
  root: {
    minHeight: 300,
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 }
});

const Information = props => {
  const classes = useClasses(styles);
  const { organization_user_id, user, city, country } = props;
  const [updated, setUpdated] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          title="Perfil"
          action={
            <Tooltip title="Editar">
              <IconButton
                size="small"
                onClick={() => setShowProfileModal(true)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <Divider />
        <CardContent className={classes.content}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell className={classes.data}>
                  {user ? (
                    <Link
                      title={`${user?.name} ${user?.surname}`}
                      to={`/users/workers/${organization_user_id}`}
                    />
                  ) : (
                    'Desconocido'
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Organización</TableCell>
                <TableCell className={classes.data}>
                  {user?.organization ? (
                    <Link
                      title={user?.organization?.name}
                      to={`/users/organizations/${user?.organization_id}`}
                    />
                  ) : (
                    'Desconocido'
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Correo electrónico</TableCell>
                <TableCell className={classes.data}>
                  {user?.email || 'Desconocido'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Teléfono</TableCell>
                <TableCell className={classes.data}>
                  {user?.phone || 'Desconocido'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ciudad</TableCell>
                <TableCell className={classes.data}>
                  {city || 'Desconocido'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>País</TableCell>
                <TableCell className={classes.data}>
                  {country || 'Desconocido'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <InformationModal
        open={showProfileModal}
        handleClose={() => setShowProfileModal(false)}
        information={props}
        fireOnClose={() =>
          setUpdated('Su configuración de usuario se actualizó correctamente.')
        }
      />
      <Alert show={updated} message={updated} severity="success" />
    </>
  );
};

export default Information;
