import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import img from 'assets/images/banco.jpg';
import Features from './Features';
import Workers from './Workers';
import Profiles from './Profiles';
import Avatar from './Avatar';
const useStyles = makeStyles(theme => ({
  root: { display: 'flex', flexWrap: 'wrap', height: '100%' },
  column: {
    display: 'flex',
    flexFirection: 'column'
  },
  media: {
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100%'
  },
  avatar: {
    height: 100,
    width: 100
  }
}));

const Summary = ({ organization = {} }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={3}>
            <Avatar organization={organization} />
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Features {...organization} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Profiles profiles={organization?.profiles} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Workers organization={organization} />
      </Grid>
    </Grid>
  );
};

export default Summary;
