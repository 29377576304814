import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector';
import { createStructuredSelector } from 'reselect';

const inventoryItemTypesSelector = (state) =>
  state.inventory.inventoryItemTypes;
const selectedInventoryItemSelector = (state) =>
  state.inventory.selectedInventoryItem;
const showInventoryItemModalSelector = (state) =>
  state.inventory.showInventoryItemModal;
const showDeleteInventoryItemModalSelector = (state) =>
  state.inventory.showDeleteInventoryItemModal;
const showInventoryUploadModalSelector = (state) =>
  state.inventory.showInventoryUploadModal;
const selectedFileSelector = (state) => state.inventory.selectedFile;
const selectedAreaSelector = (state) => state.inventory.selectedArea;
const showAreaModalSelector = (state) => state.inventory.showAreaModal;
const showDeleteAreaModalSelector = (state) =>
  state.inventory.showDeleteAreaModal;

const alertSelector = (state) => state.inventory.alert;
const importSubmittedSelector = (state) => state.inventory.importSubmitted;
const exportSubmittedSelector = (state) => state.inventory.exportSubmitted;
const showConfirmTypeModalSelector = (state) =>
  state.inventory.showConfirmTypeModal;

const selectors = createStructuredSelector({
  showInventoryItemModal: showInventoryItemModalSelector,
  showDeleteInventoryItemModal: showDeleteInventoryItemModalSelector,
  selectedInventoryItem: selectedInventoryItemSelector,
  inventoryItemTypes: inventoryItemTypesSelector,
  showInventoryUploadModal: showInventoryUploadModalSelector,
  selectedFile: selectedFileSelector,
  selectedArea: selectedAreaSelector,
  showAreaModal: showAreaModalSelector,
  showDeleteAreaModal: showDeleteAreaModalSelector,
  alert: alertSelector,
  importSubmitted: importSubmittedSelector,
  exportSubmitted: exportSubmittedSelector,
  showConfirmTypeModal: showConfirmTypeModalSelector
});

export function useInventoryState() {
  const inventoryModule = useShallowEqualSelector(selectors);
  return inventoryModule;
}
