import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';
import moment from 'moment';
import Link from 'components/Link';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  data: { fontWeight: 600 }
}));

const Features = props => {
  const classes = useStyles();
  const {
    date,
    organization_user,
    organizationUserByProcessedByUserId,
    elemento_inventario,
    solved_at
  } = props;
  return (
    <Card className={classes.root}>
      <CardHeader title="Datos" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Elemento</TableCell>
              <TableCell className={classes.data}>
                {elemento_inventario ? (
                  <Link
                    title={elemento_inventario.name}
                    to={
                      elemento_inventario.inventoryItemType === 'sensor'
                        ? `/sensors/${elemento_inventario.id}`
                        : `/inventory/${elemento_inventario.id}`
                    }
                  />
                ) : (
                  'Desconocido'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Informador</TableCell>
              <TableCell className={classes.data}>
                {organization_user
                  ? `${organization_user.name} ${organization_user.surname} `
                  : 'Desconocido'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Creada</TableCell>
              <TableCell className={classes.data}>
                {date ? moment(date).format('DD/MM/YYYY') : 'Desconocido'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Features;
