import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_MAINTENANCE_DETAILS } from '../queries';

export const ADD_TASK = gql`
  mutation addTask($objects: [work_order_task_insert_input!]!) {
    insert_work_order_task(objects: $objects) {
      affected_rows
    }
  }
`;

export const useAddTask = () => {
  let [mutate] = useMutation(ADD_TASK);

  return args => {
    return mutate({
      variables: {
        objects: args.map(
          ({
            description,
            due_to_date,
            in_charge_user_id,
            state,
            title,
            maintenanceId
          }) => {
            return {
              description,
              due_to_date,
              in_charge_user_id,
              state,
              title,
              maintenance_id: maintenanceId
            };
          }
        )
      },
      refetchQueries: [
        {
          query: GET_MAINTENANCE_DETAILS,
          variables: { id: args[0].maintenanceId }
        }
      ]
    });
  };
};
