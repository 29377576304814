import React from 'react';

const Sensor = (props) => {
  return (
    <svg width="50" height="50" viewBox="0 0 256 256" fill="none" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M124.216 98.313L34.028 241.076C31.789 244.62 33.7 250 37.197 250H127.385C129.663 250 131.51 247.564 131.51 244.559V101.797C131.509 96.708 126.685 94.404 124.216 98.313ZM119.135 228.236C119.135 231.241 117.288 233.677 115.01 233.677H63.616C60.119 233.677 58.209 228.297 60.447 224.753L111.841 143.399C114.311 139.489 119.134 141.793 119.134 146.882V228.236H119.135Z"
          fill="#FFC34C"
        />
        <path
          d="M62.028 241.076L66.702 233.677H63.616C60.119 233.677 58.209 228.297 60.447 224.753L111.841 143.399C114.311 139.489 119.134 141.793 119.134 146.882V150.679L131.508 131.091V101.797C131.508 96.708 126.684 94.404 124.214 98.314L34.028 241.076C31.789 244.62 33.7 250 37.197 250H65.197C61.7 250 59.789 244.62 62.028 241.076Z"
          fill="#FFB74B"
        />
        <path
          d="M108.132 7.968L102.939 13.184C71.698 44.562 71.698 95.436 102.939 126.814C134.18 158.192 184.831 158.192 216.072 126.814L221.265 121.598L108.132 7.968Z"
          fill="#FFB74B"
        />
        <path
          d="M130.939 126.814C102.694 98.445 99.991 54.144 122.818 22.719L108.132 7.969L102.939 13.185C71.698 44.563 71.698 95.436 102.939 126.814C122.135 146.095 148.658 153.508 173.505 149.094C157.916 146.324 142.984 138.911 130.939 126.814Z"
          fill="#FFA730"
        />
        <path
          d="M220.673 121.605C229.552 112.771 211.498 80.2287 180.348 48.92C149.199 17.6114 116.749 -0.60785 107.87 8.22622C98.9905 17.0603 117.044 49.6024 148.194 80.911C179.344 112.22 211.793 130.439 220.673 121.605Z"
          fill="#E58D23"
        />
        <path
          d="M187.341 31.608L121.388 49.357C122.664 51.171 124.009 53.013 125.42 54.878L185.442 38.724L169.125 99.888C170.954 101.372 172.768 102.799 174.561 104.162L192.527 36.817L187.341 31.608Z"
          fill="#FCE575"
        />
        <path
          d="M125.388 51.357L189.756 34.034L187.34 31.608L121.387 49.357C122.663 51.171 124.008 53.013 125.419 54.878L127.513 54.315C126.787 53.322 126.076 52.335 125.388 51.357Z"
          fill="#FFD551"
        />
        <path
          d="M195.159 40.927C201.737 40.927 207.069 35.571 207.069 28.964C207.069 22.357 201.737 17.001 195.159 17.001C188.581 17.001 183.249 22.357 183.249 28.964C183.249 35.571 188.581 40.927 195.159 40.927Z"
          fill="#FF624B"
        />
        <path
          d="M194.738 37.423C190.094 32.758 190.094 25.17 194.738 20.506C196.028 19.211 197.546 18.288 199.16 17.713C194.962 16.215 190.092 17.137 186.738 20.506C182.094 25.17 182.094 32.759 186.738 37.423C190.092 40.792 194.962 41.714 199.16 40.216C197.545 39.641 196.027 38.719 194.738 37.423Z"
          fill="#FF4B34"
        />
        <path
          d="M125.509 230C123.852 230 122.509 228.657 122.509 227V163.173C122.509 161.516 123.852 160.173 125.509 160.173C127.166 160.173 128.509 161.516 128.509 163.173V227C128.509 228.657 127.167 230 125.509 230Z"
          fill="#FFD551"
        />
        <path
          d="M119.135 76.885C124.106 76.885 128.135 72.8556 128.135 67.885C128.135 62.9144 124.106 58.885 119.135 58.885C114.164 58.885 110.135 62.9144 110.135 67.885C110.135 72.8556 114.164 76.885 119.135 76.885Z"
          fill="#FFA730"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="256" height="256" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SelectedSensor = (props) => (
  <svg width="50" height="50" viewBox="0 0 256 256" fill="none" {...props}>
    <g clipPath="url(#clip0)">
      <ellipse cx="84" cy="245" rx="66" ry="11" fill="#249A77" />
      <path
        d="M124.216 98.313L34.028 241.076C31.789 244.62 33.7 250 37.197 250H127.385C129.663 250 131.51 247.564 131.51 244.559V101.797C131.509 96.708 126.685 94.404 124.216 98.313ZM119.135 228.236C119.135 231.241 117.288 233.677 115.01 233.677H63.616C60.119 233.677 58.209 228.297 60.447 224.753L111.841 143.399C114.311 139.489 119.134 141.793 119.134 146.882V228.236H119.135Z"
        fill="#FFC34C"
      />
      <path
        d="M62.028 241.076L66.702 233.677H63.616C60.119 233.677 58.209 228.297 60.447 224.753L111.841 143.399C114.311 139.489 119.134 141.793 119.134 146.882V150.679L131.508 131.091V101.797C131.508 96.708 126.684 94.404 124.214 98.314L34.028 241.076C31.789 244.62 33.7 250 37.197 250H65.197C61.7 250 59.789 244.62 62.028 241.076Z"
        fill="#FFB74B"
      />
      <path
        d="M108.132 7.968L102.939 13.184C71.6981 44.562 71.6981 95.436 102.939 126.814C134.18 158.192 184.831 158.192 216.072 126.814L221.265 121.598L108.132 7.968Z"
        fill="#FFB74B"
      />
      <path
        d="M130.939 126.814C102.694 98.445 99.991 54.144 122.818 22.719L108.132 7.969L102.939 13.185C71.6981 44.563 71.6981 95.436 102.939 126.814C122.135 146.095 148.658 153.508 173.505 149.094C157.916 146.324 142.984 138.911 130.939 126.814Z"
        fill="#FFA730"
      />
      <path
        d="M220.673 121.605C229.552 112.771 211.498 80.2287 180.348 48.92C149.199 17.6114 116.749 -0.60785 107.87 8.22622C98.9906 17.0603 117.044 49.6024 148.194 80.911C179.344 112.22 211.793 130.439 220.673 121.605Z"
        fill="#E58D23"
      />
      <path
        d="M187.341 31.608L121.388 49.357C122.664 51.171 124.009 53.013 125.42 54.878L185.442 38.724L169.125 99.888C170.954 101.372 172.768 102.799 174.561 104.162L192.527 36.817L187.341 31.608Z"
        fill="#FCE575"
      />
      <path
        d="M125.388 51.357L189.756 34.034L187.34 31.608L121.387 49.357C122.663 51.171 124.008 53.013 125.419 54.878L127.513 54.315C126.787 53.322 126.076 52.335 125.388 51.357Z"
        fill="#FFD551"
      />
      <path
        d="M195.159 40.927C201.737 40.927 207.069 35.571 207.069 28.964C207.069 22.357 201.737 17.001 195.159 17.001C188.581 17.001 183.249 22.357 183.249 28.964C183.249 35.571 188.581 40.927 195.159 40.927Z"
        fill="#FF624B"
      />
      <path
        d="M194.738 37.423C190.094 32.758 190.094 25.17 194.738 20.506C196.028 19.211 197.546 18.288 199.16 17.713C194.962 16.215 190.092 17.137 186.738 20.506C182.094 25.17 182.094 32.759 186.738 37.423C190.092 40.792 194.962 41.714 199.16 40.216C197.545 39.641 196.027 38.719 194.738 37.423Z"
        fill="#FF4B34"
      />
      <path
        d="M125.509 230C123.852 230 122.509 228.657 122.509 227V163.173C122.509 161.516 123.852 160.173 125.509 160.173C127.166 160.173 128.509 161.516 128.509 163.173V227C128.509 228.657 127.167 230 125.509 230Z"
        fill="#FFD551"
      />
      <path
        d="M119.135 76.885C124.106 76.885 128.135 72.8556 128.135 67.885C128.135 62.9144 124.106 58.885 119.135 58.885C114.164 58.885 110.135 62.9144 110.135 67.885C110.135 72.8556 114.164 76.885 119.135 76.885Z"
        fill="#FFA730"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="256" height="256" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Sensor;
