import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedChat: undefined,
  showDeleteChatModal: false
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    loadChat: () => initialState,
    selectChat: (state, { payload }) => ({
      ...state,
      selectedChat: payload
    }),
    openDeleteChatModal: (state, { payload }) => ({
      ...state,
      selectedChat: payload,
      showDeleteChatModal: true
    }),
    closeDeleteChatModal: state => ({
      ...state,
      selectedChat: undefined,
      showDeleteChatModal: false
    })
  }
});

export const {
  loadChat,
  selectChat,
  openDeleteChatModal,
  closeDeleteChatModal
} = chatSlice.actions;

export default chatSlice.reducer;
