import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedMaintenance: undefined,
  showMaintenanceModal: false,
  showDeleteMaintenanceModal: false,
  selectedTask: undefined,
  showTaskModal: false,
  showDeleteTaskModal: false
};

const maintenancesSlice = createSlice({
  name: 'maintenances',
  initialState,
  reducers: {
    loadMaintenances: () => initialState,
    selectMaintenance: (state, { payload }) => ({
      ...state,
      selectedMaintenance: payload
    }),
    openMaintenanceModal: (state, { payload }) => ({
      ...state,
      selectedMaintenance: payload,
      showMaintenanceModal: true
    }),
    closeMaintenanceModal: state => ({
      ...state,
      selectedMaintenance: undefined,
      showMaintenanceModal: false
    }),
    openDeleteMaintenanceModal: (state, { payload }) => ({
      ...state,
      selectedMaintenance: payload,
      showDeleteMaintenanceModal: true
    }),
    closeDeleteMaintenanceModal: state => ({
      ...state,
      selectedMaintenance: undefined,
      showDeleteMaintenanceModal: false
    }),
    selectTask: (state, { payload }) => ({
      ...state,
      selectedTask: payload
    }),
    openTaskModal: (state, { payload }) => ({
      ...state,
      selectedTask: payload,
      showTaskModal: true
    }),
    closeTaskModal: state => ({
      ...state,
      selectedTask: undefined,
      showTaskModal: false
    }),
    openDeleteTaskModal: (state, { payload }) => ({
      ...state,
      selectedTask: payload,
      showDeleteTaskModal: true
    }),
    closeDeleteTaskModal: state => ({
      ...state,
      selectedTask: undefined,
      showDeleteTaskModal: false
    })
  }
});

export const {
  loadMaintenances,
  selectMaintenance,
  openMaintenanceModal,
  closeMaintenanceModal,
  openDeleteMaintenanceModal,
  closeDeleteMaintenanceModal,
  selectTask,
  openTaskModal,
  closeTaskModal,
  openDeleteTaskModal,
  closeDeleteTaskModal
} = maintenancesSlice.actions;

export default maintenancesSlice.reducer;
