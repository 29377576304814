import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const GET_SETTINGS = gql`
  query getSettings {
    settings: global_settings(limit: 1) {
      id
      location
      city
      province
      country
    }
  }
`;

export const useGetSettings = () => {
  const query = useQuery(GET_SETTINGS);
  return query;
};
