import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const initialCoordinates = [
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0]
];
export const GET_AREAS = gql`
  query getAreas($level: Int, $geometry: geometry) {
    areas: area(where: { location: { _st_within: $geometry } }) {
      id
      name
      location
      ground_type_id
      ground_type {
        id
        name
      }
    }
  }
`;

export const useGetAreas = (
  coordinates,
  level = 1,
  zoom = 14,
  signal = undefined
) => {
  let geometry = {
    type: 'Polygon',
    crs: {
      type: 'name',
      properties: {
        name: 'urn:ogc:def:crs:EPSG::4326'
      }
    },
    coordinates: [coordinates ? coordinates : initialCoordinates]
  };
  const query = useQuery(GET_AREAS, {
    variables: {
      level,
      geometry
    },
    context: {
      fetchOptions: {
        signal
      }
    }
  });

  return query;
};
