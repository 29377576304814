import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
import View from 'components/View';
import ChatList from './ChatList';
import ChatDetails from './ChatDetails';
import Placeholder from './Placeholder';
import { useGetMessagesByChat } from 'modules/chat/graphql/subscriptions';
import useRouter from 'hooks/useRouter';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    overflow: 'hidden',
    '@media (max-width: 863px)': {
      '& $list, & $details': {
        flexBasis: '100%',
        width: '100%',
        maxWidth: 'none',
        flexShrink: '0',
        transform: 'translateX(0)'
      }
    }
  },
  openChat: {
    '@media (max-width: 863px)': {
      '& $list, & $details': {
        transform: 'translateX(-100%)'
      }
    }
  },
  list: {
    width: 300,
    flexBasis: 300,
    flexShrink: 0,
    '@media (min-width: 864px)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  },
  details: {
    flexGrow: 1
  },
  placeholder: {
    flexGrow: 1
  }
}));

function Chat() {
  const classes = useStyles();
  const { data } = useGetMessagesByChat();
  const { match } = useRouter();

  return (
    <View title="Chat">
      <Card
        className={clsx({
          [classes.root]: true,
          [classes.openChat]: data?.messages
        })}>
        <ChatList chats={data?.chats} className={classes.list} />
        {match?.params?.id ? (
          <ChatDetails className={classes.details} messages={data?.messages} />
        ) : (
          <Placeholder className={classes.placeholder} />
        )}
      </Card>
    </View>
  );
}

export default Chat;
