import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    height: 200
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ErrorModal = ({ open, close, message }) => {
  const classes = useStyles();

  return (
    <Modal onClose={close} open={open}>
      <Card className={classes.root}>
        <CardHeader title="Error" />
        <Divider />
        <CardContent>
          <Typography variant="body1">
            {message || 'No se pudo completar la acción deseada.'}
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button color="secondary" onClick={close} variant="contained">
            Aceptar
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ErrorModal;
