import React from 'react';
import Table from 'components/Table';
import Link from 'components/Link';
import Actions from './Actions';
import { useGetProfiles } from 'modules/users';
const columns = [
  {
    Header: 'Nombre',
    accessor: data => data,
    Cell: ({ cell: { value } }) => (
      <Link to={`/users/profiles/${value.id}`} title={value.name} />
    )
  },

  {
    Header: '',
    id: 'actions',
    accessor: data => data,
    Cell: ({ cell: { value } }) => <Actions profile={value} />
  }
];

const Profiles = () => {
  const { data, ...rest } = useGetProfiles();
  return (
    <Table
      columns={columns}
      data={data?.profiles}
      count={data?.profilesCount?.aggregate.count}
      {...rest}
    />
  );
};

export default Profiles;
