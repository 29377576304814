import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_TASK_DETAILS } from '../queries';
import { useIssuesDispatch } from 'modules/issues';

export const UPDATE_REPORT = gql`
  mutation updateReport($id: Int!, $notes: String, $hours: Int) {
    update_task_report(
      where: { id: { _eq: $id } }
      _set: { notes: $notes, hours: $hours }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateReport = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(UPDATE_REPORT, {
    onCompleted: () => openSuccessAlert('Reporte actualizado correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });
  return args => {
    const { id, notes, hours, task_id } = args;
    return mutate({
      variables: {
        id,
        notes,
        hours
      },
      refetchQueries: [
        {
          query: GET_TASK_DETAILS,
          variables: { id: task_id }
        }
      ]
    });
  };
};
