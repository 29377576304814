import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button
} from '@material-ui/core';
import {
  useMaintenancesState,
  useMaintenancesDispatch,
  useMaintenancesMutation
} from 'modules/maintenances';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    height: 200
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ConfirmDelete = () => {
  const classes = useStyles();
  const {
    showDeleteMaintenanceModal,
    selectedMaintenance
  } = useMaintenancesState();
  const { deleteMaintenance } = useMaintenancesMutation();
  const { closeDeleteMaintenanceModal } = useMaintenancesDispatch();

  if (!showDeleteMaintenanceModal) {
    return null;
  }

  return (
    <Modal
      onClose={closeDeleteMaintenanceModal}
      open={showDeleteMaintenanceModal}>
      <Card className={classes.root}>
        <CardHeader title="¿Eliminar mantenimiento?" />
        <Divider />
        <CardContent>
          <Typography variant="body1">
            Si elimina el mantenimiento seleccionado, todos las tareas asociadas
            se eliminarán. No podrá recuperarlos más tarde.
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button onClick={closeDeleteMaintenanceModal}>Cancelar</Button>
          <Button
            color="secondary"
            onClick={async () => {
              await deleteMaintenance({
                id: selectedMaintenance.id,
                inventoryItemId: selectedMaintenance.resource_id
              });
              closeDeleteMaintenanceModal();
            }}
            variant="contained">
            Sí, eliminar
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ConfirmDelete;
