import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { SENSOR_FRAGMENT } from '../fragments';
import { INVENTORY_ITEM_FRAGMENT } from 'modules/inventory/graphql/fragments';
import useRouter from 'hooks/useRouter';
import { ISSUE_FRAGMENT } from 'modules/issues/graphql/fragments';
import { useRoles } from 'modules/authentication';

export const GET_SENSOR_DETAILS = gql`
  query getSensorDetails($id: Int!, $date: timestamp) {
    sensor: elemento_inventario_by_pk(id: $id) {
      ...inventoryItemFields
      sensor {
        ...sensorFields
        status: datastreams(
          where: { observation_values: { time: { _gte: $date } } }
        ) {
          id
        }
        lastUpdate: datastreams {
          observation_values(order_by: { time: desc }, limit: 1) {
            time
          }
        }
      }
      issues(order_by: { date: desc }) {
        ...issueFields
      }
    }
  }
  ${INVENTORY_ITEM_FRAGMENT}
  ${SENSOR_FRAGMENT}
  ${ISSUE_FRAGMENT}
`;

export const useGetSensorDetails = () => {
  const date = new Date().toDateString();
  const { sensors__administrator } = useRoles();
  const { match } = useRouter();
  const { id } = match.params || {};
  const { data, error, loading } = useQuery(GET_SENSOR_DETAILS, {
    variables: { id, date }
  });
  return {
    data,
    error,
    loading
  };
};
