import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GET_WORK_ORDER_DETAILS } from '../queries';
import { useIssuesDispatch } from 'modules/issues';

export const DELETE_TASK = gql`
  mutation deleteTask($id: Int, $workOrderId: Int) {
    delete_work_order_task(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const useDeleteTask = () => {
  const { openSuccessAlert, openErrorAlert } = useIssuesDispatch();
  let [mutate, { error }] = useMutation(DELETE_TASK, {
    onCompleted: () => openSuccessAlert('Tarea eliminada correctamente.'),
    onError: () => openErrorAlert(error?.message)
  });

  return ({ id, workOrderId }) => {
    return mutate({
      variables: { id },
      refetchQueries: [
        {
          query: GET_WORK_ORDER_DETAILS,
          variables: { id: workOrderId }
        }
      ]
    });
  };
};
